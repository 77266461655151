import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Container from "../FullPageContainer";
import { useAuth } from "../contexts/Auth";

const MessageWrapper = styled.div`
  background: #fff;
  padding: 20px 32px;
  border-radius: 4px;
  margin: 20px 0;
  border: 1px solid rgba(118, 132, 158, 0.2);
  box-shadow: 0px 1px 2px rgba(118, 132, 158, 0.1),
    inset 0px 0px 1px rgba(118, 132, 158, 0.03);
`;

const A = styled.a`
  cursor: pointer;
  color: ${p => p.theme.colors.blue};
`;

export default function ForbiddenMessage({
  location
}: Partial<RouteComponentProps<{}>>): React.ReactElement {
  const { logOut } = useAuth();

  function handleLogout(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    return logOut();
  }

  return (
    <Container>
      <MessageWrapper>
        You do not have permission to access this page.{" "}
        <A onClick={handleLogout}>Log out.</A>
      </MessageWrapper>
    </Container>
  );
}
