import React from "react";
import moment from "moment";
import Searchbar from "./Searchbar";
import UserAvatar from "./UserAvatar";
import Moment from "react-moment";
import Image from "./Image";
import * as S from "./styles";
import { useMutation } from "@apollo/react-hooks";
import Tags, { Tag } from "./Tags";
import PageNav, {
  NavInfo,
  BackButton,
  TopPageNav,
  LocalNavA,
  LocalNav,
} from "./PageNav";
import { match } from "react-router-dom";
import { archiveStudentDesignSubmissionMutation } from "./mutations/studentDesignSubmission";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { StudentDesignSubmission, UseMutationReturn } from "./apiTypes";

type StudentDesignNavProps = {
  studentDesignSubmission: StudentDesignSubmission;
  match: match<{ id: string }>;
};

function EditStudentDesignMenu({
  studentDesignSubmission,
}: {
  studentDesignSubmission: StudentDesignSubmission;
}) {
  const menu = useMenuState();

  const [archiveStudentDesignSubmission]: UseMutationReturn<
    { archiveStudentDesignSubmission: StudentDesignSubmission },
    { input: { submissionId: string } }
  > = useMutation(archiveStudentDesignSubmissionMutation, {
    refetchQueries: ["StudentDesignSubmissionQuery"],
  });
  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Award
      </MenuDisclosure>
      <Menu {...menu}>
        <MenuItem
          {...menu}
          onClick={async () => {
            if (
              window.confirm(
                "Are you sure you want to archive this Student Design Challenge entry?"
              )
            ) {
              await archiveStudentDesignSubmission({
                variables: {
                  input: { submissionId: studentDesignSubmission.id },
                },
              });
              menu.hide();
            }
          }}
        >
          Archive Entry
        </MenuItem>
      </Menu>
    </>
  );
}

const StudentDesignNav: React.FunctionComponent<StudentDesignNavProps> = ({
  match,
  studentDesignSubmission,
}) => {
  return (
    <PageNav>
      <S.Box py={3} mx={3} my={0}>
        <TopPageNav />
        <NavInfo>
          <S.Flex width={5 / 6} ml={3}>
            <Image src="" />
            <div>
              <S.H1>
                {studentDesignSubmission.name}
                <span>
                  {" "}
                  submitted{" "}
                  <Moment format="MMMM D, YYYY">
                    {studentDesignSubmission.insertedAt}
                  </Moment>
                </span>
              </S.H1>
              <Tags>
                {studentDesignSubmission.status &&
                studentDesignSubmission.status === "APPROVED" ? (
                  <>
                    <Tag color="lightblue">Approved</Tag>
                    {studentDesignSubmission.writtenDefense ? (
                      <Tag color="lightgreen">Written Defense Received</Tag>
                    ) : (
                      <Tag color="lightyellow">Awaiting Written Defense</Tag>
                    )}
                    {studentDesignSubmission.samplesReceived ? (
                      <Tag color="lightgreen">Samples Received</Tag>
                    ) : (
                      <Tag color="lightyellow">Awaiting Samples</Tag>
                    )}
                  </>
                ) : studentDesignSubmission.status &&
                  studentDesignSubmission.status === "REJECTED" ? (
                  <Tag color="lightorange">Rejected</Tag>
                ) : (
                  <Tag color="lightyellow">Pending</Tag>
                )}
                {studentDesignSubmission.awardTier &&
                studentDesignSubmission.awardTier === 1 ? (
                  <Tag color="gold">First Place</Tag>
                ) : studentDesignSubmission.awardTier &&
                  studentDesignSubmission.awardTier === 2 ? (
                  <Tag color="silver">Second Place</Tag>
                ) : studentDesignSubmission.awardTier &&
                  studentDesignSubmission.awardTier === 3 ? (
                  <Tag color="bronze">Third Place</Tag>
                ) : (
                  <></>
                )}
              </Tags>
            </div>
          </S.Flex>
          <EditStudentDesignMenu
            studentDesignSubmission={studentDesignSubmission}
          />
        </NavInfo>
      </S.Box>
    </PageNav>
  );
};

export default StudentDesignNav;
