import React from "react";
import Searchbar from "./Searchbar";
import * as S from "./styles";
import * as SVG from "./svgs";
import moment from "moment";
import { Link } from "react-router-dom";
import Currency from "react-currency-formatter";
import PageNav, { NavInfo, BackButton, TopPageNav } from "./PageNav";
import { Invoice } from "./apiTypes";
import { PrimaryEmail } from "./Emails";
import { PrimaryAddress } from "./Addresses";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";

type InvoiceNavProps = { invoice: Invoice };

function EditInvoiceMenu() {
  const menu = useMenuState();

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Invoice
      </MenuDisclosure>
      <Menu {...menu}>
        <MenuItem {...menu}>Receive Payment</MenuItem>
        <MenuItem {...menu}>Send Reminder</MenuItem>
        <MenuItem {...menu}>Edit Invoice</MenuItem>
        <MenuItem {...menu}>Print Invoice</MenuItem>
        <MenuItem {...menu}>Open In Quickbooks</MenuItem>
      </Menu>
    </>
  );
}

const InvoiceNav: React.FunctionComponent<InvoiceNavProps> = ({ invoice }) => {
  // var totalPaid = invoice.payments ? invoice.payments.reduce((acc, curr) => { var paid = curr.succeeded ? curr.amount : 0; return paid + acc;});
  var balanceDue = invoice.paidAt ? 0 : 0;
  return (
    <PageNav>
      <S.Box pt={3} mx={3} my={0}>
        <S.Flex justifyContent="space-between">
          <TopPageNav />
          <div>
            <S.H4>Balance Due</S.H4>
            <S.H1>
              <Currency quantity={invoice.total ? invoice.total : 0} />
            </S.H1>
          </div>
        </S.Flex>
        <NavInfo>
          <S.Flex ml={3} width={1} justifyContent="space-between">
            <S.Box width={1 / 4}>
              <S.H4>INVOICE #0000000</S.H4>
              <S.Flex alignItems="center">
                <SVG.CrossSvg />
                <S.H1>{invoice.status}</S.H1>
              </S.Flex>
            </S.Box>
            <S.Box width={1 / 4}>
              <S.H4>Billing Information</S.H4>
              {invoice.contact ? (
                <>
                  <Link to={`/contacts/${invoice.contact.id}`}>
                    {invoice.contact.firstName} {invoice.contact.lastName}
                  </Link>
                  <PrimaryAddress subject={invoice.contact} />
                  <PrimaryEmail subject={invoice.contact} />
                </>
              ) : (
                <></>
              )}
              {invoice.company ? (
                <>
                  <Link to={`/companies/${invoice.company.id}`}>
                    {invoice.company.name}
                  </Link>
                  <PrimaryAddress subject={invoice.company} />
                  <PrimaryEmail subject={invoice.company} />
                </>
              ) : (
                <></>
              )}
              <S.P>
                <b>Last Delivery:</b> June 21, 2019.
              </S.P>
            </S.Box>
            <S.Box width={1 / 4}>
              <S.H4 style={{ marginBottom: "14px" }}>
                Creation Date
                <span>
                  {moment(invoice.insertedAt).format("MM/DD/YYYY h:mma")}
                </span>
              </S.H4>
              <S.H4 style={{ marginBottom: "14px" }}>
                Finalization Date
                <span>
                  {moment(invoice.finalizedAt).format("MM/DD/YYYY h:mma")}
                </span>
              </S.H4>
              <S.H4 style={{ marginBottom: "14px" }}>
                Due Date
                <span>{moment(invoice.dueOn).format("MM/DD/YYYY h:mma")}</span>
              </S.H4>
              <S.H4 style={{ marginBottom: "14px" }}>
                Paid On Date
                <span>{moment(invoice.paidAt).format("MM/DD/YYYY h:mma")}</span>
              </S.H4>
              <S.H4 style={{ marginBottom: "14px" }}>
                Void Date
                <span>
                  {moment(invoice.voidedAt).format("MM/DD/YYYY h:mma")}
                </span>
              </S.H4>
              <S.H4 style={{ marginBottom: "14px" }}>
                Marked Uncollectible Date
                <span>
                  {moment(invoice.markedUncollectibleAt).format(
                    "MM/DD/YYYY h:mma"
                  )}
                </span>
              </S.H4>
            </S.Box>
            <EditInvoiceMenu />
          </S.Flex>
        </NavInfo>
      </S.Box>
    </PageNav>
  );
};

export default InvoiceNav;
