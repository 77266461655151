import React, { useState, useCallback } from "react";
import Tags, { Tag } from "./Tags";
import Avatar, { AvatarList } from "./UserAvatar";
import styled from "styled-components";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Select as FormSelect } from "./StyledForm";
import Select, { ValueType } from "react-select";
import Page, { PageButtons } from "./Page";
import NewCompany from "./NewCompany";
import { CompanyTags } from "./Company";
import { Link, useLocation } from "react-router-dom";
import { primaryContact, authorizedContacts } from "./util";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import { UrlList, PrimaryUrl } from "./Urls";
import { Logo } from "./Image";
import { PhoneNumberList, PrimaryPhoneNumber } from "./PhoneNumbers";
import { PrimaryEmail, EmailList } from "./Emails";
import { PrimaryAddress } from "./Addresses";
import { companyListQuery, companySearchQuery } from "./queries/company";
import { useQuery } from "@apollo/react-hooks";
import { mapProps } from 'styled-system';
import Location from "./Location";
import NewLocation from "./NewLocation";
import { LocationListQuery } from "./queries/location";
import { Query } from "@apollo/react-components";
import { Company, SortValue, FilterValue, LocationsListQueryData } from "./apiTypes";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";

const CompanyPlantsWrapper = styled.div`
  @media screen and (max-width: 1550px) {
    ${S.Box} {
      width: 50%;
    }
  }
  @media screen and (max-width: 900px) {
    ${S.Box} {
      width: 100%;
    }
  }
`;

type LocationsProps = {
  companyId?: string;
};

const Locations: React.FunctionComponent<LocationsProps> = ({
  companyId,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const pageSize = 24;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;
  const newDialog = useDialogState();
  const sortOptions = [
    { label: "Name A-Z", value: "NAME_ASC" },
    { label: "Name Z-A", value: "NAME_DESC" },
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
  ];
  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    companyId: companyId || undefined,
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "NAME_ASC",
  };

  const { loading, error, data } = useQuery<LocationsListQueryData>(
    LocationListQuery,
    { variables: queryVars }
  );

  if (loading && !data) return <div />;
  if (error) return <div>Oops.</div>;
  if (!data || !data.locations || !Array.isArray(data.locations.edges))
    return <div>No data.</div>;

  return (
    <>
      <S.Flex mx={3} justifyContent="space-between">
        <S.Title>
          Total Plants: {data.locations.count}
        </S.Title>
        <DialogDisclosure {...newDialog} as={S.Button}>
          Add New Plant
        </DialogDisclosure>
      </S.Flex>
      <S.Flex justifyContent="space-between" alignItems="flex-start" mx={3}>
        <div style={{ flex: 1, marginRight: 20 }}>
          <label>SORT BY</label>
          <Select
            closeMenuOnSelect={true}
            onChange={handleSort}
            options={sortOptions}
            value={sort}
          />
        </div>
      </S.Flex>
      <PageButtons
        topMargin={4}
        pageInfo={data.locations.pageInfo}
        basePath={companyId ? `companies/${companyId}/plants` : `plants`}
      />
      <S.Flex flexWrap="wrap">
        {data.locations.edges.map((edge) => {
          const location = edge.node;
          if (!location) return null;
          return (
            <S.Box key={location.id} width={1 / 3} mx={0} my={0}>
              <Location location={location} />
            </S.Box>
          );
        })}
      </S.Flex>
      <PageButtons pageInfo={data.locations.pageInfo} basePath={`companies/${companyId}/plants`} />
      {companyId && 
      <DialogWithBackdrop {...newDialog}>
        <NewLocation
          closeModal={() => newDialog.hide()}
          companyId={companyId}
        />
      </DialogWithBackdrop> }
    </>
  );
};

const LocationSelect = (props: { name: string; companyId?: string }) => {
  const formik = useFormikContext<any>();
  const { data, loading, error } = useQuery<LocationsListQueryData>(
    LocationListQuery,
    {
      variables: { first: 100, companyId: props.companyId },
    }
  );

  const handleChange = useCallback(
    ({ value }: any) => {
      formik.setFieldValue(props.name, value);
    },
    [props.name, formik.setFieldValue]
  );

  if (loading && !data) return <div />;
  if (error) return <div>Oops.</div>;
  if (!data || !data.locations || !Array.isArray(data.locations.edges))
    return <div>No data.</div>;

  var locationsList = data.locations.edges
    .map((edge) => {
      const location = edge.node;
      if (!location) return null;
      return { label: location.name, value: location.id };
    })
    .filter(Boolean) as ValueType<{ label: string; value: string }>[];

  return (
    <Field
      id="locationId"
      name="locationId"
      component={FormSelect}
      closeMenuOnSelect={true}
      options={locationsList}
      onChange={handleChange}
    />
  );
};

export { LocationSelect };
export default Locations;