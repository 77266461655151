import React, { useMemo, useContext } from "react";
import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";
import * as S from "./styles";

type TableContextValue = {
  cols?: string;
};
type TableProps = {
  cols?: string;
} & SpaceProps;

const TableContext = React.createContext<TableContextValue>({ cols: "" });

const StyledTable = styled.div`
  display: block;
  ${space}
`;

const Table: React.FunctionComponent<TableProps> = ({ cols, ...props }) => {
  const tableContextValue = useMemo<TableContextValue>(
    () => ({
      cols: cols || ""
    }),
    [cols]
  );

  return (
    <TableContext.Provider value={tableContextValue}>
      <StyledTable {...props} />
    </TableContext.Provider>
  );
};

type TableRowProps = {
  borderless?: boolean;
  header?: boolean;
  cols?: string;
} & SpaceProps;

const gridGap = "14px";

const TableRow: React.FunctionComponent<TableRowProps> = ({
  cols,
  ...props
}) => {
  const sharedContext = useContext(TableContext);
  return <StyledTableRow {...props} cols={sharedContext.cols} />;
};

const StyledTableRow = styled.div<TableRowProps>`
  display: grid;
  grid-column-gap: ${gridGap};
  grid-template-columns: ${p =>
    p.cols || "repeat(auto-fit, minmax(200px, 1fr))"};
  border-bottom: 1px solid rgba(118, 132, 158, 0.2);
  position: relative;
  ${space}
  ${p =>
    p.header &&
    css`
      font-weight: bold;
    `}
  ${p =>
    p.borderless &&
    css`
      border: none;
    `}
  &:last-child {
    border: none;
  }
`;

type TableCellProps = {
  right?: boolean;
  left?: boolean;
};

const TableCell = styled.div<TableCellProps>`
  display: flex;
  padding: 4px 0;
  align-items: center;
  line-height: 1;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }

  b {
    font-weight: 500;
    font-color: ${p => p.theme.colors.grey};
  }
  ${p =>
    p.right &&
    css`
      justify-content: flex-end;
    `}

    ${p =>
      p.left &&
      css`
        &:first-child {
          padding-left: 0;
        }
      `}

  ${S.Title} {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 500;
    color: ${p => p.theme.colors.grey};
    span {
      margin-top: 5px;
      display: block;
      font-weight: 400;
      font-size: 80%;
    }
  }
  span.crossed {
    display: block;
    font-size: 80%;
    text-decoration: line-through;
  }
`;

export default Table;
export { TableRow as TR, TableCell as TD };
