import React, { useState } from "react";
import * as S from "./styles";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { __InputValue } from "graphql";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import { createDownloadMutation } from "./mutations/download";
import { NewDownloadValues } from "./apiTypes";
import FileInput from "./FileInput";

interface NewItemData {
  id: string;
}

const NewDownload: React.FC<{
  loading?: boolean;
  onSubmit: (
    input: NewDownloadValues
  ) => Promise<void | MutationFetchResult<{ createDownload: NewItemData }>>;
}> = ({ loading, onSubmit }) => {
  const [newId, setNewId] = useState<string | null>(null);
  const handleSubmit = async (input: NewDownloadValues) => {
    try {
      const result = await onSubmit(input);
      if (!result) throw "Could not create file for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createDownload &&
        result.data.createDownload.id
      )
        return setNewId(result.data.createDownload.id);
      throw "Could not create file for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }
  };

  if (newId) return <Redirect to={`/files/${newId}`} />;

  return (
    <Formik
      initialValues={{
        name: "",
        file: undefined,
        description: "",
        report: false,
        fpaNow: false,
        acl: "PUBLIC",
      }}
      onSubmit={handleSubmit}
      render={(values) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New File</S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              <label htmlFor="name">
                File Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="name"
                name="name"
                placeholder="File Name"
                type="text"
              />
              <label htmlFor="logoUrl">
                File
                <span style={{ color: "red", display: "inline-block" }}>*</span>
                <span>May not exceed 8MB in size.</span>
              </label>
              <Field id="file" name="file" component={FileInput} required />
              <S.Flex>
                <Field id="fpaNow" name="fpaNow" type="checkbox" />
                <label htmlFor="fpaNow">This is a FPA NOW document.</label>
              </S.Flex>
              <S.Flex>
                <Field id="report" name="report" type="checkbox" />
                <label htmlFor="report">This is a Report.</label>
              </S.Flex>
              <label htmlFor="acl">File Access Level</label>
              <Field
                required
                id="acl"
                name="acl"
                component={Select}
                closeMenuOnSelect={true}
                options={[
                  {
                    label: "Open To the Public",
                    value: "PUBLIC",
                  },
                  {
                    label: "Members Only",
                    value: "MEMBERS",
                  },
                  { label: "Closed Access; Private Only.", value: "PRIVATE" },
                ]}
              />
              <label htmlFor="description">Description</label>
              <Field id="description" name="description" component="textarea" />
              <S.Button type="submit" disabled={loading}>
                Creat{loading ? "ing" : "e"} File
              </S.Button>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
};

const ConnectedNewDownload = (props: {}): React.ReactElement => {
  return (
    <Mutation<{ createDownload: NewItemData }, { input: NewDownloadValues }>
      mutation={createDownloadMutation}
    >
      {(createDownload, { loading }) => (
        <NewDownload
          {...props}
          onSubmit={(input) => createDownload({ variables: { input } })}
          loading={loading}
        />
      )}
    </Mutation>
  );
};

export default ConnectedNewDownload;
