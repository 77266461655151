import React, { useState } from "react";
import moment from "moment";
import FileUpload from "./FileUpload";
import * as S from "./styles";
import { Formik, Field, Form } from "formik";
import { CompanySelect } from "./Companies";
import { ContactListSelect } from "./Contacts";
import { Mutation, Query } from "@apollo/react-components";
import { useMutation } from "@apollo/react-hooks";
import { __InputValue } from "graphql";
import EditableContent from "./EditableContent";
import { AddressDisplay, AddressForm } from "./Addresses";
import { primaryAssociation, labelledAssociation } from "./util";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import {
  Contact,
  Company,
  NewItemData,
  NewUserValues,
  NewCompanyValues,
  NewContactValues,
  NewCompanyContactValues,
} from "./apiTypes";
import { createCompanyContactMutation } from "./mutations/companyContact";
import { createContactMutation } from "./mutations/contact";
import { createCompanyMutation } from "./mutations/company";
import { createEmailAddressMutation } from "./mutations/emailAddress";
import { createPhoneNumberMutation } from "./mutations/phoneNumber";
import { createAddressMutation } from "./mutations/address";
import { createUrlMutation } from "./mutations/url";
import { createUserMutation } from "./mutations/user";

type NewContactMembershipValues = {
  new: boolean;
  companyId: string;
  contactId: string;
  startedOn: string;
  endedOn: string;
  title: string;
  authorizedRepresentative: boolean;
  primaryContact: boolean;
  secondaryContact: boolean;
  billingContact: boolean;
  contactLevel: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  url: string;
  imageId?: string;
  address?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  state?: string;
  streetAddress?: string;
  suiteNumber?: string;
};

const NewContactMembership = ({
  company,
  closeFunction: hideModal,
}: {
  company: Company;
  closeFunction: () => void;
}) => {

  const mainAddress = labelledAssociation("main", company.addresses) || {};
  const billingAddress = labelledAssociation("billing", company.addresses) || {};

  const [createContact, { loading: contactLoading }] = useMutation<
    { createContact: { id: string } },
    { input: NewContactValues }
  >(createContactMutation);
  const [createAddress] = useMutation(createAddressMutation);
  const [createEmailAddress] = useMutation(createEmailAddressMutation);
  const [createPhoneNumber] = useMutation(createPhoneNumberMutation);
  const [createUrl] = useMutation(createUrlMutation);
  const makeContact = async (input: NewContactValues): Promise<string> => {
    try {
      const result = await createContact({ variables: { input } });
      if (!result) throw "Could not create location for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createContact &&
        result.data.createContact.id
      )
        return result.data.createContact.id;
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }

    throw "Could not create contact for unknown reason.";
  };
  
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  return (
    <Mutation<NewItemData, { input: NewCompanyContactValues }>
      mutation={createCompanyContactMutation}
      refetchQueries={["companyQuery", "ContactQuery"]}
    >
      {(createCompanyContact, { loading }) => (
        <Formik<NewContactMembershipValues>
          initialValues={{
            new: false,
            companyId: company.id,
            contactId: "",
            startedOn: "",
            endedOn: "",
            title: "",
            authorizedRepresentative: false,
            primaryContact: false,
            secondaryContact: false,
            billingContact: false,
            contactLevel: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            emailAddress: "",
            url: "",
            imageId: undefined,
            address: "main",
            city: undefined,
            country: undefined,
            postalCode: undefined,
            state: undefined,
            streetAddress: undefined,
            suiteNumber: undefined,
          }}
          onSubmit={async (values) => {
            if (values.contactLevel === "primary/billing") {
              values.billingContact = true;
              values.primaryContact = true;
              values.secondaryContact = false;
              values.authorizedRepresentative = true;
            } else if (values.contactLevel === "secondary/billing") {
              values.billingContact = true;
              values.secondaryContact = true;
              values.primaryContact = false;
              values.authorizedRepresentative = true;
            } else if (values.contactLevel === "primary") {
              values.billingContact = false;
              values.primaryContact = true;
              values.secondaryContact = false;
              values.authorizedRepresentative = true;
            } else if (values.contactLevel === "secondary") {
              values.billingContact = false;
              values.secondaryContact = true;
              values.primaryContact = false;
              values.authorizedRepresentative = true;
            } else if (values.contactLevel === "billing") {
              values.billingContact = true;
              values.secondaryContact = false;
              values.primaryContact = false;
              values.authorizedRepresentative = true;
            } else if (values.contactLevel === "authorized") {
              values.billingContact = false;
              values.secondaryContact = false;
              values.primaryContact = false;
              values.authorizedRepresentative = true;
            } else {
              values.billingContact = false;
              values.secondaryContact = false;
              values.primaryContact = false;
              values.authorizedRepresentative = false;
            }
            if (values.startedOn) {
              values.startedOn = moment(values.startedOn).format("YYYY-MM-DD");
            }
            if (values.endedOn) {
              values.endedOn = moment(values.endedOn).format("YYYY-MM-DD");
            }
            let contactId;
            if (values.new) {
              try {
                contactId = await makeContact({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  photoId: values.imageId,
                });
                if (values.emailAddress !== "") {
                  await createEmailAddress({
                    variables: {
                      input: {
                        emailAddress: values.emailAddress,
                        label: "main",
                        preferred: true,
                        subjectId: contactId,
                      },
                    },
                  });
                }
                if (values.phoneNumber !== "") {
                  await createPhoneNumber({
                    variables: {
                      input: {
                        phoneNumber: values.phoneNumber,
                        label: "main",
                        preferred: true,
                        subjectId: contactId,
                      },
                    },
                  });
                }
                if (values.url !== "") {
                  await createUrl({
                    variables: {
                      input: {
                        url: values.url,
                        label: "main",
                        preferred: true,
                        subjectId: contactId,
                      },
                    },
                  });
                }
                if (values.address) {
                  if (values.address === "billing") {
                    values.city = billingAddress.city;
                    values.country = billingAddress.country;
                    values.postalCode = billingAddress.postalCode;
                    values.state = billingAddress.state;
                    values.streetAddress = billingAddress.streetAddress;
                    values.suiteNumber = billingAddress.suiteNumber;
                  } else {
                    values.city = mainAddress.city;
                    values.country = mainAddress.country;
                    values.postalCode = mainAddress.postalCode;
                    values.state = mainAddress.state;
                    values.streetAddress = mainAddress.streetAddress;
                    values.suiteNumber = mainAddress.suiteNumber;
                  }
                }
                await createAddress({
                  variables: {
                    input: {
                      preferred: true,
                      city: values.city,
                      label: "main",
                      country: values.country,
                      postalCode: values.postalCode,
                      state: values.state,
                      streetAddress: values.streetAddress,
                      suiteNumber: values.suiteNumber,
                      subjectId: contactId,
                    },
                  },
                });
              } catch (err) {
                throw err;
              }
            } else {
              contactId = values.contactId;
            }
            try {
              await createCompanyContact({
                variables: {
                  input: {
                    contactId,
                    companyId: values.companyId,
                    startedOn: values.startedOn,
                    endedOn: values.endedOn || undefined,
                    title: values.title,
                    authorizedRepresentative: values.authorizedRepresentative,
                    primaryContact: values.primaryContact,
                    secondaryContact: values.secondaryContact,
                    billingContact: values.billingContact,
                  },
                },
              });
              hideModal();
            } catch (err) {
              throw err;
            }
          }}
          render={({ values, setFieldValue }) => (
            <StyledPanelFormWrapper>
              <Form>
                <S.Box mb={1}>
                  <S.UnderlinedHeader>
                    {!values.new ? (
                      <>
                        <label htmlFor="contactId">
                          Select An Existing Contact
                          <span
                            style={{
                              color: "red",
                              display: "inline-block",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <ContactListSelect name="contactId" />
                        <S.Button size="small" onClick={() => setFieldValue('new', true)}>
                          Or Create A New Contact
                        </S.Button>
                      </>
                    ) : (
                      <>
                        <label>
                          Name
                          <span
                            style={{
                              color: "red",
                              display: "inline-block",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <S.Flex>
                          <Field
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            type="text"
                            required
                          />
                          <Field
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            type="text"
                            required
                          />
                        </S.Flex>
                        <label>
                          Email Address
                          <span
                            style={{
                              color: "red",
                              display: "inline-block",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Field
                          required
                          id="emailAddress"
                          name="emailAddress"
                          placeholder="Email Address"
                          type="text"
                        />
                        <label>Phone Number</label>
                        <Field
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="###-###-####"
                          type="text"
                        />
                        <label>Website</label>
                        <Field
                          id="url"
                          name="url"
                          pattern="https?://.*"
                          placeholder="http://www.sample.com"
                          type="url"
                        />
                        <label htmlFor="address">Address</label>
                        <Field
                          id="address"
                          name="address"
                          component={Select}
                          closeMenuOnSelect={true}
                          options={[
                            {
                              label: "Use Company's Headquarter Address",
                              value: "main",
                            },{
                              label: "Use Company's Billing Address",
                              value: "billing",
                            },{ label: "Use Different Address", value: "new" },
                          ]}
                        />
                        {values.address === "billing" ? 
                          <S.Box mx={2}><AddressDisplay subject={company} label="billing" /></S.Box>
                          : values.address === "main" ?
                          <S.Box mx={2}><AddressDisplay subject={company} label="main" /></S.Box>
                          : <S.Box mx={2}>
                              <AddressForm />
                            </S.Box>
                        }
                        <label>Image</label>
                        <S.Flex>
                          {imageUrl ? (
                            <img
                              height="35"
                              src={imageUrl}
                              alt="Company Logo"
                            />
                          ) : (
                            <></>
                          )}
                          <FileUpload
                            onUpload={(files) => {
                              values.imageId = files[0].id;
                              setImageUrl(files[0].url);
                            }}
                            multiple={false}
                          />
                        </S.Flex>
                        <S.Button size="small" onClick={() => setFieldValue('new', false)}>
                          Or Select An Existing Contact
                        </S.Button>
                      </>
                    )}
                  </S.UnderlinedHeader>
                  <S.Flex>
                    <S.HalfWidth>
                      <label>
                        Starting Date
                        <span style={{ color: "red", display: "inline-block" }}>
                          *
                        </span>
                      </label>
                      <Field
                        id="startedOn"
                        name="startedOn"
                        type="date"
                        required
                        value={
                          values.startedOn
                            ? moment(values.startedOn).format("YYYY-MM-DD")
                            : ""
                        }
                      />
                    </S.HalfWidth>
                    <S.HalfWidth>
                      <label>Ending Date</label>
                      <Field
                        id="endedOn"
                        name="endedOn"
                        type="date"
                        value={
                          values.endedOn
                            ? moment(values.endedOn).format("YYYY-MM-DD")
                            : undefined
                        }
                      />
                    </S.HalfWidth>
                  </S.Flex>
                  <label htmlFor="title">
                    Job Title
                    <span style={{ color: "red", display: "inline-block" }}>
                      *
                    </span>
                  </label>
                  <Field
                    id="title"
                    name="title"
                    placeholder="Title"
                    type="text"
                    required
                    value={values.title ? values.title : ""}
                  />
                  <label htmlFor="contactLevel">Contact Level</label>
                  <Field
                    id="contactLevel"
                    name="contactLevel"
                    component={Select}
                    closeMenuOnSelect
                    options={[
                      { label: "None", value: "" },
                      {
                        label: "Primary & Billing Contact",
                        value: "primary/billing",
                      },
                      {
                        label: "Secondary & Billing Contact",
                        value: "secondary/billing",
                      },
                      {
                        label: "Primary Contact",
                        value: "primary",
                      },
                      {
                        label: "Secondary Contact",
                        value: "secondary",
                      },
                      {
                        label: "Billing Contact",
                        value: "billing",
                      },
                      {
                        label: "Authorized Contact",
                        value: "authorized",
                      },
                    ]}
                  />
                  <S.Flex>
                    <button type="submit" disabled={loading}>
                      Sav{loading ? "ing" : "e"}
                    </button>
                    <button onClick={hideModal} disabled={loading}>
                      Cancel{loading ? "ling" : ""}
                    </button>
                  </S.Flex>
                </S.Box>
              </Form>
            </StyledPanelFormWrapper>
          )}
        />
      )}
    </Mutation>
  );
};

export default NewContactMembership;
