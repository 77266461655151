import React, { useState } from "react";
import styled from "styled-components";
import * as S from "./styles";
import * as SVG from "./svgs";
import EditableContent from "./EditableContent";
import { Mutation, Query } from "@apollo/react-components";
import { MutationFunction } from "@apollo/react-common";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, Field, Form, FieldArray } from "formik";
import FileUpload from "./FileUpload";
import {
  StyledPanelFormWrapper,
  RepeatableFieldButton,
  Select,
} from "./StyledForm";
import {
  StoreItem,
  UpdateStoreItemValues,
  StoreCategory as StoreCategoryType,
  StoreCategoryListQueryData,
} from "./apiTypes";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import { updateStoreItemMutation } from "./mutations/storeItem";
import {
  createStoreCategoryMutation,
  updateStoreCategoryMutation,
  archiveStoreCategoryMutation,
} from "./mutations/storeCategory";
import { storeCategoriesListQuery } from "./queries/storeCategory";

export interface NewStoreCategoryValues {
  name: string;
  description?: string;
  photoId?: string;
}

export interface UpdateStoreCategoryValues {
  storeCategoryId: string;
  name: string;
  description?: string;
  photoId?: string;
}

const StoreCategories: React.FunctionComponent<{
  storeItem: StoreItem;
}> = ({ storeItem }) => {
  return (
    <EditableContent>
      {({ isOpen, open, close }) => (
        <>
          <S.UnderlinedHeader>
            <S.Flex alignItems="flex-end" justifyContent="space-between">
              Store Categories
              {!isOpen ? (
                <S.Button size="small" onClick={open}>
                  Edit
                </S.Button>
              ) : (
                <S.Button size="small" onClick={close}>
                  Cancel
                </S.Button>
              )}
            </S.Flex>
          </S.UnderlinedHeader>
          {!isOpen ? (
            storeItem.categories && storeItem.categories.length > 0 ? (
              <S.Box pl={3} pr={0}>
                <S.List noBorder noPadding>
                  {storeItem.categories.map((category) => (
                    <li key={category.id}>
                      <S.Flex alignItems="center">{category.name}</S.Flex>
                    </li>
                  ))}
                </S.List>
              </S.Box>
            ) : (
              <>Currently in no store categories.</>
            )
          ) : (
            <Mutation<any>
              mutation={updateStoreItemMutation}
              refetchQueries={["StoreItemQuery"]}
            >
              {(updateStoreItem, { loading }) => {
                return (
                  <Formik
                    initialValues={{
                      storeItemId: storeItem.id,
                      categories: storeItem.categories.map((c) => ({
                        label: c.name,
                        value: c.id,
                      })),
                    }}
                    onSubmit={(values) => {
                      var categories =
                        values.categories && values.categories.length
                          ? values.categories.map((c) => {
                              return c.value;
                            })
                          : [];
                      updateStoreItem({
                        variables: {
                          input: {
                            categoryIds: categories,
                            storeItemId: values.storeItemId,
                          },
                        },
                      });
                      close();
                    }}
                    render={({ values }) => (
                      <Query<StoreCategoryListQueryData>
                        query={storeCategoriesListQuery}
                      >
                        {({ loading, error, data }) => {
                          if (loading && !data) return <div />;
                          if (error) return <div>Oops.</div>;
                          if (!data || !data.storeCategories)
                            return <div>No data.</div>;
                          const categoryOptions = data.storeCategories.map(
                            (category) => {
                              return {
                                value: category.id,
                                label: category.name,
                              };
                            }
                          );
                          return (
                            <Form>
                              <StyledPanelFormWrapper>
                                <FieldArray
                                  name="categories"
                                  render={(arrayHelpers) => (
                                    <div>
                                      {values.categories &&
                                      values.categories.length ? (
                                        values.categories.map(
                                          (category, index) => (
                                            <S.Flex
                                              mb={1}
                                              alignItems="center"
                                              key={index}
                                            >
                                              <div
                                                style={{
                                                  flexBasis: "70%",
                                                  marginRight: 5,
                                                }}
                                              >
                                                <Field
                                                  id={`categories.${index}`}
                                                  name={`categories.${index}.value`}
                                                  component={Select}
                                                  closeMenuOnSelect={true}
                                                  options={categoryOptions}
                                                />
                                              </div>
                                              <RepeatableFieldButton
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                <SVG.MinusSvg />
                                              </RepeatableFieldButton>
                                              <RepeatableFieldButton
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.insert(
                                                    index + 1,
                                                    ""
                                                  )
                                                }
                                              >
                                                <SVG.PlusSvg />
                                              </RepeatableFieldButton>
                                            </S.Flex>
                                          )
                                        )
                                      ) : (
                                        <button
                                          type="button"
                                          onClick={() => arrayHelpers.push("")}
                                        >
                                          Add a store category.
                                        </button>
                                      )}
                                    </div>
                                  )}
                                />
                                <button type="submit" disabled={loading}>
                                  Sav{loading ? "ing" : "e"}
                                </button>
                              </StyledPanelFormWrapper>
                            </Form>
                          );
                        }}
                      </Query>
                    )}
                  />
                );
              }}
            </Mutation>
          )}
        </>
      )}
    </EditableContent>
  );
};

const SettingsStoreCategories: React.FunctionComponent<{}> = ({}) => {
  const addDialog = useDialogState();

  return (
    <S.Box pb={2} pt={3} my={3} mx={3}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="center" justifyContent="space-between">
          Store Categories
          <DialogDisclosure {...addDialog} as={S.Button} size="small">
            Add a Store Category
          </DialogDisclosure>
        </S.Flex>
      </S.UnderlinedHeader>
      <Query<StoreCategoryListQueryData> query={storeCategoriesListQuery}>
        {({ loading, error, data }) => {
          if (loading && !data) return <S.Box />;
          if (error)
            return <S.Box>There are no store categories loaded.</S.Box>;
          if (!data || !data.storeCategories)
            return <S.Box>There are no store categories in the system.</S.Box>;
          return (
            <S.Flex flexWrap="wrap">
              {data.storeCategories.map((storeCategory) => (
                <S.Panel key={storeCategory.id} width={1 / 4}>
                  <StoreCategory storeCategory={storeCategory} />
                </S.Panel>
              ))}
            </S.Flex>
          );
        }}
      </Query>
      <DialogWithBackdrop {...addDialog}>
        <AddStoreCategory closeModal={() => addDialog.hide()} />
      </DialogWithBackdrop>
    </S.Box>
  );
};

const StoreCategory: React.FunctionComponent<{
  storeCategory: StoreCategoryType;
}> = ({ storeCategory }) => {
  const editDialog = useDialogState();

  return (
    <>
      <S.UnderlinedHeader>
        <S.Flex alignItems="center" justifyContent="space-between">
          {storeCategory.name}
          <DialogDisclosure {...editDialog} as={S.Button} size="small">
            Edit
          </DialogDisclosure>
        </S.Flex>
      </S.UnderlinedHeader>

      <img height="70" src={storeCategory.photoUrl} />
      <S.P>{storeCategory.description}</S.P>
      <DialogWithBackdrop {...editDialog}>
        <EditStoreCategory
          storeCategory={storeCategory}
          closeModal={() => editDialog.hide()}
        />
      </DialogWithBackdrop>
    </>
  );
};

const EditStoreCategory: React.FunctionComponent<{
  storeCategory: StoreCategoryType;
  closeModal: () => void;
}> = ({ storeCategory, closeModal }) => {
  const [updateStoreCategory, { loading }] = useMutation<
    { updateStoreCategory: { id: string } },
    { input: UpdateStoreCategoryValues }
  >(updateStoreCategoryMutation, {
    refetchQueries: ["storeCategoriesListQuery"],
  });
  const [archiveStoreCategory] = useMutation<
    { archiveStoreCategory: { id: string } },
    { input: { storeCategoryId: string } }
  >(archiveStoreCategoryMutation, {
    refetchQueries: ["storeCategoriesListQuery"],
  });

  const [imageUrl, setImageUrl] = useState<string | undefined>(
    storeCategory.photoUrl
  );

  return (
    <S.Box p={3} my={0}>
      <S.UnderlinedHeader>Update Store Category</S.UnderlinedHeader>
      <Formik<UpdateStoreCategoryValues>
        initialValues={{
          storeCategoryId: storeCategory.id,
          name: storeCategory.name,
          description: storeCategory.description,
          photoId: undefined,
        }}
        onSubmit={async (values) => {
          let input: any = { ...values };
          await updateStoreCategory({
            variables: {
              input,
            },
          });
          closeModal();
        }}
        render={({ values }) => (
          <StyledPanelFormWrapper>
            <Form>
              <label>
                Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="name"
                name="name"
                required
                placeholder={"Name the Store Category."}
                type="text"
              />
              <label htmlFor="photoId">
                Category Image
                <span style={{ color: "red", display: "inline-block" }}>*</span>
                <span>This will display on the website's store.</span>
              </label>
              <S.Flex>
                {imageUrl ? (
                  <img
                    height="35"
                    src={imageUrl}
                    alt="Store Category Display Image"
                  />
                ) : (
                  <></>
                )}
                <FileUpload
                  onUpload={(files) => {
                    values.photoId = files[0].id;
                    setImageUrl(files[0].url);
                  }}
                  multiple={false}
                />
              </S.Flex>
              <label>
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="description"
                name="description"
                placeholder="A description of the store category, viewed on the store page."
                component="textarea"
              />
              <S.Flex alignItems="center">
                <S.Button type="submit" disabled={loading}>
                  Sav{loading ? "ing" : "e"} Category
                </S.Button>
                <S.Box py={0} px={0} my={0} mx={0} pending width={1 / 2}>
                  <S.Button
                    disabled={loading}
                    onClick={async () => {
                      if (
                        window.confirm(
                          "Are you sure you want to archive this Store Category?"
                        )
                      ) {
                        await archiveStoreCategory({
                          variables: {
                            input: {
                              storeCategoryId: storeCategory.id,
                            },
                          },
                        });
                      }
                    }}
                  >
                    Archiv{loading ? "ing" : "e"} Category
                  </S.Button>
                </S.Box>
              </S.Flex>
            </Form>
          </StyledPanelFormWrapper>
        )}
      />
    </S.Box>
  );
};

const AddStoreCategory: React.FunctionComponent<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const [createStoreCategory, { loading }] = useMutation<
    { createStoreCategory: { id: string } },
    { input: NewStoreCategoryValues }
  >(createStoreCategoryMutation, {
    refetchQueries: ["storeCategoriesListQuery"],
  });

  return (
    <S.Box p={3} my={0}>
      <S.UnderlinedHeader>Create Store Category</S.UnderlinedHeader>
      <Formik<NewStoreCategoryValues>
        initialValues={{
          name: "",
          description: "",
          photoId: undefined,
        }}
        onSubmit={async (values) => {
          await createStoreCategory({
            variables: {
              input: values,
            },
          });
          closeModal();
        }}
        render={({ values }) => (
          <StyledPanelFormWrapper>
            <Form>
              <label htmlFor="name">
                Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="name"
                name="name"
                required
                placeholder={"Name the Store Category."}
                type="text"
              />
              <label htmlFor="photoId">
                Category Image
                <span style={{ color: "red", display: "inline-block" }}>*</span>
                <span>This will display on the website's store.</span>
              </label>
              <S.Flex>
                {imageUrl ? (
                  <img
                    height="35"
                    src={imageUrl}
                    alt="Store Category Display Image"
                  />
                ) : (
                  <></>
                )}
                <FileUpload
                  onUpload={(files) => {
                    values.photoId = files[0].id;
                    setImageUrl(files[0].url);
                  }}
                  multiple={false}
                />
              </S.Flex>
              <label>
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="description"
                name="description"
                placeholder="A description of the store category, viewed on the store page."
                component="textarea"
              />
              <S.Button type="submit" disabled={loading}>
                Sav{loading ? "ing" : "e"} Category
              </S.Button>
            </Form>
          </StyledPanelFormWrapper>
        )}
      />
    </S.Box>
  );
};

export default StoreCategories;
export { SettingsStoreCategories };
