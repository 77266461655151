import { gql } from "graphql.macro";

export const LocationListQuery = gql`
  query LocationListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $companyId: String
  ) {
    locations(
      first: $first
      last: $last
      before: $before
      after: $after
      companyId: $companyId
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          company {
            id
          }
          types
          numEmployees
          addresses {
            id
            label
            city
            country
            postalCode
            state
            streetAddress
            suiteNumber
            preferred
          }
          urls {
            id
            label
            url
            preferred
          }
          phoneNumbers {
            id
            label
            phoneNumber
            preferred
          }
          emailAddresses {
            id
            label
            emailAddress
            preferred
          }
        }
      }
    }
  }
`;

export const LocationQuery = gql`
  query LocationQuery($locationId: ID!) {
    location(locationId: $locationId) {
      id
      name
      company {
        id
      }
      types
      numEmployees
      addresses {
        id
        label
        city
        country
        postalCode
        state
        streetAddress
        suiteNumber
        preferred
      }
      urls {
        id
        label
        url
        preferred
      }
      phoneNumbers {
        id
        label
        phoneNumber
        preferred
      }
      emailAddresses {
        id
        label
        emailAddress
        preferred
      }
    }
  }
`;
