import { gql } from "graphql.macro";

export const DownloadQuery = gql`
  query DownloadQuery($downloadId: ID!) {
    download(downloadId: $downloadId) {
      id
      acl
      description
      file {
        id
        contentType
        url
        filename
        insertedAt
        updatedAt
      }
      fpaNow
      report
      id
      name
      insertedAt
      updatedAt
    }
  }
`;

export const DownloadsListQuery = gql`
  query DownloadsListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: DownloadsOrderByInput
  ) {
    downloads(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          acl
          description
          file {
            id
            contentType
            url
          }
          fpaNow
          name
          report
          insertedAt
          updatedAt
        }
      }
    }
  }
`;

export const downloadsSearchQuery = gql`
  query downloadsSearchQuery(
    $first: Int!
    $last: Int
    $before: String
    $after: String
    $orderBy: DownloadsOrderByInput
  ) {
    downloads(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          acl
          description
          name
        }
      }
    }
  }
`;
