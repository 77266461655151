import React, { useState } from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import styled, { css } from "styled-components";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { Mutation } from "@apollo/react-components";
import EditableContent from "./EditableContent";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";
import { cleanUpUrl } from "./util";
import FileInput from "./FileInput";
import {
  Contact,
  UseMutationReturn,
  ImageValues,
  NewImageValues,
  StudentDesignSubmission,
  AchievementAwardSubmission,
} from "./apiTypes";
import { useMutation } from "@apollo/react-hooks";

const SecondaryImages = styled.div`
  position: absolute;
  right: 1px;
  top: 33px;
  bottom: 0;
  overflow: scroll;
  height: 250px;
  img {
    width: 50px;
    border: 2px solid white;
    display: block;
    overflow: hidden;
  }
`;
const PrimaryImage = styled.div<{ image: string }>`
  position: relative;
  background-image: url("${(p) => p.image}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  width: 100%;
  height: 250px;

  button {
    position: absolute;
    top: 3px;
    right: 3px;
  }
`;

const GalleryBox = styled.div`
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  width: 100%;
  height: 300px;
`;

const UpdateGalleryBox = styled.div`
  overflow: scroll;
  width: 100%;
  height: 250px;
`;

const StyledImage = styled.div<{ image: string }>`
  position: relative;
  background-image: url("${(p) => p.image}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  button {
    position: absolute;
    top: 3px;
    right: 3px;
  }
`;

const StyledImageWrapper = styled.div<{ outline: boolean }>`
  border: 2px solid white;
  ${(p) =>
    p.outline &&
    css`
      border-color: ${(p) => p.theme.colors.blue};
    `}
  flex-basis: 25%;
  min-width: 100px;
  min-height: 100px;
`;

const UpdateGalleryButtons = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

type ImageGalleryProps = {
  images?: ImageValues[];
  subjectId: string;
  createFunc: (variables: any) => void;
  updateFunc: (variables: any) => void;
  deleteFunc: (variables: any) => void;
};

const ImageGallery: React.FunctionComponent<ImageGalleryProps> = ({
  images,
  subjectId,
  createFunc,
  updateFunc,
  deleteFunc,
}) => {
  const primaryImage =
    images && images.filter((image) => image.preferred).filter(Boolean);
  const primaryImageUrl =
    (primaryImage &&
      primaryImage[0] &&
      primaryImage[0].file &&
      cleanUpUrl(primaryImage[0].file.url)) ||
    "/images/empty-user.png";
  const primaryImageId =
    primaryImage &&
    primaryImage[0] &&
    primaryImage[0].file &&
    primaryImage[0].file.id;
  const alternateImages =
    images && images.filter((image) => !image.preferred).filter(Boolean);
  return (
    <S.Box mx={3}>
      <div style={{ background: "#EEF1F8" }}>
        <EditableContent>
          {({ isOpen, open, close }) => (
            <>
              {!isOpen ? (
                <PrimaryImage image={primaryImageUrl}>
                  <S.Button size="small" onClick={open}>
                    Edit
                  </S.Button>
                  <SecondaryImages>
                    {alternateImages &&
                      alternateImages.map((image, index) => {
                        const imageUrl =
                          (image && image.file && cleanUpUrl(image.file.url)) ||
                          "/images/empty-user.png";
                        return <img key={index} src={imageUrl} />;
                      })}
                  </SecondaryImages>
                </PrimaryImage>
              ) : (
                <GalleryBox>
                  <UpdateImageGallery
                    primaryImageId={primaryImageId}
                    images={images}
                    subjectId={subjectId}
                    closeFunc={close}
                    createFunc={createFunc}
                    updateFunc={updateFunc}
                    deleteFunc={deleteFunc}
                  />
                </GalleryBox>
              )}
            </>
          )}
        </EditableContent>
      </div>
    </S.Box>
  );
};

type UpdateImageGalleryProps = {
  images?: ImageValues[];
  primaryImageId?: string;
  subjectId: string;
  closeFunc: () => void;
  createFunc: (variables: any) => void;
  updateFunc: (variables: any) => void;
  deleteFunc: (variables: any) => void;
};
const UpdateImageGallery: React.FunctionComponent<UpdateImageGalleryProps> = ({
  images,
  primaryImageId,
  subjectId,
  closeFunc,
  createFunc,
  updateFunc,
  deleteFunc,
}) => {
  const addDialog = useDialogState();

  const [preferred, setPreferred] = useState<string | undefined>(
    primaryImageId
  );

  return (
    <>
      <UpdateGalleryBox>
        <S.Flex flexWrap="wrap">
          {images && images.length ? (
            images.map((image) => {
              if (!image) return <></>;
              if (!image.file) return <></>;
              if (image.file.id && image.file.url)
                return (
                  <UpdateImage
                    key={image.file.id}
                    preferred={preferred}
                    image={image}
                    subjectId={subjectId}
                    updateFunc={updateFunc}
                    deleteFunc={deleteFunc}
                  />
                );
            })
          ) : (
            <S.Box px={3}>No images have been created.</S.Box>
          )}
        </S.Flex>
      </UpdateGalleryBox>
      <UpdateGalleryButtons>
        <S.Flex my={2} justifyContent="center">
          <DialogDisclosure {...addDialog} as={S.Button}>
            Add New Image
          </DialogDisclosure>
          <S.Button
            size="normal"
            onClick={closeFunc}
            style={{ marginLeft: 10 }}
          >
            Close
          </S.Button>
        </S.Flex>
      </UpdateGalleryButtons>
      <DialogWithBackdrop {...addDialog}>
        <AddImage
          createFunc={createFunc}
          subjectId={subjectId}
          closeModal={() => addDialog.hide()}
        />
      </DialogWithBackdrop>
    </>
  );
};

const AddImage: React.FunctionComponent<{
  subjectId: string;
  createFunc: (variables: any) => void;
  closeModal: () => void;
}> = ({ subjectId, createFunc, closeModal }) => {
  return (
    <Formik
      initialValues={{
        preferred: false,
        file: undefined,
        submissionId: subjectId,
      }}
      onSubmit={async (e) => {
        await createFunc({
          variables: { input: e },
        });
        closeModal();
      }}
      render={({ values }) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Image</S.UnderlinedHeader>
          <StyledPanelFormWrapper>
            <Form>
              <Field id="file" name="file" component={FileInput} required />
              <button type="submit" disabled={false}>
                Sav{false ? "ing" : "e"}
              </button>
            </Form>
          </StyledPanelFormWrapper>
        </S.Box>
      )}
    />
  );
};

const UpdateImage: React.FunctionComponent<{
  image: ImageValues;
  preferred?: string;
  subjectId: string;
  updateFunc: (variables: any) => void;
  deleteFunc: (variables: any) => void;
}> = ({ image, preferred, subjectId, updateFunc, deleteFunc }) => {
  const menu = useMenuState();

  if (!image) return <></>;
  if (!image.file) return <></>;
  if (!image.file.url || !image.file.id) return <></>;
  const imageId = image.file.id;
  const imageUrl = cleanUpUrl(image.file.url);
  return (
    <StyledImageWrapper outline={imageId === preferred}>
      <MenuDisclosure
        {...menu}
        as={StyledImage}
        image={imageUrl}
        outline={preferred === imageId}
      ></MenuDisclosure>
      <Menu {...menu}>
        <MenuItem
          {...menu}
          onClick={async () => {
            if (preferred) {
              await updateFunc({
                variables: {
                  input: {
                    submissionId: subjectId,
                    preferred: false,
                    fileId: preferred,
                  },
                },
              });
            }
            await updateFunc({
              variables: {
                input: {
                  submissionId: subjectId,
                  preferred: true,
                  fileId: imageId,
                },
              },
            });
            menu.hide();
          }}
        >
          Set Preferred
        </MenuItem>
        <MenuItem
          {...menu}
          onClick={async () => {
            if (window.confirm("Are you sure you want to delete this image?")) {
              await deleteFunc({
                variables: {
                  input: { submissionId: subjectId, fileId: imageId },
                },
              });
              menu.hide();
            }
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </StyledImageWrapper>
  );
};

export default ImageGallery;
