import React from "react";
import EventDetails from "./EventDetails";
import EventRegistrations from "./EventRegistrations";
import EventRegistrationForm from "./EventRegistrationForm";
import { Switch, Route, Redirect } from "react-router-dom";
import EventNav from "./EventNav";
import { Query } from "@apollo/react-components";
import { RouteComponentProps } from "react-router-dom";
import { eventQuery } from "./queries/event";
import TopNav from "./TopNav";
import Page from "./Page";
import { joinPath } from "./util";
import { Event as EventType } from "./apiTypes";

type EventProps = RouteComponentProps<{ eventId: string }>;

const Event: React.FunctionComponent<EventProps> = ({ match }) => {
  return (
    <Query<{ event: EventType }, { eventId: string }>
      query={eventQuery}
      variables={{ eventId: match.params.eventId }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (!data || !data.event)
          return (
            <Page>
              <TopNav />
              There is no Event associated with this id/url.
            </Page>
          );
        const event = data.event;
        if (!event)
          return (
            <Page>
              <TopNav />
              There is no Event associated with this id/url.
            </Page>
          );
        return (
          <div>
            <EventNav event={event} match={match} />
            <Switch>
              <Redirect
                exact
                from={match.path}
                to={joinPath(match.url, "/details")}
              />
              <Route
                path={`${match.path}/details`}
                render={(routeProps) => (
                  <EventDetails {...routeProps} event={event} />
                )}
              />
              <Route
                path={`${match.path}/registrations`}
                render={(routeProps) => (
                  <EventRegistrations {...routeProps} event={event} />
                )}
              />
              <Route
                path={`${match.path}/registration-form`}
                render={routeProps => (
                  <EventRegistrationForm {...routeProps} event={event} />
                )}
              />
            </Switch>
          </div>
        );
      }}
    </Query>
  );
};

export default Event;
