import React, { useState, useCallback } from "react";
import Page, { PageButtons } from "./Page";
import Select, { ValueType } from "react-select";
import NewStoreItem from "./NewStoreItem";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Link, useLocation } from "react-router-dom";
import Table, { TR, TD } from "./Table";
import { Query } from "@apollo/react-components";
import { StoreItemListQueryData, SortValue, FilterValue } from "./apiTypes";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import { StoreItemsListQuery } from "./queries/storeItem";
import NumberFormat from "react-number-format";
import { Select as FormSelect } from "./StyledForm";
import { Field, useFormikContext } from "formik";
import { storeItemsSearchQuery } from "./queries/storeItem";
import { useQuery } from "@apollo/react-hooks";

const StoreItems: React.FunctionComponent = () => {
  const query = new URLSearchParams(useLocation().search);
  const pageSize = 20;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;

  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
  };

  const addDialog = useDialogState();

  const sortOptions = [
    { label: "Name A-Z", value: "NAME_ASC" },
    { label: "Name Z-A", value: "NAME_DESC" },
    // { label: "Date (Ascending)", value: "date (ascending)" },
    // { label: "Date (Descending)", value: "date (descending)" },
    // { label: "Price (Ascending)", value: "price (ascending)" },
    // { label: "Price (Descending)", value: "price (descending)" }
  ];
  const filterOptions = [{ label: "Category", value: "category" }];

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const [filters, setFilters] = useState<FilterValue>([]);

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleFilters = (newValues: FilterValue) => {
    setFilters(newValues);
  };

  return (
    <Page>
      <TopNav />
      <S.Flex justifyContent="flex-end" mx={3}>
        <DialogDisclosure {...addDialog} as={S.Button}>
          New Store Item
        </DialogDisclosure>
      </S.Flex>
      <S.Flex justifyContent="space-between" mx={3}>
        <div style={{ flex: 1, marginRight: 20 }}>
          <label>SORT BY</label>
          <Select
            closeMenuOnSelect={true}
            onChange={handleSort}
            options={sortOptions}
            value={sort}
          />
        </div>
        {/* <div style={{ flex: 1, marginLeft: 20 }}>
          <label>FILTER BY</label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            onChange={handleFilters}
            options={filterOptions}
            value={filters}
          />
        </div> */}
      </S.Flex>
      <DialogWithBackdrop {...addDialog}>
        <NewStoreItem />
      </DialogWithBackdrop>
      <Query<StoreItemListQueryData>
        query={StoreItemsListQuery}
        variables={queryVars}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <></>;
          if (error)
            return (
              <S.Box pl={3} pending>
                <S.P>There was an issue loading Store Items.</S.P>
              </S.Box>
            );
          if (
            !data ||
            !data.storeItems ||
            !Array.isArray(data.storeItems.edges)
          )
            return (
              <S.Box>
                <S.P>There are no Store Items.</S.P>
              </S.Box>
            );
          return (
            <>
              <PageButtons
                topMargin={4}
                pageInfo={data.storeItems.pageInfo}
                basePath="store"
              />
              <S.Panel>
                <Table cols="80px 2fr 1fr 1fr 1fr 1fr">
                  <TR header>
                    <TD />
                    <TD>ITEM NAME</TD>
                    <TD>CATEGORY</TD>
                    <TD>PRICE</TD>
                    <TD>DATES AVAILABLE</TD>
                    <TD />
                  </TR>
                  {data.storeItems.edges.map((edge) => {
                    const storeItem = edge.node;
                    if (!storeItem) return <React.Fragment />;
                    return (
                      <TR key={storeItem.id} py={2}>
                        <TD>
                          {/* {storeItem.photoUrl ? (
                            <img
                              src={storeItem.photoUrl}
                              height="70"
                              width="50"
                            />
                          ) : (
                            <></>
                          )} */}
                        </TD>
                        <TD>
                          <Link to={`/store/${storeItem.id}`}>
                            <S.Title>{storeItem.name}</S.Title>
                          </Link>
                        </TD>
                        <TD>
                          {/* {storeItem.categories && storeItem.categories.name} */}
                        </TD>
                        <TD>
                          {storeItem.price ? (
                            <NumberFormat
                              value={storeItem.price / 100}
                              fixedDecimalScale
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          ) : (
                            "FREE"
                          )}
                        </TD>
                        <TD>
                          {/* <DateRange
                            start={storeItem.publishedAt}
                            end={storeItem.deactivatedAt}
                          /> */}
                        </TD>
                        <TD>
                          <S.LinkButton
                            size="small"
                            to={`/store/${storeItem.id}`}
                          >
                            View
                          </S.LinkButton>
                        </TD>
                      </TR>
                    );
                  })}
                </Table>
              </S.Panel>
              <PageButtons
                pageInfo={data.storeItems.pageInfo}
                basePath="store"
              />
            </>
          );
        }}
      </Query>
    </Page>
  );
};

const StoreItemsSelect = (props: { name: string }) => {
  const formik = useFormikContext<any>();
  const { data, loading, error } = useQuery<StoreItemListQueryData>(
    storeItemsSearchQuery,
    {
      variables: { first: 2000 },
    }
  );

  const handleChange = useCallback(
    ({ value }: any) => {
      formik.setFieldValue(props.name, value);
    },
    [props.name, formik.setFieldValue]
  );

  if (loading && !data) return <div />;
  if (error) return <div>Oops.</div>;
  if (!data || !data.storeItems || !Array.isArray(data.storeItems.edges))
    return <div>No data.</div>;

  var storeItemsList = data.storeItems.edges
    .map((edge) => {
      const storeItem = edge.node;
      if (!storeItem) return null;
      if (storeItem.archivedAt && storeItem.archivedAt !== "") return null;
      return { label: storeItem.name, value: storeItem.id };
    })
    .filter(Boolean) as ValueType<{ label: string; value: string }>[];

  return (
    <Field
      id={props.name}
      name={props.name}
      component={FormSelect}
      closeMenuOnSelect={true}
      options={storeItemsList}
      onChange={handleChange}
    />
  );
};

export { StoreItemsSelect };
export default StoreItems;
