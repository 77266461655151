import React from "react";
import * as S from "./styles";
import Searchbar from "./Searchbar";
import * as SVG from "./svgs";
import { Link } from "react-router-dom";
import { match } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import Tags, { Tag } from "./Tags";
import PageNav, { NavInfo, TopPageNav } from "./PageNav";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { StoreItem, UseMutationReturn } from "./apiTypes";
import {
  archiveStoreItemMutation,
  unarchiveStoreItemMutation
} from "./mutations/storeItem";

type StoreItemNavProps = {
  storeItem: StoreItem;
  match: match<{ storeItemId: string }>;
};

function EditStoreItemMenu({ storeItem }: { storeItem: StoreItem }) {
  const menu = useMenuState();

  const [archiveStoreItem]: UseMutationReturn<
    { archiveStoreItem: StoreItem },
    { input: { storeItemId: string } }
  > = useMutation(archiveStoreItemMutation, {
    refetchQueries: ["StoreItemQuery"]
  });

  const [unarchiveStoreItem]: UseMutationReturn<
    { unarchiveStoreItem: StoreItem },
    { input: { storeItemId: string } }
  > = useMutation(unarchiveStoreItemMutation, {
    refetchQueries: ["StoreItemQuery"]
  });

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Store Item
      </MenuDisclosure>
      <Menu {...menu}>
        <MenuItem {...menu}>
          <Link to={`https://www.flexpack.org/publications/${storeItem.id}`}>
            View On Website
          </Link>
        </MenuItem>
        <MenuItem {...menu}>
          <Link to={`/files/${storeItem.download.id}`}>View File</Link>
        </MenuItem>
        {/* {storeItem.archivedAt ? (
          <MenuItem
            {...menu}
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to reactivate this Store Item?"
                )
              ) {
                await unarchiveStoreItem({
                  variables: { input: { storeItemId: storeItem.id } }
                });
                menu.hide();
              }
            }}
          >
            Enable Contact
          </MenuItem>
        ) : (
          <MenuItem
            {...menu}
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to archived this Store item?"
                )
              ) {
                await archiveStoreItem({
                  variables: { input: { storeItemId: storeItem.id } }
                });
                menu.hide();
              }
            }}
          >
            Disable Contact
          </MenuItem>
        )} */}
      </Menu>
    </>
  );
}

const StoreItemNav: React.FunctionComponent<StoreItemNavProps> = ({
  match,
  storeItem
}) => {
  return (
    <PageNav>
      <S.Box py={3} mx={3} my={0}>
        <TopPageNav />
        <NavInfo>
          <S.Flex ml={3} alignItems="center">
            <div>
              <S.H1>{storeItem.name}</S.H1>
              <Tags>
                {storeItem.archivedAt && storeItem.archivedAt !== "" ? (
                  <Tag color="lightorange">Archived</Tag>
                ) : (
                  <Tag color="lightgreen">Available</Tag>
                )}
                {storeItem.categories &&
                  storeItem.categories.map(category => (
                    <Tag key={category.id}>{category.name}</Tag>
                  ))}

                {/* {storeItem.featuredOn && storeItem.featuredOn !== "" && (
                <Tag color="lightpurple">Featured</Tag>
              )} */}
              </Tags>
            </div>
          </S.Flex>
          <EditStoreItemMenu storeItem={storeItem} />
        </NavInfo>
      </S.Box>
    </PageNav>
  );
};

export default StoreItemNav;
