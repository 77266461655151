import React, { useState } from "react";
import Emails from "./Emails";
import Urls from "./Urls";
import PhoneNumbers from "./PhoneNumbers";
import FileUpload from "./FileUpload";
import moment from "moment";
import * as S from "./styles";
import * as SVG from "./svgs";
import Table, { TR, TD } from "./Table";
import { Formik, Field, Form, FieldArray } from "formik";
import { Mutation } from "@apollo/react-components";
import { useEditToggle } from "./hooks";
import { Company } from "./apiTypes";
import { Address } from "./Addresses";
import { Logo } from "./Image";
import { StyledPanelFormWrapper, RepeatableFieldButton } from "./StyledForm";
import { updateCompanyMutation } from "./mutations/company";

export default function CompanyHQ({ company }: { company: Company }) {
  let emailLabelOptions = [
    { label: "main", value: "main" },
    { label: "secondary", value: "secondary" },
    { label: "billing", value: "billing" },
    { label: "other", value: "other" }
  ];

  let urlLabelOptions = [
    { label: "main", value: "main" },
    { label: "secondary", value: "secondary" },
    { label: "billing", value: "billing" },
    { label: "other", value: "other" }
  ];

  let phoneNumberLabelOptions = [
    { label: "main", value: "main" },
    { label: "secondary", value: "secondary" },
    { label: "corporate", value: "corporate" },
    { label: "fax", value: "fax" },
    { label: "cell", value: "cell" },
    { label: "billing", value: "billing" },
    { label: "other", value: "other" }
  ];

  return (
    <>
      <CompanyDetails company={company} />
      <S.Panel pb={2}>
        <Address subject={company} title="Headquarters" />
      </S.Panel>
      <S.Panel pb={2}>
        <Address subject={company} title="Billing Address" label="billing" />
      </S.Panel>
      <PhoneNumbers subject={company} selectOptions={phoneNumberLabelOptions} />
      <Emails subject={company} selectOptions={emailLabelOptions} />
      <Urls subject={company} selectOptions={urlLabelOptions} />
    </>
  );
}

function CompanyDetails({ company }: { company: Company }) {
  const { isOpen, open, close } = useEditToggle();
  const [imageUrl, setImageUrl] = useState<string | undefined>(company.logoUrl);

  return (
    <S.Panel pb={2}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          Details
          {!isOpen ? (
            <S.Button size="small" onClick={open}>
              Edit
            </S.Button>
          ) : (
            <S.Button size="small" onClick={close}>
              Cancel
            </S.Button>
          )}
        </S.Flex>
      </S.UnderlinedHeader>
      {!isOpen ? (
        <S.Flex>
          {company.logoUrl ? (
            <S.Box width={1 / 3}>
              <Logo src={company.logoUrl} />
            </S.Box>
          ) : (
            <></>
          )}
          <S.Box width={2 / 3}>
            <Table cols="250px 1fr">
              <TR borderless>
                <TD>
                  <b>Company Name</b>
                </TD>
                <TD>{company.name ? company.name : ""}</TD>
              </TR>
              <TR borderless>
                <TD>
                  <b>Federal ID Number</b>
                </TD>
                <TD>
                  {company.federalIdNumber ? company.federalIdNumber : ""}
                </TD>
              </TR>
              <TR borderless>
                <TD>
                  <b>NAICS Codes</b>
                </TD>
                <TD>
                  {company.naicsCodes ? company.naicsCodes.join(", ") : ""}
                </TD>
              </TR>
              <TR borderless>
                <TD>
                  <b>Total Employees</b>
                </TD>
                <TD>{company.numEmployees ? company.numEmployees : ""}</TD>
              </TR>
            </Table>
          </S.Box>
        </S.Flex>
      ) : (
        <Mutation<any>
          mutation={updateCompanyMutation}
          refetchQueries={["companyQuery"]}
        >
          {(updateCompany, { loading }) => {
            return (
              <Formik
                initialValues={{
                  name: company.name ? company.name : "",
                  naicsCodes: company.naicsCodes ? company.naicsCodes : [""],
                  federalIdNumber: company.federalIdNumber
                    ? company.federalIdNumber
                    : "",
                  numEmployees: company.numEmployees ? company.numEmployees : 0,
                  logoUrl: company.logoUrl ? company.logoUrl : "",
                  file: {
                    id: "",
                    url: "",
                    filename: "",
                    contentType: ""
                  }
                }}
                onSubmit={async values => {
                  await updateCompany({
                    variables: {
                      input: {
                        logoId:
                          values.file && values.file.id
                            ? values.file.id
                            : undefined,
                        name: values.name,
                        naicsCodes: values.naicsCodes,
                        federalIdNumber: values.federalIdNumber,
                        numEmployees: values.numEmployees,
                        companyId: company.id
                      }
                    }
                  });
                  close();
                }}
                render={({ values }) => (
                  <Form>
                    <StyledPanelFormWrapper>
                      <label htmlFor="name">
                        Company Name
                        <span style={{ color: "red", display: "inline-block" }}>
                          *
                        </span>
                      </label>
                      <Field
                        required
                        id="name"
                        name="name"
                        placeholder={company.name ? company.name : ""}
                        type="text"
                      />
                      <label htmlFor="logoUrl">Company Logo</label>
                      <S.Flex>
                        {imageUrl ? (
                          <img height="35" src={imageUrl} alt="Company Logo" />
                        ) : (
                          <></>
                        )}
                        <FileUpload
                          onUpload={files => {
                            values.file = files[0];
                            setImageUrl(values.file.url);
                          }}
                          multiple={false}
                        />
                      </S.Flex>
                      <S.Flex>
                        <S.HalfWidth>
                          <label htmlFor="federalIdNumber">
                            Federal ID Number
                          </label>
                          <Field
                            id="federalIdNumber"
                            name="federalIdNumber"
                            placeholder={
                              company.federalIdNumber
                                ? company.federalIdNumber
                                : ""
                            }
                            type="text"
                          />
                        </S.HalfWidth>
                        <S.HalfWidth>
                          <label htmlFor="numEmployees">Total Employees</label>
                          <Field
                            id="numEmployees"
                            name="numEmployees"
                            placeholder={
                              company.numEmployees
                                ? company.numEmployees
                                : undefined
                            }
                            type="number"
                          />
                        </S.HalfWidth>
                      </S.Flex>
                      <label htmlFor="naicsCodes">NAICS Codes</label>
                      <FieldArray
                        name="naicsCodes"
                        render={arrayHelpers => (
                          <div>
                            {values.naicsCodes &&
                            values.naicsCodes.length > 0 ? (
                              values.naicsCodes.map((code, index) => (
                                <S.Flex mb={1} key={index}>
                                  <Field
                                    id={`naicsCodes.${index}`}
                                    name={`naicsCodes.${index}`}
                                    placeholder=""
                                    type="text"
                                  />
                                  <RepeatableFieldButton
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                  >
                                    <SVG.MinusSvg />
                                  </RepeatableFieldButton>
                                  <RepeatableFieldButton
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.insert(index + 1, "")
                                    } // insert an empty string at a position
                                  >
                                    <SVG.PlusSvg />
                                  </RepeatableFieldButton>
                                </S.Flex>
                              ))
                            ) : (
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push("")}
                              >
                                Add a NAICS Code.
                              </button>
                            )}
                          </div>
                        )}
                      />
                      <button type="submit" disabled={loading}>
                        Sav{loading ? "ing" : "e"}
                      </button>
                    </StyledPanelFormWrapper>
                  </Form>
                )}
              />
            );
          }}
        </Mutation>
      )}
    </S.Panel>
  );
}
