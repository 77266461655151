import React from "react";
import Tree, { TreeItem, TreeParent } from "./DropdownList";
import { PhoneNumberList } from "./PhoneNumbers";
import { EmailListButton } from "./Emails";
import { UrlList } from "./Urls";
import UserImage from "./UserAvatar";
import EditableContent from "./EditableContent";
import { PrimaryPhoneNumber } from "./PhoneNumbers";
import { PrimaryEmail } from "./Emails";
import { ContactListSelect } from "./Contacts";
import ContactCompanyMemberships from "./ContactCompanyMemberships";
import { CompanyInvoicesSummary } from "./InvoicesSummary";
import FileInput from "./FileInput";
import moment from "moment";
import ImageGallery from "./ImageGallery";
import { useMutation } from "@apollo/react-hooks";
import * as S from "./styles";
import * as SVG from "./svgs";
import Notes, { ActivityFeed } from "./Notes";
import Table, { TR, TD } from "./Table";
import { primaryAssociation } from "./util";
import TopNav from "./TopNav";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import StudentDesignNav from "./StudentDesignNav";
import { Query, Mutation } from "@apollo/react-components";
import { PrimaryAddress } from "./Addresses";
import { Tag } from "./Tags";
import {
  StyledPanelFormWrapper,
  Select,
  RepeatableFieldButton,
} from "./StyledForm";
import { Formik, Field, Form, FieldArray } from "formik";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  StudentDesignSubmission as StudentDesignSubmissionType,
  NewImageValues,
  Contact,
} from "./apiTypes";
import { updateStudentDesignSubmissionMutation } from "./mutations/studentDesignSubmission";
import { StudentDesignSubmissionQuery } from "./queries/studentDesignSubmission";
import {
  createStudentDesignImageMutation,
  updateStudentDesignImageMutation,
  deleteStudentDesignImageMutation,
} from "./mutations/studentDesignSubmission";

type StudentDesignChallengeProps = RouteComponentProps<{
  id: string;
}>;

const StudentDesign: React.FunctionComponent<StudentDesignChallengeProps> = ({
  match,
}) => {
  const [updateStudentDesign, { loading }] = useMutation(
    updateStudentDesignSubmissionMutation,
    {
      refetchQueries: ["StudentDesignSubmissionQuery"],
    }
  );
  const [createStudentDesignImage, { loading: addLoading }] = useMutation<{
    createStudentDesignImage: NewImageValues;
  }>(createStudentDesignImageMutation, {
    refetchQueries: ["StudentDesignSubmissionQuery"],
  });
  const [updateStudentDesignImage] = useMutation<{
    updateStudentDesignImage: NewImageValues;
  }>(updateStudentDesignImageMutation, {
    refetchQueries: ["StudentDesignSubmissionQuery"],
  });
  const [deleteStudentDesignImage] = useMutation<{
    deleteStudentDesignImage: NewImageValues;
  }>(deleteStudentDesignImageMutation, {
    refetchQueries: ["StudentDesignSubmissionQuery"],
  });

  return (
    <Query<
      { studentDesignSubmission: StudentDesignSubmissionType },
      { submissionId: string }
    >
      query={StudentDesignSubmissionQuery}
      variables={{
        submissionId: match.params.id,
      }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (error)
          return (
            <Page>
              <TopNav />
              There is no Student Design Challenge entry associated with this
              id/url.
            </Page>
          );
        if (!data || !data.studentDesignSubmission)
          return (
            <Page>
              <TopNav />
              There is no Student Design Challenge entry associated with this
              id/url.
            </Page>
          );
        const studentDesignSubmission = data.studentDesignSubmission;
        if (!studentDesignSubmission)
          return (
            <Page>
              <TopNav />
              There is no Student Design Challenge entry associated with this
              id/url.
            </Page>
          );

        return (
          <div>
            <StudentDesignNav
              studentDesignSubmission={studentDesignSubmission}
              match={match}
            />
            <Page>
              <S.Flex>
                <S.Box width={1 / 3} my={0}>
                  <ImageGallery
                    images={studentDesignSubmission.images}
                    subjectId={studentDesignSubmission.id}
                    createFunc={createStudentDesignImage}
                    updateFunc={updateStudentDesignImage}
                    deleteFunc={deleteStudentDesignImage}
                  />
                  <S.Panel>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader noMargin>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              Submission Information
                              {!isOpen ? (
                                <S.Button size="small" onClick={open}>
                                  Edit
                                </S.Button>
                              ) : (
                                <S.Button size="small" onClick={close}>
                                  Cancel
                                </S.Button>
                              )}
                            </S.Flex>
                          </S.UnderlinedHeader>
                          {!isOpen ? (
                            <>
                              {studentDesignSubmission.status &&
                              studentDesignSubmission.status === "APPROVED" ? (
                                <>
                                  <Tag color="lightblue">Approved</Tag>
                                  {studentDesignSubmission.samplesReceived ? (
                                    <Tag color="lightgreen">
                                      Samples Received
                                    </Tag>
                                  ) : (
                                    <Tag color="lightyellow">
                                      Awaiting Samples
                                    </Tag>
                                  )}
                                </>
                              ) : studentDesignSubmission.status &&
                                studentDesignSubmission.status === "REJECTED" ? (
                                <Tag color="lightorange">Not Approved</Tag>
                              ) : (
                                <Tag color="lightyellow">Pending</Tag>
                              )}
                              {studentDesignSubmission.awardTier &&
                              studentDesignSubmission.awardTier === 1 ? (
                                <Tag color="gold">First Place</Tag>
                              ) : studentDesignSubmission.awardTier &&
                                studentDesignSubmission.awardTier === 2 ? (
                                <Tag color="silver">Second Place</Tag>
                              ) : studentDesignSubmission.awardTier &&
                                studentDesignSubmission.awardTier === 3 ? (
                                <Tag color="bronze">Third Place</Tag>
                              ) : (
                                <></>
                              )}
                              <Table cols="2fr 2fr" mt={4} mb={3}>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SUBMISSION NAME</b>
                                  </TD>
                                  <TD>{studentDesignSubmission.name}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SUBMISSION YEAR</b>
                                  </TD>
                                  <TD>{studentDesignSubmission.year}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>GROUP ID</b>
                                  </TD>
                                  <TD>{studentDesignSubmission.groupId}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SAMPLES STATUS</b>
                                  </TD>
                                  <TD>
                                    {studentDesignSubmission.samplesReceived
                                      ? "Received"
                                      : "Pending"}
                                  </TD>
                                </TR>
                                <TR>
                                  <TD>
                                    <b>AWARD</b>
                                  </TD>
                                  <TD>
                                    {studentDesignSubmission.awardTier &&
                                    studentDesignSubmission.awardTier === 1 ? (
                                      <>
                                        <SVG.AwardsSvg color="gold" />
                                        First Place
                                      </>
                                    ) : studentDesignSubmission.awardTier &&
                                      studentDesignSubmission.awardTier === 2 ? (
                                      <>
                                        <SVG.AwardsSvg color="silver" />
                                        Second Place
                                      </>
                                    ) : studentDesignSubmission.awardTier &&
                                      studentDesignSubmission.awardTier === 3 ? (
                                      <>
                                        <SVG.AwardsSvg color="bronze" />
                                        Third Place
                                      </>
                                    ) : (
                                      <>N/A</>
                                    )}
                                  </TD>
                                </TR>
                              </Table>
                            </>
                          ) : (
                            <Formik
                              initialValues={{
                                name: studentDesignSubmission.name
                                  ? studentDesignSubmission.name
                                  : "",
                                groupId: studentDesignSubmission.groupId
                                  ? studentDesignSubmission.groupId
                                  : undefined,
                                status: studentDesignSubmission.status
                                  ? studentDesignSubmission.status
                                  : undefined,
                                year: studentDesignSubmission.year
                                  ? studentDesignSubmission.year
                                  : new Date().getFullYear(),
                                samplesReceived: studentDesignSubmission.samplesReceived
                                  ? studentDesignSubmission.samplesReceived
                                  : false,
                                awardTier: studentDesignSubmission.awardTier
                                  ? studentDesignSubmission.awardTier
                                  : undefined,
                              }}
                              onSubmit={(values) => {
                                updateStudentDesign({
                                  variables: {
                                    input: {
                                      ...values,
                                      submissionId: studentDesignSubmission.id,
                                    },
                                  },
                                });
                                close();
                              }}
                              render={() => (
                                <Form>
                                  <StyledPanelFormWrapper>
                                    <label htmlFor="name">
                                      Entry Name
                                      <span
                                        style={{
                                          color: "red",
                                          display: "inline-block",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      required
                                      id="name"
                                      name="name"
                                      placeholder=""
                                      type="text"
                                    />
                                    <label htmlFor="year">
                                      Submission Year
                                      <span
                                        style={{
                                          color: "red",
                                          display: "inline-block",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      required
                                      id="year"
                                      name="year"
                                      type="number"
                                    />
                                    <label htmlFor="status">
                                      Submission Status
                                      <span
                                        style={{
                                          color: "red",
                                          display: "inline-block",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      required
                                      id="status"
                                      name="status"
                                      component={Select}
                                      closeMenuOnSelect={true}
                                      options={[
                                        {
                                          label: "Approved",
                                          value: "APPROVED",
                                        },
                                        {
                                          label: "Not Approved",
                                          value: "REJECTED",
                                        },
                                      ]}
                                    />
                                    <label htmlFor="awardTier">
                                      Submission Award Tier
                                    </label>
                                    <Field
                                      id="awardTier"
                                      name="awardTier"
                                      component={Select}
                                      closeMenuOnSelect={true}
                                      options={[
                                        {
                                          label: "None",
                                          value: 0,
                                        },
                                        {
                                          label: "First Place",
                                          value: 1,
                                        },
                                        {
                                          label: "Second Place",
                                          value: 2,
                                        },
                                        {
                                          label: "Third Place",
                                          value: 3,
                                        },
                                      ]}
                                    />
                                    <label htmlFor="groupId">
                                      Group Identifier
                                    </label>
                                    <Field
                                      id="groupId"
                                      name="groupId"
                                      placeholder=""
                                      type="text"
                                    />
                                    <S.Flex>
                                      <Field
                                        id="samplesReceived"
                                        name="samplesReceived"
                                        type="checkbox"
                                      />
                                      <label htmlFor="samplesReceived">
                                        Receieved Sample Packaging
                                      </label>
                                    </S.Flex>
                                    <button type="submit" disabled={loading}>
                                      Sav{loading ? "ing" : "e"}
                                    </button>
                                  </StyledPanelFormWrapper>
                                </Form>
                              )}
                            />
                          )}
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                  <S.Panel>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader noMargin>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              Supporting Documents
                              {!isOpen ? (
                                <S.Button size="small" onClick={open}>
                                  Edit
                                </S.Button>
                              ) : (
                                <S.Button size="small" onClick={close}>
                                  Cancel
                                </S.Button>
                              )}
                            </S.Flex>
                          </S.UnderlinedHeader>
                          {!isOpen ? (
                            <>
                              {studentDesignSubmission.status &&
                              studentDesignSubmission.status === "APPROVED" ? (
                                <>
                                  {studentDesignSubmission.conceptOutline ? (
                                    <Tag color="lightgreen">
                                      Concept Received
                                    </Tag>
                                  ) : (
                                    <Tag color="lightyellow">
                                      Awaiting Concept
                                    </Tag>
                                  )}
                                  {studentDesignSubmission.writtenDefense ? (
                                    <Tag color="lightgreen">
                                      Written Defense Received
                                    </Tag>
                                  ) : (
                                    <Tag color="lightyellow">
                                      Awaiting Written Defense
                                    </Tag>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              <S.Box pl={3} mt={5}>
                                {!studentDesignSubmission.conceptOutline &&
                                  !studentDesignSubmission.writtenDefense && (
                                    <>
                                      No supporting documents have been
                                      uploaded.
                                    </>
                                  )}
                                {studentDesignSubmission.conceptOutline && (
                                  <S.Box width={1 / 2} my={2}>
                                    <S.A
                                      href={
                                        studentDesignSubmission.conceptOutline
                                          .url
                                      }
                                    >
                                      <SVG.BookSvg />
                                      Concept Outline
                                    </S.A>
                                  </S.Box>
                                )}
                                {studentDesignSubmission.writtenDefense && (
                                  <S.Box width={1 / 2} my={2}>
                                    <S.A
                                      href={
                                        studentDesignSubmission.writtenDefense
                                          .url
                                      }
                                    >
                                      <SVG.BookSvg />
                                      Written Defense
                                    </S.A>
                                  </S.Box>
                                )}
                              </S.Box>
                            </>
                          ) : (
                            <Formik
                              initialValues={{
                                writtenDefense: undefined,
                                conceptOutline: undefined,
                              }}
                              onSubmit={async (values) => {
                                if (
                                  values.writtenDefense &&
                                  values.conceptOutline
                                ) {
                                  await updateStudentDesign({
                                    variables: {
                                      input: {
                                        ...values,
                                        submissionId:
                                          studentDesignSubmission.id,
                                      },
                                    },
                                  });
                                } else if (values.writtenDefense) {
                                  await updateStudentDesign({
                                    variables: {
                                      input: {
                                        conceptOutlineId: studentDesignSubmission.conceptOutline
                                          ? studentDesignSubmission
                                              .conceptOutline.id
                                          : undefined,
                                        writtenDefense: values.writtenDefense,
                                        submissionId:
                                          studentDesignSubmission.id,
                                      },
                                    },
                                  });
                                } else if (values.conceptOutline) {
                                  await updateStudentDesign({
                                    variables: {
                                      input: {
                                        writtenDefenseId: studentDesignSubmission.writtenDefense
                                          ? studentDesignSubmission
                                              .writtenDefense.id
                                          : undefined,
                                        conceptOutline: values.conceptOutline,
                                        submissionId:
                                          studentDesignSubmission.id,
                                      },
                                    },
                                  });
                                }
                                close();
                              }}
                              render={({ values }) => (
                                <Form>
                                  <StyledPanelFormWrapper>
                                    <label htmlFor="conceptOutline">
                                      Concept Outline
                                    </label>
                                    <Field
                                      id="conceptOutline"
                                      name="conceptOutline"
                                      value={values.conceptOutline}
                                      component={FileInput}
                                    />
                                    <label htmlFor="writtenDefense">
                                      Written Defense
                                    </label>
                                    <Field
                                      id="writtenDefense"
                                      name="writtenDefense"
                                      value={values.writtenDefense}
                                      component={FileInput}
                                    />
                                    <button type="submit" disabled={loading}>
                                      Sav{loading ? "ing" : "e"}
                                    </button>
                                  </StyledPanelFormWrapper>
                                </Form>
                              )}
                            />
                          )}
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                </S.Box>
                <S.Box width={2 / 3} my={0}>
                  <S.Panel>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              Student(s) Information
                              <S.Flex>
                                {studentDesignSubmission.contacts &&
                                studentDesignSubmission.contacts.length ? (
                                  <div style={{ marginRight: 10 }}>
                                    <EmailListButton
                                      contacts={
                                        studentDesignSubmission.contacts
                                      }
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {!isOpen ? (
                                  <S.Button size="small" onClick={open}>
                                    Edit
                                  </S.Button>
                                ) : (
                                  <S.Button size="small" onClick={close}>
                                    Cancel
                                  </S.Button>
                                )}
                              </S.Flex>
                            </S.Flex>
                          </S.UnderlinedHeader>
                          {!isOpen ? (
                            <S.Flex flexWrap="wrap">
                              {studentDesignSubmission.contacts &&
                              studentDesignSubmission.contacts.length ? (
                                studentDesignSubmission.contacts.map(
                                  (contact) => {
                                    return (
                                      <S.Box key={contact.id} width={1 / 3} my={2} ml={0} mr={3}>
                                        <Link to={`/contacts/${contact.id}`}>
                                          <S.Flex my={2} alignItems="center">
                                            <UserImage
                                              large
                                              src={
                                                contact.photoUrl
                                                  ? contact.photoUrl
                                                  : "/images/empty-user.png"
                                              }
                                            />
                                            <S.H3>
                                              {`${contact.firstName} ${contact.lastName}`}
                                            </S.H3>
                                          </S.Flex>
                                        </Link>
                                        <PrimaryPhoneNumber subject={contact} />
                                        <PrimaryEmail subject={contact} />
                                        <PrimaryAddress subject={contact} />
                                      </S.Box>
                                    );
                                  }
                                )
                              ) : (
                                <S.Box width={1} my={0} ml={0} mr={3}>
                                  No contacts have been assigned to this
                                  submission.
                                </S.Box>
                              )}
                            </S.Flex>
                          ) : (
                            <>
                              <Formik
                                initialValues={{
                                  contactIds: studentDesignSubmission.contacts
                                    ? studentDesignSubmission.contacts
                                        .map((c) => ({
                                          label: `${c.firstName} ${c.lastName}`,
                                          value: c.id,
                                        }))
                                        .filter(Boolean)
                                    : [],
                                }}
                                onSubmit={(values) => {
                                  updateStudentDesign({
                                    variables: {
                                      input: {
                                        contactIds:
                                          (values.contactIds &&
                                            values.contactIds.map((c) => {
                                              return c.value;
                                            })) ||
                                          [],
                                        submissionId:
                                          studentDesignSubmission.id,
                                      },
                                    },
                                  });
                                  close();
                                }}
                                render={({ values }) => (
                                  <StyledPanelFormWrapper>
                                    <Form>
                                      <FieldArray
                                        name="contactIds"
                                        render={(arrayHelpers) => (
                                          <div>
                                            {values.contactIds &&
                                            values.contactIds.length ? (
                                              values.contactIds.map(
                                                (contactId, index) => (
                                                  <S.Flex
                                                    mb={1}
                                                    alignItems="center"
                                                    key={index}
                                                  >
                                                    <div
                                                      style={{
                                                        flexBasis: "70%",
                                                        marginRight: 5,
                                                      }}
                                                    >
                                                      <ContactListSelect
                                                        name={`contactIds.${index}`}
                                                        value={true}
                                                      />
                                                    </div>
                                                    <RepeatableFieldButton
                                                      type="button"
                                                      onClick={() =>
                                                        arrayHelpers.remove(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <SVG.MinusSvg />
                                                    </RepeatableFieldButton>
                                                    <RepeatableFieldButton
                                                      type="button"
                                                      onClick={() =>
                                                        arrayHelpers.insert(
                                                          index + 1,
                                                          ""
                                                        )
                                                      }
                                                    >
                                                      <SVG.PlusSvg />
                                                    </RepeatableFieldButton>
                                                  </S.Flex>
                                                )
                                              )
                                            ) : (
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.push("")
                                                }
                                              >
                                                Add a contact.
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      />
                                      <button type="submit" disabled={loading}>
                                        Sav{loading ? "ing" : "e"}
                                      </button>
                                    </Form>
                                  </StyledPanelFormWrapper>
                                )}
                              />
                            </>
                          )}
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                  <S.Panel>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              School Information
                              {!isOpen ? (
                                <S.Button size="small" onClick={open}>
                                  Edit
                                </S.Button>
                              ) : (
                                <S.Button size="small" onClick={close}>
                                  Cancel
                                </S.Button>
                              )}
                            </S.Flex>
                          </S.UnderlinedHeader>
                          {!isOpen ? (
                            <>
                              <Table cols="2fr 2fr">
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SCHOOL</b>
                                  </TD>
                                  <TD>{studentDesignSubmission.school}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SUPERVISOR</b>
                                  </TD>
                                  <TD>
                                    {studentDesignSubmission.supervisorName}
                                  </TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SUPERVISOR EMAIL</b>
                                  </TD>
                                  <TD>
                                    <S.A
                                      href={`mailto:${studentDesignSubmission.supervisorEmail}`}
                                    >
                                      {studentDesignSubmission.supervisorEmail}
                                    </S.A>
                                  </TD>
                                </TR>
                              </Table>
                            </>
                          ) : (
                            <Formik
                              initialValues={{
                                school: studentDesignSubmission.school
                                  ? studentDesignSubmission.school
                                  : "",
                                supervisorName: studentDesignSubmission.supervisorName
                                  ? studentDesignSubmission.supervisorName
                                  : "",
                                supervisorEmail: studentDesignSubmission.supervisorEmail
                                  ? studentDesignSubmission.supervisorEmail
                                  : "",
                              }}
                              onSubmit={(values) => {
                                updateStudentDesign({
                                  variables: {
                                    input: {
                                      ...values,
                                      submissionId: studentDesignSubmission.id,
                                    },
                                  },
                                });
                                close();
                              }}
                              render={() => (
                                <Form>
                                  <StyledPanelFormWrapper>
                                    <label htmlFor="school">
                                      School Name
                                      <span
                                        style={{
                                          color: "red",
                                          display: "inline-block",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      required
                                      id="school"
                                      name="school"
                                      placeholder=""
                                      type="text"
                                    />
                                    <label htmlFor="supervisorName">
                                      Supervisor's Name
                                      <span
                                        style={{
                                          color: "red",
                                          display: "inline-block",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      required
                                      id="supervisorName"
                                      name="supervisorName"
                                      placeholder="John Doe"
                                      type="text"
                                    />
                                    <label htmlFor="supervisorEmail">
                                      Supervisor's Email
                                      <span
                                        style={{
                                          color: "red",
                                          display: "inline-block",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      required
                                      id="supervisorEmail"
                                      name="supervisorEmail"
                                      placeholder="john.doe@university.org"
                                      type="email"
                                    />
                                    <button type="submit" disabled={loading}>
                                      Sav{loading ? "ing" : "e"}
                                    </button>
                                  </StyledPanelFormWrapper>
                                </Form>
                              )}
                            />
                          )}
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                  <S.Panel pb={2}>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              Description
                              {!isOpen ? (
                                <S.Button size="small" onClick={open}>
                                  Edit
                                </S.Button>
                              ) : (
                                <S.Button size="small" onClick={close}>
                                  Cancel
                                </S.Button>
                              )}
                            </S.Flex>
                          </S.UnderlinedHeader>
                          {!isOpen ? (
                            <S.P>
                              {studentDesignSubmission.description
                                ? studentDesignSubmission.description
                                : ""}
                            </S.P>
                          ) : (
                            <Formik
                              initialValues={{
                                description: studentDesignSubmission.description
                                  ? studentDesignSubmission.description
                                  : "",
                              }}
                              onSubmit={(values) => {
                                updateStudentDesign({
                                  variables: {
                                    input: {
                                      ...values,
                                      submissionId: studentDesignSubmission.id,
                                    },
                                  },
                                });
                                close();
                              }}
                              render={() => (
                                <Form>
                                  <StyledPanelFormWrapper>
                                    <Field
                                      required
                                      id="description"
                                      name="description"
                                      placeholder="..."
                                      component="textarea"
                                    />
                                    <button type="submit" disabled={loading}>
                                      Sav{loading ? "ing" : "e"}
                                    </button>
                                  </StyledPanelFormWrapper>
                                </Form>
                              )}
                            />
                          )}
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                </S.Box>
              </S.Flex>
              <Notes subject={studentDesignSubmission} />
              <ActivityFeed subject={studentDesignSubmission} />
            </Page>
          </div>
        );
      }}
    </Query>
  );
};

export default StudentDesign;
