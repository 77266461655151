import React from "react";
import * as S from "./styles";
import Table, { TR, TD } from "./Table";
import * as SVG from "./svgs";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
// import Date from "./Date";
import { Tag } from "./Tags";
import { Query, Mutation } from "@apollo/react-components";
import Notes, { ActivityFeed } from "./Notes";
import { Registration as RegistrationType, Event } from "./apiTypes";
import RegistrationNav from "./RegistrationNav";
import moment from "moment";
import EditableContent from "./EditableContent";
import { registrationQuery } from "./queries/registration";
import { StyledPanelFormWrapper } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { Link, RouteComponentProps } from "react-router-dom";

type RegistrationProps = RouteComponentProps<{ registrationId: string }>;

const Registration: React.FunctionComponent<RegistrationProps> = ({
  match
}) => {
  return (
    <Query<{ registration: RegistrationType }, { registrationId: string }>
      query={registrationQuery}
      variables={{ registrationId: match.params.registrationId }}
    >
      {({ loading, error, data }) => {
        if (loading && !data) return <div />;
        if (error) return <div>Oops.</div>;
        if (!data || !data.registration) return <div>No data.</div>;
        const registration = data.registration;
        if (!registration) return <div />;

        return (
          <div>
            <RegistrationNav registration={registration} match={match} />
            <Page>
              <S.Panel pb={2}>
                <EditableContent>
                  {({ isOpen, open, close }) => (
                    <>
                      <S.UnderlinedHeader>
                        <S.Flex
                          alignItems="flex-end"
                          justifyContent="space-between"
                        >
                          Registration Information
                          {!isOpen ? (
                            <S.Button size="small" onClick={open}>
                              Edit
                            </S.Button>
                          ) : (
                            <S.Button size="small" onClick={close}>
                              Cancel
                            </S.Button>
                          )}
                        </S.Flex>
                      </S.UnderlinedHeader>
                      {!isOpen ? (
                        <Table cols="2fr 2fr">
                          <TR py={2}>
                            <TD>
                              <b>NAME</b>
                            </TD>
                            <TD>Dani Diehlmann</TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>NICKNAME</b>
                            </TD>
                            <TD>Dani</TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>COMPANY</b>
                            </TD>
                            <TD>Flexible Packaging Association</TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>JOB TITLE</b>
                            </TD>
                            <TD>Marketing Manager</TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>ADDRESS</b>
                            </TD>
                            <TD>
                              <S.Address>
                                185 Admiral Cochrane Drive
                                <span>Suite 105</span>
                                <span>Annapolis, Maryland 21401</span>
                                <span>United States of America</span>
                              </S.Address>
                            </TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>PHONE NUMBER</b>
                            </TD>
                            <TD>888-888-8888</TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>EMAIL ADDRESS</b>
                            </TD>
                            <TD>ddiehlmann@flexpack.org</TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>FIRST TIME ATTENDEE</b>
                            </TD>
                            <TD>
                              <SVG.CrossSvg />
                              NO
                            </TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>SPEAKER</b>
                            </TD>
                            <TD>
                              <SVG.CrossSvg />
                              NO
                            </TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>SPONSOR</b>
                            </TD>
                            <TD>
                              <SVG.CrossSvg />
                              NO
                            </TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>STAFF</b>
                            </TD>
                            <TD>
                              <SVG.CheckSvg />
                              YES
                            </TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>GUEST</b>
                            </TD>
                            <TD>
                              <Table cols="2fr 2fr">
                                <TR py={2}>
                                  <TD>
                                    <b>NAME</b>
                                  </TD>
                                  <TD>Lourdes Pogue</TD>
                                </TR>
                                <TR py={2}>
                                  <TD>
                                    <b>NICKNAME</b>
                                  </TD>
                                  <TD />
                                </TR>
                                <TR py={2}>
                                  <TD>
                                    <b>ADDRESS</b>
                                  </TD>
                                  <TD>
                                    <S.Address>
                                      185 Admiral Cochrane Drive
                                      <span>Suite 105</span>
                                      <span>Annapolis, Maryland 21401</span>
                                      <span>United States of America</span>
                                    </S.Address>
                                  </TD>
                                </TR>
                                <TR py={2}>
                                  <TD>
                                    <b>PHONE NUMBER</b>
                                  </TD>
                                  <TD>123-456-7890</TD>
                                </TR>
                                <TR py={2}>
                                  <TD>
                                    <b>EMAIL ADDRESS</b>
                                  </TD>
                                  <TD>guest@flexpack.org</TD>
                                </TR>
                                <TR py={2}>
                                  <TD>
                                    <b>FIRST TIME ATTENDEE</b>
                                  </TD>
                                  <TD>
                                    <SVG.CheckSvg />
                                    YES
                                  </TD>
                                </TR>
                              </Table>
                            </TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>NO. EVENT ATTENDEES</b>
                            </TD>
                            <TD>
                              <Table cols="2fr 2fr">
                                <TR py={2}>
                                  <TD>
                                    <b>Welcome Reception</b>
                                  </TD>
                                  <TD>2</TD>
                                </TR>
                                <TR py={2}>
                                  <TD>
                                    <b>Luncheon</b>
                                  </TD>
                                  <TD>0</TD>
                                </TR>
                                <TR py={2}>
                                  <TD>
                                    <b>Networking Reception</b>
                                  </TD>
                                  <TD>1</TD>
                                </TR>
                              </Table>
                            </TD>
                          </TR>
                          <TR py={2}>
                            <TD>
                              <b>COMPLETION DATE</b>
                            </TD>
                            <TD>July 21, 2019 3:18pm</TD>
                          </TR>
                          {/* <TR py={2}>
                            <TD>
                              <b>CANCELLATION DATE</b>
                            </TD>
                            <TD />
                          </TR> */}
                        </Table>
                      ) : (
                        // <Mutation<any> mutation={updateRegistrationMutation}>
                        //   {(updateRegistration, { loading }) => {
                        //     const regFormValues = {
                        //       description: registrationForm.description
                        //         ? registrationForm.description
                        //         : ""
                        //     }
                        //     return (
                        //       <Formik
                        //         initialValues={regFormValues}
                        //         onSubmit={values => {
                        //           updateRegistration({
                        //             variables: {
                        //               input: { ...values, registrationFormId: registrationForm.id }
                        //             }
                        //           });
                        //           close();
                        //         }}
                        //         render={() => (
                        //           <StyledPanelFormWrapper>
                        //             <Form>
                        //               <Field
                        //                 id="description"
                        //                 name="description"
                        //                 placeholder="..."
                        //                 component="textarea"
                        //               />
                        //               <button type="submit" disabled={loading}>
                        //                 Sav{loading ? "ing" : "e"}
                        //               </button>
                        //             </Form>
                        //           </StyledPanelFormWrapper>
                        //         )}
                        //       />
                        //     );
                        //   }}
                        // </Mutation>
                        <StyledPanelFormWrapper>
                          <Form>
                            <label>Name</label>
                            <Field
                              id="description"
                              name="description"
                              placeholder="..."
                              component="textarea"
                            />
                            <button type="submit">Save</button>
                          </Form>
                        </StyledPanelFormWrapper>
                      )}
                    </>
                  )}
                </EditableContent>
              </S.Panel>
              <S.Panel pb={2}>
                <S.UnderlinedHeader>Registration Fees</S.UnderlinedHeader>
                <Table cols="2fr 150px 120px 200px">
                  <TR borderless>
                    <TD>Invoice #2935222</TD>
                    <TD>
                      <b>$1,600.00</b>
                    </TD>
                    <TD>
                      <Tag color="lightblue">Paid</Tag>
                    </TD>
                    <TD>
                      <S.LinkButton
                        style={{ marginRight: "10px" }}
                        size="small"
                        to="/"
                      >
                        View
                      </S.LinkButton>
                    </TD>
                  </TR>
                </Table>
                <Table mt={3} cols="2fr 150px 120px 200px">
                  <TR header>
                    <TD>FEE</TD>
                    <TD>QUANTITY</TD>
                    <TD>COST</TD>
                    <TD>TOTAL</TD>
                  </TR>
                  <TR borderless py={2}>
                    <TD>
                      <b>Member Registration</b>
                    </TD>
                    <TD>1</TD>
                    <TD>$1,2000.00</TD>
                    <TD>$1,200.00</TD>
                  </TR>
                  <TR borderless py={2}>
                    <TD>
                      <b>Member Guest Registration</b>
                    </TD>
                    <TD>1</TD>
                    <TD>$400.00</TD>
                    <TD>$400.00</TD>
                  </TR>
                  <TR borderless py={2}>
                    <TD>
                      <b>Welcome Reception</b>
                    </TD>
                    <TD>2</TD>
                    <TD>FREE</TD>
                    <TD>$0</TD>
                  </TR>
                  <TR borderless py={2}>
                    <TD>
                      <b>Luncheon</b>
                    </TD>
                    <TD>2</TD>
                    <TD>$15.00</TD>
                    <TD>$30.00</TD>
                  </TR>
                  <TR borderless py={2}>
                    <TD>
                      <b>Networking Reception</b>
                    </TD>
                    <TD>1</TD>
                    <TD>FREE</TD>
                    <TD>$0</TD>
                  </TR>
                  <TR />
                  <TR py={2}>
                    <TD />
                    <TD />
                    <TD>
                      <b>SUBTOTAL</b>
                    </TD>
                    <TD>
                      <b>$1,630.00</b>
                    </TD>
                  </TR>
                </Table>
              </S.Panel>
            </Page>
          </div>
        );
      }}
    </Query>
  );
};

export default Registration;
