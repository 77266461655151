import React from "react";
import moment from "moment";
import UserAvatar from "./UserAvatar";
import * as S from "./styles";
import { useMutation } from "@apollo/react-hooks";
import Tags, { Tag } from "./Tags";
import PageNav, {
  NavInfo,
  TopPageNav,
} from "./PageNav";
import { match } from "react-router-dom";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { Contact, UseMutationReturn } from "./apiTypes";
import { Formik, Field, Form } from "formik";
import { StyledPanelFormWrapper } from "./StyledForm";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import {
  activateContactMutation,
  deactivateContactMutation,
} from "./mutations/contact";
import {
  sendPasswordResetMutation,
  createUserMutation,
} from "./mutations/user";

type ContactNavProps = {
  contact: Contact;
  match: match<{ contactId: string }>;
};

function EditContactMenu({ contact }: { contact: Contact }) {
  const menu = useMenuState();
  const newDialog = useDialogState();
  const [createUser, { loading }] = useMutation(createUserMutation, {
    refetchQueries: ["ContactQuery"],
  });
  const [activateContact]: UseMutationReturn<
    { activateContact: Contact },
    { input: { contactId: string } }
  > = useMutation(activateContactMutation, {
    refetchQueries: ["ContactQuery"],
  });
  const [deactivateContact]: UseMutationReturn<
    { deactivateContact: Contact },
    { input: { contactId: string } }
  > = useMutation(deactivateContactMutation, {
    refetchQueries: ["ContactQuery"],
  });
  const [sendPasswordReset] = useMutation(sendPasswordResetMutation, {
    refetchQueries: ["ContactQuery"],
  });

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Contact
      </MenuDisclosure>
      <Menu {...menu}>
        {/* <MenuItem {...menu}>Merge Contact</MenuItem> */}
        {contact.deactivatedAt ? (
          <MenuItem
            {...menu}
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to unarchive this Contact?"
                )
              ) {
                await activateContact({
                  variables: { input: { contactId: contact.id } },
                });
                menu.hide();
              }
            }}
          >
            Unarchive Contact
          </MenuItem>
        ) : (
          <MenuItem
            {...menu}
            onClick={async () => {
              if (
                window.confirm("Are you sure you want to archive this Contact?")
              ) {
                await deactivateContact({
                  variables: { input: { contactId: contact.id } },
                });
                menu.hide();
              }
            }}
          >
            Archive Contact
          </MenuItem>
        )}
        {contact.user ? (
          <MenuItem
            {...menu}
            onClick={async () => {
              await sendPasswordReset({
                variables: {
                  input: { userId: contact.user ? contact.user.id : "" },
                },
              });
              menu.hide();
            }}
          >
            Reset Password
          </MenuItem>
        ) : (
          <MenuItem
            {...menu}
            onClick={async () => {
              newDialog.show();
              menu.hide();
            }}
          >
            Create Credentials
          </MenuItem>
        )}
      </Menu>
      <DialogWithBackdrop {...newDialog}>
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={async (values) => {
            await createUser({
              variables: {
                input: {
                  contactId: contact.id,
                  email: values.email,
                  password: "password1",
                },
              },
            });
            newDialog.hide();
          }}
          render={({ values }) => (
            <S.Box p={3} my={0}>
              <S.UnderlinedHeader>New User</S.UnderlinedHeader>
              <Form>
                <StyledPanelFormWrapper>
                  <label htmlFor="email">
                    Email Address
                    <span style={{ color: "red", display: "inline-block" }}>
                      *
                    </span>
                  </label>
                  <Field
                    required
                    id="email"
                    name="email"
                    placeholder="test@email.com"
                    type="text"
                  />

                  <S.Button type="submit" disabled={loading}>
                    Creat{loading ? "ing" : "e"} User
                  </S.Button>
                </StyledPanelFormWrapper>
              </Form>
            </S.Box>
          )}
        />
      </DialogWithBackdrop>
    </>
  );
}

const ContactNav: React.FunctionComponent<ContactNavProps> = ({
  match,
  contact,
}) => {
  return (
    <PageNav>
      <S.Box pt={3} pb={4} px={3} my={0}>
        <TopPageNav />
        <NavInfo>
          <S.Flex ml={3} alignItems="center">
            {contact.photoUrl ? (
              <UserAvatar large src={contact.photoUrl} />
            ) : (
              <></>
            )}
            <div>
              <S.H1>
                {contact.firstName}{" "}
                {contact.nickname ? `'` + contact.nickname + `' ` : " "}
                {contact.lastName}{" "}
                <span>
                  {" "}
                  since {moment(contact.insertedAt).format("MMMM D, YYYY")}
                </span>
              </S.H1>
              {/* <ContactTags contact={contact} /> */}
              <Tags>
                {contact.deactivatedAt ? (
                  <Tag color="lightorange">Archived</Tag>
                ) : (
                  <Tag color="lightgreen">Active</Tag>
                )}
              </Tags>
            </div>
          </S.Flex>
          <EditContactMenu contact={contact} />
        </NavInfo>
      </S.Box>
    </PageNav>
  );
};

const ContactTags: React.FunctionComponent<{contact: Contact}> = ({
  contact
}) => {
  const contactMembershipsStatuses = contact.companyMemberships && contact.companyMemberships.map(m => { return m.endedOn});
  return (
    <Tags>
      {contact.deactivatedAt && <Tag color="lightorange">Archived</Tag>}
      {/* {contactMembershipsStatus ? (
        <Tag color="lightgreen">Active</Tag>
      ) : !contactMembershipsStatus ? (
        <Tag color="lightyellow">Previously Active</Tag>
      ) : (
        <></>
      )} */}
    </Tags>
  )
}

export default ContactNav;
