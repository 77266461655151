import { gql } from "graphql.macro";

export const StudentDesignSubmissionQuery = gql`
  query StudentDesignSubmissionQuery($submissionId: ID!) {
    studentDesignSubmission(submissionId: $submissionId) {
      id
      awardTier
      conceptOutline {
        id
        url
      }
      contacts {
        id
        firstName
        lastName
        photoUrl
        phoneNumbers {
          label
          phoneNumber
          preferred
        }
        emailAddresses {
          label
          emailAddress
          preferred
        }
        addresses {
          label
          city
          country
          postalCode
          state
          streetAddress
          suiteNumber
          preferred
        }
      }
      description
      documents {
        file {
          id
          filename
          url
        }
      }
      groupId
      images {
        file {
          id
          filename
          url
        }
        preferred
      }
      insertedAt
      name
      samplesReceived
      school
      status
      supervisorEmail
      supervisorName
      updatedAt
      writtenDefense {
        id
        filename
        url
      }
      year
      archivedAt
    }
  }
`;

export const StudentDesignSubmissionListQuery = gql`
  query StudentDesignSubmissionListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $year: Int
    $orderBy: StudentDesignSubmissionsOrderByInput
    $filter: StudentDesignSubmissionsFilterInput
  ) {
    studentDesignSubmissions(
      first: $first
      last: $last
      before: $before
      after: $after
      year: $year
      orderBy: $orderBy
      filter: $filter
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          awardTier
          groupId
          contacts {
            id
            firstName
            lastName
          }
          conceptOutline {
            id
            url
          }
          images {
            file {
              id
              url
            }
            preferred
          }
          insertedAt
          name
          samplesReceived
          school
          status
          writtenDefense {
            id
            url
          }
          year
          archivedAt
        }
      }
    }
  }
`;
