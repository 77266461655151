import React, { useState } from "react";
import * as S from "./styles";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form, FieldProps } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { __InputValue } from "graphql";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import { createStudentDesignSubmissionMutation } from "./mutations/studentDesignSubmission";
import { NewItemData, NewStudentDesignSubmissionValues } from "./apiTypes";
import FileInput from "./FileInput";

const NewStudentDesignSubmission: React.FC<{
  loading?: boolean;
  onSubmit: (
    input: NewStudentDesignSubmissionValues
  ) => Promise<void | MutationFetchResult<{
    createStudentDesign: NewItemData;
  }>>;
}> = ({ loading, onSubmit }) => {
  const [newId, setNewId] = useState<string | null>(null);

  const handleSubmit = async (input: NewStudentDesignSubmissionValues) => {
    try {
      const result = await onSubmit(input);
      if (!result)
        throw "Could not create student design challenge submission for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createStudentDesign &&
        result.data.createStudentDesign.id
      )
        return setNewId(result.data.createStudentDesign.id);
      throw "Could not create student design challenge submission for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }
  };

  if (newId) return <Redirect to={`/awards/student/${newId}`} />;

  return (
    <Formik
      initialValues={{
        awardTier: undefined,
        conceptOutline: undefined,
        description: "",
        groupId: undefined,
        name: "",
        samplesReceived: false,
        school: "",
        status: undefined,
        supervisorEmail: "",
        supervisorName: "",
        writtenDefense: undefined,
        year: new Date().getFullYear() + 1,
      }}
      onSubmit={handleSubmit}
      render={() => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>
            New Student Design Challenge Submission
          </S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              {/* awardTierm, groupId, samplesReceived, year */}
              <label htmlFor="name">
                Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="name"
                name="name"
                placeholder="Submission Name"
                type="text"
              />
              <label htmlFor="description">
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="description"
                name="description"
                placeholder="Describe the student design challenge submission."
                component="textarea"
              />
              <label htmlFor="status">
                Submission Status
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="status"
                name="status"
                component={Select}
                closeMenuOnSelect={true}
                options={[
                  {
                    label: "Approved",
                    value: "APPROVED",
                  },
                  {
                    label: "Rejected",
                    value: "REJECTED",
                  },
                ]}
              />
              <label htmlFor="school">
                School's Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="school"
                name="school"
                placeholder=""
                type="text"
              />
              <label htmlFor="supervisorName">
                School Supervisor's Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="supervisorName"
                name="supervisorName"
                placeholder="Dr. John Doe"
                type="text"
              />
              <label htmlFor="supervisorEmail">
                School Supervisor's Email Address
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="supervisorEmail"
                name="supervisorEmail"
                placeholder="j.doe@university.org"
                type="email"
              />
              <S.Flex>
                <div>
                  <label htmlFor="conceptOutline">Concept Outline</label>
                  <Field
                    id="conceptOutline"
                    name="conceptOutline"
                    component={FileInput}
                  />
                </div>
                <div>
                  <label htmlFor="writtenDefense">Written Defense</label>
                  <Field
                    id="writtenDefense"
                    name="writtenDefense"
                    component={FileInput}
                  />
                </div>
              </S.Flex>
              <S.Button type="submit" disabled={loading}>
                Creat{loading ? "ing" : "e"} Student Design Challenge Submission
              </S.Button>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
};

const ConnectedNewStudentDesignSubmission = (props: {}): React.ReactElement => {
  return (
    <Mutation<
      { createStudentDesign: NewItemData },
      { input: NewStudentDesignSubmissionValues }
    >
      mutation={createStudentDesignSubmissionMutation}
    >
      {(createStudentDesign, { loading }) => (
        <NewStudentDesignSubmission
          {...props}
          onSubmit={(input) => createStudentDesign({ variables: { input } })}
          loading={loading}
        />
      )}
    </Mutation>
  );
};

export default ConnectedNewStudentDesignSubmission;
