import React, { useState } from "react";
import Urls from "./Urls";
import PhoneNumbers from "./PhoneNumbers";
import Emails from "./Emails";
import Groups from "./Groups";
import FileUpload from "./FileUpload";
import EditableContent from "./EditableContent";
import Avatar from "./UserAvatar";
import { ContactAnalyticsSummary } from "./ContactAnalytics";
import ContactCompanyMemberships from "./ContactCompanyMemberships";
import { ContactInvoicesSummary } from "./InvoicesSummary";
import moment from "moment";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Contact } from "./apiTypes";
import Notes, { ActivityFeed } from "./Notes";
import Table, { TR, TD } from "./Table";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import { Mutation } from "@apollo/react-components";
import { Address } from "./Addresses";
import { Tag } from "./Tags";
import { RouteComponentProps } from "react-router-dom";
import {
  StyledPanelFormWrapper,
  Select,
} from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { updateContactMutation } from "./mutations/contact";

type SharedContactProps = {
  contact: Contact;
};
type ContactSummaryProps = RouteComponentProps<{ contactId: string }> &
  SharedContactProps;

// TODO: Implement props typing
const ContactSummary: React.FunctionComponent<ContactSummaryProps> = ({
  contact,
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(
    contact.photoUrl
  );

  let emailLabelOptions = [
    { label: "main", value: "main" },
    { label: "other", value: "other" },
  ];

  let urlLabelOptions = [
    { label: "main", value: "main" },
    { label: "other", value: "other" },
  ];

  let phoneNumberLabelOptions = [
    { label: "main", value: "main" },
    { label: "cell", value: "cell" },
    { label: "fax", value: "fax" },
    { label: "other", value: "other" },
  ];

  if (contact.companyMemberships) {
    contact.companyMemberships.map((companyMembership) => {
      emailLabelOptions.push({
        label: companyMembership.company.name,
        value: companyMembership.company.name,
      });
      urlLabelOptions.push({
        label: companyMembership.company.name,
        value: companyMembership.company.name,
      });
      phoneNumberLabelOptions.push({
        label: companyMembership.company.name,
        value: companyMembership.company.name,
      });
    });
  }

  return (
    <PageWithSidePanel thirds>
      <Page>
        <S.Flex>
          <S.Box width={2 / 3} mx={3}>
            <EditableContent>
              {({ isOpen, open, close }) => (
                <>
                  <S.UnderlinedHeader>
                    <S.Flex
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      Contact Details
                      {!isOpen ? (
                        <S.Button size="small" onClick={open}>
                          Edit
                        </S.Button>
                      ) : (
                        <S.Button size="small" onClick={close}>
                          Cancel
                        </S.Button>
                      )}
                    </S.Flex>
                  </S.UnderlinedHeader>
                  {!isOpen ? (
                    <S.Flex alignItems="center" mr={4}>
                      <S.Box mr={4}>
                        {contact.photoUrl ? (
                          <Avatar userIcon src={contact.photoUrl} />
                        ) : (
                          <></>
                        )}
                        {contact.birthday ? (
                          <S.Flex
                            justifyContent="center"
                            mb={2}
                            alignItems="center"
                          >
                            <SVG.CakeSvg />
                            {moment(contact.birthday).format("MMMM D")}
                          </S.Flex>
                        ) : (
                          ""
                        )}
                      </S.Box>
                      <S.Box pl={3}>
                        <S.H3>
                          {contact.prefix} {contact.firstName}{" "}
                          {contact.middleName} {contact.lastName}
                          {contact.suffix ? `, ${contact.suffix}` : ""}
                          {contact.nickname ? (
                            <span>{`'` + contact.nickname + `' `}</span>
                          ) : (
                            ""
                          )}
                        </S.H3>
                        {contact.allergies ? (
                          <S.P>
                            <b>Food Allergies/Restrictions:</b>{" "}
                            {contact.allergies}
                          </S.P>
                        ) : (
                          <></>
                        )}
                        {contact.golfHandedness ? (
                          <S.P>
                            <b>Golf Handedness:</b> {contact.golfHandedness}
                          </S.P>
                        ) : (
                          <></>
                        )}
                      </S.Box>
                    </S.Flex>
                  ) : (
                    <Mutation<any>
                      mutation={updateContactMutation}
                      refetchQueries={["ContactQuery"]}
                    >
                      {(updateContact, { loading }) => {
                        return (
                          <Formik
                            initialValues={{
                              firstName: contact.firstName
                                ? contact.firstName
                                : "",
                              middleName: contact.middleName
                                ? contact.middleName
                                : "",
                              lastName: contact.lastName
                                ? contact.lastName
                                : "",
                              nickname: contact.nickname
                                ? contact.nickname
                                : "",
                              suffix: contact.suffix ? contact.suffix : "",
                              prefix: contact.prefix ? contact.prefix : "",
                              birthday: contact.birthday
                                ? contact.birthday
                                : null,
                              photoUrl: contact.photoUrl
                                ? contact.photoUrl
                                : "",
                              file: {
                                id: "",
                                url: "",
                                filename: "",
                                contentType: "",
                              },
                              golfHandedness: contact.golfHandedness
                                ? contact.golfHandedness
                                : "",
                              allergies: contact.allergies
                                ? contact.allergies
                                : "",
                            }}
                            onSubmit={async (values) => {
                              // files mutation -> return fileId and use in updateContact mutation with photoId
                              if (values.birthday) {
                                values.birthday = moment(
                                  values.birthday
                                ).format("YYYY-MM-DD");
                              }
                              await updateContact({
                                variables: {
                                  input: {
                                    firstName: values.firstName,
                                    middleName: values.middleName,
                                    lastName: values.lastName,
                                    nickname: values.nickname,
                                    suffix: values.suffix,
                                    prefix: values.prefix,
                                    birthday: values.birthday || null,
                                    golfHandedness: values.golfHandedness,
                                    allergies: values.allergies,
                                    photoId:
                                      values.file && values.file.id
                                        ? values.file.id
                                        : undefined,
                                    contactId: contact.id,
                                  },
                                },
                              });
                              close();
                            }}
                            render={({ values }) => (
                              <Form>
                                <StyledPanelFormWrapper>
                                  <label>NAME</label>
                                  <S.Flex>
                                    <S.HalfWidth>
                                      <label htmlFor="firstName">
                                        First
                                        <span
                                          style={{
                                            color: "red",
                                            display: "inline-block",
                                          }}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <Field
                                        required
                                        id="firstName"
                                        name="firstName"
                                        placeholder="First"
                                        type="text"
                                      />
                                    </S.HalfWidth>
                                    <S.HalfWidth>
                                      <label htmlFor="middleName">Middle</label>
                                      <Field
                                        id="middleName"
                                        name="middleName"
                                        placeholder="Middle"
                                        type="text"
                                      />
                                    </S.HalfWidth>
                                    <S.HalfWidth>
                                      <label htmlFor="lastName">
                                        Last
                                        <span
                                          style={{
                                            color: "red",
                                            display: "inline-block",
                                          }}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <Field
                                        required
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Last"
                                        type="text"
                                      />
                                    </S.HalfWidth>
                                  </S.Flex>
                                  <S.Flex>
                                    <S.HalfWidth>
                                      <label htmlFor="prefix">Prefix</label>
                                      <Field
                                        id="prefix"
                                        name="prefix"
                                        placeholder="Prefix"
                                        type="text"
                                      />
                                    </S.HalfWidth>
                                    <S.HalfWidth>
                                      <label htmlFor="suffix">Suffix</label>
                                      <Field
                                        id="suffix"
                                        name="suffix"
                                        placeholder="Suffix"
                                        type="text"
                                      />
                                    </S.HalfWidth>
                                    <S.HalfWidth>
                                      <label htmlFor="nickname">Nickname</label>
                                      <Field
                                        id="nickname"
                                        name="nickname"
                                        placeholder=""
                                        type="text"
                                      />
                                    </S.HalfWidth>
                                  </S.Flex>
                                  <label htmlFor="photoUrl">
                                    Contact Image
                                  </label>
                                  <S.Flex>
                                    {imageUrl ? (
                                      <img
                                        height="35"
                                        src={imageUrl}
                                        alt="Contact Image"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <FileUpload
                                      onUpload={(files) => {
                                        values.file = files[0];
                                        setImageUrl(values.file.url);
                                      }}
                                      multiple={false}
                                    />
                                  </S.Flex>
                                  <label>Food Restrictions/Allergies</label>
                                  <Field
                                    id="allergies"
                                    name="allergies"
                                    placeholder="List any dietary restrictions or allergies."
                                    type="text"
                                  />
                                  <S.Flex>
                                    <S.HalfWidth>
                                      <label>Golf-Handedness</label>
                                      <Field
                                        id="golfHandedness"
                                        name="golfHandedness"
                                        classNamePrefix="label"
                                        component={Select}
                                        closeMenuOnSelect={true}
                                        options={[
                                          { value: "", label: "" },
                                          {
                                            value: "left",
                                            label: "Left",
                                          },
                                          {
                                            value: "right",
                                            label: "Right",
                                          },
                                        ]}
                                      />
                                    </S.HalfWidth>
                                    <S.HalfWidth>
                                      <label>Birthday</label>
                                      <Field
                                        id="birthday"
                                        name="birthday"
                                        type="date"
                                        min={`${new Date().getFullYear()}-01-01`}
                                        max={`${new Date().getFullYear()}-12-31`}
                                        value={
                                          values.birthday
                                            ? moment(values.birthday).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""
                                        }
                                      />
                                    </S.HalfWidth>
                                  </S.Flex>
                                  <S.Flex>
                                    <button type="submit" disabled={loading}>
                                      Sav{loading ? "ing" : "e"}
                                    </button>
                                  </S.Flex>
                                </StyledPanelFormWrapper>
                              </Form>
                            )}
                          />
                        );
                      }}
                    </Mutation>
                  )}
                </>
              )}
            </EditableContent>
          </S.Box>
          <S.Box width={1 / 3}>
            <Groups contact={contact} />
          </S.Box>
        </S.Flex>
        <S.Panel>
          <Address subject={contact} title="Address" />
        </S.Panel>
        <PhoneNumbers
          subject={contact}
          selectOptions={phoneNumberLabelOptions}
        />
        <Emails subject={contact} selectOptions={emailLabelOptions} />
        <Urls subject={contact} selectOptions={urlLabelOptions} />
        <ContactCompanyMemberships contact={contact} />
        <Notes subject={contact} />
        <ActivityFeed subject={contact} />
      </Page>
      <PageSidePanel data-testid="side-panel">
        <S.Box pending my={2}>
          <S.UnderlinedHeader>
            <S.Flex justifyContent="space-between" alignItems="flex-end">
              Registrations
              {/* <S.LinkButton
                size="small"
                to={`/contacts/${contact.id}/registrations`}
              >
                View More
              </S.LinkButton> */}
            </S.Flex>
          </S.UnderlinedHeader>
          <ContactRegistrations contact={contact} />
        </S.Box>
        <ContactInvoicesSummary contact={contact} />
        <S.Box pending my={2}>
          <S.UnderlinedHeader>
            <S.Flex justifyContent="space-between" alignItems="flex-end">
              Browsing History
              {/* <S.LinkButton
                size="small"
                to={`/contacts/${contact.id}/analytics`}
              >
                View More
              </S.LinkButton> */}
            </S.Flex>
          </S.UnderlinedHeader>
          <ContactAnalyticsSummary contact={contact} />
        </S.Box>
      </PageSidePanel>
    </PageWithSidePanel>
  );
};

const ContactRegistrations: React.FunctionComponent<{ contact: Contact }> = ({
  contact,
}) => {
  return (
    <Table cols="2fr 1fr 1fr" mt={1}>
      <TR>
        <TD>
          <S.Title>
            FPA Board of Directors Conference
            <span>June 1, 2018</span>
          </S.Title>
        </TD>
        <TD>
          <Tag color="lightblue">Paid</Tag>
        </TD>
        <TD>
          <S.LinkButton size="small" to="/">
            View
          </S.LinkButton>
        </TD>
      </TR>
      <TR>
        <TD>
          <S.Title>
            FPA Board of Directors Conference
            <span>June 1, 2018</span>
          </S.Title>
        </TD>
        <TD>
          <Tag color="lightblue">Paid</Tag>
        </TD>
        <TD>
          <S.LinkButton size="small" to="/">
            View
          </S.LinkButton>
        </TD>
      </TR>
    </Table>
  );
};

export default ContactSummary;
