import React, { useState, useCallback } from "react";
import Page, { PageButtons } from "./Page";
import Select, { ValueType } from "react-select";
import { Select as FormSelect } from "./StyledForm";
import { Field } from "formik";
import NewDownload from "./NewDownload";
import moment from "moment";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import Tags, { Tag } from "./Tags";
import { Link, useLocation } from "react-router-dom";
import Table, { TR, TD } from "./Table";
import { useQuery } from "@apollo/react-hooks";
import { useFormikContext } from "formik";
import { Query } from "@apollo/react-components";
import { DownloadListQueryData, SortValue, FilterValue } from "./apiTypes";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import { DownloadsListQuery } from "./queries/download";

const Downloads: React.FunctionComponent = () => {
  const query = new URLSearchParams(useLocation().search);
  const pageSize = 20;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;

  const addDialog = useDialogState();

  const sortOptions = [
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
    { label: "File Name A-Z", value: "NAME_ASC" },
    { label: "File Name Z-A", value: "NAME_DESC" }
  ];
  const filterOptions = [
    { label: "Public", value: "PUBLIC" },
    { label: "Members Only", value: "MEMBERS" },
    { label: "Private Only", value: "PRIVATE" },
  ];
  const categoryOptions = [
    { label: "FPA Now", value: "FPANOW" },
    { label: "Report", value: "REPORT" },
  ];
  const statusOptions = [
    { label: "All", value: undefined },
    // { labeel: "Store Item", value: "STORE" },
    { label: "Archived", value: "ARCHIVED" },
  ];

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const [status, setStatus] = useState<SortValue>();
  const [filters, setFilters] = useState<FilterValue>([]);
  const [category, setCategory] = useState<FilterValue>([]);
  const [fpaNow, setFpaNow] = useState<Boolean | undefined>();
  const [report, setReport] = useState<Boolean | undefined>();

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleStatus = (newValue: SortValue) => {
    setStatus(newValue);
  };
  const handleFilters = (newValues: FilterValue) => {
    setFilters(newValues);
  };
  const handleCategory = (newValues: FilterValue) => {
    setCategory(newValues);
    if (category && Array.isArray(category)) {
      if (
        category.filter((c) => c.value === "FPANOW") &&
        category.filter((c) => c.value === "FPANOW").length > 0
      )
        setFpaNow(true);
      if (
        category.filter((c) => c.value === "REPORT") &&
        category.filter((c) => c.value === "REPORT").length > 0
      )
        setReport(true);
    } else {
      setFpaNow(undefined);
      setReport(undefined);
    }
  };

  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "NAME_ASC",
    filter: {
      archived:
        status &&
        !Array.isArray(status) &&
        (status as { label: string; value: string }).value === "ARCHIVED",
      fpaNow,
      report,
    },
  };

  return (
    <Page>
      <TopNav />
      <S.Flex justifyContent="flex-end" mx={3}>
        <DialogDisclosure {...addDialog} as={S.Button}>
          New File
        </DialogDisclosure>
      </S.Flex>
      <S.Flex justifyContent="space-between" alignItems="flex-start" mx={3}>
        <div style={{ flex: 1, marginRight: 20 }}>
          <label>SORT BY</label>
          <Select
            closeMenuOnSelect={true}
            onChange={handleSort}
            options={sortOptions}
            value={sort}
          />
        </div>
        <div style={{ flex: 1, marginLeft: 20 }}>
          <label>FILTER BY</label>
          <S.Flex alignItems="center" justifyContent="space-between" my={2}>
            Status
            <div
              style={{
                flexBasis: "70%",
                marginLeft: "10",
              }}
            >
              <Select
                closeMenuOnSelect={false}
                onChange={handleStatus}
                options={statusOptions}
                value={status}
              />
            </div>
          </S.Flex>
          <S.Flex alignItems="center" justifyContent="space-between" my={2}>
            Publication Category
            <div
              style={{
                flexBasis: "70%",
                marginLeft: "10",
              }}
            >
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={handleCategory}
                options={categoryOptions}
                value={category}
              />
            </div>
          </S.Flex>
          <S.Flex alignItems="center" justifyContent="space-between" my={2}>
            Accessibility
            <div
              style={{
                flexBasis: "70%",
                marginLeft: "10",
              }}
            >
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={handleFilters}
                options={filterOptions}
                value={filters}
              />
            </div>
          </S.Flex>
        </div>
      </S.Flex>
      <DialogWithBackdrop {...addDialog}>
        <NewDownload />
      </DialogWithBackdrop>
      <Query<DownloadListQueryData>
        query={DownloadsListQuery}
        fetch-policy="network-only"
        variables={queryVars}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Oops.</div>;
          if (!data || !data.downloads || !Array.isArray(data.downloads.edges))
            return <div>No data.</div>;
          return (
            <>
              <PageButtons
                topMargin={4}
                pageInfo={data.downloads.pageInfo}
                basePath="files"
              />
              <S.Panel>
                <Table cols="2fr 1fr 150px">
                  <TR header>
                    <TD>FILE NAME</TD>
                    <TD>DATE ADDED</TD>
                    <TD />
                  </TR>
                  {data.downloads.edges.map((edge) => {
                    const file = edge.node;
                    if (!file) return <React.Fragment />;
                    return (
                      <TR key={file.id} pt={3} pb={2}>
                        <TD>
                          <Link to={`/files/${file.id}`}>
                            <S.Title>{file.name}</S.Title>
                          </Link>
                        </TD>
                        <TD>
                          {moment(file.insertedAt).format("MMMM D, YYYY")}
                        </TD>
                        <TD>
                          {file.file && file.file.url ? (
                            <S.ButtonedLink
                              size="small"
                              href={file.file.url}
                              target="_blank"
                              download={file.file.url}
                            >
                              <S.Flex alignItems="center">
                                <SVG.DownloadSvg />
                                Download
                              </S.Flex>
                            </S.ButtonedLink>
                          ) : (
                            <></>
                          )}
                        </TD>
                        <Tags>
                          {file.acl && file.acl === "PRIVATE" ? (
                            <Tag color="lightorange">Private</Tag>
                          ) : file.acl && file.acl === "PUBLIC" ? (
                            <Tag color="lightgreen">Public</Tag>
                          ) : file.acl && file.acl === "MEMBERS" ? (
                            <Tag color="lightblue">Members Only</Tag>
                          ) : (<></>)}
                          {file.report && <Tag color="darkgreen">Report</Tag>}
                          {file.fpaNow && <Tag color="darkblue">FPA NOW</Tag>}
                          {file.archivedAt && (
                            <Tag color="lightorange">Archived</Tag>
                          )}
                        </Tags>
                      </TR>
                    );
                  })}
                </Table>
              </S.Panel>
              <PageButtons
                pageInfo={data.downloads.pageInfo}
                basePath="files"
              />
            </>
          );
        }}
      </Query>
    </Page>
  );
};

const DownloadSelect = (props: { name: string }) => {
  const formik = useFormikContext<any>();
  const { data, loading, error } = useQuery<DownloadListQueryData>(
    DownloadsListQuery,
    {
      variables: { first: 2000, orderBy: "NAME_ASC" },
    }
  );

  const handleChange = useCallback(
    ({ value }: any) => {
      formik.setFieldValue(props.name, value);
    },
    [props.name, formik.setFieldValue]
  );

  if (loading && !data) return <div />;
  if (error) return <div>Oops.</div>;
  if (!data || !data.downloads || !Array.isArray(data.downloads.edges))
    return <div>No data.</div>;

  var downloadsList = data.downloads.edges
    .map((edge) => {
      const download = edge.node;
      if (!download) return null;
      const downloadName = download.name + " (" + download.acl + ")";
      return { label: downloadName, value: download.id };
    })
    .filter(Boolean) as ValueType<{ label: string; value: string }>[];

  return (
    <Field
      id="downloadId"
      name="downloadId"
      component={FormSelect}
      closeMenuOnSelect={true}
      options={downloadsList}
      onChange={handleChange}
    />
  );
};

export default Downloads;
export { DownloadSelect };
