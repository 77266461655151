import React from "react";
import { FieldProps } from "formik";
export default (props: FieldProps<any>) => {
  const { field, form } = props;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    // Add code here to transform the file such as with an api call, etc
    form.setFieldValue(field.name, file);
  };

  return (
    <div>
      <input type="file" onChange={event => handleChange(event)} />
    </div>
  );
};
