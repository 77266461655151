import React, { useState } from "react";
import * as S from "./styles";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { DownloadSelect } from "./Downloads";
import { __InputValue } from "graphql";
import {
  StyledPanelFormWrapper,
  Select,
  MoneyField,
  FormikDatetime,
  FormikDate,
} from "./StyledForm";
import EditableContent from "./EditableContent";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { createStoreItemMutation } from "./mutations/storeItem";
import { createDownloadMutation } from "./mutations/download";
import { DownloadQuery } from "./queries/download";
import {
  NewDownloadValues,
  NewStoreItemValues,
  File,
  Download,
} from "./apiTypes";
import FileInput from "./FileInput";
import FileUpload from "./FileUpload";
import NumberInput from "./NumberInput";
import moment from "moment";

interface NewItemData {
  id: string;
}

interface NewStoreItemCallValues {
  name: string;
  downloadId: string | undefined;
  file: File | undefined;
  sku: string | undefined;
  description: string;
  summary: string;
  price: number | string;
  publishedAt: string | undefined;
  deactivatedAt: string | undefined;
  acl: NewDownloadValues["acl"];
}

const NewStoreItem: React.FC<{}> = () => {
  const [newId, setNewId] = useState<string | null>(null);
  const apollo = useApolloClient();

  const [createDownload, { loading: downloadLoading }] = useMutation<
    { createDownload: { id: string } },
    { input: NewDownloadValues }
  >(createDownloadMutation);

  const [createStoreItem, { loading: storeItemLoading }] = useMutation<
    { createStoreItem: { id: string } },
    { input: NewStoreItemValues }
  >(createStoreItemMutation);

  const loading = downloadLoading || storeItemLoading;

  if (newId) return <Redirect to={`/store/${newId}`} />;

  return (
    <Formik<NewStoreItemCallValues>
      initialValues={{
        name: "",
        downloadId: "",
        file: undefined,
        sku: undefined,
        description: "",
        summary: "",
        price: "0",
        publishedAt: undefined,
        deactivatedAt: undefined,
        acl: "PUBLIC",
      }}
      onSubmit={async (values) => {
        if (values.file) {
          let download = await createDownload({
            variables: {
              input: {
                name: values.name,
                file: values.file,
                description: values.description,
                acl: values.acl,
              },
            },
          });
          values.downloadId =
            download && download.data && download.data.createDownload.id;
        } else {
          const download = await apollo.query<{ download: Download }>({
            query: DownloadQuery,
            variables: { downloadId: values.downloadId },
          });
          const downloadData = download.data && download.data.download;
          if (downloadData) {
            values.name = downloadData.name || "";
            values.downloadId = downloadData.id || "";
            values.description = downloadData.description || values.description;
          }
        }
        let price = values.price;
        if (typeof price === "string") {
          price = parseFloat(price) * 100;
        } else {
          price = price * 100;
        }
        let storeItem = await createStoreItem({
          variables: {
            input: {
              name: values.name,
              downloadId: values.downloadId,
              sku: values.sku,
              description: values.description,
              summary: values.summary,
              price: price,
              publishedAt: values.publishedAt,
              deactivatedAt: values.deactivatedAt,
            },
          },
        });
        return setNewId(
          (storeItem && storeItem.data && storeItem.data.createStoreItem.id) ||
            null
        );
      }}
      render={() => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Store Item</S.UnderlinedHeader>
          <StyledPanelFormWrapper>
            <Form>
              <S.UnderlinedHeader>
                <EditableContent>
                  {({ isOpen, open, close }) => (
                    <>
                      {!isOpen ? (
                        <>
                          <label htmlFor="downloadId">
                            Select A File
                            <span
                              style={{
                                color: "red",
                                display: "inline-block",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <DownloadSelect name="downloadId" />
                          <S.Button size="small" onClick={open}>
                            Or Create A New File
                          </S.Button>
                        </>
                      ) : (
                        <>
                          <label htmlFor="name">
                            File Name
                            <span
                              style={{ color: "red", display: "inline-block" }}
                            >
                              *
                            </span>
                          </label>
                          <Field
                            required
                            id="name"
                            name="name"
                            placeholder="File Name"
                            type="text"
                          />
                          <S.Flex>
                            <S.HalfWidth>
                              <label htmlFor="file">
                                File
                                <span
                                  style={{
                                    color: "red",
                                    display: "inline-block",
                                  }}
                                >
                                  *
                                </span>
                                <span>May not exceed 8MB in size.</span>
                              </label>
                              <Field
                                id="file"
                                name="file"
                                component={FileInput}
                                required
                              />
                            </S.HalfWidth>
                            <S.HalfWidth>
                              <label htmlFor="acl">File Access Level</label>
                              <Field
                                required
                                id="acl"
                                name="acl"
                                component={Select}
                                closeMenuOnSelect={true}
                                options={[
                                  {
                                    label: "Open To the Public",
                                    value: "PUBLIC",
                                  },
                                  {
                                    label: "Members Only",
                                    value: "MEMBERS",
                                  },
                                  {
                                    label: "Closed Access; Private Only.",
                                    value: "PRIVATE",
                                  },
                                ]}
                              />
                            </S.HalfWidth>
                          </S.Flex>
                          <S.Button size="small" onClick={close}>
                            Choose Existing Download
                          </S.Button>
                        </>
                      )}
                    </>
                  )}
                </EditableContent>
              </S.UnderlinedHeader>
              <S.Flex>
                <S.HalfWidth>
                  <label>SKU</label>
                  <Field
                    required
                    id="sku"
                    name="sku"
                    placeholder="####"
                    type="text"
                  />
                </S.HalfWidth>
                <S.HalfWidth>
                  <label>Member Price</label>
                  {/*
                    required
                    id="price"
                    name="price"
                    component={MoneyField}
                  /> */}
                  <Field
                    required
                    id="price"
                    name="price"
                    component={NumberInput}
                    decimalScale={2}
                    fixedDecimalScale={2}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </S.HalfWidth>
                {/* <S.HalfWidth>
                  <label>Non-Member Price</label>
                  <Field
                    required
                    id="price"
                    name="price"
                    component={MoneyField}
                  />
                </S.HalfWidth> */}
              </S.Flex>
              <label htmlFor="summary">Short Summary</label>
              <Field id="summary" name="summary" component="textarea" />
              <label htmlFor="description">Description</label>
              <Field id="description" name="description" component="textarea" />
              <S.Flex>
                <div>
                  <label htmlFor="publishedAt">Date Add To Store</label>
                  <Field
                    id="publishedAt"
                    name="publishedAt"
                    value={new Date()}
                    component={FormikDatetime}
                  />
                </div>
                <div>
                  <label htmlFor="deactivatedAt">Date Remove From Store</label>
                  <Field
                    id="deactivatedAt"
                    name="deactivatedAt"
                    component={FormikDatetime}
                  />
                </div>
              </S.Flex>
              <S.Button type="submit" disabled={loading}>
                Creat{loading ? "ing" : "e"} Store Item
              </S.Button>
            </Form>
          </StyledPanelFormWrapper>
        </S.Box>
      )}
    />
  );
};

export default NewStoreItem;
