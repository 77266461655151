import { gql } from "graphql.macro";

export const createAddressMutation = gql`
  mutation createAddressMutation($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
      label
      streetAddress
      suiteNumber
      city
      state
      country
      postalCode
      preferred
    }
  }
`;
export const deleteAddressMutation = gql`
  mutation deleteAddressMutation($input: DeleteAddressInput!) {
    deleteAddress(input: $input) {
      id
    }
  }
`;

export const updateAddressMutation = gql`
  mutation updateAddressMutation($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      id
      label
      streetAddress
      suiteNumber
      city
      state
      country
      postalCode
      subjectId
      preferred
    }
  }
`;
