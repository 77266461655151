import styled from "styled-components";
import { readableColor } from "polished";

type TagProps = {
  color?: string;
};

function tagColor<T extends any>(p: T) {
  if (!p.color) return p.theme.tagColors.lightblue;
  return p.theme.tagColors[p.color] || p.color;
}

const Tags = styled.div`
  position: absolute;
  top: -2px;
  left: 20px;
`;

const Tag = styled.div<TagProps>`
  padding: 3px 24px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 0 0 4px 4px;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;

  color: ${p => readableColor(tagColor(p))};
  background: ${tagColor};
`;

export default Tags;
export { Tag };
