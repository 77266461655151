import { CompanyContact } from "./apiTypes";

export function joinPath(base: string, path: string) {
  return base.charAt(base.length - 1) === "/"
    ? base.slice(0, -1) + path
    : base + path;
}

type MutationInput = {
  id?: string;
};

type AssociationMutationFunction<Input> = (i: Input) => Promise<any>;

type MutationFunctions<Input extends MutationInput> = {
  create: AssociationMutationFunction<Input>;
  update: AssociationMutationFunction<Input>;
  destroy: AssociationMutationFunction<Input>;
};

// associations are from the Form input, while existingAssociations are from the subject's input
export function associationMutations<Input extends MutationInput>(
  associations: Input[],
  existingAssociations: Input[],
  functions: MutationFunctions<Input>
) {
  const { create, update, destroy } = functions;
  const deletes = existingAssociations.filter(existingEl => {
    return (
      associations.filter(el => {
        return el.id === existingEl.id;
      }).length === 0
    );
  });
  // deletes if found in existingAssociations (with id) but not associations
  deletes.map(a => destroy(a));
  // creates if associations have no id
  associations.filter(a => !a.id).map(a => create(a));
  // updates if associations have id
  associations.filter(a => a.id).map(a => update(a));
}

type PrimaryAssociationInput = {
  preferred?: boolean;
  [x: string]: any;
};

export function primaryAssociation(
  associations: PrimaryAssociationInput[] | undefined
) {
  const main = associations
    ? associations.filter(obj => {
        return obj.label === "main";
      })
    : [];
  const primary = associations
    ? associations.filter(obj => {
        return obj.preferred === true;
      })
    : [];
  return main.length > 0
    ? main[0]
    : primary.length > 0
    ? primary[0]
    : undefined;
}

type LabelledAssociationInput = {
  label?: string;
  [x: string]: any;
};

export function labelledAssociation(
  label: string,
  associations: LabelledAssociationInput[] | undefined
) {
  const returnAssociations = associations
    ? associations.filter(obj => {
        return obj.label === label;
      })
    : [];
  return returnAssociations.length >= 1 ? returnAssociations[0] : undefined;
}

export function primaryContact(contacts: CompanyContact[]) {
  const primary = contacts
    ? contacts.filter(obj => {
        return obj.primaryContact === true;
      })
    : [];
  return primary.length >= 1 ? primary[0] : undefined;
}

export function authorizedContacts(contacts: CompanyContact[]) {
  const authorizedContacts = contacts
    ? contacts.filter(obj => {
        return obj.authorizedRepresentative === true;
      })
    : [];
  return authorizedContacts.length >= 1 ? authorizedContacts : undefined;
}

export function isObject(value: any): value is Object {
  return value && value.constructor === Object;
}

export function extractFiles(value: any, path: string = "") {
  let clone: any;
  const files = new Map<File | Blob, string>();

  function addFile(key: string, file: File | Blob) {
    const storedFile = files.get(file);
    if (storedFile) return storedFile;
    else files.set(file, key);
  }

  const prefix = path ? `${path}.` : "";

  if (
    (typeof File !== "undefined" && value instanceof File) ||
    (typeof Blob !== "undefined" && value instanceof Blob)
  ) {
    clone = `${prefix}0`;
    addFile(clone, value);
  } else {
    if (typeof FileList !== "undefined" && value instanceof FileList) {
      clone = Array.prototype.map.call(value, (file, i) => {
        const filePath = `${prefix}${i}`;
        addFile(filePath, file);
        return filePath;
      });
    } else if (Array.isArray(value)) {
      clone = value.map((child, i) => {
        const result = extractFiles(child, `${prefix}${i}`);
        result.files.forEach(addFile);
        return result.clone;
      });
    } else if (isObject(value)) {
      clone = {};
      for (const i in value) {
        const result = extractFiles(value[i], `${prefix}${i}`);
        result.files.forEach(addFile);
        clone[i] = result.clone;
      }
    } else clone = value;
  }

  return { clone, files };
}

export function cleanUpUrl(
  url: string
) {
  return url.replace(/\s/g, "%20");
}