import React, { useState } from "react";
import * as S from "./styles";
import { Redirect } from "react-router-dom";
import { CompanySelect } from "./Companies";
import { CompanyContactSelect } from "./Contacts";
import { LocationSelect } from "./Locations";
import { Formik, Field, Form, FieldProps } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { __InputValue } from "graphql";
import { StyledPanelFormWrapper, FormikDatetime, Select } from "./StyledForm";
import { companyListQuery, companySearchQuery } from "./queries/company";
import { createAchievementAwardSubmissionMutation } from "./mutations/achievementAwardSubmission";
import {
  NewItemData,
  CompanyListQueryData,
  AchievementAwardCategory,
  CreateAchievementAwardSubmissionValues,
} from "./apiTypes";
import { useQuery } from "@apollo/react-hooks";
import { achievementAwardCategoryListQuery } from "./queries/achievementAwardSubmission";

const NewAchievementAward: React.FC<{
  loading?: boolean;
  companyId?: string;
  onSubmit: (
    input: CreateAchievementAwardSubmissionValues
  ) => Promise<void | MutationFetchResult<{
    createAchievementAwardSubmission: NewItemData;
  }>>;
}> = ({ loading, companyId, onSubmit }) => {
  const [newId, setNewId] = useState<string | null>(null);

  const { data, error } = useQuery<{
    achievementAwardCategories: AchievementAwardCategory[];
  }>(achievementAwardCategoryListQuery);
  const categories =
    data &&
    data.achievementAwardCategories.map((c) => {
      return { label: c.name, value: c.id };
    });

  const handleSubmit = async (
    input: CreateAchievementAwardSubmissionValues
  ) => {
    try {
      const result = await onSubmit(input);
      if (!result)
        throw "Could not create achievement award for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createAchievementAwardSubmission &&
        result.data.createAchievementAwardSubmission.id
      )
        return setNewId(result.data.createAchievementAwardSubmission.id);
      throw "Could not create achievement award for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }
  };
  if (newId) return <Redirect to={`/awards/achievement/${newId}`} />;

  return (
    <Formik
      initialValues={{
        categoryIds: [],
        certified: undefined,
        companyId: companyId || "",
        contactId: "",
        description: "",
        designerFirm: undefined,
        designerName: undefined,
        endUser: undefined,
        groupId: undefined,
        highestAchievement: undefined,
        keySuppliers: undefined,
        locationId: "",
        name: "",
        produced: undefined,
        samplesReceived: undefined,
        stripeCheckoutSessionId: undefined,
        year: new Date().getFullYear() + 1,
      }}
      onSubmit={handleSubmit}
      render={(props) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>
            New Achievement Award Submission
          </S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              <label htmlFor="name">
                Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field required id="name" name="name" type="text" />
              <label htmlFor="description">
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="description"
                name="description"
                placeholder="A brief overview of the submission."
                component="textarea"
              />
              <label htmlFor="companyId">
                Company
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <CompanySelect name="companyId" />
              {props.values.companyId && (
                <>
                  <label htmlFor="contactId">
                    Contact
                    <span style={{ color: "red", display: "inline-block" }}>
                      *
                    </span>
                  </label>
                  <CompanyContactSelect
                    name="contactId"
                    companyId={props.values.companyId}
                  />
                  <label htmlFor="locationId">
                    Plant
                    <span style={{ color: "red", display: "inline-block" }}>
                      *
                    </span>
                  </label>
                  <LocationSelect
                    name="locationId"
                    companyId={props.values.companyId}
                  />
                </>
              )}
              <label htmlFor="categoryIds">Categories</label>
              <Field
                isMulti
                id="categoryIds"
                name="categoryIds"
                component={Select}
                closeMenuOnSelect={false}
                options={categories}
              />
              <S.Flex>
                <Field
                  id="samplesReceived"
                  name="samplesReceived"
                  type="checkbox"
                />
                <label htmlFor="samplesReceived">
                  Receieved Sample Packaging
                </label>
              </S.Flex>
              <S.Button type="submit" disabled={loading}>
                Creat{loading ? "ing" : "e"} Submission
              </S.Button>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
};

const ConnectedNewAchievementAward = (props: {
  companyId?: string;
}): React.ReactElement => {
  return (
    <Mutation<
      { createAchievementAwardSubmission: NewItemData },
      { input: CreateAchievementAwardSubmissionValues }
    >
      mutation={createAchievementAwardSubmissionMutation}
    >
      {(createAchievementAwardSubmission, { loading }) => (
        <NewAchievementAward
          {...props}
          onSubmit={(input) =>
            createAchievementAwardSubmission({ variables: { input } })
          }
          loading={loading}
        />
      )}
    </Mutation>
  );
};

export default ConnectedNewAchievementAward;
