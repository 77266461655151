import { gql } from "graphql.macro";

export const createDownloadMutation = gql`
  mutation createDownloadMutation($input: CreateDownloadInput!) {
    createDownload(input: $input) {
      id
    }
  }
`;

export const updateDownloadMutation = gql`
  mutation updateDownloadMutation($input: UpdateDownloadInput!) {
    updateDownload(input: $input) {
      id
    }
  }
`;

export const archiveDownloadMutation = gql`
  mutation archiveDownloadMutation($input: ArchiveDownloadInput!) {
    archiveDownload(input: $input) {
      downloadId
    }
  }
`;
