import React, { useState } from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import { useEditToggle } from "./hooks";
import { Formik, Field, Form, FieldArray } from "formik";
import { Company, Contact, Location, EmailAddress } from "./apiTypes";
import {
  StyledPanelFormWrapper,
  RepeatableFieldButton,
  RadioButton,
  EditableSelect,
  SelectOptions,
} from "./StyledForm";
import { associationMutations, primaryAssociation } from "./util";
import {
  updateEmailAddressMutation,
  createEmailAddressMutation,
  deleteEmailAddressMutation,
} from "./mutations/emailAddress";
import { useMutation } from "@apollo/react-hooks";

export default function Emails({
  subject,
  selectOptions,
}: {
  subject: Company | Contact | Location;
  selectOptions: SelectOptions[];
}) {
  const { isOpen, open, close } = useEditToggle();
  const [loading, setLoading] = useState(false);
  const preferredIdx: number = (subject.emailAddresses || []).findIndex(
    (p) => p.preferred
  );
  const preferredIdxValue: string | null =
    preferredIdx >= 0 ? preferredIdx.toString() : null;
  const [createEmailAddress] = useMutation<{
    createEmailAddress: EmailAddress;
  }>(createEmailAddressMutation, {
    refetchQueries: ["companyQuery", "ContactQuery"],
  });
  const [updateEmailAddress] = useMutation<{
    updateEmailAddress: EmailAddress;
  }>(updateEmailAddressMutation);
  const [deleteEmailAddress] = useMutation<{
    deleteEmailAddress: EmailAddress;
  }>(deleteEmailAddressMutation, {
    refetchQueries: ["companyQuery", "ContactQuery"],
  });

  return (
    <S.Panel pb={2}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          <S.Flex alignItems="center" justifyContent="flex-start">
            <SVG.EmailSvg />
            Email Addresses
          </S.Flex>
          {!isOpen ? (
            <S.Button size="small" onClick={open}>
              Edit
            </S.Button>
          ) : (
            <S.Button size="small" onClick={close}>
              Cancel
            </S.Button>
          )}
        </S.Flex>
      </S.UnderlinedHeader>
      {!isOpen ? (
        <S.Box>
          <S.ContactList>
            {subject.emailAddresses && subject.emailAddresses.length > 0
              ? subject.emailAddresses.map((emailAddress) => (
                  <li key={emailAddress.id}>
                    {emailAddress.preferred ? "*" : ""}
                    {emailAddress.label}:{" "}
                    <b>
                      <S.A href={`mailto:${emailAddress.emailAddress}`}>
                        {emailAddress.emailAddress}
                      </S.A>
                    </b>
                  </li>
                ))
              : "No Email Addresses on Record"}
          </S.ContactList>
        </S.Box>
      ) : (
        <Formik
          initialValues={{
            emailAddresses: subject.emailAddresses || [],
            preferredIdx: preferredIdxValue,
          }}
          onSubmit={async (values) => {
            let input: any = { ...values };
            if (values.preferredIdx) {
              input.emailAddresses[
                parseInt(values.preferredIdx)
              ].preferred = true;
            }
            setLoading(true);
            try {
              await associationMutations(
                input.emailAddresses,
                subject.emailAddresses ? subject.emailAddresses : [],
                {
                  create: (a) =>
                    createEmailAddress({
                      variables: {
                        input: {
                          emailAddress: a.emailAddress,
                          label: a.label,
                          subjectId: subject.id,
                          preferred: a.preferred,
                        },
                      },
                    }),
                  update: ({ id, ...a }) =>
                    updateEmailAddress({
                      variables: {
                        input: {
                          emailAddressId: id,
                          emailAddress: a.emailAddress,
                          label: a.label,
                          preferred: a.preferred,
                        },
                      },
                    }),
                  destroy: ({ id }) =>
                    deleteEmailAddress({
                      variables: { input: { emailAddressId: id } },
                    }),
                }
              );
            } catch (err) {
              console.error(err);
            } finally {
              setLoading(false);
            }
            close();
          }}
          render={({ values }) => (
            <Form>
              <StyledPanelFormWrapper>
                <FieldArray
                  name="emailAddresses"
                  render={(arrayHelpers) => (
                    <div>
                      {values.emailAddresses &&
                      values.emailAddresses.filter((a) => a.label !== "delete")
                        .length > 0 ? (
                        values.emailAddresses.map((email, index) => (
                          <S.Flex mb={1} alignItems="center" key={index}>
                            <div
                              style={{
                                flexBasis: "5%",
                                marginRight: 5,
                              }}
                            >
                              <Field
                                name="preferredIdx"
                                id={`emailAddresses.${index}.preferred`}
                                index={index}
                                component={RadioButton}
                                label="Preferred Email"
                              />
                            </div>
                            <div
                              style={{
                                flexBasis: "30%",
                                marginRight: 5,
                              }}
                            >
                              <Field
                                required
                                id={`emailAddresses.${index}.label`}
                                name={`emailAddresses.${index}.label`}
                                className="label-container"
                                classNamePrefix="label"
                                component={EditableSelect}
                                closeMenuOnSelect={true}
                                options={selectOptions}
                              />
                            </div>
                            <div style={{ flexBasis: "60%" }}>
                              <Field
                                required
                                id={`emailAddresses.${index}.emailAddress`}
                                name={`emailAddresses.${index}.emailAddress`}
                                placeholder="sample@co.org"
                                type="text"
                              />
                            </div>
                            <RepeatableFieldButton
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              <SVG.MinusSvg />
                            </RepeatableFieldButton>
                            <RepeatableFieldButton
                              type="button"
                              onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                            >
                              <SVG.PlusSvg />
                            </RepeatableFieldButton>
                          </S.Flex>
                        ))
                      ) : (
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                        >
                          Add an email.
                        </button>
                      )}
                    </div>
                  )}
                />

                <button type="submit" disabled={loading}>
                  Sav{loading ? "ing" : "e"}
                </button>
              </StyledPanelFormWrapper>
            </Form>
          )}
        />
      )}
    </S.Panel>
  );
}

export function EmailList({
  subject,
  label,
  labelled,
  primary,
}: {
  subject: Company | Contact | Location;
  label?: string;
  labelled?: boolean;
  primary?: boolean;
}) {
  if (!subject.emailAddresses)
    return <S.ContactList>No Emails on Record</S.ContactList>;
  if (subject.emailAddresses.length < 1)
    return <S.ContactList>No Emails on Record</S.ContactList>;

  let list;

  const primaries = subject.emailAddresses.filter((email) => email.preferred);
  const sites = label
    ? subject.emailAddresses.filter((email) => email.label === label)
    : subject.emailAddresses;

  if (label) {
    if (!sites) list = primaries;
    if (sites.length < 1) list = primaries;
  } else if (primary) list = primaries;
  else list = sites;

  if (!list) return <S.ContactList>No Emails on Record</S.ContactList>;
  if (list.length < 1)
    return <S.ContactList>No Emails on Record</S.ContactList>;

  return (
    <S.ContactList>
      {list.map((email) => (
        <li key={email.id}>
          {email.label}:{" "}
          <b>
            <S.A href={`mailto:${email.emailAddress}`}>
              {email.emailAddress}
            </S.A>
          </b>
        </li>
      ))}
    </S.ContactList>
  );
}

const EmailListButton: React.FunctionComponent<{ contacts: Contact[] }> = ({
  contacts,
}) => {
  const emails = contacts
    .map((contact) => {
      const email = primaryAssociation(contact.emailAddresses);
      if (email && email.emailAddress) return email.emailAddress;
    })
    .reduce((accumulator, item) => {
      if (item) return accumulator + item + ",";
      else return accumulator;
    }, "");

  if (emails && emails.length)
    return (
      <S.ButtonedLink size="small" href={`mailto:${emails}`}>
        Email All
      </S.ButtonedLink>
    );
  else return <></>;
};

export function PrimaryEmail({
  subject,
}: {
  subject: Company | Contact | Location;
}) {
  const primary = primaryAssociation(subject.emailAddresses);
  return (
    <>
      {primary ? (
        <S.A href={`mailto:${primary.emailAddress}`}>
          <S.Flex ml={0} my={1} py={1} alignItems="center">
            <SVG.EmailSvg />
            {primary.emailAddress}
          </S.Flex>
        </S.A>
      ) : (
        <></>
      )}
    </>
  );
}

export { EmailListButton };
