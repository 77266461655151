import React from "react";
import CompanyNav from "./CompanyNav";
import CompanySummary from "./CompanySummary";
import CompanyContacts from "./CompanyContacts";
import CompanyPlants from "./CompanyPlants";
import CompanyMembership from "./CompanyMembership";
import CompanyAwards from "./CompanyAwards";
import * as S from "./styles";
import { RouteComponentProps } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import { Logo } from "./Image";
import { MembershipTags } from "./CompanyMembership";
import { Query } from "@apollo/react-components";
import { joinPath } from "./util";
import TopNav from "./TopNav";
import Page from "./Page";
import { Link } from "react-router-dom";
import { Tag } from './Tags';
import {
  Company as CompanyType,
  CompanyContact as CompanyContactType,
} from "./apiTypes";
import { CompanyQuery } from "./queries/company";

type CompanyProps = RouteComponentProps<{ companyId: string }>;

const Company: React.FunctionComponent<CompanyProps> = ({ match }) => {
  return (
    <Query<{ company: CompanyType }, { companyId: string }>
      query={CompanyQuery}
      variables={{ companyId: match.params.companyId }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (error) {
          console.log("error");
        }
        if (!data || !data.company)
          return (
            <Page>
              <TopNav />
              There is no Company associated with this id/url.
            </Page>
          );
        const company = data.company;
        if (!company)
          return (
            <Page>
              <TopNav />
              There is no Company associated with this id/url.
            </Page>
          );
        return (
          <div>
            <CompanyNav company={company} match={match} />
            <Switch>
              <Redirect
                exact
                from={match.path}
                to={joinPath(match.url, "/summary")}
              />
              <Route
                path={`${match.path}/summary`}
                render={(routeProps) => (
                  <CompanySummary {...routeProps} company={company} />
                )}
              />
              <Route
                path={`${match.path}/contacts`}
                render={(routeProps) => (
                  <CompanyContacts {...routeProps} company={company} />
                )}
              />
              <Route
                path={`${match.path}/plants`}
                render={(routeProps) => (
                  <CompanyPlants {...routeProps} company={company} />
                )}
              />
              <Route
                path={`${match.path}/awards`}
                render={(routeProps) => (
                  <CompanyAwards {...routeProps} company={company} />
                )}
              />
              <Route
                path={`${match.path}/dues`}
                render={(routeProps) => (
                  <CompanyMembership {...routeProps} company={company} />
                )}
              />
            </Switch>
          </div>
        );
      }}
    </Query>
  );
};

const CompanyLink = (companyContact: CompanyContactType) => {
  return (
    <Link to={`/company/${companyContact.company.id}`}>
      <S.Flex my={2} alignItems="center">
        {companyContact.company.logoUrl && <Logo src={companyContact.company.logoUrl} />}
        <S.H3>
          {companyContact.company.name}
          <span>{companyContact.title}</span>
        </S.H3>
      </S.Flex>
    </Link>
  );
};

const CompanyTags: React.FunctionComponent<{company: CompanyType}> = ({
  company,
}) => {
  const recentSalesReport =
    company.salesReports &&
    company.salesReports.length &&
    company.salesReports[company.salesReports.length - 1] &&
    company.salesReports[company.salesReports.length - 1];

  return (
    <>
      {company.deactivatedAt || company.deactivatedAt === "" ? (
        <Tag color="lightyellow">Archived</Tag>
      ) : ( 
        <></>
      )}
      {company.mergedAt || company.mergedAt === "" ? (
        <Tag color="lightyellow">Merged</Tag>
      ) : ( 
        <></>
      )}
      { company.memberships && company.memberships.length > 0 ? (
        <>
          {company.activeMembership ? (
            <>
              <Tag color="lightgreen">Member</Tag>
              <MembershipTags membership={company.activeMembership} recentSalesReport={recentSalesReport || undefined} />
            </>
          ) : company.memberships[company.memberships.length - 1].applicationStatus === "PENDING" ? (
            <>
              <Tag color="lightyellow">Applicant</Tag>
              <MembershipTags membership={company.memberships && company.memberships[company.memberships.length - 1]} recentSalesReport={recentSalesReport || undefined} />
            </>
          ) : company.memberships.length ? (
            <>
            <Tag color="darkgreen">Previous Member</Tag>
            <Tag color="darkgreen">Non-Member</Tag>
            </>
          ) : (
            <Tag color="darkgreen">Non-Member</Tag>
          )}
        </>
      ) : (
          <></>
      )}
    </>
  );
}

export default Company;
export { CompanyLink, CompanyTags };
