import { gql } from "graphql.macro";

export const InvoiceQuery = gql`
  query InvoiceQuery($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      company
      contact
      dueOn
      finalizedAt
      id
      insertedAt
      lines {
        amount
        currency
        description
        id
        insertedAt
        membership
        quantity
        updatedAt
      }
      markedUncollectibleAt
      paidAt
      payments {
        amount
        clearedAt
        id
        insertedAt
        paymentMethod
        stripeChargeId
        succeeded
        updatedAt
      }
      status
      subtotal
      tax
      total
      updatedAt
      voidedAt
    }
  }
`;

export const InvoicesListQuery = gql`
  query InvoicesListQuery(
    $first: Int!
    $last: Int
    $before: String
    $after: String
  ) {
    invoices(first: $first, last: $last, before: $before, after: $after) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          company
          contact
          dueOn
          finalizedAt
          id
          insertedAt
          lines {
            amount
            currency
            description
            id
            insertedAt
            membership
            quantity
            updatedAt
          }
          markedUncollectibleAt
          paidAt
          payments {
            amount
            clearedAt
            id
            insertedAt
            paymentMethod
            stripeChargeId
            succeeded
            updatedAt
          }
          status
          subtotal
          tax
          total
          updatedAt
          voidedAt
        }
      }
    }
  }
`;
