import React, { useState } from "react";
import Page, { PageButtons } from "./Page";
import Select from "react-select";
import Moment from "react-moment";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Link, useLocation } from "react-router-dom";
import Table, { TR, TD } from "./Table";
import { Query } from "@apollo/react-components";
import Tags, { Tag } from "./Tags";
import { match } from "react-router-dom";
import {
  SortValue,
  BooleanSortValue,
  IntSortValue,
  StudentDesignSubmissionListQueryData,
} from "./apiTypes";
import { StudentDesignSubmissionListQuery } from "./queries/studentDesignSubmission";

const StudentDesignChallenges: React.FunctionComponent<{ match: match }> = ({
  match,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const pageSize = 24;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;

  const sortOptions = [
    { label: "Name A-Z", value: "NAME_ASC" },
    { label: "Name Z-A", value: "NAME_DESC" },
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
  ];
  const awardTiers = [
    { label: "First Place Winner", value: 1 },
    { label: "Second Place Winner", value: 2 },
    { label: "Third Place Winner", value: 3 },
    { label: "N/A", value: undefined },
  ];

  const statuses = [
    { label: "All", value: undefined },
    { label: "Approved", value: "APPROVED" },
    { label: "Not Approved", value: "REJECTED" },
    { label: "Archived", value: "ARCHIVED" },
  ];

  const newDate = new Date();
  let yearOptions = newDate.getMonth() > 6 ? [
    {
      label: `${newDate.getFullYear() + 1}`,
      value: `${newDate.getFullYear() + 1}`,
    },
  ] : [];
  const currentYear = newDate.getFullYear();
  for (let i = currentYear; i >= 2020; i--) {
    yearOptions.push({ label: `${i}`, value: `${i}` });
  }

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const [awardFilters, setAwardFilters] = useState<IntSortValue>();
  const [statusFilter, setStatusFilter] = useState<SortValue>();
  const [samplesReceived, setSamplesReceived] = useState<BooleanSortValue>();
  const [hasWrittenDefense, setHasWrittenDefense] = useState<
    BooleanSortValue
  >();
  const [hasConceptOutline, setHasConceptOutline] = useState<
    BooleanSortValue
  >();
  const [year, setYear] = useState<SortValue>(yearOptions[0]);

  const documentOptions = [
    { label: "Received", value: true },
    { label: "Not Received", value: false },
    { label: "N/A", value: undefined },
  ];

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleStatus = (newValue: SortValue) => {
    setStatusFilter(newValue);
  };
  const handleAwardTier = (newValue: IntSortValue) => {
    setAwardFilters(newValue);
  };
  const handleSamplesReceived = (newValue: BooleanSortValue) => {
    setSamplesReceived(newValue);
  };
  const handleHasWrittenDefense = (newValue: BooleanSortValue) => {
    setHasWrittenDefense(newValue);
  };
  const handleHasConceptOutline = (newValue: BooleanSortValue) => {
    setHasConceptOutline(newValue);
  };
  const handleYear = (newValues: SortValue) => {
    setYear(newValues);
  };

  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "NAME_ASC",
    filter: {
      archived:
        statusFilter &&
        !Array.isArray(statusFilter) &&
        (statusFilter as { label: string; value: string }).value === "ARCHIVED",
      awardTier:
        awardFilters && !Array.isArray(awardFilters)
          ? (awardFilters as { label: string; value: number }).value
          : undefined,
      status:
        statusFilter &&
        !Array.isArray(statusFilter) &&
        (statusFilter as { label: string; value: string }).value !== "ARCHIVED"
          ? (statusFilter as { label: string; value: string }).value
          : undefined,
      samplesReceived:
        samplesReceived && !Array.isArray(samplesReceived)
          ? (samplesReceived as { label: string; value: boolean }).value
          : undefined,
      hasWrittenDefense:
        hasWrittenDefense && !Array.isArray(hasWrittenDefense)
          ? (hasWrittenDefense as { label: string; value: boolean }).value
          : undefined,
      hasConceptOutline:
        hasConceptOutline && !Array.isArray(hasConceptOutline)
          ? (hasConceptOutline as { label: string; value: boolean }).value
          : undefined,
      year: year && !Array.isArray(year)
        ? parseInt((year as { label: string; value: string }).value)
        : new Date().getFullYear(),
    },
  };

  return (
    <Page>
      <Query<StudentDesignSubmissionListQueryData>
        query={StudentDesignSubmissionListQuery}
        fetch-policy="network-only"
        variables={queryVars}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Oops.</div>;
          if (
            !data ||
            !data.studentDesignSubmissions ||
            !Array.isArray(data.studentDesignSubmissions.edges)
          )
            return <div>No data.</div>;
          return (
            <>
              <S.Flex justifyContent="space-between" mb={3} mx={3}>
                <S.Title>
                  Total Entries: {data.studentDesignSubmissions.count}
                </S.Title>
              </S.Flex>
              <S.Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mx={3}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <label>SORT BY</label>
                  <Select
                    closeMenuOnSelect={true}
                    onChange={handleSort}
                    options={sortOptions}
                    value={sort}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <label>FILTER BY</label>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Submission Year
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleYear}
                        options={yearOptions}
                        value={year}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Status
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleStatus}
                        options={statuses}
                        value={statusFilter}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Award Tier
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleAwardTier}
                        options={awardTiers}
                        value={awardFilters}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Concept Outline Status
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleHasConceptOutline}
                        options={documentOptions}
                        value={hasConceptOutline}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Samples Status
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleSamplesReceived}
                        options={documentOptions}
                        value={samplesReceived}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Written Defense Status
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleHasWrittenDefense}
                        options={documentOptions}
                        value={hasWrittenDefense}
                      />
                    </div>
                  </S.Flex>
                </div>
                <div style={{ marginLeft: 20, marginTop: 26 }}>
                  <S.ButtonedLink
                    size="small"
                    href="https://data.heroku.com/dataclips/nbbmzztvuxdlwmwlghdflaistbhm.csv"
                    target="_blank"
                    download="https://data.heroku.com/dataclips/nbbmzztvuxdlwmwlghdflaistbhm.csv"
                  >
                    <S.Flex alignItems="center">
                      <SVG.DownloadSvg />
                      Download
                    </S.Flex>
                  </S.ButtonedLink>
                </div>
              </S.Flex>
              <PageButtons
                topMargin={4}
                pageInfo={data.studentDesignSubmissions.pageInfo}
                basePath="awards/student"
              />
              <S.Panel>
                <Table cols="80px 3fr 2fr 2fr 1fr 1fr">
                  <TR header>
                    <TD>GROUP ID</TD>
                    <TD>NAME</TD>
                    <TD>STUDENTS</TD>
                    <TD>SCHOOL</TD>
                    <TD>SUBMISSION DATE</TD>
                    <TD />
                  </TR>
                  {data.studentDesignSubmissions.count === 0 && <S.Box>No entries found.</S.Box>}
                  {data.studentDesignSubmissions.edges.map((edge) => {
                    const studentDesignSubmission = edge.node;
                    if (!studentDesignSubmission) return <React.Fragment />;
                    const primaryImage =
                      studentDesignSubmission.images &&
                      studentDesignSubmission.images.filter(
                        (image) => image.preferred
                      );
                    return (
                      <TR key={studentDesignSubmission.id} pt={4} pb={2}>
                        <TD>{studentDesignSubmission.groupId}</TD>
                        <TD>
                          {primaryImage &&
                            primaryImage[0] &&
                            primaryImage[0].file &&
                            primaryImage[0].file.url && (
                              <img
                                style={{ marginRight: 10 }}
                                src={primaryImage[0].file.url}
                                width="80"
                              />
                            )}
                          <Link
                            to={`${match.path}/${studentDesignSubmission.id}`}
                          >
                            <S.H3>{studentDesignSubmission.name}</S.H3>
                          </Link>
                        </TD>
                        <TD>
                          <S.List noBorder noPadding>
                            {studentDesignSubmission.contacts &&
                              studentDesignSubmission.contacts.map(
                                (contact) => (
                                  <li key={contact.id}>
                                    <Link to={`/contacts/${contact.id}`}>
                                      {contact.firstName} {contact.lastName}
                                    </Link>
                                  </li>
                                )
                              )}
                          </S.List>
                        </TD>
                        <TD>
                          <S.Title>{studentDesignSubmission.school}</S.Title>
                        </TD>
                        <TD>
                          <Moment format="MMMM D, YYYY">
                            {studentDesignSubmission.insertedAt}
                          </Moment>
                        </TD>
                        <TD>
                          <S.LinkButton
                            size="small"
                            to={`${match.path}/${studentDesignSubmission.id}`}
                          >
                            View
                          </S.LinkButton>
                        </TD>
                        <Tags>
                          {studentDesignSubmission.archivedAt && (
                            <Tag color="lightorange">Archived</Tag>
                          )}
                          {studentDesignSubmission.status &&
                          studentDesignSubmission.status === "APPROVED" ? (
                            <>
                              <Tag color="lightblue">Approved</Tag>
                              {studentDesignSubmission.conceptOutline ? (
                                <Tag color="lightgreen">Concept Received</Tag>
                              ) : (
                                <Tag color="lightyellow">Awaiting Concept</Tag>
                              )}
                              {studentDesignSubmission.writtenDefense ? (
                                <Tag color="lightgreen">
                                  Written Defense Received
                                </Tag>
                              ) : (
                                <Tag color="lightyellow">
                                  Awaiting Written Defense
                                </Tag>
                              )}
                              {studentDesignSubmission.samplesReceived ? (
                                <Tag color="lightgreen">Samples Received</Tag>
                              ) : (
                                <Tag color="lightyellow">Awaiting Samples</Tag>
                              )}
                            </>
                          ) : studentDesignSubmission.status &&
                            studentDesignSubmission.status === "REJECTED" ? (
                            <Tag color="lightorange">Not Approved</Tag>
                          ) : (
                            <Tag color="lightyellow">Pending</Tag>
                          )}
                          {studentDesignSubmission.awardTier &&
                          studentDesignSubmission.awardTier === 1 ? (
                            <Tag color="gold">First Place</Tag>
                          ) : studentDesignSubmission.awardTier &&
                            studentDesignSubmission.awardTier === 2 ? (
                            <Tag color="silver">Second Place</Tag>
                          ) : studentDesignSubmission.awardTier &&
                            studentDesignSubmission.awardTier === 3 ? (
                            <Tag color="bronze">Third Place</Tag>
                          ) : (
                            <></>
                          )}
                        </Tags>
                      </TR>
                    );
                  })}
                </Table>
              </S.Panel>
              <PageButtons
                topMargin={4}
                pageInfo={data.studentDesignSubmissions.pageInfo}
                basePath="awards/student"
              />
            </>
          );
        }}
      </Query>
    </Page>
  );
};

export default StudentDesignChallenges;
