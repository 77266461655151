import React from "react";
import NewAchievementAwardSubmission from "./NewAchievementAwardSubmission";
import NewStudentDesignSubmission from "./NewStudentDesignSubmission";
import TopNav from "./TopNav";
import * as S from "./styles";
import { joinPath } from "./util";
import { RouteComponentProps, match } from "react-router-dom";
import PageNav, { LocalNavA, LocalNav } from "./PageNav";
import { Switch, Route, Redirect } from "react-router-dom";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import AchievementAwards from "./AchievementAwards";
import StudentDesigns from "./StudentDesigns";

type AwardsProps = RouteComponentProps;

const Awards: React.FunctionComponent<AwardsProps> = ({ match }) => {
  return (
    <div>
      <AwardsNav match={match} />
      <Switch>
        <Redirect
          exact
          from={match.path}
          to={joinPath(match.url, "/achievement")}
        />
        <Route
          exact
          path={`${match.path}/student`}
          render={(routeProps) => <StudentDesigns {...routeProps} />}
        />
        <Route
          exact
          path={`${match.path}/achievement`}
          render={(routeProps) => <AchievementAwards {...routeProps} />}
        />
      </Switch>
    </div>
  );
};

type NavProps = { match: match };

const AwardsNav: React.FunctionComponent<NavProps> = ({ match }) => {
  const addAchievementAwardDialog = useDialogState();
  const addStudentDesignChallengeDialog = useDialogState();
  const menu = useMenuState();

  return (
    <PageNav>
      <S.Box my={0} mx={4} pt={3}>
        <TopNav />
        <S.Flex justifyContent="space-between">
          <S.Box pt={0} my={0} mb={2}>
            <LocalNav>
              <LocalNavA to={`${match.path}/achievement`}>
                Achievement Awards
              </LocalNavA>
              <LocalNavA to={`${match.path}/student`}>
                Student Design Challenges
              </LocalNavA>
            </LocalNav>
          </S.Box>
          <MenuDisclosure {...menu} as={S.Button}>
            Add an Award
          </MenuDisclosure>
          <Menu {...menu}>
            <MenuItem {...menu}>
              <DialogDisclosure {...addAchievementAwardDialog} as={S.P}>
                Achievement Award
              </DialogDisclosure>
            </MenuItem>
            <MenuItem {...menu}>
              <DialogDisclosure {...addStudentDesignChallengeDialog} as={S.P}>
                Student Design Challenge
              </DialogDisclosure>
            </MenuItem>
          </Menu>
        </S.Flex>
      </S.Box>
      <DialogWithBackdrop {...addAchievementAwardDialog}>
        <NewAchievementAwardSubmission />
      </DialogWithBackdrop>
      <DialogWithBackdrop {...addStudentDesignChallengeDialog}>
        <NewStudentDesignSubmission />
      </DialogWithBackdrop>
    </PageNav>
  );
};

export default Awards;
export { AwardsNav };
