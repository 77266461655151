import React from "react";
// import * as S from "./styles";
// import Table, { TR, TD } from "./Table";
// import * as SVG from "./svgs";
// import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
// // import Date from "./Date";
// import { Tag } from "./Tags";
// import { Query, Mutation } from "@apollo/react-components";
// import Notes, { ActivityFeed } from "./Notes";
import {
  Registration as RegistrationType,
  Event,
  Contact,
  Company
} from "./apiTypes";
// import RegistrationNav from "./RegistrationNav";
// import moment from "moment";
// import EditableContent from "./EditableContent";
// import { registrationQuery } from "./queries/registration";
// import { StyledPanelFormWrapper } from "./StyledForm";
// import { Formik, Field, Form } from "formik";
// import { Link, RouteComponentProps } from "react-router-dom";

const Registration: React.FunctionComponent<{ subject: Contact | Company }> = ({
  subject
}) => {
  return <>Item Here</>;
};

export default Registration;
