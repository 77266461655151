import React from "react";
import { PhoneNumberList, PrimaryPhoneNumber } from "./PhoneNumbers";
import { EmailList } from "./Emails";
import { UrlList } from "./Urls";
import { CompanySelect } from "./Companies";
import { LocationAtAGlance } from "./Location";
import { Link, RouteComponentProps } from "react-router-dom";
import { CompanyContactSelect } from "./Contacts";
import UserImage from "./UserAvatar";
import { PrimaryEmail } from "./Emails";
import EditableContent from "./EditableContent";
import ImageGallery from "./ImageGallery";
import { Logo } from "./Image";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import Notes, { ActivityFeed } from "./Notes";
import Table, { TR, TD } from "./Table";
import { PrimaryAddress } from "./Addresses";
import Page from "./Page";
import AchievementAwardNav from "./AchievementAwardNav";
import { Query } from "@apollo/react-components";
import { Tag } from "./Tags";
import { DocumentGallery } from "./Document";
import {
  StyledPanelFormWrapper,
  Select
} from "./StyledForm";
import { LocationSelect } from "./Locations";
import { Formik, Field, Form } from "formik";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  AchievementAwardSubmission as AchievementAwardType,
  LocationsListQueryData,
  UpdateAchievementAwardSubmissionValues,
  NewImageValues,
  NewDocumentValues,
  AchievementAwardCategory,
  AchievementAwardWinnerValues,
  DeleteAchievementAwardWinnerValues,
} from "./apiTypes";
import { updateAchievementAwardSubmissionMutation } from "./mutations/achievementAwardSubmission";
import {
  achievementAwardCategoryListQuery,
  achievementAwardQuery,
} from "./queries/achievementAwardSubmission";
import { LocationListQuery } from "./queries/location";
import { ValueType } from "react-select";
import {
  createAchievementAwardImageMutation,
  updateAchievementAwardImageMutation,
  deleteAchievementAwardImageMutation,
  createAchievementAwardDocumentMutation,
  deleteAchievementAwardDocumentMutation,
  createAchievementAwardWinnerMutation,
  updateAchievementAwardWinnerMutation,
  deleteAchievementAwardWinnerMutation,
} from "./mutations/achievementAwardSubmission";

type AchievementAwardProps = RouteComponentProps<{
  id: string;
}>;

const AchievementAward: React.FunctionComponent<AchievementAwardProps> = ({
  match,
}) => {
  const [updateAchievementAwardSubmission, { loading }] = useMutation<{
    updateAchievementAwardSubmission: UpdateAchievementAwardSubmissionValues;
  }>(updateAchievementAwardSubmissionMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [createAchievementAwardImage, { loading: addLoading }] = useMutation<{
    createStudentDesignImage: NewImageValues;
  }>(createAchievementAwardImageMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [updateAchievementAwardImage] = useMutation<{
    updateAchievementAwardImage: NewImageValues;
  }>(updateAchievementAwardImageMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [deleteAchievementAwardImage] = useMutation<{
    deleteAchievementAwardImage: NewImageValues;
  }>(deleteAchievementAwardImageMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [createAchievementAwardDocument] = useMutation<{
    createStudentDesignDocument: NewDocumentValues;
  }>(createAchievementAwardDocumentMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [deleteAchievementAwardDocument] = useMutation<{
    deleteAchievementAwardDocument: NewDocumentValues;
  }>(deleteAchievementAwardDocumentMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });

  return (
    <Query<
      { achievementAwardSubmission: AchievementAwardType },
      { submissionId: string }
    >
      query={achievementAwardQuery}
      variables={{
        submissionId: match.params.id,
      }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (error)
          return (
            <Page>
              <TopNav />
              There is no Achievement Award entry associated with this id/url.
            </Page>
          );
        if (!data || !data.achievementAwardSubmission)
          return (
            <Page>
              <TopNav />
              There is no Achievement Award entry associated with this id/url.
            </Page>
          );
        const achievementAward = data.achievementAwardSubmission;
        if (!achievementAward)
          return (
            <Page>
              <TopNav />
              There is no Achievement Award entry associated with this id/url.
            </Page>
          );

        return (
          <div>
            <AchievementAwardNav
              achievementAward={achievementAward}
              match={match}
            />
            <Page>
              <S.Flex>
                <S.Box width={1 / 3} my={0}>
                  <ImageGallery
                    images={achievementAward.images}
                    subjectId={achievementAward.id}
                    createFunc={createAchievementAwardImage}
                    updateFunc={updateAchievementAwardImage}
                    deleteFunc={deleteAchievementAwardImage}
                  />
                  <S.Panel>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader noMargin>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              Submission Information
                              {!isOpen ? (
                                <S.Button size="small" onClick={open}>
                                  Edit
                                </S.Button>
                              ) : (
                                <S.Button size="small" onClick={close}>
                                  Cancel
                                </S.Button>
                              )}
                            </S.Flex>
                          </S.UnderlinedHeader>
                          <S.Flex>
                            {!achievementAward.certified && (
                              <Tag color="lightyellow">Uncertified</Tag>
                            )}
                            {achievementAward.samplesReceived ? (
                              <Tag color="lightgreen">Samples Received</Tag>
                            ) : (
                              <Tag color="lightyellow">Awaiting Samples</Tag>
                            )}
                          </S.Flex>
                          <S.Box pt={1}>
                            {!isOpen ? (
                              <Table cols="170px 2fr">
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SUBMISSION NAME</b>
                                  </TD>
                                  <TD>{achievementAward.name}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>SUBMISSION YEAR</b>
                                  </TD>
                                  <TD>{achievementAward.year}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>GROUP ID</b>
                                  </TD>
                                  <TD>
                                    {achievementAward.groupId
                                      ? achievementAward.groupId
                                      : null}
                                  </TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>CURRENTLY PRODUCED / SOLD</b>
                                  </TD>
                                  <TD>
                                    {achievementAward.produced ? (
                                      <>
                                        <SVG.CheckSvg /> Yes
                                      </>
                                    ) : (
                                      <>
                                        <SVG.CrossSvg /> No
                                      </>
                                    )}
                                  </TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>END-USER / CUSTOMER NAME</b>
                                  </TD>
                                  <TD>{achievementAward.endUser}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>DESIGN FIRM</b>
                                  </TD>
                                  <TD>{achievementAward.designerFirm}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>DESIGNER NAME</b>
                                  </TD>
                                  <TD>{achievementAward.designerName}</TD>
                                </TR>
                                <TR my={2} borderless>
                                  <TD>
                                    <b>KEY SUPPLIERS</b>
                                  </TD>
                                  <TD>{achievementAward.keySuppliers}</TD>
                                </TR>
                              </Table>
                            ) : (
                              <Formik
                                initialValues={{
                                  name: achievementAward.name
                                    ? achievementAward.name
                                    : "",
                                  groupId: achievementAward.groupId
                                    ? achievementAward.groupId
                                    : null,
                                  designerName: achievementAward.designerName
                                    ? achievementAward.designerName
                                    : "",
                                  designerFirm: achievementAward.designerFirm
                                    ? achievementAward.designerFirm
                                    : "",
                                  endUser: achievementAward.endUser
                                    ? achievementAward.endUser
                                    : "",
                                  keySuppliers: achievementAward.keySuppliers
                                    ? achievementAward.keySuppliers
                                    : "",
                                  produced: achievementAward.produced
                                    ? achievementAward.produced
                                    : false,
                                  certified: achievementAward.certified
                                    ? achievementAward.certified
                                    : false,
                                  samplesReceived: achievementAward.samplesReceived
                                    ? achievementAward.samplesReceived
                                    : false,
                                  year: achievementAward.year
                                    ? achievementAward.year
                                    : new Date().getFullYear(),
                                }}
                                onSubmit={async (values) => {
                                  await updateAchievementAwardSubmission({
                                    variables: {
                                      input: {
                                        ...values,
                                        groupId: values.groupId ? +values.groupId : null,
                                        submissionId: achievementAward.id,
                                      },
                                    },
                                  });
                                  close();
                                }}
                                render={({ values }) => (
                                  <StyledPanelFormWrapper>
                                    <Form>
                                      <label htmlFor="name">
                                        Submission Name
                                        <span
                                          style={{
                                            color: "red",
                                            display: "inline-block",
                                          }}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <Field
                                        id="name"
                                        name="name"
                                        type="text"
                                      />
                                      <S.Flex>
                                        <S.HalfWidth>
                                          <label htmlFor="year">
                                            Submission Year
                                            <span
                                              style={{
                                                color: "red",
                                                display: "inline-block",
                                              }}
                                            >
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            required
                                            id="year"
                                            name="year"
                                            type="number"
                                          />
                                        </S.HalfWidth>
                                        <S.HalfWidth>
                                          <label htmlFor="groupId">
                                            Group ID
                                          </label>
                                          <Field
                                            id="groupId"
                                            name="groupId"
                                            placeholder={
                                              values.groupId
                                                ? values.groupId
                                                : null
                                            }
                                            type="number"
                                          />
                                        </S.HalfWidth>
                                      </S.Flex>
                                      <label htmlFor="endUser">End-User</label>
                                      <Field
                                        id="endUser"
                                        name="endUser"
                                        type="text"
                                      />
                                      <label htmlFor="designerFirm">
                                        Design Firm
                                      </label>
                                      <Field
                                        id="designerFirm"
                                        name="designerFirm"
                                        type="text"
                                      />
                                      <label htmlFor="designerName">
                                        Designer
                                      </label>
                                      <Field
                                        id="designerName"
                                        name="designerName"
                                        type="text"
                                      />
                                      <label htmlFor="keySuppliers">
                                        Key Suppliers
                                      </label>
                                      <Field
                                        id="keySuppliers"
                                        name="keySuppliers"
                                        component="textarea"
                                      />
                                      <S.Flex>
                                        <Field
                                          id="produced"
                                          name="produced"
                                          type="checkbox"
                                        />
                                        <label htmlFor="produced">
                                          Currently Produced
                                        </label>
                                      </S.Flex>
                                      <S.Flex>
                                        <Field
                                          id="samplesReceived"
                                          name="samplesReceived"
                                          type="checkbox"
                                        />
                                        <label htmlFor="samplesReceived">
                                          Receieved Sample Packaging
                                        </label>
                                      </S.Flex>
                                      <button type="submit" disabled={loading}>
                                        Sav{loading ? "ing" : "e"}
                                      </button>
                                    </Form>
                                  </StyledPanelFormWrapper>
                                )}
                              />
                            )}
                          </S.Box>
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                  <DocumentGallery
                    documents={achievementAward.documents}
                    subjectId={achievementAward.id}
                    createFunc={createAchievementAwardDocument}
                    deleteFunc={deleteAchievementAwardDocument}
                  />
                </S.Box>
                <S.Box width={2 / 3} my={0}>
                  <AchievementAwardsList achievementAward={achievementAward} />
                  <S.Panel>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              Description
                              {!isOpen ? (
                                <S.Button size="small" onClick={open}>
                                  Edit
                                </S.Button>
                              ) : (
                                <S.Button size="small" onClick={close}>
                                  Cancel
                                </S.Button>
                              )}
                            </S.Flex>
                          </S.UnderlinedHeader>
                          {!isOpen ? (
                            <S.P>
                              {achievementAward.description
                                ? achievementAward.description
                                : ""}
                            </S.P>
                          ) : (
                            <Formik
                              initialValues={{
                                description: achievementAward.description
                                  ? achievementAward.description
                                  : "",
                              }}
                              onSubmit={async (values) => {
                                await updateAchievementAwardSubmission({
                                  variables: {
                                    input: {
                                      ...values,
                                      submissionId: achievementAward.id,
                                    },
                                  },
                                });
                                close();
                              }}
                              render={() => (
                                <StyledPanelFormWrapper>
                                  <Form>
                                    <Field
                                      id="description"
                                      name="description"
                                      placeholder="..."
                                      component="textarea"
                                    />
                                    <button type="submit" disabled={loading}>
                                      Sav{loading ? "ing" : "e"}
                                    </button>
                                  </Form>
                                </StyledPanelFormWrapper>
                              )}
                            />
                          )}
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                  <S.Panel>
                    <EditableContent>
                      {({ isOpen, open, close }) => (
                        <>
                          <S.UnderlinedHeader>
                            <S.Flex
                              alignItems="flex-end"
                              justifyContent="space-between"
                            >
                              Contact, Company and Plant Information
                              {!isOpen ? (
                                <S.Button size="small" onClick={open}>
                                  Edit
                                </S.Button>
                              ) : (
                                <S.Button size="small" onClick={close}>
                                  Cancel
                                </S.Button>
                              )}
                            </S.Flex>
                          </S.UnderlinedHeader>
                          <S.Flex ml={0} mr={3}>
                            {!isOpen ? (
                              <>
                                <S.Box width={1 / 3}>
                                  {achievementAward.company ? (
                                    <>
                                      {achievementAward.company.logoUrl && (
                                        <Logo
                                          src={achievementAward.company.logoUrl}
                                        />
                                      )}
                                      <Link
                                        to={`/companies/${achievementAward.company &&
                                          achievementAward.company.id}`}
                                      >
                                        <S.Title>
                                          {achievementAward.company.name}
                                        </S.Title>
                                      </Link>
                                      <PrimaryAddress
                                        subject={achievementAward.company}
                                      />
                                      <EmailList
                                        subject={achievementAward.company}
                                        primary
                                      />
                                      <UrlList
                                        subject={achievementAward.company}
                                        primary
                                      />
                                      <PhoneNumberList
                                        subject={achievementAward.company}
                                        primary
                                      />
                                    </>
                                  ) : (
                                    <>No Company has been assigned.</>
                                  )}
                                </S.Box>
                                <S.Box width={1 / 3}>
                                  {achievementAward.contact ? (
                                    <>
                                      <Link
                                        to={`/contacts/${achievementAward.contact.id}`}
                                      >
                                        <S.Flex my={2} alignItems="center">
                                          <UserImage
                                            large
                                            src={
                                              achievementAward.contact.photoUrl
                                                ? achievementAward.contact
                                                    .photoUrl
                                                : "/images/empty-user.png"
                                            }
                                          />
                                          <S.H3>
                                            {`${achievementAward.contact.firstName} ${achievementAward.contact.lastName}`}
                                          </S.H3>
                                        </S.Flex>
                                      </Link>
                                      <PrimaryPhoneNumber
                                        subject={achievementAward.contact}
                                      />
                                      <PrimaryEmail
                                        subject={achievementAward.contact}
                                      />
                                      <PrimaryAddress
                                        subject={achievementAward.contact}
                                      />
                                    </>
                                  ) : (
                                    <>No Contact has been assigned.</>
                                  )}
                                </S.Box>
                                <S.Box width={1 / 3}>
                                  <Link
                                    to={`/companies/${achievementAward.location &&
                                      achievementAward.location.company &&
                                      achievementAward.location.company
                                        .id}/plants`}
                                  >
                                    <S.H3>
                                      {achievementAward.location &&
                                        achievementAward.location.name}
                                    </S.H3>
                                  </Link>
                                  {achievementAward.location && (
                                    <LocationAtAGlance
                                      location={achievementAward.location}
                                      email={true}
                                      phone={true}
                                      employees={false}
                                    />
                                  )}
                                </S.Box>
                              </>
                            ) : (
                              <Formik
                                initialValues={{
                                  companyId:
                                    (achievementAward.company &&
                                      achievementAward.company.id) ||
                                    "",
                                  contactId:
                                    (achievementAward.contact &&
                                      achievementAward.contact.id) ||
                                    "",
                                  locationId:
                                    (achievementAward.location &&
                                      achievementAward.location.id) ||
                                    "",
                                }}
                                onSubmit={async (values) => {
                                  await updateAchievementAwardSubmission({
                                    variables: {
                                      input: {
                                        companyId:
                                          values.companyId !== "" &&
                                          values.companyId,
                                        contactId:
                                          values.contactId !== "" &&
                                          values.contactId,
                                        locationId:
                                          values.locationId !== "" &&
                                          values.locationId,
                                        submissionId: achievementAward.id,
                                      },
                                    },
                                  });
                                  close();
                                }}
                                render={({ values }) => (
                                  <Query<LocationsListQueryData>
                                    query={LocationListQuery}
                                    variables={{
                                      first: 20,
                                      companyId: values.companyId,
                                    }}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading && !data) return <div />;
                                      if (error) return <div>Oops.</div>;
                                      if (
                                        !data ||
                                        !data.locations ||
                                        !Array.isArray(data.locations.edges)
                                      )
                                        return <div>No data.</div>;
                                      var locations = data.locations.edges;

                                      var locationsList = locations
                                        .map((edge) => {
                                          var location = edge.node;
                                          if (!location) return null;
                                          return {
                                            label: location.name,
                                            value: location.id,
                                          };
                                        })
                                        .filter(Boolean) as ValueType<{
                                        label: string;
                                        value: string;
                                      }>[];

                                      return (
                                        <StyledPanelFormWrapper
                                          style={{ width: "100%" }}
                                        >
                                          <Form>
                                            <label htmlFor="companyId">
                                              Company
                                              <span
                                                style={{
                                                  color: "red",
                                                  display: "inline-block",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <CompanySelect name="companyId" />
                                            {values.companyId && (
                                              <>
                                                <label htmlFor="contactId">
                                                  Contact
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      display: "inline-block",
                                                    }}
                                                  >
                                                    *
                                                  </span>
                                                </label>
                                                <CompanyContactSelect
                                                  name="contactId"
                                                  companyId={values.companyId}
                                                />
                                                <label htmlFor="locationId">
                                                  Plant
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      display: "inline-block",
                                                    }}
                                                  >
                                                    *
                                                  </span>
                                                </label>
                                                <LocationSelect
                                                  name="locationId"
                                                  companyId={values.companyId}
                                                />
                                              </>
                                            )}
                                            <button
                                              type="submit"
                                              disabled={loading}
                                            >
                                              Sav{loading ? "ing" : "e"}
                                            </button>
                                          </Form>
                                        </StyledPanelFormWrapper>
                                      );
                                    }}
                                  </Query>
                                )}
                              />
                            )}
                          </S.Flex>
                        </>
                      )}
                    </EditableContent>
                  </S.Panel>
                </S.Box>
              </S.Flex>
              <Notes subject={achievementAward} />
              <ActivityFeed subject={achievementAward} />
            </Page>
          </div>
        );
      }}
    </Query>
  );
};

const AchievementAwardsList: React.FunctionComponent<{
  achievementAward: AchievementAwardType;
}> = ({ achievementAward }) => {
  const submissionCategories =
    (achievementAward.submissionCategories &&
      achievementAward.submissionCategories.map((submissionCategory) => {
        const category = submissionCategory && submissionCategory.category;
        const award =
          achievementAward.awards &&
          category &&
          achievementAward.awards.filter(
            (award) => award.category.id === category.id
          );
        return {
          name: category && category.name,
          defense: submissionCategory && submissionCategory.defense,
          tier: (award && award[0] && award[0].tier) || undefined,
        };
      })) ||
    [];
  return (
    <S.Panel>
      <EditableContent>
        {({ isOpen, open, close }) => (
          <>
            <S.UnderlinedHeader noMargin>
              <S.Flex alignItems="flex-end" justifyContent="space-between">
                Categories and Awards
                {!isOpen ? (
                  <S.Button size="small" onClick={open}>
                    Edit
                  </S.Button>
                ) : (
                  <S.Button size="small" onClick={close}>
                    Cancel
                  </S.Button>
                )}
              </S.Flex>
            </S.UnderlinedHeader>
            <S.Flex>
              {achievementAward.highestAchievement && (
                <Tag
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  color="lightpurple"
                >
                  <S.Flex alignItems="center">Highest Achievement Award</S.Flex>
                </Tag>
              )}
              {submissionCategories &&
                submissionCategories.map((submissionCategory) => (
                  <Tag
                    key={submissionCategory.name}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <S.Flex alignItems="center">
                      {submissionCategory.tier &&
                      submissionCategory.tier === "GOLD" ? (
                        <SVG.AwardsSvg color="gold" />
                      ) : submissionCategory.tier &&
                        submissionCategory.tier === "SILVER" ? (
                        <SVG.AwardsSvg color="silver" />
                      ) : submissionCategory.tier &&
                        submissionCategory.tier === "BRONZE" ? (
                        <SVG.AwardsSvg color="bronze" />
                      ) : (
                        <></>
                      )}
                      {submissionCategory.name}
                    </S.Flex>
                  </Tag>
                ))}
            </S.Flex>
            {!isOpen ? (
              <S.Box mt={5}>
                {submissionCategories ? (
                  <Table cols="2fr">
                    {submissionCategories.map((submissionCategory) => {
                      if (!submissionCategory) return <></>;
                      return (
                        <TR borderless py={1} key={submissionCategory.name}>
                          <TD style={{ display: "block" }}>
                            <S.Title>{submissionCategory.name}</S.Title>
                            <S.P>
                              {submissionCategory.defense ||
                                "No written defense provided for this category - this may impact reports for this submission."}
                            </S.P>
                          </TD>
                        </TR>
                      );
                    })}
                  </Table>
                ) : (
                  <S.P>No categories have been assigned</S.P>
                )}
              </S.Box>
            ) : (
              <UpdateAchievementAwardsList
                achievementAward={achievementAward}
                closeFunc={close}
              />
            )}
          </>
        )}
      </EditableContent>
    </S.Panel>
  );
};

const UpdateAchievementAwardsList: React.FunctionComponent<{
  achievementAward: AchievementAwardType;
  closeFunc: () => void;
}> = ({ achievementAward, closeFunc }) => {
  const awardOptions = [
    { label: "N/A", value: undefined },
    { label: "GOLD", value: "GOLD" },
    { label: "SILVER", value: "SILVER" },
    { label: "BRONZE", value: "BRONZE" },
  ];

  const [updateAchievementAwardSubmission] = useMutation<{
    updateStudentDesignSubmission: UpdateAchievementAwardSubmissionValues;
  }>(updateAchievementAwardSubmissionMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [createAchievementAwardWinner] = useMutation<{
    createStudentDesignWinner: AchievementAwardWinnerValues;
  }>(createAchievementAwardWinnerMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [updateAchievementAwardWinner] = useMutation<{
    updateStudentDesignWinner: AchievementAwardWinnerValues;
  }>(updateAchievementAwardWinnerMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const [deleteAchievementAwardWinner] = useMutation<{
    deleteAchievementAwardWinner: DeleteAchievementAwardWinnerValues;
  }>(deleteAchievementAwardWinnerMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });
  const { data, loading, error } = useQuery<{
    achievementAwardCategories: AchievementAwardCategory[];
  }>(achievementAwardCategoryListQuery);

  if (!data) return <></>;
  if (!data.achievementAwardCategories) return <></>;
  const categoryOptions = data.achievementAwardCategories.map((category) => {
    return { value: category.id, label: category.name };
  });

  const categoryIds = achievementAward.submissionCategories
    ? achievementAward.submissionCategories.map((submissionCategory) => {
        if (submissionCategory.category)
          return submissionCategory.category && submissionCategory.category.id;
      })
    : [];
  const defenses = achievementAward.submissionCategories
    ? achievementAward.submissionCategories.map((submissionCategory) => {
        return submissionCategory.defense;
      })
    : [];

  const awards = achievementAward.submissionCategories ? achievementAward.submissionCategories.map((submissionCategory) => {
    let catId = submissionCategory && submissionCategory.category && submissionCategory.category.id;
    let awardList = achievementAward.awards && achievementAward.awards.filter(award => { return award.category.id === catId });
    return awardList && awardList[0];
  }) : [];

  return (
    <Formik
      initialValues={{
        highestAchievement: achievementAward.highestAchievement || false,
        categoryIds: categoryIds || [],
        defenses: defenses || [],
        awards: awards.map(award => {return award ? award.tier : undefined}) || [],
      }}
      onSubmit={async (values) => {
        let submissionCategories = values.categoryIds.map((id, i) => ({
          categoryId: id,
          defense: values.defenses[i]
        }));
        if (
          values.highestAchievement !== achievementAward.highestAchievement &&
          window.confirm(
            "Are you sure you want to change this Achievement Award's Highest Achievement Award status?"
          )
        ) {
          await updateAchievementAwardSubmission({
            variables: {
              input: {
                submissionCategories,
                highestAchievement: values.highestAchievement,
                submissionId: achievementAward.id,
              },
            },
          });
        } else {
          await updateAchievementAwardSubmission({
            variables: {
              input: {
                submissionCategories,
                highestAchievement: values.highestAchievement,
                submissionId: achievementAward.id,
              },
            },
          });
        }
        for (const i in categoryOptions) {
          let oldAwardObject = awards.filter(award => {
            return award && award.category && award.category.id === categoryOptions[i].value
          })[0];
          let newAwardTier = values.awards[values.categoryIds.indexOf(categoryOptions[i].value)];
          if (
            (oldAwardObject && oldAwardObject.tier && !newAwardTier)
          ) {
            await deleteAchievementAwardWinner({
              variables: {
                input: {
                  categoryId: categoryOptions[i].value,
                  submissionId: achievementAward.id,
                },
              },
            });
          } else if (
            oldAwardObject && oldAwardObject.tier && newAwardTier
          ) {
            await updateAchievementAwardWinner({
              variables: {
                input: {
                  categoryId: categoryOptions[i].value,
                  submissionId: achievementAward.id,
                  tier: newAwardTier,
                },
              },
            });
          } else if (newAwardTier) {
            await createAchievementAwardWinner({
              variables: {
                input: {
                  categoryId: categoryOptions[i].value,
                  submissionId: achievementAward.id,
                  tier: newAwardTier,
                },
              },
            });
          }
        }
        closeFunc();
      }}
      render={(props) => (
        <StyledPanelFormWrapper>
          <Form>
            <label>Submission Categories</label>
            <Field
              isMulti
              id="categoryIds"
              name="categoryIds"
              component={Select}
              closeMenuOnSelect={false}
              options={categoryOptions}
            />
            <Table cols="200px 2fr">
              {props.values.categoryIds.map((id, index) => {
                let categoryOption = categoryOptions.find(co => co.value === id);

                return (
                  <>
                    <TR borderless py={1}>
                      <strong>{categoryOption ? categoryOption.label : "Unknown Category"}</strong>
                    </TR>
                    <TR borderless py={1}>
                      <TD>Award Tier</TD>
                      <TD>
                        <div style={{ width: "100%" }}>
                          <Field
                            id={`awards.${index}`}
                            name={`awards.${index}`}
                            component={Select}
                            closeMenuOnSelect={true}
                            options={awardOptions}
                          />
                        </div>
                      </TD>
                    </TR>
                    <TR borderless py={1}>
                      <TD>Defense</TD>
                      <TD>
                        <Field
                          id={`defenses.${index}`}
                          name={`defenses.${index}`}
                          component="textarea"
                          default="Entered"
                        />
                      </TD>
                    </TR>
                  </>
                );
              })}
              {props.values.awards && props.values.awards.includes("GOLD") && (
                <>
                  <TR borderless py={1}>
                    <strong>Highest Achievement Award</strong>
                  </TR>
                  <TR borderless py={1}>
                    <TD />
                    <TD>
                      <S.Flex>
                        <Field
                          id="highestAchievement"
                          name="highestAchievement"
                          type="checkbox"
                        />
                        <label htmlFor="highestAchievement">
                          Grant Highest Achievement Award
                        </label>
                      </S.Flex>
                    </TD>
                  </TR>
                </>
              )}
            </Table>
            <button type="submit" disabled={loading}>
              Sav{loading ? "ing" : "e"}
            </button>
          </Form>
        </StyledPanelFormWrapper>
      )}
    />
  );
};
export default AchievementAward;
