import React from "react";
import styled, { css } from "styled-components";
import * as S from "./styles";
import { __InputValue } from "graphql";
import Select from "react-select";

const FieldWrapper = styled.div<{ active?: boolean }>`
  cursor: pointer;
  ${(p) =>
    p.active &&
    css`
      background: #e6e9ee;
    `}

  svg {
    margin: 0 5px;
  }
`;

const Label = styled.div`
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-weight: 600;
  color: ${(p) => p.theme.colors.grey};
  span {
    display: block;
    font-weight: 400;
    font-size: 80%;
  }
`;
const TextWrapper = styled.div`
  height: 38px;
  width: 100%;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.colors.lightgrey};
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: ${(p) => p.theme.colors.grey};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-right: 10px;
  margin-bottom: 8px;
`;
const TextAreaWrapper = styled.div`
  height: 68px;
  width: 100%;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.colors.lightgrey};
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: ${(p) => p.theme.colors.grey};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-right: 10px;
  margin-bottom: 6px;
`;

const RadioList = styled.ul`
  list-style-type: circle;
  margin-left: 10px;
`;

const Checklist = styled.ul`
  list-style-type: none;
  margin-left: -10px;
`;

type Option = {
  value: string;
  label: string;
};
export type FormField = {
  type: string;
  name: string;
  placeholder?: string;
  copy?: string;
  required?: boolean;
  options?: Option[];
  allowMulti?: boolean;
  previousButton?: string;
  nextButton?: string;
  time?: boolean;
};

const EventRegistrationFormField: React.FC<{
  field: FormField;
  active: boolean;
  toggledFieldFunction: (e: FormField) => void;
}> = ({ field, active, toggledFieldFunction }) => {
  return (
    <>
      {field.type === "p" ? (
        <>
          {field.copy ? <S.P>{field.copy}</S.P> : <></>}
        </>
      ) : field.type === "header" ? (
        <>
          {field.copy ? <S.H3>{field.copy}</S.H3> : <></>}
        </>
      ) : field.type === "subheader" ? (
        <S.Box pt={3}>
          {field.copy ? <S.H4>{field.copy}</S.H4> : <></>}
        </S.Box>
      ) : (
        <FieldWrapper onClick={() => toggledFieldFunction(field)} active={active}>
          <S.Box my={0} mx={0}>
            <Label>
              {field.name}
              {field.required ? <span style={{ color: "red", fontSize: "110%", display: "inline-block" }}>*</span> : <></>}
            </Label>
            {field.type === "text" ? (
              <TextWrapper>{field.placeholder ? field.placeholder : "Enter text here."}</TextWrapper>
            ) : field.type === "textarea" ? (
              <TextAreaWrapper>{field.placeholder ? field.placeholder : "Enter text here."}</TextAreaWrapper>
            ) : field.type === "select" ? (
              <S.Box my={0} pl={0}>
                <Select
                  closeMenuOnSelect={false}
                  options={field.options}
                />
              </S.Box>
            ) : field.type === "radio" ? (
              <S.Box my={0} pl={3}>
                <RadioList>
                  {field.options && field.options.map((opt) => (
                   <li key={opt.label}>{opt.label}</li>
                  ))}
                </RadioList>
              </S.Box>
            ) : field.type === "checkboxes" ? (
              <S.Box my={0} pl={3}>
                <Checklist>
                  {field.options && field.options.map((opt) => (
                   <li key={opt.label}>&#9633; {opt.label}</li>
                  ))}
                </Checklist>
              </S.Box>
            ) : field.type === "number" ? (           
              <TextWrapper>
                ###
              </TextWrapper>
            ) : field.type === "name" ? (
              <S.Flex>
                <S.HalfWidth>
                  <TextWrapper>
                    First Name
                  </TextWrapper>
                </S.HalfWidth>
                <S.HalfWidth>
                  <TextWrapper>
                    Last Name
                  </TextWrapper>
                </S.HalfWidth>
              </S.Flex>
            ) : field.type === "address" ? (
              <>
                <TextWrapper>Address Line 1</TextWrapper>
                <TextWrapper>Address Line 2</TextWrapper>
                <S.Flex>
                  <S.HalfWidth>
                    <TextWrapper>
                      City
                    </TextWrapper>
                  </S.HalfWidth>
                  <S.HalfWidth>
                    <TextWrapper>
                      State
                    </TextWrapper>
                  </S.HalfWidth>
                </S.Flex>
                <S.Flex>
                  <S.HalfWidth>
                    <TextWrapper>
                      Postal Code
                    </TextWrapper>
                  </S.HalfWidth>
                  <S.HalfWidth>
                    <TextWrapper>
                      Country
                    </TextWrapper>
                  </S.HalfWidth>
                </S.Flex>
              </>
            ) : field.type === "date" ? (
              <TextWrapper>
                MM/DD/YYYY{field.time && " HH:MMam"}
              </TextWrapper>
            ) : field.type === "phone" ? (
              <TextWrapper>
                #-###-###-####
              </TextWrapper>
            ) : field.type === "email" ? (
              <TextWrapper>
                sample@email.com
              </TextWrapper>
            ) : field.type === "file" ? (
              <TextWrapper>
                Choose a file.
              </TextWrapper>
            ) : (
              <></>
            )}
          </S.Box>
        </FieldWrapper>
      )}
    </>
  );
};

export default EventRegistrationFormField;
// export { ReturnedFormField };
