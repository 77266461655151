import React from "react";
import { useMutation } from "@apollo/react-hooks";
import * as S from "./styles";
import { Formik, Field, Form, FieldArray } from "formik";
import { StyledPanelFormWrapper, RepeatableFieldButton } from "./StyledForm";
import * as SVG from "./svgs";
import { Company, NewLocationValues } from "./apiTypes";
import { createLocationMutation } from "./mutations/location";
import { createAddressMutation } from "./mutations/address";
import { createEmailAddressMutation } from "./mutations/emailAddress";
import { createPhoneNumberMutation } from "./mutations/phoneNumber";

function NewLocation({
  companyId,
  closeModal
}: {
  companyId: string;
  closeModal: () => void;
}): React.ReactElement {
  const [createLocation, { loading }] = useMutation<
    { createLocation: { id: string } },
    { input: NewLocationValues }
  >(createLocationMutation, {
    refetchQueries: ["LocationListQuery"]
  });
  const [createEmailAddress] = useMutation(createEmailAddressMutation, {
    refetchQueries: ["LocationListQuery"]
  });
  const [createPhoneNumber] = useMutation(createPhoneNumberMutation, {
    refetchQueries: ["LocationListQuery"]
  });
  const [createAddress] = useMutation(createAddressMutation, {
    refetchQueries: ["LocationListQuery"]
  });
  const makeLocation = async (input: NewLocationValues) => {
    try {
      const result = await createLocation({ variables: { input } });
      if (!result) throw "Could not create location for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createLocation &&
        result.data.createLocation.id
      )
        return result.data.createLocation;
      throw "Could not create location for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }
  };

  return (
    <Formik
      initialValues={{
        companyId: companyId,
        name: "",
        types: ["plant"],
        numEmployees: undefined,
        address: {
          city: "",
          country: "",
          postalCode: "",
          state: "",
          streetAddress: "",
          suiteNumber: ""
        },
        phoneNumbers: [
          { label: "main", phoneNumber: undefined },
          { label: "fax", phoneNumber: undefined }
        ],
        emailAddresses: [{ label: "main", emailAddress: undefined }]
      }}
      onSubmit={async values => {
        if (
          (values.address.country === "US" || values.address.country === "USA" || values.address.country === "United States" || values.address.country === "United States of America")
          && !/^[0-9]{5}-[0-9]{4}$/.test(values.address.postalCode)) {
          alert('Please enter a 9 digit postal code using the pattern #####-####');
        } else {
          try {
            let plant = await makeLocation({
              name: values.name,
              companyId: values.companyId,
              types: values.types,
              numEmployees: values.numEmployees
            });
            if (plant) {
              const plantId = plant.id;
              values.phoneNumbers && values.phoneNumbers.map(phoneNumber => {
                if (phoneNumber.phoneNumber && phoneNumber.phoneNumber !== "") {
                createPhoneNumber({
                  variables: {
                    input: {
                      phoneNumber: phoneNumber.phoneNumber,
                      label: phoneNumber.label,
                      subjectId: plantId
                    }
                  }
                });
              }
              });
              values.emailAddresses && values.emailAddresses.map(email => {
                if (email.emailAddress && email.emailAddress !== "") {
                createEmailAddress({
                  variables: {
                    input: {
                      emailAddress: email.emailAddress,
                      label: email.label,
                      subjectId: plantId
                    }
                  }
                });
              }
              });
              createAddress({
                variables: {
                  input: {
                    city: values.address.city,
                    country: values.address.country,
                    postalCode: values.address.postalCode,
                    state: values.address.state,
                    streetAddress: values.address.streetAddress,
                    suiteNumber: values.address.suiteNumber,
                    label: "plant",
                    preferred: true,
                    subjectId: plantId
                  }
                }
              });
              closeModal();
            }
          } catch (err) {
            throw err;
          }
        }
      }}
      render={({ values }) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Location</S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              <label htmlFor="name">
                Location Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="name"
                name="name"
                placeholder="Location Name"
                type="text"
                value={values.name ? values.name : ""}
              />
              <label htmlFor="name">Number of Employees</label>
              <Field
                id="numEmployees"
                name="numEmployees"
                placeholder="###"
                type="number"
                value={values.numEmployees ? values.numEmployees : undefined}
              />
              <label htmlFor={`address.streetAddress`}>Street Address</label>
              <Field
                id={`address.streetAddress`}
                name={`address.streetAddress`}
                placeholder="Street Address"
                type="text"
                value={
                  values.address.streetAddress
                    ? values.address.streetAddress
                    : ""
                }
              />
              <label htmlFor={`address.suiteNumber`}>Suite Number</label>
              <Field
                id={`address.suiteNumber`}
                name={`address.suiteNumber`}
                placeholder="Suite Number"
                type="text"
                value={
                  values.address.suiteNumber ? values.address.suiteNumber : ""
                }
              />
              <S.Flex>
                <S.HalfWidth>
                  <label htmlFor={`address.city`}>City</label>
                  <Field
                    id={`address.city`}
                    name={`address.city`}
                    placeholder="City"
                    type="text"
                    value={values.address.city ? values.address.city : ""}
                  />
                </S.HalfWidth>
                <S.HalfWidth>
                  <label htmlFor={`address.state`}>State</label>
                  <Field
                    id={`address.state`}
                    name={`address.state`}
                    placeholder="State"
                    type="text"
                    value={values.address.state ? values.address.state : ""}
                  />
                </S.HalfWidth>
              </S.Flex>
              <S.Flex>
                <S.HalfWidth>
                  <label htmlFor={`address.postalCode`}>Postal Code</label>
                  <Field
                    id={`address.postalCode`}
                    name={`address.postalCode`}
                    placeholder="######-####"
                    type="text"
                    value={
                      values.address.postalCode ? values.address.postalCode : ""
                    }
                  />
                  <span style={{fontSize: 10, lineHeight: 0.9, fontWeight: "bold"}}>Please consult the <a style={{color: "#3496E3"}} target="_blank" href="https://m.usps.com/m/ZipLookupAction">USPS website</a> to identify the correct 9 digit postal code for this location.</span>
                </S.HalfWidth>
                <S.HalfWidth>
                  <label htmlFor={`address.country`}>Country</label>
                  <Field
                    id={`address.country`}
                    name={`address.country`}
                    placeholder="Country"
                    type="text"
                    value={values.address.country ? values.address.country : ""}
                  />
                </S.HalfWidth>
              </S.Flex>
              <label htmlFor="phoneNumbers">Phone Numbers</label>
              <FieldArray
                name="phoneNumbers"
                render={arrayHelpers => (
                  <div>
                    {values.phoneNumbers &&
                    values.phoneNumbers.filter(a => a.label !== "delete")
                      .length > 0 ? (
                      values.phoneNumbers.map((phoneNumber, index) => (
                        <S.Flex mb={1} key={index}>
                          <Field
                            id={`phoneNumbers.${index}.label`}
                            name={`phoneNumbers.${index}.label`}
                            component="select"
                            className="tag"
                          >
                            {["", "main", "office", "fax", "cell"].map(opt => (
                              <option
                                value={opt}
                                key={opt}
                                selected={phoneNumber.label === opt}
                              >
                                {opt}
                              </option>
                            ))}
                          </Field>
                          <Field
                            id={`phoneNumbers.${index}.phoneNumber`}
                            name={`phoneNumbers.${index}.phoneNumber`}
                            placeholder="###-###-#### ext: ###"
                            type="text"
                            className="tagged-field"
                          />
                          <RepeatableFieldButton
                            type="button"
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          >
                            <SVG.MinusSvg />
                          </RepeatableFieldButton>
                          <RepeatableFieldButton
                            type="button"
                            onClick={() => {
                              arrayHelpers.insert(index + 1, {
                                label: ""
                              });
                            }}
                          >
                            <SVG.PlusSvg />
                          </RepeatableFieldButton>
                        </S.Flex>
                      ))
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          arrayHelpers.push({ label: "" });
                        }}
                      >
                        Add a phone number.
                      </button>
                    )}
                  </div>
                )}
              />
              <label htmlFor="emails">Email Addresses</label>
              <FieldArray
                name="emailAddresses"
                render={arrayHelpers => (
                  <div>
                    {values.emailAddresses &&
                    values.emailAddresses.filter(a => a.label !== "delete")
                      .length > 0 ? (
                      values.emailAddresses.map((email, index) => (
                        <S.Flex mb={1} key={index}>
                          <Field
                            id={`emailAddresses.${index}.label`}
                            name={`emailAddresses.${index}.label`}
                            component="select"
                            className="tag"
                          >
                            {["", "main", "corporate", "contact"].map(opt => (
                              <option
                                key={opt}
                                value={opt}
                                selected={email.label === opt}
                              >
                                {opt}
                              </option>
                            ))}
                          </Field>
                          <Field
                            id={`emailAddresses.${index}.emailAddress`}
                            name={`emailAddresses.${index}.emailAddress`}
                            placeholder="sample@co.org"
                            type="text"
                            className="tagged-field"
                          />
                          <RepeatableFieldButton
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            <SVG.MinusSvg />
                          </RepeatableFieldButton>
                          <RepeatableFieldButton
                            type="button"
                            onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                          >
                            <SVG.PlusSvg />
                          </RepeatableFieldButton>
                        </S.Flex>
                      ))
                    ) : (
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        Add an email.
                      </button>
                    )}
                  </div>
                )}
              />
              <S.Flex>
                <button type="submit" disabled={loading}>
                  Sav{loading ? "ing" : "e"}
                </button>
                <button onClick={closeModal} disabled={loading}>
                  Cancel{loading ? "ling" : ""}
                </button>
              </S.Flex>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
}

export default NewLocation;
