import React, { useState } from "react";
import * as S from "./styles";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form, FieldProps } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { __InputValue } from "graphql";
import { StyledPanelFormWrapper, FormikDatetime } from "./StyledForm";
import { createEventMutation } from "./mutations/event";
import { NewItemData, NewEventValues } from "./apiTypes";

const NewEvent: React.FC<{
  loading?: boolean;
  onSubmit: (
    input: NewEventValues
  ) => Promise<void | MutationFetchResult<{ createEvent: NewItemData }>>;
}> = ({ loading, onSubmit }) => {
  const [newId, setNewId] = useState<string | null>(null);

  const handleSubmit = async (input: NewEventValues) => {
    try {
      const result = await onSubmit(input);
      if (!result) throw "Could not create event for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (result.data && result.data.createEvent && result.data.createEvent.id)
        return setNewId(result.data.createEvent.id);
      throw "Could not create event for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }
  };

  if (newId) return <Redirect to={`/events/${newId}`} />;

  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
        startsAt: undefined,
        endsAt: undefined,
        registrationOpensAt: undefined,
        registrationClosesAt: undefined,
      }}
      onSubmit={handleSubmit}
      render={() => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Event</S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              <label htmlFor="title">
                Title
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="title"
                name="title"
                placeholder="Event Title"
                type="text"
              />
              <S.Flex>
                <div>
                  <label htmlFor="startsAt">Start Date</label>
                  <Field
                    id="startsAt"
                    name="startsAt"
                    component={FormikDatetime}
                  />
                </div>
                <div>
                  <label htmlFor="endsAt">End Date</label>
                  <Field id="endsAt" name="endsAt" component={FormikDatetime} />
                </div>
              </S.Flex>
              <S.Flex>
                <div>
                  <label htmlFor="registrationOpensAt">
                    Registration Open Date
                  </label>
                  <Field
                    id="registrationOpensAt"
                    name="registrationOpensAt"
                    component={FormikDatetime}
                  />
                </div>
                <div>
                  <label htmlFor="registrationClosesAt">
                    Registration Close Date
                  </label>
                  <Field
                    id="registrationClosesAt"
                    name="registrationClosesAt"
                    component={FormikDatetime}
                  />
                </div>
              </S.Flex>
              <label htmlFor="description">Description</label>
              <Field
                id="description"
                name="description"
                placeholder="An event within the flexible packaging industry."
                component="textarea"
              />
              <S.Button type="submit" disabled={loading}>
                Creat{loading ? "ing" : "e"} Event
              </S.Button>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
};

const ConnectedNewEvent = (props: {}): React.ReactElement => {
  return (
    <Mutation<{ createEvent: NewItemData }, { input: NewEventValues }>
      mutation={createEventMutation}
    >
      {(createEvent, { loading }) => (
        <NewEvent
          {...props}
          onSubmit={(input) => createEvent({ variables: { input } })}
          loading={loading}
        />
      )}
    </Mutation>
  );
};

export default ConnectedNewEvent;
