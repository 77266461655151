import React, { useState } from "react";
import styled from "styled-components";
import * as SVG from "./svgs";
import * as S from "./styles";
import { Formik, Field, Form, FieldProps } from "formik";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import { NavLink } from "react-router-dom";

const NavWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 60px;
  padding: 10px 40px;
  margin: 0;
  width: 300px;
  background: #fff;
  display: block;

  @media screen and (max-width: 1350px) {
    width: 250px;
    padding: 10px 20px;
  }
`;
const SupportRequestButton = styled.button`
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0;
  width: 300px;
  display: flex;
  padding: 20px 0;
  height: 60px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: ${(p) => p.theme.colors.lightgrey};
  display: block;

  &:hover {
    background: ${(p) => p.theme.colors.blue};
  }
  @media screen and (max-width: 1350px) {
    width: 250px;
    padding: 10px 20px;
  }
`;
const Logo = styled.div`
  padding: 3px 10px;
  margin-bottom: 20px;
`;
const StyledNavLink = styled(NavLink)`
  color: ${(p) => p.theme.colors.grey};
  padding: 3px 10px;
  margin: 10px 0;
  font-weight: 300;
  display: flex;
  align-items: center;
  transition: all 0.25s;
  &:hover {
    color: ${(p) => p.theme.colors.lightgrey};
  }
  &.active {
    color: ${(p) => p.theme.colors.blue};
    svg path {
      fill: ${(p) => p.theme.colors.blue};
    }
  }
`;

type SupportRequestValues = {
  url: string;
  panel: string;
  description: string;
  error: string;
  level: string;
};

const SupportRequest: React.FC<{}> = () => {
  const panelOptions = [
    {
      value: "Authentication",
      label: "Logins/Authentication/User Management",
      message:
        "Please include the Contact's name and the email address they are using for logins in your description.",
    },
    { value: "Search Bar", label: "Search Bar", message: "" },
    {
      value: "Dashboard",
      label: "Dashboard",
      message:
        "Please include the specific section in question in your description.",
    },
    { value: "Company Listing", label: "Company Listing", message: "" },
    {
      value: "Company",
      label: "Company Management",
      message:
        "Please include the specific section in question in your description.",
    },
    {
      value: "Plants",
      label: "Plants Management",
      message:
        "If applicable, please include if this was an update/create/delete in your description.",
    },
    {
      value: "Memberships",
      label: "Memberships Management",
      message:
        "If applicable, please include if this was an update/create/delete in your description.",
    },
    {
      value: "Sales Reports",
      label: "Sales Reports Management",
      message:
        "If applicable, please include if this was an update/create/delete in your description.",
    },
    { value: "Contact Listing", label: "Contact Listing", message: "" },
    {
      value: "Contact",
      label: "Contact Management",
      message:
        "If applicable, please include if this was an update/create/delete in your description.",
    },
    {
      value: "Company Contact",
      label: "Company Contact/Work History Management",
      message:
        "If applicable, please include if this was an update/create/delete in your description.",
    },
    {
      value: "Reports",
      label: "Reports",
      message:
        "If applicable, please include the name of the Report in question or supply the specifics needs for your new report.",
    },
    {
      value: "Events",
      label: "Events",
      message:
        "If applicable, please include if this was an update/create/delete in your description.",
    },
    {
      value: "Files",
      label: "Files",
      message:
        "If applicable, please include if this was an update/create/delete in your description.",
    },
    {
      value: "Settings",
      label: "Settings",
      message:
        "Please include the specific section in question in your description.",
    },
    {
      value: "Other",
      label: "Other / Not in List",
      message:
        "Please provide as many details as possible in your description.",
    },
  ];

  const [submitting, setSubmitting] = useState<boolean>(false);
  const url = (window.location && window.location.href) || "unknown";

  const handleSubmit = async (input: SupportRequestValues) => {
    setSubmitting(true);
    const emailSubject = `(${input.level}) FPA DB Support Re: ${input.panel}`;
    const emailTo = `maddie@merakke.com`;
    const emailCc = `lourdes@merakke.com; akeane@flexpack.org`;
    const emailBody = `Support Level: ${input.level}%0d%0a%0d%0aURL: ${input.url}%0d%0a%0d%0aDatabase Panel: ${input.panel}%0d%0a%0d%0aDescription: ${input.description}%0d%0a%0d%0aError: ${input.error}`;
    window.location.href = `mailto:${emailTo}?subject=${emailSubject}&cc=${emailCc}&body=${emailBody}`;
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        url: url,
        panel: "",
        level: "Standard",
        error: "",
        description: "",
      }}
      onSubmit={handleSubmit}
      render={({ values }) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>Request Support</S.UnderlinedHeader>
          <StyledPanelFormWrapper>
            <Form>
              <label htmlFor="name">
                Priority Level
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="level"
                name="level"
                component={Select}
                closeMenuOnSelect={true}
                options={[
                  { value: "Standard", label: "Standard" },
                  { value: "Change", label: "Addition/Change Request" },
                  { value: "ASAP", label: "Needs Attention Immediately" },
                ]}
              />
              <label htmlFor="name">
                What Is This Regarding?
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="panel"
                name="panel"
                component={Select}
                closeMenuOnSelect={true}
                options={panelOptions.map((option) => {
                  return { value: option.value, label: option.label };
                })}
              />
              {values.panel !== "" && (
                <S.P style={{ color: "red" }}>
                  {(panelOptions.filter(
                    (option) => values.panel === option.value
                  ) &&
                    panelOptions.filter(
                      (option) => values.panel === option.value
                    )[0].message) ||
                    ""}
                </S.P>
              )}
              <label htmlFor="description">
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="description"
                name="description"
                placeholder="Please explain your issue/need in detail. Thank you!"
                required
                component="textarea"
              />
              <label htmlFor="error">
                If You Received An Error Message, Include it Below:
              </label>
              <Field
                id="error"
                name="error"
                placeholder="Screen blanked out, etc."
                component="textarea"
              />
              <S.Button type="submit" disabled={submitting}>
                Creat{submitting ? "ing" : "e"} Request
              </S.Button>
            </Form>
            <S.P>
              Please attach any relevant screenshots/videos/etc. to your
              generated support email. Thank you!
            </S.P>
          </StyledPanelFormWrapper>
        </S.Box>
      )}
    />
  );
};

const MainNav = () => {
  const supportDialog = useDialogState();

  return (
    <>
      <NavWrapper>
        <Logo>
          <SVG.FpaLogo />
        </Logo>
        <StyledNavLink to="/dashboard">
          <SVG.DashboardSvg />
          Dashboard
        </StyledNavLink>
        <StyledNavLink to="/companies">
          <SVG.CompaniesSvg />
          Companies
        </StyledNavLink>
        <StyledNavLink to="/contacts">
          <SVG.UsersSvg />
          Contacts
        </StyledNavLink>
        <StyledNavLink to="/reports">
          <SVG.ReportsSvg />
          Reports
        </StyledNavLink>
        <StyledNavLink to="/events">
          <SVG.EventsSvg />
          Registrations
        </StyledNavLink>
        <StyledNavLink to="/awards">
          <SVG.AwardsSvg color="grey" />
          Awards
        </StyledNavLink>
        <StyledNavLink to="/files">
          <SVG.FilesSvg />
          Files
        </StyledNavLink>
        {/* <StyledNavLink to="/store">
          <SVG.StoreSvg />
          Store
        </StyledNavLink> */}
        <StyledNavLink to="/invoices">
          <SVG.InvoicingSvg />
          Invoicing
        </StyledNavLink>
        <StyledNavLink to="/settings">
          <SVG.SettingsSvg />
          Settings
        </StyledNavLink>
      </NavWrapper>
      <DialogDisclosure {...supportDialog} as={SupportRequestButton}>
        Create Support Request
      </DialogDisclosure>
      <DialogWithBackdrop {...supportDialog}>
        <SupportRequest />
      </DialogWithBackdrop>
    </>
  );
};

export default MainNav;
