import React, { useState, useCallback } from "react";
import Tags, { Tag } from "./Tags";
import Avatar, { AvatarList } from "./UserAvatar";
import styled from "styled-components";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Select as FormSelect } from "./StyledForm";
import Select, { ValueType } from "react-select";
import Page, { PageButtons } from "./Page";
import NewCompany from "./NewCompany";
import { CompanyTags } from "./Company";
import { Link, useLocation } from "react-router-dom";
import { primaryContact, authorizedContacts } from "./util";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import { UrlList, PrimaryUrl } from "./Urls";
import { Logo } from "./Image";
import { PhoneNumberList, PrimaryPhoneNumber } from "./PhoneNumbers";
import { PrimaryEmail, EmailList } from "./Emails";
import { PrimaryAddress } from "./Addresses";
import { companyListQuery, companySearchQuery } from "./queries/company";
import { useQuery } from "@apollo/react-hooks";
import { mapProps } from 'styled-system';
import Location from "./Location";
import Locations from "./Locations";
import NewLocation from "./NewLocation";
import { LocationListQuery } from "./queries/location";
import { Query } from "@apollo/react-components";
import { Company, FilterValue, LocationsListQueryData } from "./apiTypes";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";

const CompanyPlantsWrapper = styled.div`
  @media screen and (max-width: 1550px) {
    ${S.Box} {
      width: 50%;
    }
  }
  @media screen and (max-width: 900px) {
    ${S.Box} {
      width: 100%;
    }
  }
`;

type CompanyPlantsProps = {
  company: Company;
};

const CompanyPlants: React.FunctionComponent<CompanyPlantsProps> = ({
  company,
}) => {
  return (
    <CompanyPlantsWrapper>
      <Page>
        <Locations companyId={company.id} />
      </Page>
    </CompanyPlantsWrapper>
  );
};

export default CompanyPlants;