import { gql } from "graphql.macro";

export const createUrlMutation = gql`
  mutation createUrlMutation($input: CreateUrlInput!) {
    createUrl(input: $input) {
      label
      url
      id
      preferred
    }
  }
`;

export const deleteUrlMutation = gql`
  mutation deleteUrlMutation($input: DeleteUrlInput!) {
    deleteUrl(input: $input) {
      id
    }
  }
`;

export const updateUrlMutation = gql`
  mutation updateUrlMutation($input: UpdateUrlInput!) {
    updateUrl(input: $input) {
      label
      url
      id
      preferred
    }
  }
`;
