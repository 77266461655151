import React from "react";
import Searchbar from "./Searchbar";
import * as S from "./styles";
import * as SVG from "./svgs";
import { match } from "react-router-dom";
import { useMenuState, Menu, MenuDisclosure } from "./Menu";
import PageNav, { NavInfo, BackButton, TopPageNav } from "./PageNav";
import { Registration } from "./apiTypes";

type RegistrationNavProps = {
  registration: Registration;
  match: match<{ registrationId: string }>;
};

function EditRegistrationMenu({
  registration
}: {
  registration: Registration;
}) {
  const menu = useMenuState();
  // const [cloneEvent]: UseMutationReturn<
  //   { cloneEvent: Event },
  //   { input: { eventId: string } }
  // > = useMutation(cloneEventMutation);
  // const [deleteEvent]: UseMutationReturn<
  //   { deleteEvent: Event },
  //   { input: { eventId: string } }
  // > = useMutation(deleteEventMutation);

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Registration
      </MenuDisclosure>
      <Menu {...menu}>
        {/* <MenuItem
          {...menu}
          onClick={() =>
            cloneEvent({
              variables: { input: { eventId: event.id } }
            })
          }
        >
          Clone Event
        </MenuItem>
        <MenuItem
          {...menu}
          onClick={() =>
            deleteEvent({
              variables: { input: { eventId: event.id } }
            })
          }
        >
          Delete Event
        </MenuItem> */}
      </Menu>
    </>
  );
}

const RegistrationNav: React.FunctionComponent<RegistrationNavProps> = ({
  match,
  registration
}) => {
  return (
    <PageNav>
      <S.Box pt={3} px={3} my={0}>
        <S.Flex alignItems="center" justifyContent="space-between">
          <TopPageNav />
          {registration.cancelledAt ? (
            <div>
              <SVG.CrossSvg />
              CANCELLED
            </div>
          ) : registration.completedAt ? (
            <div>
              <SVG.CheckSvg />
              COMPLETED
            </div>
          ) : (
            <div>INCOMPLETE</div>
          )}
        </S.Flex>
        <NavInfo>
          <S.Flex
            ml={3}
            pb={3}
            width={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <S.H1>Registration</S.H1>
              <S.P>Event Name – Sample Here</S.P>
            </div>
            <EditRegistrationMenu registration={registration} />
          </S.Flex>
        </NavInfo>
      </S.Box>
    </PageNav>
  );
};

export default RegistrationNav;
