import React from "react";
import styled from "styled-components";
import { Dialog, DialogBackdrop, DialogStateReturn } from "reakit/Dialog";

export const StyledDialogBackdrop = styled(DialogBackdrop)`
  background: hsl(0, 0%, 0%, 0.75);
`;

const dialogOffset = 28;
export const StyledDialog = styled(Dialog)`
  box-sizing: border-box;
  position: fixed;
  top: ${dialogOffset}px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  min-width: 600px;
  max-height: calc(100vh - ${dialogOffset * 2}px);
  box-shadow: 0 1px 40px hsla(0, 0%, 0%, 0.33);
  border-radius: 4px;
  overflow: scroll;
`;

export function DialogWithBackdrop({
  children,
  ...rest
}: React.PropsWithChildren<DialogStateReturn>) {
  return (
    <>
      <StyledDialogBackdrop {...rest} />
      <StyledDialog {...rest} children={children} />
    </>
  );
}

export { StyledDialog as Dialog, StyledDialogBackdrop as DialogBackdrop };
export { useDialogState, DialogDisclosure } from "reakit/Dialog";
