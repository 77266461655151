import React from "react";
import FullPageContainer from "./FullPageContainer";
import Loader from "./Loader";

export default function FullPageLoader(): React.ReactElement {
  return (
    <FullPageContainer>
      <Loader />
    </FullPageContainer>
  );
}
