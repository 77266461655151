import React, { useRef } from "react";
import * as S from "./styles";
import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { ConnectionPageInfo } from "./apiTypes";
import { TD } from "./Table";
import { useSticky } from "./hooks";

type PageWithSidePanelProps = {
  thirds?: boolean;
};

type PageProps = {} & SpaceProps;

export const Page = styled.div<PageProps>`
  display: flex;
  padding: 25px;
  margin-left: 300px;
  width: calc(100vw - 300px);
  display: block;
  background: transparent;
  ${space}

  @media screen and (max-width: 1350px) {
    width: calc(100vw - 250px);
    margin-left: 250px;
  }
`;

export const PageWithSidePanel = styled.div<PageWithSidePanelProps>`
  margin-left: 300px;
  display: flex;
  width: calc(100vw - 300px);
  @media screen and (max-width: 1350px) {
    width: calc(100vw - 250px);
    margin-left: 250px;
  }

  ${Page} {
    margin: 0;
    width: 50%;
    padding: 25px;
    display: inline-block;

    ${TD} {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
  ${p =>
    p.thirds &&
    css`
      ${Page} {
        width: calc(66.66666vw - 200px);
        @media screen and (max-width: 1350px) {
          width: calc(66.66666vw - 167px);
        }
      }
      ${PageSidePanelWrapper} {
        width: calc(33.33333vw - 100px);
        @media screen and (max-width: 1350px) {
          width: calc(33.33333vw - 83px);
        }
      }
    `}
`;

type SidePanelWrapperProps = { stuck?: boolean };
export const PageSidePanelWrapper = styled.div<SidePanelWrapperProps>`
  width: 50%;
  background: #fff;
  border-radius: 4px;
  padding: 10px 25px;
  display: block;
  box-shadow: 0 2px 8px rgba(118, 132, 158, 0.2);

  &#form-settings {
    padding: 0;
  }

  ${p =>
    p.stuck &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      overflow-y: auto;
    `}

  ${S.Box} {
    padding: 10px 0 35px 0;
    margin: 0;
  }
  ${S.List} li {
    padding-left: 0;
  }
  ${TD} {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
  a:hover {
    h1,
    h2,
    h3,
    h4 {
      color: ${p => p.theme.colors.blue};
    }
  }
`;

type SidePanelProps = { id?: string };

export const PageSidePanel: React.FunctionComponent<
  SidePanelProps & SidePanelWrapperProps
> = ({ children, ...restProps }) => {
  const panelRef = useRef<HTMLDivElement | null>(null);
  const stuck = useSticky(panelRef);

  return (
    <PageSidePanelWrapper {...restProps} ref={panelRef} stuck={stuck}>
      {children}
    </PageSidePanelWrapper>
  );
};

export type PageButtonsProps = {
  pageInfo: ConnectionPageInfo;
  basePath: string;
  topMargin?: number;
};

export const PageButtons: React.FunctionComponent<PageButtonsProps> = ({
  pageInfo,
  basePath,
  topMargin
}) => {
  return (
    <S.Flex
      justifyContent="space-between"
      alignItems="center"
      mx={3}
      mt={topMargin}
      mb={1}
    >
      {pageInfo.hasPreviousPage ? (
        <S.LinkButton to={`/${basePath}?before=${pageInfo.startCursor}`}>
          Previous
        </S.LinkButton>
      ) : (
        <div />
      )}
      {pageInfo.hasNextPage ? (
        <S.LinkButton to={`/${basePath}?after=${pageInfo.endCursor}`}>
          Next
        </S.LinkButton>
      ) : (
        <div />
      )}
    </S.Flex>
  );
};

export default Page;
