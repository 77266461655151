import React, { useState } from "react";
import Select from "react-select";
import FileUpload from "./FileUpload";
import * as S from "./styles";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { StyledPanelFormWrapper } from "./StyledForm";
import { createCompanyMutation } from "./mutations/company";
import { NewItemData, NewCompanyValues } from "./apiTypes";

const NewCompany: React.FC<{
  loading?: boolean;
  onSubmit: (
    input: NewCompanyValues
  ) => Promise<void | MutationFetchResult<{ createCompany: NewItemData }>>;
}> = ({ loading, onSubmit }) => {
  const [newId, setNewId] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const handleSubmit = async (input: NewCompanyValues) => {
    try {
      const result = await onSubmit(input);
      if (!result) throw "Could not create company for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createCompany &&
        result.data.createCompany.id
      )
        return setNewId(result.data.createCompany.id);
      throw "Could not create company for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }
  };

  if (newId) return <Redirect to={`/companies/${newId}`} />;

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        federalIdNumber: "",
        naicsCodes: [""],
        numEmployees: undefined,
        logoId: undefined,
        reportedSales: undefined
      }}
      onSubmit={handleSubmit}
      render={({ values }) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Company</S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              <label htmlFor="name">
                Company Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="name"
                name="name"
                placeholder="Flexible Packaging Association"
                type="text"
              />
              <label htmlFor="description">
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="description"
                name="description"
                placeholder="A company within the flexible packaging industry."
                required
                component="textarea"
              />
              <label htmlFor="logoUrl">Company Logo</label>
              <S.Flex>
                {imageUrl ? (
                  <img height="35" src={imageUrl} alt="Company Logo" />
                ) : (
                  <></>
                )}
                <FileUpload
                  onUpload={files => {
                    values.logoId = files[0].id;
                    setImageUrl(files[0].url);
                  }}
                  multiple={false}
                />
              </S.Flex>
              <label htmlFor="numEmployees">Number of Employees</label>
              <Field
                id="numEmployees"
                name="numEmployees"
                placeholder="###"
                type="number"
              />
              <label htmlFor="reportedSales">US Sales for this Year</label>
              <Field id="reportedSales" name="reportedSales" type="number" />
              <S.Button type="submit" disabled={loading}>
                Creat{loading ? "ing" : "e"} Company
              </S.Button>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
};

const ConnectedNewCompany = (props: {}): React.ReactElement => {
  return (
    <Mutation<{ createCompany: NewItemData }, { input: NewCompanyValues }>
      mutation={createCompanyMutation}
    >
      {(createCompany, { loading }) => (
        <NewCompany
          {...props}
          onSubmit={input => createCompany({ variables: { input } })}
          loading={loading}
        />
      )}
    </Mutation>
  );
};

export default ConnectedNewCompany;
