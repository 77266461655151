import React from "react";
import moment from "moment";
import * as S from "./styles";
import NumberFormat from "react-number-format";
import Table, { TR, TD } from "./Table";
import Tags, { Tag } from "./Tags";
import { useQuery } from "@apollo/react-hooks";
import { Company, Contact, StripeInvoice, StripeInvoiceLine, StripeCustomer, StripeCoupon, StripeDiscount } from "./apiTypes";
import { stripeInvoiceListQuery } from "./queries/stripeInvoices";


const InvoicesSummary: React.FunctionComponent<{ subjectId: string }> = ({ subjectId }) => {

  const { loading, error, data } = useQuery<{stripeInvoices: StripeInvoice[]}>(
    stripeInvoiceListQuery, { 
      variables: {
        filter: { 
          subjectId
        }
      }
    }
  );

  if (loading && !data) return <></>;
  if (error) return <>Oops.</>;
  if (!data || !data.stripeInvoices || data.stripeInvoices.length < 1)
    return <>There are no invoices on file for this customer.</>;

  return (
    <Table cols="250px 180px 2fr" mt={1}>
      {data.stripeInvoices.map((stripeInvoice) => {
        let status = stripeInvoice.status;
        const dueDate = moment(stripeInvoice.dueDate);
        const created = moment(stripeInvoice.createdAt);
        const today = moment();

        return (
          <TR key={stripeInvoice.id} borderless>
            <TD>
              <S.Title>
                {stripeInvoice.description} - <NumberFormat
                style={{display: "inline-block", fontSize: "100%"}}
                value={stripeInvoice.total/100}
                fixedDecimalScale
                decimalScale={2}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
                {/* <span><b>Invoice ID: </b>{stripeInvoice.id}</span> */}
                <span><b>Created: </b>{created.format("MMMM D, YYYY h:mma")}</span>
                <span><b>Due: </b>{dueDate.format("MMMM D, YYYY h:mma")}</span>
              </S.Title>
            </TD>
            <TD>
              <S.Title>
                { stripeInvoice.status === 'paid' ? (
                  <Tag color="lightgreen">PAID</Tag>
                ) : stripeInvoice.status === 'open' ? (
                  // check is late show if open or late
                  <>
                    {dueDate > today ? (
                    <Tag color="lightblue">OPEN</Tag>
                  ) : (
                    <Tag color="lightorange">LATE</Tag>
                  )}
                  <span><b>Paid: </b>
                  <NumberFormat
                    style={{display: "inline-block"}}
                    value={stripeInvoice.amountPaid/100}
                    fixedDecimalScale
                    decimalScale={2}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
                <span><b>Due: </b>
                  <NumberFormat
                    style={{display: "inline-block"}}
                    value={stripeInvoice.amountDue/100}
                    fixedDecimalScale
                    decimalScale={2}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
                </>
                ) : stripeInvoice.status === 'draft' ? (
                  <Tag color="lightblue">DRAFT/UNSENT</Tag>
                ) : (
                  <Tag color="lightyellow">VOIDED/UNCOLLECTIBLE</Tag>
                )}
              </S.Title>
            </TD>
            <TD>
              <S.ButtonedLink style={{ marginRight: "10px" }} size="small" target="_blank" href={`https://dashboard.stripe.com/invoices/${stripeInvoice.id}`}>
                View In Stripe
              </S.ButtonedLink>
              { stripeInvoice.status === 'open' && 
                <S.ButtonedLink style={{ marginRight: "10px" }} size="small" target="_blank" href={stripeInvoice.hostedInvoiceUrl}>
                  Open Payment Portal
                </S.ButtonedLink>
              }
              { stripeInvoice.status !== 'draft' &&
                <S.ButtonedLink size="small" href={stripeInvoice.invoicePdf} target="_blank" download={stripeInvoice.invoicePdf}>
                  Download Invoice
                </S.ButtonedLink>
              }
              {/* {stripeInvoice.status === 'open' && dueDate < today && 
                <S.ButtonedLink style={{ marginLeft: "10px" }} size="small" href="/" target="_blank">
                  Remind
                </S.ButtonedLink> 
              } */}
            </TD>
          </TR>
        );
      })}
    </Table>
  );
}

const CompanyInvoicesSummary: React.FunctionComponent<{ company: Company }> = ({
  company
}) => {
  return (
    <S.Panel pb={2}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          Invoice History
          { company.stripeCustomerId && <S.ButtonedLink
            size="small"
            href={`https://dashboard.stripe.com/customers/${company.stripeCustomerId}`}
            target="_blank"
          >View All</S.ButtonedLink> }
        </S.Flex>
      </S.UnderlinedHeader>
      {company.preferredInvoiceDeliveryMethod ? (
        <S.Box width={2 / 3}>
          <Table cols="250px 1fr">
            <TR borderless>
              <TD>
                <b>Invoice Delivery Method</b>
              </TD>
              <TD>{company.preferredInvoiceDeliveryMethod}</TD>
            </TR>
          </Table>
        </S.Box>
      ) : (
        <></>
      )}
      <S.Box p={0} m={0}><InvoicesSummary subjectId={company.id} /></S.Box>
    </S.Panel>
  );
};

const ContactInvoicesSummary: React.FunctionComponent<{ contact: Contact }> = ({
  contact
}) => {
  return (
    <S.Box pb={2}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          Invoice History
          {contact.stripeCustomerId &&
          <S.ButtonedLink
            size="small"
            href={`https://dashboard.stripe.com/customers/${contact.stripeCustomerId}`}
            target="_blank"
          >View All</S.ButtonedLink> }
        </S.Flex>
      </S.UnderlinedHeader>
      <InvoicesSummary subjectId={contact.id} />
    </S.Box>
  );
};

export default InvoicesSummary;
export { CompanyInvoicesSummary, ContactInvoicesSummary };
