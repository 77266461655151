import { gql } from "graphql.macro";

export const createAchievementAwardSubmissionMutation = gql`
  mutation createAchievementAwardSubmissionMutation(
    $input: CreateAchievementAwardSubmissionInput!
  ) {
    createAchievementAwardSubmission(input: $input) {
      id
    }
  }
`;
export const updateAchievementAwardSubmissionMutation = gql`
  mutation updateAchievementAwardSubmissionMutation(
    $input: UpdateAchievementAwardSubmissionInput!
  ) {
    updateAchievementAwardSubmission(input: $input) {
      id
    }
  }
`;

export const createAchievementAwardImageMutation = gql`
  mutation createAchievementAwardImageMutation(
    $input: CreateAchievementAwardImageInput!
  ) {
    createAchievementAwardImage(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const updateAchievementAwardImageMutation = gql`
  mutation updateAchievementAwardImageMutation(
    $input: UpdateAchievementAwardImageInput!
  ) {
    updateAchievementAwardImage(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const deleteAchievementAwardImageMutation = gql`
  mutation deleteAchievementAwardImageMutation(
    $input: DeleteAchievementAwardImageInput!
  ) {
    deleteAchievementAwardImage(input: $input) {
      submission {
        id
      }
    }
  }
`;

export const createAchievementAwardDocumentMutation = gql`
  mutation createAchievementAwardDocumentMutation(
    $input: CreateAchievementAwardDocumentInput!
  ) {
    createAchievementAwardDocument(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const deleteAchievementAwardDocumentMutation = gql`
  mutation deleteAchievementAwardDocumentMutation(
    $input: DeleteAchievementAwardDocumentInput!
  ) {
    deleteAchievementAwardDocument(input: $input) {
      submission {
        id
      }
    }
  }
`;

export const createAchievementAwardWinnerMutation = gql`
  mutation createAchievementAwardWinnerMutation(
    $input: CreateAchievementAwardWinnerInput!
  ) {
    createAchievementAwardWinner(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const updateAchievementAwardWinnerMutation = gql`
  mutation updateAchievementAwardWinnerMutation(
    $input: UpdateAchievementAwardWinnerInput!
  ) {
    updateAchievementAwardWinner(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const deleteAchievementAwardWinnerMutation = gql`
  mutation deleteAchievementAwardWinnerMutation(
    $input: DeleteAchievementAwardWinnerInput!
  ) {
    deleteAchievementAwardWinner(input: $input) {
      submission {
        id
      }
    }
  }
`;

export const createAchievementAwardCategoryMutation = gql`
  mutation createAchievementAwardCategoryMutation(
    $input: CreateAchievementAwardCategoryInput!
  ) {
    createAchievementAwardCategory(input: $input) {
      id
    }
  }
`;
export const updateAchievementAwardCategoryMutation = gql`
  mutation updateAchievementAwardCategoryMutation(
    $input: UpdateAchievementAwardCategoryInput!
  ) {
    updateAchievementAwardCategory(input: $input) {
      id
    }
  }
`;
export const attachAchievementAwardCategoryMutation = gql`
  mutation attachAchievementAwardCategoryMutation(
    $input: AttachAchievementAwardCategoryInput!
  ) {
    attachAchievementAwardCategory(input: $input) {
      id
    }
  }
`;
export const detachAchievementAwardCategoryMutation = gql`
  mutation detachAchievementAwardCategoryMutation(
    $input: DetachAchievementAwardCategoryInput!
  ) {
    detachAchievementAwardCategory(input: $input) {
      id
    }
  }
`;

export const archiveAchievementAwardSubmissionMutation = gql`
  mutation archiveAchievementAwardSubmissionMutation(
    $input: ArchiveAchievementAwardSubmissionInput!
  ) {
    archiveAchievementAwardSubmission(input: $input) {
      id
    }
  }
`;
