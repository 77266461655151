import { gql } from "graphql.macro";

export const createPhoneNumberMutation = gql`
  mutation createPhoneNumberMutation($input: CreatePhoneNumberInput!) {
    createPhoneNumber(input: $input) {
      label
      phoneNumber
      id
      preferred
    }
  }
`;

export const deletePhoneNumberMutation = gql`
  mutation deletePhoneNumberMutation($input: DeletePhoneNumberInput!) {
    deletePhoneNumber(input: $input) {
      id
    }
  }
`;

export const updatePhoneNumberMutation = gql`
  mutation updatePhoneNumberMutation($input: UpdatePhoneNumberInput!) {
    updatePhoneNumber(input: $input) {
      label
      phoneNumber
      id
      preferred
    }
  }
`;
