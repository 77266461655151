import { gql } from "graphql.macro";

export const eventListQuery = gql`
  query eventListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: EventsOrderByInput
  ) {
    events(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          allDay
          description
          endsAt
          registrationClosesAt
          registrationOpensAt
          startsAt
          title
          insertedAt
          updatedAt
          registrations(first: 10) {
            count
          }
        }
      }
    }
  }
`;

export const eventQuery = gql`
  query eventQuery($eventId: ID!) {
    event(eventId: $eventId) {
      id
      allDay
      description
      endsAt
      registrationClosesAt
      registrationOpensAt
      startsAt
      title
      insertedAt
      updatedAt
      registrations(first: 10) {
        count
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            cancelledAt
            completedAt
            event {
              id
            }
            contact {
              id
            }
            form {
              id
            }
            insertedAt
            updatedAt
          }
        }
      }
    }
  }
`;
