import React, { useState, useContext, useEffect, useMemo } from "react";
import { User } from "../apiTypes";
import {
  receiveToken,
  logOut,
  bootstrapAppData,
  APIOAuthToken,
  exchangeAuthCode
} from "../auth";
import { joinPath } from "../util";
import config from "../config";
import { usePromise } from "../hooks";
import FullPageLoader from "../FullPageLoader";

interface AuthContext {
  user: User | null;
  isAuthenticated: boolean;
  receiveToken(token?: APIOAuthToken): Promise<void>;
  exchangeAuthCode(code: string): Promise<void>;
  logOut(): Promise<void>;
  reload(): Promise<void>;
}

export const AuthContext = React.createContext<AuthContext>({
  user: null,
  isAuthenticated: false,
  receiveToken: () => Promise.resolve(),
  exchangeAuthCode: () => Promise.resolve(),
  logOut: () => Promise.resolve(),
  reload: () => Promise.resolve()
});

export function AuthProvider(
  props: React.PropsWithChildren<{}>
): React.ReactElement {
  const [firstAttemptSettled, setFirstAttemptSettled] = useState(false);
  const { data, isPending, isSettled, reload } = usePromise(bootstrapAppData);

  useEffect(() => {
    if (isSettled) setFirstAttemptSettled(true);
  }, [isSettled]);

  const context = useMemo(() => {
    const user = (data && data.user) || null;
    return {
      user,
      isAuthenticated: !!user,
      receiveToken: (token?: APIOAuthToken) => {
        receiveToken(token);
        return reload();
      },
      exchangeAuthCode: (code: string) => {
        return exchangeAuthCode(code).then(reload);
      },
      logOut: () => {
        // Delete token from storage
        logOut();

        // Redirect to API logout
        window.location.href = joinPath(
          config.apiUrl,
          `/logout?redirect_uri=${config.appUrl}`
        );
        return Promise.resolve();
      },
      reload
    };
  }, [data, reload]);

  if (!firstAttemptSettled && isPending) {
    return <FullPageLoader />;
  }

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContext {
  const context = useContext(AuthContext);
  return context;
}
