import React, { useState } from "react";
import Table, { TR, TD } from "./Table";
import gql from "graphql-tag";
import { DateRange } from "./Date";
import { Query } from "@apollo/react-components";
import Select from "react-select";
import { PageButtons } from "./Page";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Connection,
  Registration,
  SortValue,
  Event,
  FilterValue,
  RegistrationsListQueryData,
} from "./apiTypes";
import { registrationListQuery } from "./queries/registration";
import { Link, useLocation } from "react-router-dom";
import * as S from "./styles";
import * as SVG from "./svgs";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";
import moment from "moment";


const RegistrationsList: React.FunctionComponent<{
  event: Event;
}> = ({ event }) => {

  const addDialog = useDialogState();

  const sortOptions = [
    { label: "Completion Date (Newest)", value: "COMPLETED_AT_DESC" },
    { label: "Completion Date (Oldester)", value: "COMPLETED_AT_ASC" },
    { label: "Created Date (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Created Date (Oldester)", value: "INSERTED_AT_ASC" },
    { label: "Registrant Name A-Z", value: "registrant a-z" },
    { label: "Registrant Name Z-A", value: "registrant z-a" },
    { label: "Company Name A-Z", value: "company a-z" },
    { label: "Company Name Z-A", value: "company z-a" },
    { label: "Registration Balance", value: "balance" },
  ];
  // const filterOptions = [
  //   { label: "Paid", value: "paid" },
  //   { label: "Past Due", value: "past due" },
  //   { label: "Incomplete", value: "incomplete" },
  // ];
  const filterOptions = [
    { label: "Cancelled", value: "CANCELLED" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Deleted", value: "ARCHIVED" },
  ];

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const [filters, setFilters] = useState<FilterValue>([]);

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleFilters = (newValues: FilterValue) => {
    setFilters(newValues);
  };

  const query = new URLSearchParams(useLocation().search);
  const pageSize = 24;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;
  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "NAME_ASC",
    filter: {
      eventId: event.id,
      archived:
        filters &&
        !Array.isArray(filters) &&
        !(
          (filters as { label: string; value: string }).value ==
          "ARCHIVED"
        ),
      completedAt: undefined,
      cancelledAt: undefined,
      insertedAt: undefined,
    },
  };

  const { loading, error, data } = useQuery<RegistrationsListQueryData>(
    registrationListQuery,
    { variables: queryVars }
  );

  return (
    <div>
      <S.Flex justifyContent="space-between" alignItems="start" mx={3} mb={2}>
        <div>
        </div>
        <DialogDisclosure {...addDialog} as={S.Button}>
          Add New Registration
        </DialogDisclosure>
      </S.Flex>
      <S.Flex
        justifyContent="space-between"
        alignItems="flex-start"
        mx={3}
      >
        <div style={{ flex: 1, marginRight: 20 }}>
          <label>SORT BY</label>
          <Select
            closeMenuOnSelect={true}
            onChange={handleSort}
            options={sortOptions}
            value={sort}
          />
        </div>
        <div style={{ flex: 1, marginLeft: 20 }}>
          <label>FILTER BY</label>
          <Select
            closeMenuOnSelect={false}
            onChange={handleFilters}
            options={filterOptions}
            value={filters}
          />
        </div>
      </S.Flex>
      { event.registrations && <PageButtons
      topMargin={4}
      pageInfo={event.registrations.pageInfo}
      basePath={`events/${event.id}/registrations`}
    />}
      
      <S.Panel>
        <Table cols="2fr 2fr 2fr 130px 130px 100px">
          <TR header>
            <TD>CREATED ON</TD>
            <TD>REGISTRANT NAME</TD>
            <TD>REGISTRANT COMPANY</TD>
            <TD>REGISTRATION STATUS</TD>
            <TD><S.Box my={0} mx={0} pending>PAYMENT STATUS</S.Box></TD>
            <TD></TD>
          </TR>
          {loading && !data ? (
            <>Loading...</>
          ) : error ? (
            <>There was an error fetching the registrations.</>
          ) : !data || !data.registrations || !Array.isArray(data.registrations.edges) ? (
            <>No registration exist for this event.</>
          ) : (
            data.registrations && data.registrations.edges && data.registrations.edges.map((edge) => {
              const registration = edge.node;
              if (!registration) return <React.Fragment />;
              return (
                <TR key={registration.id} pt={3} pb={2}>
                  <TD>{moment(registration.insertedAt).format("MMMM D, YYYY h:MM a")}</TD>
                  <TD>
                    <Link to={`/contacts/${registration.contact.id}`}>
                      {registration.contact.firstName} {registration.contact.lastName}
                    </Link>
                  </TD>
                  <TD>
                    <S.List noPadding noBorder>
                      {registration.contact.companyMemberships ? (
                        registration.contact.companyMemberships.map((membership) => {
                          if (membership.endedOn) return null;
                          const company = membership.company;
                          if (!company) return null;
                          if (
                            !company.id ||
                            !company.name ||
                            company.deactivatedAt
                          )
                            return null;
                          return (
                            <li key={company.id}>
                              <Link to={`/companies/${company.id}`}>
                                <S.Title>{company.name}</S.Title>
                              </Link>
                            </li>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </S.List>
                  </TD>
                  <TD>
                    {registration.completedAt ? (
                      <><SVG.CheckSvg /> Complete</>
                    ) : registration.cancelledAt ? (
                      <><SVG.CrossSvg /> Cancelled</>
                    ) : (
                      <></>
                    )}
                  </TD>
                  <TD>
                    {/* {true ? (
                      <><SVG.CheckSvg /> Paid</>
                    ) : (
                      <><SVG.CrossSvg /> Past Due</>
                    )} */}
                    <S.Box my={0} mx={0} pending>Payment Status</S.Box> 
                  </TD>
                  <TD>
                    <S.LinkButton
                      size="small"
                      to={`events/${event.id}/registrations/${registration.id}`}
                    >
                      View Registration
                    </S.LinkButton>
                  </TD>
                </TR>
              );
            })
          )}
        </Table>
      </S.Panel>
      { event.registrations && <PageButtons
      topMargin={4}
      pageInfo={event.registrations.pageInfo}
      basePath={`events/${event.id}/registrations`}
    />}
    <DialogWithBackdrop {...addDialog}>
      {/* <NewRegistration /> */}
      <></>
    </DialogWithBackdrop>
    </div>
  );
};

export default RegistrationsList;
