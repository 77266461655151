import React from "react";
import styled, { css } from "styled-components";
import * as S from "./styles";
import * as SVG from "./svgs";
import { useMutation } from "@apollo/react-hooks";
import Searchbar from "./Searchbar";
import UserMenu from "./UserMenu";
import Tags from "./Tags";
import { Link, match } from "react-router-dom";
import { Calendar } from "./Date";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { NavLink } from "react-router-dom";

const SubNavWrapper = styled.div`
  background: ${p => p.theme.colors.lightblue};
  margin-left: 300px;
  width: calc(100vw - 300px);
  display: block;
  @media screen and (max-width: 1350px) {
    width: calc(100vw - 250px);
    margin-left: 250px;
  }
`;

const NavInfo = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  ${S.H1} {
    color: ${p => p.theme.colors.grey};
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    span {
      font-weight: 400;
      font-size: 60%;
    }
  }
  ${Tags} {
    position: static;
  }
`;
const LocalNav = styled.div`
  margin-top: 30px;
  display: flex;
`;

type NavLinkProps = {
  pending?: boolean;
};
const LocalNavA = styled(NavLink)<NavLinkProps>`
  font-weight: 500;
  padding: 6px 10px;
  margin: 0 10px;
  border-bottom: 2px solid ${p => p.theme.colors.lightblue};
  &:hover,
  &.active {
    color: ${p => p.theme.colors.blue};
    border-color: ${p => p.theme.colors.blue};
  }
  ${p =>
    p.pending &&
    css`
      filter: blur(1.5px);
    `}
`;
const BackButtonStyles = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  padding: 6px 10px;
  margin: 0 4px;
  background: none;
  border: none;
  color: ${p => p.theme.colors.lightgrey};
  svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    path {
      fill: ${p => p.theme.colors.lightgrey};
    }
  }
  &:hover,
  &.active {
    color: ${p => p.theme.colors.blue};
    svg path {
      fill: ${p => p.theme.colors.blue};
    }
  }
`;

function BackButton() {
  return (
    <BackButtonStyles onClick={() => window.history.go(-1)}>
      <S.Flex alignItems="center">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 12C21 12.6 20.6 13 20 13H6.39999L10.7 17.3C11.1 17.7 11.1 18.3 10.7 18.7C10.5 18.9 10.3 19 9.99999 19C9.69999 19 9.49999 18.9 9.29999 18.7L3.29999 12.7C3.19999 12.6 3.09999 12.5 3.09999 12.4C2.99999 12.2 2.99999 11.9 3.09999 11.6C3.19999 11.5 3.19999 11.4 3.29999 11.3L9.29999 5.3C9.69999 4.9 10.3 4.9 10.7 5.3C11.1 5.7 11.1 6.3 10.7 6.7L6.39999 11H20C20.6 11 21 11.4 21 12Z"
            fill="#555F73"
          />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="5"
            width="18"
            height="14"
          >
            <path
              d="M21 12C21 12.6 20.6 13 20 13H6.39999L10.7 17.3C11.1 17.7 11.1 18.3 10.7 18.7C10.5 18.9 10.3 19 9.99999 19C9.69999 19 9.49999 18.9 9.29999 18.7L3.29999 12.7C3.19999 12.6 3.09999 12.5 3.09999 12.4C2.99999 12.2 2.99999 11.9 3.09999 11.6C3.19999 11.5 3.19999 11.4 3.29999 11.3L9.29999 5.3C9.69999 4.9 10.3 4.9 10.7 5.3C11.1 5.7 11.1 6.3 10.7 6.7L6.39999 11H20C20.6 11 21 11.4 21 12Z"
              fill="white"
            />
          </mask>
        </svg>{" "}
        Back
      </S.Flex>
    </BackButtonStyles>
  );
}

const TopPageNav = () => {
  return (
    <>
      <S.Flex mb={3} justifyContent="space-between">
        <BackButton />
        <UserMenu />
      </S.Flex>
      <S.Box mt={0} pt={0} mx={3}>
        <Searchbar />
      </S.Box>
    </>
  );
};

export default SubNavWrapper;
export { NavInfo, BackButton, LocalNavA, LocalNav, TopPageNav };
