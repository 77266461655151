import { gql } from "graphql.macro";

export const noteListQuery = gql`
  query noteListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $type: NoteType
    $subjectId: String
  ) {
    notes(
      first: $first
      last: $last
      before: $before
      after: $after
      type: $type
      subjectId: $subjectId
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          body
          type
          company {
            id
          }
          location {
            id
          }
          contact {
            id
          }
          author {
            id
            contact {
              id
              firstName
              lastName
            }
          }
          subjectId
          insertedAt
          updatedAt
        }
      }
    }
  }
`;

export const noteQuery = gql`
  query noteQuery($noteId: ID!) {
    note(noteId: $noteId) {
      id
      body
      type
      company {
        id
      }
      location {
        id
      }
      contact {
        id
      }
      author {
        id
        name
      }
      subjectId
      insertedAt
      updatedAt
    }
  }
`;
