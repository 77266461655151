import React from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Formik, Field, Form, FieldArray } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFunction } from "@apollo/react-common";
import { useEditToggle } from "./hooks";
import { Company, Contact, Location } from "./apiTypes";
import {
  StyledPanelFormWrapper,
  RepeatableFieldButton,
  RadioButton,
  Select,
  EditableSelect,
  SelectOptions,
} from "./StyledForm";
import { associationMutations, primaryAssociation } from "./util";
import {
  createPhoneNumberMutation,
  updatePhoneNumberMutation,
  deletePhoneNumberMutation,
} from "./mutations/phoneNumber";
import { useMutation } from "@apollo/react-hooks";

export default function PhoneNumbers({
  subject,
  selectOptions,
}: {
  subject: Company | Contact | Location;
  selectOptions: SelectOptions[];
}) {
  const { isOpen, open, close } = useEditToggle();
  const preferredIdx: number = (subject.phoneNumbers || []).findIndex(
    (p) => p.preferred
  );
  const preferredIdxValue: string | null =
    preferredIdx >= 0 ? preferredIdx.toString() : null;

  const [createPhoneNumber, { loading: createLoading }] = useMutation(
    createPhoneNumberMutation,
    {
      refetchQueries: ["companyQuery", "ContactQuery"],
    }
  );
  const [updatePhoneNumber, { loading: updateLoading }] = useMutation(
    updatePhoneNumberMutation
  );
  const [deletePhoneNumber, { loading: deleteLoading }] = useMutation(
    deletePhoneNumberMutation,
    {
      refetchQueries: ["companyQuery", "ContactQuery"],
    }
  );

  const loading = createLoading || updateLoading || deleteLoading;

  return (
    <S.Panel pb={2}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          <S.Flex alignItems="center" justifyContent="flex-start">
            <SVG.PhoneSvg />
            Phone Numbers
          </S.Flex>
          {!isOpen ? (
            <S.Button size="small" onClick={open}>
              Edit
            </S.Button>
          ) : (
            <S.Button size="small" onClick={close}>
              Cancel
            </S.Button>
          )}
        </S.Flex>
      </S.UnderlinedHeader>
      {!isOpen ? (
        <S.Box>
          <S.ContactList>
            {subject.phoneNumbers && subject.phoneNumbers.length > 0
              ? subject.phoneNumbers.map((phoneNumber) => (
                  <li key={phoneNumber.id}>
                    {phoneNumber.preferred ? "*" : ""}
                    {phoneNumber.label}:{" "}
                    <b>
                      <S.A href={`tel:${phoneNumber.phoneNumber}`}>
                        {phoneNumber.phoneNumber}
                      </S.A>
                    </b>
                  </li>
                ))
              : "No Phone Numbers on Record"}
          </S.ContactList>
        </S.Box>
      ) : (
        <Formik
          initialValues={{
            phoneNumbers: subject.phoneNumbers || [],
            preferredIdx: preferredIdxValue,
          }}
          onSubmit={async (values) => {
            let input: any = { ...values };
            if (values.preferredIdx) {
              input.phoneNumbers[
                parseInt(values.preferredIdx)
              ].preferred = true;
            }
            await associationMutations(
              input.phoneNumbers,
              subject.phoneNumbers ? subject.phoneNumbers : [],
              {
                create: (a) =>
                  createPhoneNumber({
                    variables: {
                      input: {
                        label: a.label,
                        phoneNumber: a.phoneNumber,
                        subjectId: subject.id,
                        preferred: a.preferred,
                      },
                    },
                  }),
                update: ({ id, ...a }) =>
                  updatePhoneNumber({
                    variables: {
                      input: {
                        label: a.label,
                        phoneNumber: a.phoneNumber,
                        phoneNumberId: id,
                        preferred: a.preferred,
                      },
                    },
                  }),
                destroy: ({ id }) =>
                  deletePhoneNumber({
                    variables: { input: { phoneNumberId: id } },
                  }),
              }
            );
            close();
          }}
          render={({ values }) => (
            <Form>
              <StyledPanelFormWrapper>
                <FieldArray
                  name="phoneNumbers"
                  render={(arrayHelpers) => (
                    <div>
                      {values.phoneNumbers &&
                      values.phoneNumbers.filter((a) => a.label !== "delete")
                        .length > 0 ? (
                        values.phoneNumbers.map((phoneNumber, index) => (
                          <S.Flex mb={1} alignItems="center" key={index}>
                            <div
                              style={{
                                flexBasis: "5%",
                                marginRight: 5,
                              }}
                            >
                              <Field
                                name="preferredIdx"
                                id={`phoneNumbers.${index}.preferred`}
                                index={index}
                                component={RadioButton}
                                label="Preferred Phone Number"
                              />
                            </div>
                            <div
                              style={{
                                flexBasis: "30%",
                                marginRight: 5,
                              }}
                            >
                              <Field
                                required
                                id={`phoneNumbers.${index}.label`}
                                name={`phoneNumbers.${index}.label`}
                                component={EditableSelect}
                                className="label-container"
                                classNamePrefix="label"
                                closeMenuOnSelect={true}
                                options={selectOptions}
                              />
                            </div>
                            <div style={{ flexBasis: "60%" }}>
                              <Field
                                required
                                id={`phoneNumbers.${index}.phoneNumber`}
                                name={`phoneNumbers.${index}.phoneNumber`}
                                placeholder="###-###-#### ext: ###"
                                type="text"
                                className="tagged-field"
                              />
                            </div>
                            <RepeatableFieldButton
                              type="button"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <SVG.MinusSvg />
                            </RepeatableFieldButton>
                            <RepeatableFieldButton
                              type="button"
                              onClick={() => {
                                arrayHelpers.insert(index + 1, {
                                  label: "",
                                });
                              }}
                            >
                              <SVG.PlusSvg />
                            </RepeatableFieldButton>
                          </S.Flex>
                        ))
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            arrayHelpers.push({ label: "" });
                          }}
                        >
                          Add a phone number.
                        </button>
                      )}
                    </div>
                  )}
                />

                <button type="submit" disabled={loading}>
                  Sav{loading ? "ing" : "e"}
                </button>
              </StyledPanelFormWrapper>
            </Form>
          )}
        />
      )}
    </S.Panel>
  );
}

export function PhoneNumberList({
  subject,
  label,
  labelled,
  primary,
}: {
  subject: Company | Contact | Location;
  label?: string;
  labelled?: boolean;
  primary?: boolean;
}) {
  if (!subject.phoneNumbers)
    return <S.ContactList>No Phone Numbers on Record</S.ContactList>;
  if (subject.phoneNumbers.length < 1)
    return <S.ContactList>No Phone Numbers on Record</S.ContactList>;

  let phoneNums;

  const primaries = subject.phoneNumbers.filter((email) => email.preferred);
  const nums = label
    ? subject.phoneNumbers.filter((email) => email.label === label)
    : subject.phoneNumbers;

  if (label) {
    if (!nums) phoneNums = primaries;
    if (nums.length < 1) phoneNums = primaries;
  } else if (primary) phoneNums = primaries;
  else phoneNums = nums;

  if (!phoneNums)
    return <S.ContactList>No Phone Numbers on Record</S.ContactList>;
  if (phoneNums.length < 1)
    return <S.ContactList>No Phone Numbers on Record</S.ContactList>;

  return (
    <S.ContactList>
      {phoneNums.map((phoneNumber) => (
        <li key={phoneNumber.id}>
          {phoneNumber.preferred ? "*" : ""}
          {phoneNumber.label}:{" "}
          <b>
            <S.A href={`tel:${phoneNumber.phoneNumber}`}>
              {phoneNumber.phoneNumber}
            </S.A>
          </b>
        </li>
      ))}
    </S.ContactList>
  );
}

export function PrimaryPhoneNumber({
  subject,
}: {
  subject: Company | Contact | Location;
}) {
  const primary = primaryAssociation(subject.phoneNumbers);
  return (
    <>
      {primary ? (
        <S.A href={`tel:${primary.phoneNumber}`}>
          <S.Flex my={1} py={1} alignItems="center">
            <SVG.PhoneSvg />
            {primary.phoneNumber}
          </S.Flex>
        </S.A>
      ) : (
        <></>
      )}
    </>
  );
}
