import React from "react";
import styled from "styled-components";
import ReactSelect, {
  ValueType,
  ActionMeta,
  Props as ReactSelectProps,
  OptionsType,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import Datetime from "react-datetime";
import { FieldProps } from "formik";
import moment, { Moment } from "moment";
import NumberFormat from "react-number-format";

const RepeatableFieldButton = styled.button`
  height: 38px;
  width: 38px !important;
  padding: 5px !important;
  margin: 0 5px !important;
  border: none !important;
  box-shadow: none !important;
  svg path {
    fill: ${(p) => p.theme.colors.grey};
  }
  &:hover {
    svg path {
      fill: ${(p) => p.theme.colors.blue};
    }
  }
`;

const StyledPanelFormWrapper = styled.div`
  button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    padding: 6px 12px;
    margin: 10px 0;
    height: 38px;
    background: #fff;
    border: 1px solid rgba(118, 132, 158, 0.2); /* Control */
    box-shadow: 0px 1px 2px rgba(118, 132, 158, 0.2),
      inset 0px 0px 1px rgba(118, 132, 158, 0.06);
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.75rem;
    text-decoration: none;
    text-align: center;
    color: ${(p) => p.theme.colors.grey};
    width: 100%;
  }
  button[type="submit"] + button,
  input[type="submit"] + button {
    margin-left: 10px;
  }
  input[type="checkbox"] {
    width: 20px;
    margin-right: 10px;
  }
  button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  button:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="button"]:focus {
    color: ${(p) => p.theme.colors.blue};
    border-color: ${(p) => p.theme.colors.blue};
    outline: 0;
  }

  input,
  textarea,
  select {
    height: 38px;
    width: 100%;
    padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
    background-color: #fff;
    border: 1px solid ${(p) => p.theme.colors.lightgrey};
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    color: ${(p) => p.theme.colors.grey};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    margin-right: 10px;
  }
  textarea {
    height: 76px;
    max-width: 100%;
    min-width: 100%;
  }

  /* Removes awkward default styles on some inputs for iOS */
  textarea {
    min-height: 65px;
    padding-top: 6px;
    padding-bottom: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input:focus,
  textarea:focus,
  select:focus {
    border: 1px solid ${(p) => p.theme.tagColors.lightorange};
    outline: 0;
  }
  label {
    display: block;
    margin: 1rem 0 0.5rem 0;
    font-weight: 600;
    color: ${(p) => p.theme.colors.grey};
    span {
      display: block;
      font-weight: 400;
      font-size: 80%;
    }
  }
  fieldset {
    padding: 0;
    border-width: 0;
  }
  input[type="checkbox"],
  input[type="radio"] {
    display: inline;
  }
  label > .label-body {
    display: inline-block;
    margin-left: 0.5rem;
    font-weight: normal;
  }
`;

const StyledFormWrapper = styled.div`
  button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    width: 200px;
    margin-top: 20px;
    height: 38px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  button:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="button"]:focus {
    color: ${(p) => p.theme.colors.blue};
    border-color: ${(p) => p.theme.colors.blue};
    outline: 0;
  }

  input,
  textarea,
  select {
    height: 38px;
    width: 200px;
    padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
    background-color: #fff;
    border: 1px solid ${(p) => p.theme.colors.lightgrey};
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    color: ${(p) => p.theme.colors.grey};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  /* Removes awkward default styles on some inputs for iOS */
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input[type="checkbox"] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
  }
  textarea {
    min-height: 65px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  input:focus,
  textarea:focus,
  select:focus {
    border: 1px solid ${(p) => p.theme.tagColors.lightorange};
    outline: 0;
  }
  label {
    display: block;
    margin: 1rem 0 0.5rem 0;
    font-weight: 600;
    color: #fff;
  }
  fieldset {
    padding: 0;
    border-width: 0;
  }
  input[type="checkbox"],
  input[type="radio"] {
    display: inline;
  }
  label > .label-body {
    display: inline-block;
    margin-left: 0.5rem;
    font-weight: normal;
  }
`;

export function RadioButton({
  id,
  index,
  field: { name, value, ...otherField },
  form,
  style = {},
  ...otherProps
}: any) {
  return (
    <input
      name={name}
      id={id}
      type="radio"
      value={`${index}`}
      checked={`${index}` === value}
      style={{ alignSelf: "center", height: 18, flex: "0", ...style }}
      {...otherField}
      {...otherProps}
    />
  );
}

export type SelectOptions = {
  label: string;
  value: string;
};

type SelectProps<OptionType> = Omit<
  ReactSelectProps<OptionType>,
  "name" | "value" | "onChange"
> &
  FieldProps<string> & {
    options: OptionType[];
    onChange: (e: {
      target: { name: string; value: string | string[] | null };
    }) => void;
  };

const StyledReactSelect = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.grey};

  .css-b8ldur-Input {
    input {
      height: 20px;
      color: ${(p) => p.theme.colors.grey};
    }
  }
  .css-1uccc91-singleValue {
    transform: none;
    top: auto;
  }
  .css-yk16xz-control,
  .css-yk16xz-control:hover {
    border-color: ${(p) => p.theme.colors.grey};
  }
  .css-1hwfws3 {
    height: 38px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    .css-1okebmr-indicatorSeparator {
      background-color: ${(p) => p.theme.colors.grey};
    }
    .css-6q0nyr-Svg path {
      fill: ${(p) => p.theme.colors.grey};
    }
  }
  .label-container {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    .label__indicator {
      height: 38px;
    }
    .label__indicators {
      height: 38px;
    }
    .label__control {
      height: 38px;
      width: 100%;
      background-color: #fff;
      border: 1px solid ${(p) => p.theme.colors.lightgrey};
      color: ${(p) => p.theme.colors.grey};
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-weight: 500;
      font-size: 0.85rem;

      &.label__control--is-focused {
        svg {
          fill: ${(p) => p.theme.colors.grey};
        }
      }
      .label__value-container {
        height: 38px;
        padding: 0 8px;

        > div:nth-child(2) {
          margin: 0;
          padding: 0;
          height: 38px;
        }
        .label__placeholder {
          color: ${(p) => p.theme.colors.lightgrey};
          transform: translateY(-50%);
          top: 50%;
          left: 8px;
        }
        .label__single-value {
          color: ${(p) => p.theme.colors.grey};
          > div {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
`;

export function Select<
  OptionType extends { label: string; value: string } = {
    label: string;
    value: string;
  }
>({
  field: { name, value, onChange, ...fieldProps },
  options,
  ...props
}: SelectProps<OptionType>) {
  function handleChange(
    value: ValueType<OptionType>,
    action: ActionMeta
  ): void {
    let newValue: string | string[] | null = null;

    if (Array.isArray(value)) {
      newValue = (value as OptionsType<OptionType>).map((v) => v.value);
    } else if (value) {
      newValue = (value as OptionType).value;
    }

    if (onChange) {
      onChange({
        target: {
          name: name,
          value: newValue,
        },
      });
    }
  }

  const selectValue = (options || []).filter(({ value: optValue }) => {
    if (Array.isArray(value)) {
      return value.indexOf(optValue) > -1;
    } else {
      return value === optValue;
    }
  });

  return (
    <StyledReactSelect>
      <ReactSelect
        {...props}
        {...fieldProps}
        name={name}
        options={options}
        value={selectValue}
        onChange={handleChange}
      />
    </StyledReactSelect>
  );
}

export function EditableSelect<
  OptionType extends { label: string; value: string } = {
    label: string;
    value: string;
  }
>({
  field: { name, value, onChange, ...fieldProps },
  options,
  ...props
}: SelectProps<OptionType>) {
  function handleChange(
    value: ValueType<OptionType>,
    action: ActionMeta
  ): void {
    let newValue: string | string[] | null = null;

    if (Array.isArray(value)) {
      newValue = (value as OptionsType<OptionType>).map((v) => v.value);
    } else if (value) {
      newValue = (value as OptionType).value;
    }

    if (onChange) {
      onChange({
        target: {
          name: name,
          value: newValue,
        },
      });
    }
  }

  const selectValue = options.filter(
    ({ value: optValue }) => value === optValue
  );

  return (
    <StyledReactSelect>
      <CreatableSelect
        {...props}
        {...fieldProps}
        name={name}
        options={options}
        value={selectValue}
        onChange={handleChange}
      />
    </StyledReactSelect>
  );
}

const StyledFormikDatetime = styled.div`
  .rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }

  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }

  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: "";
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }

  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }

  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }

  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }

  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }

  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }

  .rdtCounters {
    display: inline-block;
  }

  .rdtCounters > div {
    float: left;
  }

  .rdtCounter {
    height: 100px;
  }

  .rdtCounter {
    width: 40px;
  }

  .rdtCounterSeparator {
    line-height: 100px;
  }

  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }

  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }

  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }

  .rdtTime td {
    cursor: default;
  }
`;

export function FormikDatetime({
  field: { name, value, onChange, onBlur },
}: FieldProps) {
  function handleChange(value: string | Moment) {
    onChange({ target: { name, value } });
  }

  function handleBlur(value: string | Moment | React.FocusEvent<any>) {
    if (moment.isMoment(value) && value.isValid()) {
      onBlur({ target: { name, value } });
    }
  }
  // function renderDay(props, currentDate, selectedDate) {
  //   return <td {...props}>{"0" + currentDate.date()}</td>;
  // }

  // function renderMonth(props, month, year, selectedDate) {
  //   return <td {...props}>{month}</td>;
  // }

  // function renderYear(props, year, selectedDate) {
  //   return <td {...props}>{year % 100}</td>;
  // }

  return (
    <StyledFormikDatetime>
      <Datetime
        value={value}
        input={false}
        dateFormat="MM/DD/YYYY"
        timeFormat="h:mma"
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{ name }}
        // renderDay={renderDay}
        // renderMonth={renderMonth}
        // renderYear={renderYear}
      />
    </StyledFormikDatetime>
  );
}

export function FormikDate({
  field: { name, value, onChange, onBlur },
}: FieldProps) {
  function handleChange(value: string | Moment) {
    onChange({ target: { name, value } });
  }

  function handleBlur(value: string | Moment | React.FocusEvent<any>) {
    if (moment.isMoment(value) && value.isValid()) {
      onBlur({ target: { name, value } });
    }
  }

  return (
    <StyledFormikDatetime>
      <Datetime
        value={value}
        input={false}
        timeFormat={false}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{ name }}
      />
    </StyledFormikDatetime>
  );
}

export function MoneyField({
  field: { name, value, onChange, onBlur },
}: FieldProps) {
  return (
    <NumberFormat
      name={name}
      id={name}
      value={value}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
}

export default StyledFormWrapper;
export { StyledPanelFormWrapper, RepeatableFieldButton, StyledReactSelect };
