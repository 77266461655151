import { gql } from "graphql.macro";

export const contactQuery = gql`
  query ContactQuery($contactId: ID!) {
    contact(contactId: $contactId) {
      id
      firstName
      middleName
      lastName
      nickname
      suffix
      prefix
      deactivatedAt
      photoUrl
      updatedAt
      birthday
      golfHandedness
      allergies
      stripeCustomerId
      groups {
        id
        name
        restricted
      }
      insertedAt
      addresses {
        id
        label
        city
        country
        postalCode
        state
        streetAddress
        suiteNumber
        preferred
      }
      phoneNumbers {
        id
        label
        phoneNumber
        preferred
      }
      emailAddresses {
        id
        label
        emailAddress
        preferred
      }
      urls {
        id
        label
        url
        preferred
      }
      user {
        id
      }
      companyMemberships {
        id
        authorizedRepresentative
        primaryContact
        secondaryContact
        billingContact
        company {
          id
          name
          deactivatedAt
        }
        contact {
          id
        }
        endedOn
        startedOn
        title
        insertedAt
        updatedAt
      }
    }
  }
`;

export const contactsListQuery = gql`
  query ContactsListQuery(
    $first: Int!
    $last: Int
    $before: String
    $after: String
    $filter: ContactsFilterInput
    $orderBy: ContactsOrderByInput
  ) {
    contacts(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
      orderBy: $orderBy
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          firstName
          lastName
          insertedAt
          deactivatedAt
          photoUrl
          groups {
            id
            name
            restricted
          }
          companyMemberships {
            authorizedRepresentative
            primaryContact
            secondaryContact
            company {
              id
              name
            }
            endedOn
            startedOn
            title
          }
        }
      }
    }
  }
`;

export const contactSearchQuery = gql`
  query contactSearchQuery(
    $first: Int!
    $last: Int
    $before: String
    $after: String
    $orderBy: ContactsOrderByInput
  ) {
    contacts(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          firstName
          lastName
          deactivatedAt
        }
      }
    }
  }
`;
