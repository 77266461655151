import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Container from "../FullPageContainer";

const MessageWrapper = styled.div`
  background: #fff;
  padding: 20px 32px;
  border-radius: 4px;
  margin: 20px 0;
  border: 1px solid rgba(118, 132, 158, 0.2);
  box-shadow: 0px 1px 2px rgba(118, 132, 158, 0.1),
    inset 0px 0px 1px rgba(118, 132, 158, 0.03);
`;

const A = styled(Link)`
  cursor: pointer;
  color: ${p => p.theme.colors.blue};
`;

export default function UnauthedMessage({
  location
}: Partial<RouteComponentProps<{}>>): React.ReactElement {
  return (
    <Container>
      <MessageWrapper>
        Please{" "}
        <A
          to={{
            pathname: "/login",
            state: { referrer: (location && location.pathname) || undefined }
          }}
        >
          log in
        </A>{" "}
        to continue.
      </MessageWrapper>
    </Container>
  );
}
