import React, { useState } from "react";
import Urls from "./Urls";
import PhoneNumbers from "./PhoneNumbers";
import Emails from "./Emails";
import Groups from "./Groups";
import FileUpload from "./FileUpload";
import EditableContent from "./EditableContent";
import Avatar from "./UserAvatar";
import Registrations from "./Registrations";
import ContactAnalytics, { ContactAnalyticsSummary } from "./ContactAnalytics";
import ContactCompanyMemberships from "./ContactCompanyMemberships";
import { Link } from "react-router-dom";
import moment from "moment";
import * as S from "./styles";
import * as SVG from "./svgs";
import Notes, { ActivityFeed } from "./Notes";
import Table, { TR, TD } from "./Table";
import TopNav from "./TopNav";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import ContactNav from "./ContactNav";
import { Query, Mutation } from "@apollo/react-components";
import { Address } from "./Addresses";
import { Tag } from "./Tags";
import { RouteComponentProps } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import { joinPath } from "./util";
import ContactSummary from "./ContactSummary";
import {
  StyledPanelFormWrapper,
  RepeatableFieldButton,
  Select,
} from "./StyledForm";
import { Formik, Field, Form, FieldArray } from "formik";
import { Contact as ContactType } from "./apiTypes";
import { updateContactMutation } from "./mutations/contact";
import { contactQuery } from "./queries/contact";

type ContactProps = RouteComponentProps<{ contactId: string }>;

const Contact: React.FunctionComponent<ContactProps> = ({ match }) => {
  return (
    <Query<{ contact: ContactType }, { contactId: string }>
      query={contactQuery}
      variables={{ contactId: match.params.contactId }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (error) {
          return (
            <Page>
              <TopNav />
              There is no Contact associated with this id/url.
            </Page>
          );
        }
        if (!data || !data.contact)
          return (
            <Page>
              <TopNav />
              There is no Contact associated with this id/url.
            </Page>
          );
        const contact = data.contact;
        if (!contact)
          return (
            <Page>
              <TopNav />
              There is no Contact associated with this id/url.
            </Page>
          );
        return (
          <div>
            <ContactNav contact={contact} match={match} />
            <Switch>
              <Redirect
                exact
                from={match.path}
                to={joinPath(match.url, "/summary")}
              />
              <Route
                path={`${match.path}/summary`}
                render={(routeProps) => (
                  <ContactSummary {...routeProps} contact={contact} />
                )}
              />
              <Route
                path={`${match.path}/registrations`}
                render={(routeProps) => (
                  <Registrations {...routeProps} subject={contact} />
                )}
              />
              <Route
                path={`${match.path}/analytics`}
                render={(routeProps) => (
                  <ContactAnalytics {...routeProps} contact={contact} />
                )}
              />
            </Switch>
          </div>
        );
      }}
    </Query>
  );
};

export default Contact;
