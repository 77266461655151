import { gql } from "graphql.macro";

export const createEventMutation = gql`
  mutation createEventMutation($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      title
      description
      startsAt
      endsAt
      registrationOpensAt
      registrationClosesAt
    }
  }
`;

export const updateEventMutation = gql`
  mutation updateEventMutation($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      title
      description
      startsAt
      endsAt
      registrationOpensAt
      registrationClosesAt
      id
    }
  }
`;

export const archiveEventMutation = gql`
  mutation archiveEventMutation($input: ArchiveEventInput!) {
    archiveEvent(input: $input) {
      eventId
    }
  }
`;

export const cloneEventMutation = gql`
  mutation cloneEventMutation($input: UpdateEventInput!) {
    cloneEvent(input: $input) {
      title
      startDate
      endDate
      description
      cancelled
      id
    }
  }
`;
