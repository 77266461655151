import React, { useState, useMemo } from "react";
import styled from "styled-components";
import * as S from "./styles";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import {
  Registration,
  RegistrationsListQueryData
} from "./apiTypes";
import { registrationListQuery } from "./queries/registration";
import { useQuery } from "@apollo/react-hooks";

const styles = {
  graphContainer: {
    margin: "20px 30px 20px 0"
  }
};

const GraphNav = styled.div`
  button {
    border: none;
    font-size: 1.05rem;
    font-weight: 300;
    color: ${p => p.theme.colors.grey};
    padding: 5px 16px;
    &.active {
      color: ${p => p.theme.colors.blue};
    }
  }
`;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

function CustomizedAxisTick({ x, y, stroke, payload }: any) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={10}
        y={10}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-25)"
      >
        {payload.value}
      </text>
    </g>
  );
}

type GraphData = {
  name: string;
  registrations: number;
};

const Graph: React.FunctionComponent = () => {
  const today = new Date();
  const rangeOptions = [
    {
      from: new Date(new Date().setDate(today.getDate() - 6)),
      until: today
    }, //daily
    {
      from: new Date(new Date().setMonth(today.getMonth() - 1)),
      until: today
    }, // weekly
    {
      from: new Date(new Date().setFullYear(today.getFullYear() - 1)),
      until: today
    }, // monthly
    { from: new Date("01-01-2020"), until: today } // yearly starting day of DB launch
  ];
  const [range, setRange] = useState<{ from: Date; until: Date }>(
    rangeOptions[2]
  ); // starting with monthly view active
  const { data, loading, error } = useQuery<RegistrationsListQueryData>(
    registrationListQuery,
    {
      variables: { filter: { insertedAt: range }, first: 10000 }
    }
  );
  const registrations =
    data && data.registrations && Array.isArray(data.registrations.edges)
      ? data.registrations.edges.map(registration => {
          return registration.node;
        })
      : [];
  const [dataType, setDataType] = useState<string>("monthly");

  const chartData = useMemo(() => {
    let newData = [];

    let start = new Date(new Date().setDate(today.getDate() - 7));
    let end = new Date(new Date().setDate(today.getDate() - 6));
    if (dataType === "daily") {
      while (newData.length < 7) {
        const filteredRegistrations =
          registrations &&
          registrations.length &&
          registrations.filter(
            registration =>
              registration &&
              new Date(registration.insertedAt) >= start &&
              new Date(registration.insertedAt) <= end
          );
        newData.push({
          name: days[end.getDate() % 7 === 0 ? 6 : (end.getDate() % 7) - 1],
          registrations:
            (filteredRegistrations && filteredRegistrations.length) || 0
        });
        start = new Date(start.setDate(start.getDate() + 1));
        end = new Date(end.setDate(end.getDate() + 1));
      }
    } else if (dataType === "weekly") {
      let start = new Date(new Date().setDate(today.getDate() - 28));
      let end = new Date(new Date().setDate(today.getDate() - 21));
      while (newData.length < 4) {
        let range = `${moment(start).format("M/D")} - ${moment(end).format(
          "M/D"
        )}`;
        const filteredRegistrations =
          registrations &&
          registrations.filter(
            registration =>
              registration &&
              new Date(registration.insertedAt) >= start &&
              new Date(registration.insertedAt) <= end
          );
        newData.push({
          name: range,
          registrations:
            (filteredRegistrations && filteredRegistrations.length) || 0
        });
        start = new Date(start.setDate(start.getDate() + 7));
        end = new Date(end.setDate(end.getDate() + 7));
      }
    } else if (dataType === "yearly") {
      let start = new Date(new Date().setFullYear(2019));
      let end = new Date(new Date().setFullYear(2020));
      while (today.getFullYear() >= start.getFullYear()) {
        const filteredRegistrations =
          registrations &&
          registrations.filter(
            registration =>
              registration &&
              new Date(registration.insertedAt) >= start &&
              new Date(registration.insertedAt) <= end
          );
        newData.push({
          name: moment(end).format("YYYY"),
          registrations:
            (filteredRegistrations && filteredRegistrations.length) || 0
        });
        start = new Date(start.setFullYear(start.getFullYear() + 1));
        end = new Date(end.setFullYear(end.getFullYear() + 1));
      }
    } else {
      // monthly
      let start = new Date(
        new Date(new Date().setMonth(today.getMonth() - 12)).setDate(1)
      );
      let end = new Date(
        new Date(new Date().setMonth(today.getMonth() - 11)).setDate(1)
      );
      while (newData.length < 12) {
        let range = `${moment(start).format("MMM YYYY")}`;
        const filteredRegistrations =
          registrations &&
          registrations.filter(
            registration =>
              registration &&
              new Date(registration.insertedAt) <= start &&
              new Date(registration.insertedAt) <= end
          );
        newData.push({
          name: range,
          registrations:
            (filteredRegistrations && filteredRegistrations.length) || 0
        });
        start = new Date(start.setMonth(start.getMonth() + 1));
        end = new Date(end.setMonth(end.getMonth() + 1));
      }
    }

    return newData;
  }, [registrations]);

  const handleSelect = (type: string, registrations?: Registration[]) => {
    setDataType(type);
    if (type === "daily") {
      setRange(rangeOptions[0]);
    } else if (type === "weekly") {
      setRange(rangeOptions[1]);
    } else if (type === "yearly") {
      setRange(rangeOptions[3]);
    } else {
      setRange(rangeOptions[2]);
    }
  };

  if (loading && !data) return <div />;
  if (error) return <div>Oops.</div>;
  if (!data || !data.registrations || !Array.isArray(data.registrations.edges))
    return <div>No data.</div>;

  return (
    <div style={styles.graphContainer}>
      <GraphNav>
        <S.Flex justifyContent="space-between">
          <S.H2 className="header">Event Registration</S.H2>
          <div>
            <S.H4 className="inline">Timeframe</S.H4>
            <button
              value="daily"
              onClick={() => handleSelect("daily")}
              disabled={loading || dataType === "daily"}
              className={dataType === "daily" ? "active" : ""}
            >
              Daily
            </button>
            <button
              value="weekly"
              onClick={() => handleSelect("weekly")}
              disabled={loading || dataType === "weekly"}
              className={dataType === "weekly" ? "active" : ""}
            >
              Weekly
            </button>
            <button
              value="monthly"
              onClick={() => handleSelect("monthly")}
              disabled={loading || dataType === "monthly"}
              className={dataType === "monthly" ? "active" : ""}
            >
              Monthly
            </button>
            <button
              value="yearly"
              onClick={() => handleSelect("yearly")}
              disabled={loading || dataType === "yearly"}
              className={dataType === "yearly" ? "active" : ""}
            >
              Yearly
            </button>
          </div>
        </S.Flex>
      </GraphNav>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" height={80} tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dot={{ stroke: "#3496E3", strokeWidth: 2, r: 4 }}
            activeDot={{ stroke: "#3496E3", strokeWidth: 2, r: 6 }}
            dataKey="registrations"
            stroke="#3496E3"
            strokeWidth="2"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;
