import { gql } from "graphql.macro";

export const groupsListQuery = gql`
  query groupsListQuery {
    groups {
      id
      applicableTo
      color
      name
      restricted
    }
  }
`;

export const groupQuery = gql`
  query groupQuery($groupId: ID!) {
    group(groupId: $groupId) {
      id
      applicableTo
      color
      companies {
        id
      }
      contacts {
        id
      }
      name
      restricted
    }
  }
`;
