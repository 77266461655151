import React from "react";
import { Formik, Field, Form } from "formik";
import { Mutation } from "@apollo/react-components";
import * as S from "./styles";
import { useEditToggle } from "./hooks";
import { Company, Contact, Location } from "./apiTypes";
import { StyledPanelFormWrapper } from "./StyledForm";
import { primaryAssociation, labelledAssociation } from "./util";
import {
  createAddressMutation,
  updateAddressMutation
} from "./mutations/address";

export default function Addresses({
  subject
}: {
  subject: Company | Contact | Location;
}) {
  const { isOpen, open, close } = useEditToggle();
  const primary = primaryAssociation(subject.addresses);
  const mutationFunction = primary
    ? updateAddressMutation
    : createAddressMutation;

  return (
    <S.Panel width={1} pb={2}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          Headquarters
          {!isOpen ? (
            <S.Button size="small" onClick={open}>
              Edit
            </S.Button>
          ) : (
            <S.Button size="small" onClick={close}>
              Cancel
            </S.Button>
          )}
        </S.Flex>
      </S.UnderlinedHeader>
      {!isOpen ? (
        <>
          {primary ? (
            <S.Address>
              {primary.streetAddress}
              {primary.suiteNumber ? <span>{primary.suiteNumber}</span> : <></>}
              <span>
                {primary.city}, {primary.state} {primary.postalCode}
              </span>
              <span>{primary.country}</span>
            </S.Address>
          ) : (
            <S.Address>No Address on Record</S.Address>
          )}
        </>
      ) : (
        <Mutation<any>
          mutation={mutationFunction}
          refetchQueries={["LocationListQuery", "LocationQuery"]}
        >
          {(mutate, { loading }) => {
            return (
              <Formik
                initialValues={{
                  city: primary && primary.city ? primary.city : "",
                  label: primary && primary.label ? primary.label : "default",
                  country: primary && primary.country ? primary.country : "",
                  postalCode:
                    primary && primary.postalCode ? primary.postalCode : "",
                  state: primary && primary.state ? primary.state : "",
                  streetAddress:
                    primary && primary.streetAddress
                      ? primary.streetAddress
                      : "",
                  suiteNumber:
                    primary && primary.suiteNumber ? primary.suiteNumber : ""
                }}
                onSubmit={values => {
                  let input: any = { ...values };
                  if (
                    (input.country === "US" || input.country === "USA" || input.country === "United States" || input.country === "United States of America")
                    && !/^[0-9]{5}-[0-9]{4}$/.test(input.postalCode)) {
                    alert('Please enter a 9 digit postal code using the pattern #####-####');
                  } else {
                    if (primary && primary.id) {
                      input.addressId = primary.id;
                    } else {
                      input.subjectId = subject.id;
                    }
                    mutate({
                      variables: { input }
                    });
                    close();
                  }
                }}
                render={({ values, errors }) => (
                  <Form>
                    <StyledPanelFormWrapper>
                      <AddressForm />
                      <button type="submit" disabled={loading}>
                        Sav{loading ? "ing" : "e"}
                      </button>
                    </StyledPanelFormWrapper>
                  </Form>
                )}
              />
            );
          }}
        </Mutation>
      )}
    </S.Panel>
  );
}

export function PrimaryAddress({
  subject
}: {
  subject: Company | Contact | Location;
}) {
  const primary = primaryAssociation(subject.addresses);
  return (
    <>
      {primary ? (
        <S.Address>
          {primary.streetAddress}
          {primary.suiteNumber ? <span>{primary.suiteNumber}</span> : <></>}
          {/* {primary.streetAddressThree ? <span>{primary.streetAddressThree}</span> : <></>} */}
          <span>
            {primary.city}, {primary.state} {primary.postalCode}
          </span>
          <span>{primary.country}</span>
        </S.Address>
      ) : (
        <S.Address>No Address On Record</S.Address>
      )}
    </>
  );
}

export function AddressDisplay({
  subject,
  label,
}: {
  subject: Company | Contact | Location;
  label?: string;
}) {
  const labelled = label ? labelledAssociation(label, subject.addresses) : {};
  return (
    <>
      {labelled ? (
        <S.Address>
          {labelled.streetAddress}
          {labelled.suiteNumber ? <span>{labelled.suiteNumber}</span> : <></>}
          <span>
            {labelled.city}, {labelled.state} {labelled.postalCode}
          </span>
          <span>{labelled.country}</span>
        </S.Address>
      ) : (
        <S.Address>No Address On Record</S.Address>
      )}
    </>
  );
}

export function Address({
  subject,
  label,
  title
}: {
  subject: Company | Contact | Location;
  label?: string;
  title: string;
}) {
  const { isOpen, open, close } = useEditToggle();
  const primary = primaryAssociation(subject.addresses);
  const labelled = label ? labelledAssociation(label, subject.addresses) : {};
  let mutationFunction =
    label && labelled
      ? updateAddressMutation
      : label
      ? createAddressMutation
      : primary
      ? updateAddressMutation
      : createAddressMutation;

  return (
    <div>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          {title}
          {!isOpen ? (
            <S.Button size="small" onClick={open}>
              Edit
            </S.Button>
          ) : (
            <S.Button size="small" onClick={close}>
              Cancel
            </S.Button>
          )}
        </S.Flex>
      </S.UnderlinedHeader>
      {!isOpen ? (
        <>
          {label && labelled ? (
            <S.Address>
              {labelled.streetAddress}
              {labelled.suiteNumber ? (
                <span>{labelled.suiteNumber}</span>
              ) : (
                <></>
              )}
              {/* {labelled.streetAddressThree ? <span>{labelled.streetAddressThree}</span> : <></>} */}
              <span>
                {labelled.city}, {labelled.state} {labelled.postalCode}
              </span>
              <span>{labelled.country}</span>
            </S.Address>
          ) : !label && primary ? (
            <S.Address>
              {primary.streetAddress}
              {primary.suiteNumber ? <span>{primary.suiteNumber}</span> : <></>}
              {/* {primary.streetAddressThree ? <span>{primary.streetAddressThree}</span> : <></>} */}
              <span>
                {primary.city}, {primary.state} {primary.postalCode}
              </span>
              <span>{primary.country}</span>
            </S.Address>
          ) : (
            <S.Address>No Address On Record</S.Address>
          )}
        </>
      ) : (
        <Mutation<any>
          mutation={mutationFunction}
          refetchQueries={[
            "companyQuery",
            "ContactQuery",
            "LocationListQuery",
            "LocationQuery"
          ]}
        >
          {(mutate, { loading }) => {
            return (
              <Formik
                initialValues={{
                  preferred: !label ? true : false,
                  city:
                    label && labelled
                      ? labelled.city
                      : !label && primary
                      ? primary.city
                      : "",
                  label: label ? label : "main",
                  country:
                    label && labelled
                      ? labelled.country
                      : !label && primary
                      ? primary.country
                      : "",
                  postalCode:
                    label && labelled
                      ? labelled.postalCode
                      : !label && primary
                      ? primary.postalCode
                      : "",
                  state:
                    label && labelled
                      ? labelled.state
                      : !label && primary
                      ? primary.state
                      : "",
                  streetAddress:
                    label && labelled
                      ? labelled.streetAddress
                      : !label && primary
                      ? primary.streetAddress
                      : "",
                  suiteNumber:
                    label && labelled
                      ? labelled.suiteNumber
                      : !label && primary
                      ? primary.suiteNumber
                      : ""
                }}
                onSubmit={values => {
                  let input: any = { ...values };
                  if (
                    (input.country === "US" || input.country === "USA" || input.country === "United States" || input.country === "United States of America")
                    && !/^[0-9]{5}-[0-9]{4}$/.test(input.postalCode)) {
                    alert('Please enter a 9 digit postal code using the pattern #####-####');
                  } else {
                    if (label && labelled && labelled.id) {
                      input.addressId = labelled.id;
                    } else if (!label && primary && primary.id) {
                      input.addressId = primary.id;
                    } else {
                      input.subjectId = subject.id;
                    }
                    mutate({
                      variables: { input }
                    });
                    close();
                  }
                }}
                render={({ values }) => (
                  <Form>
                    <StyledPanelFormWrapper>
                      <AddressForm />
                      <button type="submit" disabled={loading}>
                        Sav{loading ? "ing" : "e"}
                      </button>
                    </StyledPanelFormWrapper>
                  </Form>
                )}
              />
            );
          }}
        </Mutation>
      )}
    </div>
  );
}

export const AddressForm = () => {
  return (
    <>
      <label htmlFor="streetAddress">
        Street Address
        <span style={{ color: "red", display: "inline-block" }}>*</span>
      </label>
      <Field
        required
        id="streetAddress"
        name="streetAddress"
        placeholder="Street Address"
        type="text"
      />
      <label htmlFor="suiteNumber">Street Address 2</label>
      <Field id="suiteNumber" name="suiteNumber" placeholder="" type="text" />
      <S.Flex>
        <S.HalfWidth>
          <label htmlFor="city">
            City<span style={{ color: "red", display: "inline-block" }}>*</span>
          </label>
          <Field
            required
            id="city"
            name="city"
            placeholder="City"
            type="text"
          />
        </S.HalfWidth>
        <S.HalfWidth>
          <label htmlFor="state">
            State
            <span style={{ color: "red", display: "inline-block" }}>*</span>
          </label>
          <Field
            required
            id="state"
            name="state"
            placeholder="State"
            type="text"
          />
        </S.HalfWidth>
      </S.Flex>
      <S.Flex>
        <S.HalfWidth>
          <label htmlFor="postalCode">
            Postal Code
            <span style={{ color: "red", display: "inline-block" }}>*</span>
          </label>
          <Field
            required
            id="postalCode"
            name="postalCode"
            placeholder="#####-####"
            type="text"
          />
          <span style={{fontSize: 10, lineHeight: 0.9, fontWeight: "bold"}}>Please consult the <a style={{color: "#3496E3"}} target="_blank" rel="noopener noreferrer" href="https://m.usps.com/m/ZipLookupAction">USPS website</a> to identify the correct 9 digit postal code for this location.</span>
        </S.HalfWidth>
        <S.HalfWidth>
          <label htmlFor="country">
            Country
            <span style={{ color: "red", display: "inline-block" }}>*</span>
          </label>
          <Field
            required
            id="country"
            name="country"
            placeholder="Country"
            type="text"
          />
        </S.HalfWidth>
      </S.Flex>
    </>
  );
};
