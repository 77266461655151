import { gql } from "graphql.macro";

export const buyersGuideCategoriesListQuery = gql`
  query buyersGuideCategoriesListQuery {
    buyersGuideCategories {
      id
      name
      parentId
      insertedAt
      updatedAt
    }
  }
`;

export const buyersGuideCategoryQuery = gql`
  query buyersGuideCategoryQuery($categoryId: ID!) {
    buyersGuideCategory(categoryId: $categoryId) {
      id
      name
      parentId
      insertedAt
      updatedAt
    }
  }
`;
