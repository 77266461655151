import React, { useState } from "react";
import * as S from "./styles";
import FileUpload from "./FileUpload";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { useMutation } from "@apollo/react-hooks";
import { __InputValue } from "graphql";
import { StyledPanelFormWrapper } from "./StyledForm";
import { createUserMutation } from "./mutations/user";
import { createContactMutation } from "./mutations/contact";
import { createEmailAddressMutation } from "./mutations/emailAddress";
import {
  NewItemData,
  NewContactValues,
  NewContactAndUserValues,
  NewUserValues,
  EmailAddress
} from "./apiTypes";

const NewContact: React.FC<{}> = () => {
  const [newId, setNewId] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const [createContact] = useMutation<
    { createContact: NewItemData },
    { input: NewContactValues }
  >(createContactMutation);
  const [createEmailAddress] = useMutation<
    { createEmailAddress: EmailAddress },
    {
      input: {
        subjectId: string;
        emailAddress: string;
        label: string;
        preferred?: boolean;
      };
    }
  >(createEmailAddressMutation);
  const [createUser] = useMutation<
    { createUser: { id: string } },
    { input: NewUserValues }
  >(createUserMutation);

  const makeUser = async (input: NewUserValues): Promise<string> => {
    try {
      const result = await createUser({ variables: { input } });
      if (!result) throw "Could not create location for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (result.data && result.data.createUser && result.data.createUser.id)
        return result.data.createUser.id;
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }

    throw "Could not create contact for unknown reason.";
  };

  const handleSubmit = async (input: NewContactAndUserValues) => {
    setSubmitting(true);

    try {
      const result = await createContact({
        variables: {
          input: {
            firstName: input.firstName,
            lastName: input.lastName,
            suffix: input.suffix,
            prefix: input.prefix,
            middleName: input.middleName,
            nickname: input.nickname,
            photoId: input.photoId
          }
        }
      });
      if (!result) throw "Could not create contact for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createContact &&
        result.data.createContact.id
      ) {
        await createEmailAddress({
          variables: {
            input: {
              emailAddress: input.emailAddress,
              label: "main",
              subjectId: result.data.createContact.id,
              preferred: true
            }
          }
        });
        await makeUser({
          contactId: result.data.createContact.id,
          email: input.emailAddress,
          password: "password1"
        });
        return setNewId(result.data.createContact.id);
      }
      throw "Could not create contact for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  if (newId) return <Redirect to={`/contacts/${newId}`} />;

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        suffix: "",
        prefix: "",
        middleName: "",
        nickname: "",
        emailAddress: "",
        photoId: undefined
      }}
      onSubmit={handleSubmit}
      render={({ values }) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Contact</S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              <label htmlFor="prefix">Prefix</label>
              <Field id="prefix" name="prefix" placeholder="" type="text" />
              <label htmlFor="firstName">
                First Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="firstName"
                name="firstName"
                placeholder="John"
                type="text"
              />
              <label htmlFor="middleName">Middle Name</label>
              <Field
                id="middleName"
                name="middleName"
                placeholder=""
                type="text"
              />
              <label htmlFor="lastName">
                Last Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="lastName"
                name="lastName"
                placeholder="Doe"
                type="text"
              />
              <label htmlFor="suffix">Suffix</label>
              <Field id="suffix" name="suffix" placeholder="" type="text" />
              <label htmlFor="nickname">Nickname</label>
              <Field
                id="nickname"
                name="nickname"
                placeholder="Johnny"
                type="text"
              />
              <label htmlFor="emailAddress">
                Email Address
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="emailAddress"
                name="emailAddress"
                placeholder="test@email.com"
                type="text"
              />
              <label htmlFor="logoUrl">Image</label>
              <S.Flex>
                {imageUrl ? (
                  <img height="35" src={imageUrl} alt="Contact Image" />
                ) : (
                  <></>
                )}
                <FileUpload
                  onUpload={files => {
                    values.photoId = files[0].id;
                    setImageUrl(files[0].url);
                  }}
                  multiple={false}
                />
              </S.Flex>
              <S.Button type="submit" disabled={submitting}>
                Creat{submitting ? "ing" : "e"} Contact
              </S.Button>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
};

export default NewContact;
