import React from "react";
import styled, { css } from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import {
  flex,
  space,
  width,
  height,
  justifyContent,
  alignItems,
  flexWrap,
  FlexProps as SSFlexProps,
  SpaceProps,
  WidthProps,
  HeightProps,
  JustifyContentProps,
  AlignItemsProps,
  FlexWrapProps,
} from "styled-system";
import { Tag } from "./Tags";
import { ChevronLeft } from "react-feather";

import { Button, LinkButton, ButtonedLink } from "./styles/Button";
export { Button, LinkButton, ButtonedLink } from "./styles/Button";

export const A = styled.a`
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.blue};
  }
`;

type FlexProps = SSFlexProps &
  WidthProps &
  SpaceProps &
  JustifyContentProps &
  AlignItemsProps &
  FlexWrapProps;

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${flex}
  ${width}
  ${space}
  ${justifyContent}
  ${alignItems}
  ${flexWrap}
`;

type BoxProps = {
  hidden?: boolean;
  pending?: boolean;
} & WidthProps &
  SpaceProps;

export const Box = styled.div<BoxProps>`
  ${width}
  ${space}
  position: relative;
  ${(p) =>
    p.hidden &&
    css`
      display: none;
    `}
  ${(p) =>
    p.pending &&
    css`
      position: relative;
      filter: blur(1.5px);
      background: #e3e3e3;
      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        content: "COMING SOON";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-weight: 800;
        margin: auto;
        text-align: center;
        color: #3e3e3e;
      }
    `}
`;

Box.defaultProps = {
  mx: 0,
  my: 3,
};

export const P = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  margin: 0.5rem 0 1.5rem 0;

  b {
    font-weight: 600;
  }
  span {
    margin-top: 6px;
    font-size: 70%;
    display: block;
  }
`;

export const H1 = styled.h1``;
export const H2 = styled.h2``;

type UnderlinedHeaderProps = {
  header?: boolean;
  noMargin?: boolean;
};

export const UnderlinedHeader = styled.h3<UnderlinedHeaderProps>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightblue};
  ${(p) =>
    p.header &&
    css`
      margin: 0 20px 0 20px;
    `}
  ${(p) =>
    p.noMargin &&
    css`
      margin: 0;
    `}
`;

export const H3 = styled.h3`
  font-weight: 500;
  span {
    margin-top: 6px;
    color: ${(p) => p.theme.colors.lightgrey};
    display: block;
    font-weight: 300;
    font-size: 80%;
  }
`;

export const Title = styled.h3``;
export const H4 = styled.h4`
  span {
    margin-top: 6px;
    color: ${(p) => p.theme.colors.lightgrey};
    display: block;
    font-weight: 300;
    font-size: 80%;
    text-transform: none;
  }
`;

export const Address = styled.p`
  font-weight: 300;
  margin-top: 0;
  font-size: 1rem;
  line-height: 1.5;
  span {
    display: block;
  }
`;

export const ContactList = styled.ul`
  margin: 3px 0;
  list-style: none;
  li {
    border: none;
    font-size: 1rem;
    padding: 0;
  }
`;

export const GroupList = styled.ul`
  padding: 0;
  list-style: none;
  li {
    position: relative;
    margin-top: 0;
    border-bottom: 1px solid ${(p) => p.theme.colors.lightblue};
    > div {
      margin-top: 0;
    }
    &:last-child {
      border: none;
    }
  }
`;
export const List = styled.ul<{
  noBorder?: boolean;
  noPadding?: boolean;
}>`
  padding: 0;
  margin: 0;

  ${(p) =>
    p.noBorder &&
    css`
      li {
        border-bottom: none !important;
      }
    `}
  ${(p) =>
    p.noPadding &&
    css`
      li {
        padding: 4px 20px 4px 0 !important;
      }
    `}
  li {
    padding: 4px 20px;
    border-bottom: 1px solid rgba(118, 132, 158, 0.2);
    h3 {
      margin: 8px 0;
      font-weight: 500;
      span {
        margin-top: 8px;
        display: block;
        font-weight: 300;
        font-size: 80%;
      }
    }
    &:last-child {
      border: none;
    }
  }
  ${H3} {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25;
    span {
      margin-top: 6px;
      color: ${(p) => p.theme.colors.lightgrey};
      display: block;
      font-weight: 300;
      font-size: 80%;
    }
  }
  ${Tag} {
    margin: auto 0;
    border-radius: 4px;
  }
`;

export const HalfWidth = styled.div`
  width: 50%;
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
`;

export const SVG = styled.svg<{ color?: string; leftMargin?: boolean }>`
  margin-right: 10px;
  margin-left: 0;
  ${(p) =>
    p.leftMargin &&
    css`
      margin-left: 5px;
    `}
  ${(p) =>
    p.color && p.color === "grey"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.colors.grey};
          }
        `
      : p.color && p.color === "gold"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.tagColors.gold};
          }
        `
      : p.color && p.color === "silver"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.tagColors.silver};
          }
        `
      : p.color && p.color === "bronze"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.tagColors.bronze};
          }
        `
      : p.color && p.color === "purple"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.colors.purple};
          }
        `
      : p.color && p.color === "red"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.colors.red};
          }
        `
      : p.color && p.color === "green"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.colors.green};
          }
        `
      : p.color && p.color === "orange"
      ? css`
          path {
            transition: all 0.25s;
            fill: ${(p) => p.theme.colors.orange};
          }
        `
      : css`
          path {
            fill: #fff !important;
            transition: none;
          }
        `}
`;

type PanelProps = {
  hidden?: boolean;
} & WidthProps &
  SpaceProps &
  HeightProps;

export const Panel = styled.div<PanelProps>`
  background: #fff;
  box-shadow: 0 2px 8px rgba(118, 132, 158, 0.2);
  border-radius: 4px;
  padding: 16px 32px;
  position: relative;
  ${width}
  ${space}
  ${height}

  ${(p) =>
    p.hidden &&
    css`
      display: none;
    `}
  a:hover {
    h1,
    h2,
    h3,
    h4 {
      color: ${(p) => p.theme.colors.blue};
    }
    ${SVG} path {
      fill: ${(p) => p.theme.colors.blue};
    }
  }
  ${H1} {
    font-weight: 500;
    margin-top: 0;
    span {
      margin-top: 8px;
      color: ${(p) => p.theme.colors.lightgrey};
      display: block;
      font-weight: 300;
      font-size: 80%;
    }
  }
  ${H2} {
    text-transform: uppercase;
    margin-top: 0;
  }
  ${H3} {
    margin-top: 0;
    font-weight: 500;
  }
  ${Title} {
    font-weight: 500;
    margin: 0.5rem 0;
    span {
      margin-top: 8px;
      color: ${(p) => p.theme.colors.lightgrey};
      display: block;
      font-weight: 300;
      font-size: 80%;
    }
    &.inline {
      span {
        display: inline-block;
        color: ${(p) => p.theme.colors.grey};
        font-weight: 500;
      }
    }
  }
  ${H4} {
    margin-top: 0;
  }
  ${H4}.inline {
    display: inline-block;
  }
  ul {
    padding: 0;
    margin-top: 0;
  }
  ${Button}, ${LinkButton}, ${ButtonedLink} {
    border-color: ${(p) => p.theme.colors.grey};
  }
`;

Panel.defaultProps = {
  mx: 3,
  my: 3,
};
