import React, { useState, useCallback } from "react";
import Page, { PageButtons } from "./Page";
import NewContact from "./NewContact";
import UserImage from "./UserAvatar";
import Moment from "react-moment";
import { Select as FormSelect } from "./StyledForm";
import Select, { ValueType } from "react-select";
import { Field } from "formik";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import Table, { TR, TD } from "./Table";
import Tags, { Tag } from "./Tags";
import { useFormikContext } from "formik";
import { Query } from "@apollo/react-components";
import { Link, useLocation } from "react-router-dom";
import {
  ContactsListQueryData,
  GroupListQueryData,
  SortValue,
  FilterValue,
  Company,
  Contact,
  Connection,
} from "./apiTypes";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";
import { contactsListQuery, contactSearchQuery } from "./queries/contact";
import { CompanyQuery } from "./queries/company";
import { useQuery } from "@apollo/react-hooks";
import { groupsListQuery } from "./queries/group";

const Contacts: React.FunctionComponent = () => {
  const query = new URLSearchParams(useLocation().search);
  const pageSize = 20;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;

  const addDialog = useDialogState();

  const sortOptions = [
    { label: "Last Name A-Z", value: "LAST_NAME_ASC" },
    { label: "Last Name Z-A", value: "LAST_NAME_DESC" },
    // { label: "Account Balance", value: "account balance" },
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
  ];

  const companyOptions = [
    { label: "Primary Contact", value: "primary" },
    { label: "Secondary Contact", value: "secondary" },
    { label: "Authorized Representative", value: "authorized" },
    { label: "Billing Contact", value: "billing" },
  ];

  const statuses = [
    { label: "Unarchived", value: undefined },
    { label: "Archived", value: "ARCHIVED" },
    // { label: "Past Due", value: "PASTDUE" },
  ];

  const { loading, error, data } = useQuery<GroupListQueryData>(
    groupsListQuery
  );
  const contactGroups =
    (data &&
      data.groups &&
      data.groups.map((g) => {
        return { label: g.name, value: g.id };
      })) ||
    [];

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const [status, setStatus] = useState<SortValue>(statuses[0]);
  const [companyOpts, setCompanyOpts] = useState<FilterValue>([]);
  const [groups, setGroups] = useState<FilterValue>([]);

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleStatus = (newValues: FilterValue) => {
    setStatus(newValues);
  };
  const handleCompanyOpts = (newValues: FilterValue) => {
    setCompanyOpts(newValues);
  };
  const handleGroups = (newValues: FilterValue) => {
    setGroups(newValues);
  };

  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "LAST_NAME_ASC",
    filter: {
      active: status && "value" in status ? status.value !== "ARCHIVED" : true,
      groupIds: groups && Array.isArray(groups) && groups.map((g) => g.value),
    },
  };

  return (
    <Page>
      <TopNav />
      <Query<ContactsListQueryData>
        query={contactsListQuery}
        variables={queryVars}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Oops.</div>;
          if (!data || !data.contacts || !Array.isArray(data.contacts.edges))
            return <div>No data.</div>;
          return (
            <>
              <S.Flex justifyContent="space-between" mx={3}>
                <S.Title>Total Contacts: {data.contacts.count}</S.Title>
                <DialogDisclosure {...addDialog} as={S.Button}>
                  Add New Contact
                </DialogDisclosure>
              </S.Flex>
              <S.Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mx={3}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <label>SORT BY</label>
                  <Select
                    closeMenuOnSelect={true}
                    onChange={handleSort}
                    options={sortOptions}
                    value={sort}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <label>FILTER BY</label>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Status
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={false}
                        onChange={handleStatus}
                        options={statuses}
                        value={status}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Contact Groups
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={handleGroups}
                        options={contactGroups}
                        value={groups}
                      />
                    </div>
                  </S.Flex>
                </div>
                <div style={{ marginLeft: 20, marginTop: 26 }}>
                  <S.ButtonedLink
                    size="small"
                    href="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
                    target="_blank"
                    download="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
                  >
                    <S.Flex alignItems="center">
                      <SVG.DownloadSvg />
                      Download
                    </S.Flex>
                  </S.ButtonedLink>
                </div>
              </S.Flex>
              <PageButtons
                topMargin={4}
                pageInfo={data.contacts.pageInfo}
                basePath="contacts"
              />
              <S.Panel>
                <Table cols="2fr 2fr 2fr 1fr">
                  <TR header>
                    <TD>CONTACT</TD>
                    <TD>COMPANIES</TD>
                    <TD>GROUPS</TD>
                    <TD>JOIN DATE</TD>
                  </TR>
                  {data.contacts.edges.map((edge) => {
                    const contact = edge.node;
                    if (!contact) return <React.Fragment />;
                    return (
                      <TR key={contact.id} pt={3} pb={2}>
                        <TD>
                          <Link to={`/contacts/${contact.id}`}>
                            <S.Flex my={2} alignItems="center">
                              {contact.photoUrl ? (
                                <UserImage large src={contact.photoUrl} />
                              ) : (
                                <></>
                              )}
                              <S.H3>
                                {contact.firstName} {contact.lastName}
                              </S.H3>
                            </S.Flex>
                          </Link>
                        </TD>
                        <TD>
                          <S.List noPadding noBorder>
                            {contact.companyMemberships ? (
                              contact.companyMemberships.map((membership) => {
                                if (membership.endedOn) return null;
                                const company = membership.company;
                                if (!company) return null;
                                if (
                                  !company.id ||
                                  !company.name ||
                                  company.deactivatedAt
                                )
                                  return <React.Fragment />;
                                return (
                                  <li key={company.id}>
                                    <Link to={`/companies/${company.id}`}>
                                      <S.Title>
                                        {company.name}
                                        {membership.billingContact ? (
                                          membership.primaryContact ? (
                                            <span>
                                              Primary &amp; Billing Contact
                                            </span>
                                          ) : membership.secondaryContact ? (
                                            <span>
                                              Secondary &amp; Billing Contact
                                            </span>
                                          ) : (
                                            <span>Billing Contact</span>
                                          )
                                        ) : membership.primaryContact ? (
                                          <span>Primary Contact</span>
                                        ) : membership.secondaryContact ? (
                                          <span>Secondary Contact</span>
                                        ) : membership.authorizedRepresentative ? (
                                          <span>Authorized Contact</span>
                                        ) : (
                                          <></>
                                        )}
                                      </S.Title>
                                    </Link>
                                  </li>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </S.List>
                        </TD>
                        <TD>
                          <S.List noPadding noBorder>
                            {contact.groups ? (
                              contact.groups.map((group) => {
                                return <li key={group.id}>{group.name}</li>;
                              })
                            ) : (
                              <></>
                            )}
                          </S.List>
                        </TD>
                        <TD>
                          <Moment format="MMMM D, YYYY">
                            {contact.insertedAt}
                          </Moment>
                        </TD>
                        <Tags>
                          {contact.deactivatedAt ? (
                            <Tag color="lightorange">Archived</Tag>
                          ) : (
                            <Tag color="lightgreen">Active</Tag>
                          )}
                        </Tags>
                      </TR>
                    );
                  })}
                </Table>
              </S.Panel>
              <PageButtons
                pageInfo={data.contacts.pageInfo}
                basePath="contacts"
              />
            </>
          );
        }}
      </Query>
      <DialogWithBackdrop {...addDialog}>
        <NewContact />
      </DialogWithBackdrop>
    </Page>
  );
};

const CompanyContactSelect = (props: {
  name: string;
  companyId: string;
  value?: boolean;
}) => {
  const { data, loading, error } = useQuery<{ company: Company }>(
    CompanyQuery,
    {
      variables: { companyId: props.companyId },
    }
  );

  if (loading && !data) return <div />;
  // if (error) return <div>Oops.</div>;
  if (
    !data ||
    !data.company ||
    !data.company.contactMemberships ||
    !Array.isArray(data.company.contactMemberships)
  )
    return <div>No data.</div>;

  let contacts: Contact[] = data.company.contactMemberships.map(
    (c) => c.contact
  );

  return (
    <ContactSelect name={props.name} contacts={contacts} value={props.value} />
  );
};

const ContactListSelect = (props: { name: string; value?: boolean }) => {
  const { data, loading, error } = useQuery<ContactsListQueryData>(
    contactSearchQuery,
    {
      variables: { first: 3000, orderBy: "LAST_NAME_ASC" },
    }
  );

  if (loading && !data) return <div />;
  if (error) return <div>Oops.</div>;
  if (!data || !data.contacts || !Array.isArray(data.contacts.edges))
    return <div>No data.</div>;

  let contacts: Contact[] = data.contacts.edges
    .map((e) => e.node)
    .filter(Boolean) as Contact[];

  return (
    <ContactSelect name={props.name} contacts={contacts} value={props.value} />
  );
};

const ContactSelect = (props: {
  name: string;
  contacts: Contact[];
  value?: boolean;
}) => {
  const formik = useFormikContext<any>();

  const handleChange = useCallback(
    ({ value }: any) => {
      formik.setFieldValue(props.name, value);
    },
    [props.name, formik.setFieldValue]
  );

  var contactsList = props.contacts
    .map((contact) => {
      if (!contact) return null;
      if (contact.deactivatedAt && contact.deactivatedAt !== "") return null;
      const contactName = contact.firstName + " " + contact.lastName;
      return { label: contactName, value: contact.id };
    })
    .filter(Boolean) as ValueType<{ label: string; value: string }>[];

  return (
    <Field
      id={props.name}
      name={props.value ? `${props.name}.value` : props.name}
      component={FormSelect}
      closeMenuOnSelect={true}
      options={contactsList}
      onChange={handleChange}
    />
  );
};

export default Contacts;
export { ContactListSelect, CompanyContactSelect };
