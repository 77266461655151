import { gql } from "graphql.macro";

export const companyListQuery = gql`
  query CompanyListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: CompaniesOrderByInput
    $filter: CompanyFilterInput
  ) {
    companies(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          logoUrl
          insertedAt
          deactivatedAt
          termsAcceptedAt
          stripeCustomerId
          mergedAt
          memberships {
            id
            categories
            applicationStatus
            endedOn
          }
          salesReports {
            id
            dollarAmount
            insertedAt
            reportedOn
            status
            updatedAt
            year
          }
          activeMembership {
            id
            categories
            deactivateWhenDelinquent
            applicationStatus
            deactivatedAt
            easeInto
            endedOn
            paymentInterval
            startedOn
            insertedAt
            updatedAt
          }
          contactMemberships {
            id
            primaryContact
            authorizedRepresentative
            endedOn
            title
            contact {
              id
              firstName
              lastName
              photoUrl
              phoneNumbers {
                label
                phoneNumber
                preferred
              }
              emailAddresses {
                label
                emailAddress
                preferred
              }
              urls {
                label
                preferred
                url
              }
              addresses {
                label
                city
                country
                postalCode
                state
                streetAddress
                suiteNumber
                preferred
              }
            }
          }
          addresses {
            id
            label
            city
            country
            postalCode
            state
            streetAddress
            suiteNumber
            preferred
          }
          urls {
            id
            label
            url
            preferred
          }
          phoneNumbers {
            id
            label
            phoneNumber
            preferred
          }
          emailAddresses {
            id
            label
            emailAddress
            preferred
          }
        }
      }
    }
  }
`;

export const companySearchQuery = gql`
  query companySearchQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: CompaniesOrderByInput
    $filter: CompanyFilterInput
  ) {
    companies(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export const CompanyQuery = gql`
  query companyQuery($companyId: ID!) {
    company(companyId: $companyId) {
      id
      deactivatedAt
      insertedAt
      updatedAt
      federalIdNumber
      naicsCodes
      name
      numEmployees
      description
      stripeCustomerId
      termsAcceptedAt
      buyersGuideCategories {
        id
        name
        parentId
      }
      preferredInvoiceDeliveryMethod
      logoUrl
      mergedAt
      mergedInto {
        id
        name
      }
      salesReports {
        id
        dollarAmount
        insertedAt
        reportedOn
        status
        updatedAt
        year
      }
      activeMembership {
        id
        categories
        deactivateWhenDelinquent
        deactivatedAt
        easeInto
        yearsToEase
        endedOn
        paymentInterval
        applicationStatus
        startedOn
        insertedAt
        updatedAt
      }
      memberships {
        id
        categories
        deactivateWhenDelinquent
        deactivatedAt
        easeInto
        yearsToEase
        endedOn
        paymentInterval
        startedOn
        insertedAt
        updatedAt
        applicationStatus
      }
      addresses {
        id
        label
        city
        country
        postalCode
        state
        streetAddress
        suiteNumber
        preferred
      }
      contactMemberships {
        id
        primaryContact
        secondaryContact
        authorizedRepresentative
        billingContact
        startedOn
        endedOn
        title
        contact {
          id
          firstName
          lastName
          birthday
          photoUrl
          phoneNumbers {
            id
            label
            phoneNumber
            preferred
          }
          emailAddresses {
            id
            label
            emailAddress
            preferred
          }
          urls {
            id
            label
            preferred
            url
          }
          addresses {
            id
            label
            city
            country
            postalCode
            state
            streetAddress
            suiteNumber
            preferred
          }
        }
      }
      phoneNumbers {
        id
        label
        phoneNumber
        preferred
      }
      emailAddresses {
        id
        label
        emailAddress
        preferred
      }
      urls {
        id
        label
        url
        preferred
      }
    }
  }
`;
