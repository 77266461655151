import React from "react";
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues
} from "react-number-format";
import { FieldProps } from "formik";

export default function NumberInput({
  field: { onChange: onFieldChange, onBlur, ...field },
  ...otherProps
}: NumberFormatProps & FieldProps) {
  function onChange({ value }: NumberFormatValues) {
    return onFieldChange({
      target: { name: field.name, value }
    });
  }

  return (
    <NumberFormat
      isNumericString
      {...field}
      onValueChange={onChange}
      {...otherProps}
    />
  );
}
