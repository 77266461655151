import React from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import moment from "moment";
import EditableContent from "./EditableContent";
import Date from "./Date";
import { MutationFunction } from "@apollo/react-common";
import { Query, Mutation } from "@apollo/react-components";
import { Formik, Field, Form } from "formik";
import { StyledPanelFormWrapper } from "./StyledForm";
import {
  Company,
  Contact,
  Location,
  Event,
  Download,
  NoteListQueryData,
  Note as NoteType,
  Invoice,
  Registration,
  AchievementAwardSubmission,
  StudentDesignSubmission
} from "./apiTypes";
import {
  archiveNoteMutation,
  createNoteMutation,
  updateNoteMutation
} from "./mutations/note";
import { noteListQuery } from "./queries/note";
import Moment from "react-moment";

export default function Notes({
  subject
}: {
  subject:
    | Company
    | Contact
    | Location
    | Event
    | Registration
    | Invoice
    | Download
    | AchievementAwardSubmission
    | StudentDesignSubmission;
}) {
  return (
    <S.Panel pb={2}>
      <S.UnderlinedHeader>Notes</S.UnderlinedHeader>
      <Query<NoteListQueryData>
        query={noteListQuery}
        variables={{ first: 10, subjectId: subject.id, type: "NOTE" }}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Unable to fetch notes.</div>;
          const notes = data && data.notes ? data.notes.edges : undefined;
          return (
            <>
              {notes ? (
                notes.map((edge, index) => <SingleNote key={index} note={edge.node} />)
              ) : (
                <></>
              )}
              <S.Box>
                <Mutation<MutationFunction>
                  mutation={createNoteMutation}
                  refetchQueries={["noteListQuery"]}
                >
                  {(createNote, { loading }) => (
                    <Formik
                      initialValues={{
                        body: ""
                      }}
                      onSubmit={values => {
                        createNote({
                          variables: {
                            input: { ...values, subjectId: subject.id }
                          }
                        });
                      }}
                      render={({ values }) => (
                        <StyledPanelFormWrapper>
                          <Form>
                            <Field
                              required
                              component="textarea"
                              id="body"
                              name="body"
                              placeholder="Add a new note..."
                            />
                            <S.Flex>
                              <button type="submit" disabled={loading}>
                                Sav{loading ? "ing" : "e"}
                              </button>
                            </S.Flex>
                          </Form>
                        </StyledPanelFormWrapper>
                      )}
                    />
                  )}
                </Mutation>
              </S.Box>
            </>
          );
        }}
      </Query>
    </S.Panel>
  );
}

export function SingleNote({ note }: { note: NoteType | undefined }) {
  if (!note) return <></>;
  return (
    <S.Box>
      <EditableContent>
        {({ isOpen, open, close }) => (
          <>
            {!isOpen ? (
              <S.Flex
                alignItems="flex-end"
                justifyContent="space-between"
                my={0}
              >
                <S.P>
                  <b>
                    {note.author && note.author.contact
                      ? `${note.author.contact.firstName} ${note.author.contact.lastName}`
                      : "Admin"}
                  </b>{" "}
                  <Date date={note.insertedAt} format="MM/DD/YY" /> <br />
                  {note.body}
                  {note.updatedAt !== note.insertedAt
                    ? ` (updated ${moment(note.updatedAt).format(
                        "MM/DD/YY h:MM a"
                      )})`
                    : ``}
                </S.P>
                <S.Button size="small" onClick={open}>
                  Edit
                </S.Button>
              </S.Flex>
            ) : (
              <Mutation<MutationFunction>
                mutation={updateNoteMutation}
                refetchQueries={["noteListQuery"]}
              >
                {(updateNote, { loading }) => (
                  <Mutation<MutationFunction>
                    mutation={archiveNoteMutation}
                    refetchQueries={["noteListQuery"]}
                  >
                    {(archiveNote, { loading }) => {
                      const archive = archiveNote({
                        variables: { input: { noteId: note.id } }
                      });
                      return (
                        <Formik
                          initialValues={{
                            body: note.body ? note.body : ""
                          }}
                          onSubmit={values => {
                            updateNote({
                              variables: {
                                input: { ...values, noteId: note.id }
                              }
                            });
                            close();
                          }}
                          render={({ values }) => (
                            <StyledPanelFormWrapper>
                              <Form>
                                <Field
                                  required
                                  id="body"
                                  name="body"
                                  placeholder=""
                                  component="textarea"
                                />
                                <S.Flex>
                                  <button type="submit" disabled={loading}>
                                    Sav{loading ? "ing" : "e"}
                                  </button>
                                  <button disabled={loading} onClick={close}>
                                    Cancel{loading ? "ling" : ""}
                                  </button>
                                  <button
                                    disabled={loading}
                                    onClick={() => archive}
                                  >
                                    Archiv{loading ? "ing" : "e"}
                                  </button>
                                </S.Flex>
                              </Form>
                            </StyledPanelFormWrapper>
                          )}
                        />
                      );
                    }}
                  </Mutation>
                )}
              </Mutation>
            )}
          </>
        )}
      </EditableContent>
    </S.Box>
  );
}

export function ActivityFeed({
  subject
}: {
  subject:
    | Company
    | Contact
    | Location
    | Event
    | Registration
    | Invoice
    | Download
    | AchievementAwardSubmission
    | StudentDesignSubmission;
}) {
  return (
    <S.Box pb={2} pt={3} my={3} mx={3} pending>
      <S.UnderlinedHeader>Activity Feed</S.UnderlinedHeader>
      <Query<NoteListQueryData>
        query={noteListQuery}
        variables={{ first: 10, subjectId: subject.id, type: "ACTIVITY" }}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Unable to fetch notes.</div>;
          const notes = data && data.notes ? data.notes.edges : undefined;
          if (!notes) return <></>;
          return (
            <S.GroupList>
              {notes.map(edge => {
                if (edge.node && edge.node.body) {
                  const timestamp = edge.node.insertedAt;
                  const author = edge.node.author;

                  return (
                    <li key={edge.node.id}>
                      <S.P>
                        <b>
                          {author && author.contact
                            ? ` ${author.contact.firstName} ${author.contact.lastName} `
                            : "Admin "}
                        </b>
                        <Moment format="M/D/YYYY h:MM a">{timestamp}</Moment>
                        <br />
                        {edge.node.body}
                      </S.P>
                    </li>
                  );
                }
                return <></>;
              })}
            </S.GroupList>
          );
        }}
      </Query>
    </S.Box>
  );
}
