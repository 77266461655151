import React from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import Moment from "react-moment";
import { useMutation } from "@apollo/react-hooks";
import { Tag } from "./Tags";
import Image from "./Image";
import PageNav, {
  NavInfo,
  TopPageNav,
} from "./PageNav";
import { match } from "react-router-dom";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { archiveAchievementAwardSubmissionMutation } from "./mutations/achievementAwardSubmission";
import {
  AchievementAwardSubmission as AchievementAward,
  UseMutationReturn,
} from "./apiTypes";

type AchievementAwardNavProps = {
  achievementAward: AchievementAward;
  match: match<{ id: string }>;
};

function EditAchievementAwardMenu({
  achievementAward,
}: {
  achievementAward: AchievementAward;
}) {
  const menu = useMenuState();

  const [archiveAchievementAwardSubmission]: UseMutationReturn<
    { archiveAchievementAwardSubmission: AchievementAward },
    { input: { submissionId: string } }
  > = useMutation(archiveAchievementAwardSubmissionMutation, {
    refetchQueries: ["achievementAwardQuery"],
  });

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Award
      </MenuDisclosure>
      <Menu {...menu}>
        <MenuItem
          {...menu}
          onClick={async () => {
            if (
              window.confirm(
                "Are you sure you want to archive this Achievement Award entry?"
              )
            ) {
              await archiveAchievementAwardSubmission({
                variables: {
                  input: { submissionId: achievementAward.id },
                },
              });
              menu.hide();
            }
          }}
        >
          Archive Entry
        </MenuItem>
      </Menu>
    </>
  );
}

const AchievementAwardNav: React.FunctionComponent<AchievementAwardNavProps> = ({
  match,
  achievementAward,
}) => {
  const categorization: { [x: string]: any } = {
    "Printing and Shelf Impact": "lightblue",
    "Technical Innovation": "lightorange",
    "Expanding the Use of Flexible Packaging": "lightyellow",
    Sustainability: "lightgreen",
  };
  const categories =
    (achievementAward.categories &&
      achievementAward.categories.map((category) => {
        const award =
          achievementAward.awards &&
          achievementAward.awards.filter(
            (award) => award.category.id === category.id
          );
        return {
          name: category.name,
          tier: (award && award[0] && award[0].tier) || undefined,
        };
      })) ||
    [];
  return (
    <PageNav>
      <S.Box py={3} mx={3} my={0}>
        <TopPageNav />
        <NavInfo>
          <S.Flex width={5 / 6} ml={3}>
            <Image src="" />
            <div>
              <S.H1>
                {achievementAward.name}
                <span>
                  {" "}
                  submitted{" "}
                  <Moment format="MMMM D, YYYY">
                    {achievementAward.insertedAt}
                  </Moment>
                </span>
              </S.H1>
              <S.Flex>
                {achievementAward.highestAchievement && (
                  <Tag
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    color="lightpurple"
                  >
                    <S.Flex alignItems="center">
                      Highest Achievement Award
                    </S.Flex>
                  </Tag>
                )}
                {categories &&
                  categories.map((category) => (
                    <Tag
                      key={category.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <S.Flex alignItems="center">
                        {category.tier && category.tier === "GOLD" ? (
                          <SVG.AwardsSvg color="gold" />
                        ) : category.tier && category.tier === "SILVER" ? (
                          <SVG.AwardsSvg color="silver" />
                        ) : category.tier && category.tier === "BRONZE" ? (
                          <SVG.AwardsSvg color="bronze" />
                        ) : (
                          <></>
                        )}
                        {category.name}
                      </S.Flex>
                    </Tag>
                  ))}
                {achievementAward.samplesReceived ? (
                  <Tag
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    color="lightgreen"
                  >
                    {" "}
                    <S.Flex alignItems="center">Samples Received</S.Flex>
                  </Tag>
                ) : (
                  <Tag
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    color="lightyellow"
                  >
                    {" "}
                    <S.Flex alignItems="center">Awaiting Samples</S.Flex>
                  </Tag>
                )}
              </S.Flex>
            </div>
          </S.Flex>
          <EditAchievementAwardMenu achievementAward={achievementAward} />
        </NavInfo>
      </S.Box>
    </PageNav>
  );
};

export default AchievementAwardNav;
