import React from "react";
import moment from "moment";
import * as S from "./styles";
import Searchbar from "./Searchbar";
import { useMutation } from "@apollo/react-hooks";
import { Calendar } from "./Date";
import { match } from "react-router-dom";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import PageNav, {
  NavInfo,
  BackButton,
  LocalNavA,
  LocalNav,
  TopPageNav
} from "./PageNav";
import { Event, UseMutationReturn } from "./apiTypes";
import { Logo } from "./Image";
import { archiveEventMutation } from "./mutations/event";

type EventNavProps = {
  event: Event;
  match: match<{ eventId: string }>;
};

function EditEventMenu({ event }: { event: Event }) {
  const menu = useMenuState();
  // const [cloneEvent]: UseMutationReturn<
  //   { cloneEvent: Event },
  //   { input: { eventId: string } }
  // > = useMutation(cloneEventMutation);
  const [archiveEvent]: UseMutationReturn<
    { archiveEvent: Event },
    { input: { eventId: string } }
  > = useMutation(archiveEventMutation);

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Event
      </MenuDisclosure>
      <Menu {...menu}>
        {/* <MenuItem
          {...menu}
          onClick={() =>
            cloneEvent({
              variables: { input: { eventId: event.id } }
            })
          }
        >
          Clone Event
        </MenuItem> */}
        <MenuItem
          {...menu}
          onClick={() =>
            archiveEvent({
              variables: { input: { eventId: event.id } }
            })
          }
        >
          Delete Event
        </MenuItem>
      </Menu>
    </>
  );
}

const EventNav: React.FunctionComponent<EventNavProps> = ({ match, event }) => {
  return (
    <PageNav>
      <S.Box pt={3} px={3} my={0}>
        <TopPageNav />
        <NavInfo>
          <S.Flex ml={3} alignItems="center">
            {event.startsAt && event.endsAt ? (
              <Calendar start={event.startsAt} end={event.endsAt} />
            ) : (
              <></>
            )}
            <S.H1>{event.title}</S.H1>
          </S.Flex>
          <EditEventMenu event={event} />
        </NavInfo>
        <LocalNav>
          <LocalNavA to={`${match.url}/details`}>Details</LocalNavA>
          <LocalNavA pending to={`${match.url}/registrations`}>
            Registrations
          </LocalNavA>
          <LocalNavA pending to={`${match.url}/registration-form`}>
            Registration Form
          </LocalNavA>
        </LocalNav>
      </S.Box>
    </PageNav>
  );
};

export default EventNav;
