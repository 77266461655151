import { gql } from "graphql.macro";

export const stripeInvoiceListQuery = gql`
  query stripeInvoiceListQuery(
    $filter: StripeInvoiceFilterInput
  ) {
    stripeInvoices (
      filter: $filter
    ) {
      id
      amountDue
      amountPaid
      createdAt
      customer {
        id
      }
      description
      discount {
        coupon {
          id
          amountOff
          percentOff
          redeemBy
        }
      }
      dueDate
      hostedInvoiceUrl
      invoicePdf
      lines {
        amount
        description
        discountable
        quantity
        type
      }
      status
      total
    }
  }
`;