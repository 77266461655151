import React from "react";
import moment from "moment";
import styled from "styled-components";
import EditableContent from "./EditableContent";
import {
  StyledPanelFormWrapper,
  Select,
  RepeatableFieldButton,
  MoneyField,
  FormikDatetime,
} from "./StyledForm";
import { Formik, Field, Form, FieldArray } from "formik";
import { Query, Mutation } from "@apollo/react-components";
import { RouteComponentProps } from "react-router-dom";
import * as S from "./styles";
import * as SVG from "./svgs";
import Notes, { ActivityFeed } from "./Notes";
import StoreCategories from "./StoreCategories";
import Table, { TR, TD } from "./Table";
import Page from "./Page";
import TopNav from "./TopNav";
import { Link } from "react-router-dom";
import ImageGallery from "./ImageGallery";
import StoreItemNav from "./StoreItemNav";
import { StoreItem as StoreItemType, UpdateStoreItemValues } from "./apiTypes";
import { updateStoreItemMutation } from "./mutations/storeItem";
import { StoreItemQuery } from "./queries/storeItem";
import { DateRange } from "./Date";
import NumberFormat from "react-number-format";
import { StoreItemsSelect } from "./StoreItems";

type StoreItemProps = RouteComponentProps<{ storeItemId: string }>;

const StoreItem: React.FunctionComponent<StoreItemProps> = ({ match }) => {
  return (
    <Query<{ storeItem: StoreItemType }, { storeItemId: string }>
      query={StoreItemQuery}
      variables={{ storeItemId: match.params.storeItemId }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (error)
          return (
            <Page>
              <TopNav />
              There is no Store Item associated with this id/url.
            </Page>
          );
        if (!data || !data.storeItem)
          return (
            <Page>
              <TopNav />
              There is no Store Item associated with this id/url.
            </Page>
          );
        const storeItem = data.storeItem;
        if (!storeItem)
          return (
            <Page>
              <TopNav />
              There is no Store Item associated with this id/url.
            </Page>
          );
        return (
          <div>
            <StoreItemNav storeItem={storeItem} match={match} />
            <Page>
              <S.Flex mt={3} mb={0} pb={0}>
                <S.Panel my={0} width={1 / 2}>
                  <EditableContent>
                    {({ isOpen, open, close }) => (
                      <>
                        <S.UnderlinedHeader>
                          <S.Flex
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            Item Details
                            {!isOpen ? (
                              <S.Button size="small" onClick={open}>
                                Edit
                              </S.Button>
                            ) : (
                              <S.Button size="small" onClick={close}>
                                Cancel
                              </S.Button>
                            )}
                          </S.Flex>
                        </S.UnderlinedHeader>
                        {!isOpen ? (
                          <S.Flex>
                            <S.Box pr={4}>
                              <img width="220px" src="/images/empty-user.png" />
                              <Table cols="3fr 2fr">
                                <TR borderless>
                                  <TD left>
                                    <S.Title>Attached File</S.Title>
                                  </TD>
                                  <TD>
                                    <Link
                                      to={`/files/${storeItem.download.id}`}
                                    >
                                      <S.Title>
                                        {storeItem.download.name}
                                      </S.Title>
                                    </Link>
                                  </TD>
                                </TR>
                              </Table>
                            </S.Box>
                            <S.Box>
                              <S.Title>{storeItem.name}</S.Title>
                              <S.P>{`SKU: ${storeItem.sku}`}</S.P>
                              <Table cols="170px 2fr">
                                <TR borderless>
                                  <TD left>
                                    <S.Title>Member Price</S.Title>
                                  </TD>
                                  <TD>
                                    {storeItem.price ? (
                                      <NumberFormat
                                        value={storeItem.price / 100}
                                        fixedDecimalScale
                                        decimalScale={2}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    ) : (
                                      "FREE"
                                    )}
                                  </TD>
                                </TR>
                                <TR borderless>
                                  <TD left>
                                    <S.Title>Non-Member Price</S.Title>
                                  </TD>
                                  <TD>FREE</TD>
                                </TR>
                                <TR borderless>
                                  <TD left>
                                    <S.Title>Quantity Available</S.Title>
                                  </TD>
                                  <TD>Unlimited</TD>
                                </TR>
                                <TR borderless>
                                  <TD left>
                                    <S.Title>Featured</S.Title>
                                  </TD>
                                  <TD>
                                    <SVG.CheckSvg />
                                    Yes
                                  </TD>
                                </TR>
                                <TR borderless>
                                  <TD left>
                                    <S.Title>Availability</S.Title>
                                  </TD>
                                  <TD>
                                    {!storeItem.publishedAt ? (
                                      "Currently Not Displayed For Purchase"
                                    ) : !storeItem.deactivatedAt ? (
                                      `Since ${moment(
                                        storeItem.publishedAt
                                      ).format("MMMM D, YYYY")}`
                                    ) : (
                                      <DateRange
                                        start={storeItem.publishedAt}
                                        end={storeItem.deactivatedAt}
                                      />
                                    )}
                                  </TD>
                                </TR>
                              </Table>
                            </S.Box>
                          </S.Flex>
                        ) : (
                          <Mutation<UpdateStoreItemValues>
                            mutation={updateStoreItemMutation}
                          >
                            {(updateStoreItem, { loading }) => {
                              return (
                                <Formik
                                  initialValues={{
                                    storeItemId: storeItem.id,
                                    name: storeItem.name,
                                    sku: storeItem.sku,
                                    price: storeItem.price / 100,
                                    publishedAt: storeItem.publishedAt,
                                    deactivatedAt: storeItem.deactivatedAt,
                                  }}
                                  onSubmit={async (e) => {
                                    await updateStoreItem({
                                      variables: { input: e },
                                    });
                                  }}
                                  render={({ values }) => (
                                    <Form>
                                      <StyledPanelFormWrapper>
                                        <label htmlFor="name">Name</label>
                                        <Field
                                          required
                                          id="name"
                                          name="name"
                                          placeholder="Store Item Name"
                                          type="text"
                                        />
                                        <S.Flex>
                                          <S.HalfWidth>
                                            <label>SKU</label>
                                            <Field
                                              required
                                              id="sku"
                                              name="sku"
                                              placeholder="####"
                                              type="text"
                                            />
                                          </S.HalfWidth>
                                          <S.HalfWidth>
                                            <label>Member Price</label>
                                            <Field
                                              required
                                              id="price"
                                              name="price"
                                              component={MoneyField}
                                            />
                                          </S.HalfWidth>
                                          {/* <S.HalfWidth>
                                            <label>Non-Member Price</label>
                                            <Field
                                              required
                                              id="price"
                                              name="price"
                                              component={MoneyField}
                                            />
                                          </S.HalfWidth> */}
                                        </S.Flex>
                                        <S.Flex>
                                          <S.HalfWidth>
                                            <label htmlFor="publishedAt">
                                              Date Add To Store
                                            </label>
                                            <Field
                                              id="publishedAt"
                                              name="publishedAt"
                                              value={moment(new Date()).format(
                                                "MM/DD/YYYY h:mma"
                                              )}
                                              component={FormikDatetime}
                                            />
                                          </S.HalfWidth>
                                          <S.HalfWidth>
                                            <label htmlFor="deactivatedAt">
                                              Date Remove From Store
                                            </label>
                                            <Field
                                              id="deactivatedAt"
                                              name="deactivatedAt"
                                              component={FormikDatetime}
                                            />
                                          </S.HalfWidth>
                                        </S.Flex>
                                        <button
                                          type="submit"
                                          disabled={loading}
                                        >
                                          Sav{loading ? "ing" : "e"}
                                        </button>
                                      </StyledPanelFormWrapper>
                                    </Form>
                                  )}
                                />
                              );
                            }}
                          </Mutation>
                        )}
                      </>
                    )}
                  </EditableContent>
                </S.Panel>
                <S.Panel my={0} width={1 / 4}>
                  <StoreCategories storeItem={storeItem} />
                </S.Panel>
                <S.Panel my={0} width={1 / 4}>
                  <EditableContent>
                    {({ isOpen, open, close }) => (
                      <>
                        <S.UnderlinedHeader>
                          <S.Flex
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            Sales &amp; Promotion Codes
                            {!isOpen ? (
                              <S.Button size="small" onClick={open}>
                                Edit
                              </S.Button>
                            ) : (
                              <S.Button size="small" onClick={close}>
                                Cancel
                              </S.Button>
                            )}
                          </S.Flex>
                        </S.UnderlinedHeader>
                        {!isOpen ? (
                          storeItem.categories &&
                          storeItem.categories.length > 0 ? (
                            <S.Box pl={3} pr={0}>
                              <S.List noBorder noPadding>
                                {storeItem.categories.map((category) => (
                                  <li key={category.id}>
                                    <S.Flex alignItems="center">
                                      {category.name}
                                    </S.Flex>
                                  </li>
                                ))}
                              </S.List>
                            </S.Box>
                          ) : (
                            <>Currently not for sale.</>
                          )
                        ) : (
                          <>Edit Here</>
                          // <Mutation<any> mutation={updateStoreItemMutation}>
                          //   {(updateStoreItem, { loading }) => {
                          //     return (
                          //       <Formik
                          //         initialValues={{
                          //           name: "",
                          //           file: "",
                          //           membersOnly: false
                          //         }}
                          //         onSubmit={async e => {
                          //           await updateStoreItem({
                          //             variables: { input: e }
                          //           });
                          //         }}
                          //         render={({ values }) => (
                          //           <Form>
                          //             <StyledPanelFormWrapper>
                          //               <label htmlFor="name">File Name</label>
                          //               <Field
                          //                 required
                          //                 id="name"
                          //                 name="name"
                          //                 placeholder="File Name"
                          //                 type="text"
                          //               />
                          //               <label htmlFor="membersOnly">
                          //                 Members Only Access
                          //               </label>
                          //               <Field
                          //                 id="membersOnly"
                          //                 name="membersOnly"
                          //                 component={Select}
                          //                 closeMenuOnSelect
                          //                 options={[
                          //                   { label: "Yes", value: true },
                          //                   { label: "No", value: false }
                          //                 ]}
                          //               />
                          //               <button
                          //                 type="submit"
                          //                 disabled={loading}
                          //               >
                          //                 Sav{loading ? "ing" : "e"}
                          //               </button>
                          //             </StyledPanelFormWrapper>
                          //           </Form>
                          //         )}
                          //       />
                          //     );
                          //   }}
                          // </Mutation>
                        )}
                      </>
                    )}
                  </EditableContent>
                </S.Panel>
              </S.Flex>
              <S.Panel pb={2}>
                <EditableContent>
                  {({ isOpen, open, close }) => (
                    <>
                      <S.UnderlinedHeader>
                        <S.Flex
                          alignItems="flex-end"
                          justifyContent="space-between"
                        >
                          Short Summary
                          {!isOpen ? (
                            <S.Button size="small" onClick={open}>
                              Edit
                            </S.Button>
                          ) : (
                            <S.Button size="small" onClick={close}>
                              Cancel
                            </S.Button>
                          )}
                        </S.Flex>
                      </S.UnderlinedHeader>
                      {!isOpen ? (
                        <S.P>{storeItem.summary}</S.P>
                      ) : (
                        <Mutation<UpdateStoreItemValues>
                          mutation={updateStoreItemMutation}
                        >
                          {(updateStoreItem, { loading }) => {
                            return (
                              <Formik
                                initialValues={{
                                  storeItemId: storeItem.id,
                                  summary: storeItem.summary || "",
                                }}
                                onSubmit={async (e) => {
                                  await updateStoreItem({
                                    variables: { input: e },
                                  });
                                }}
                                render={({ values }) => (
                                  <Form>
                                    <StyledPanelFormWrapper>
                                      <Field
                                        id="summary"
                                        name="summary"
                                        placeholder="A short summary displayed on the list view of store items."
                                        component="textarea"
                                      />
                                      <button type="submit" disabled={loading}>
                                        Sav{loading ? "ing" : "e"}
                                      </button>
                                    </StyledPanelFormWrapper>
                                  </Form>
                                )}
                              />
                            );
                          }}
                        </Mutation>
                      )}
                    </>
                  )}
                </EditableContent>
              </S.Panel>
              <S.Panel pb={2}>
                <EditableContent>
                  {({ isOpen, open, close }) => (
                    <>
                      <S.UnderlinedHeader>
                        <S.Flex
                          alignItems="flex-end"
                          justifyContent="space-between"
                        >
                          Description
                          {!isOpen ? (
                            <S.Button size="small" onClick={open}>
                              Edit
                            </S.Button>
                          ) : (
                            <S.Button size="small" onClick={close}>
                              Cancel
                            </S.Button>
                          )}
                        </S.Flex>
                      </S.UnderlinedHeader>
                      {!isOpen ? (
                        <S.P>{storeItem.description}</S.P>
                      ) : (
                        <Mutation<UpdateStoreItemValues>
                          mutation={updateStoreItemMutation}
                        >
                          {(updateStoreItem, { loading }) => {
                            return (
                              <Formik
                                initialValues={{
                                  storeItemId: storeItem.id,
                                  description: storeItem.description || "",
                                }}
                                onSubmit={async (e) => {
                                  await updateStoreItem({
                                    variables: { input: e },
                                  });
                                }}
                                render={({ values }) => (
                                  <Form>
                                    <StyledPanelFormWrapper>
                                      <Field
                                        id="description"
                                        name="description"
                                        placeholder="A description of the store item, viewed on the item's store page."
                                        component="textarea"
                                      />
                                      <button type="submit" disabled={loading}>
                                        Sav{loading ? "ing" : "e"}
                                      </button>
                                    </StyledPanelFormWrapper>
                                  </Form>
                                )}
                              />
                            );
                          }}
                        </Mutation>
                      )}
                    </>
                  )}
                </EditableContent>
              </S.Panel>
              <S.Panel pb={2}>
                <EditableContent>
                  {({ isOpen, open, close }) => (
                    <>
                      <S.UnderlinedHeader>
                        <S.Flex
                          alignItems="flex-end"
                          justifyContent="space-between"
                        >
                          Related Items
                          {!isOpen ? (
                            <S.Button size="small" onClick={open}>
                              Edit
                            </S.Button>
                          ) : (
                            <S.Button size="small" onClick={close}>
                              Cancel
                            </S.Button>
                          )}
                        </S.Flex>
                      </S.UnderlinedHeader>
                      {!isOpen ? (
                        <S.Flex>
                          {storeItem.relatedItems &&
                            storeItem.relatedItems.map((relatedItem) => (
                              <S.Box key={relatedItem.id} width={1 / 4}>
                                <Link to={`/store/${relatedItem.id}`}>
                                  <img
                                    src={relatedItem.photoUrl}
                                    height="150"
                                    width="120"
                                  />
                                  <S.P>{storeItem.name}</S.P>
                                </Link>
                              </S.Box>
                            ))}
                        </S.Flex>
                      ) : (
                        <Mutation<any> mutation={updateStoreItemMutation}>
                          {(updateStoreItem, { loading }) => {
                            return (
                              <Formik
                                initialValues={{
                                  relatedItems: [],
                                }}
                                onSubmit={async (e) => {
                                  await updateStoreItem({
                                    variables: { input: e },
                                  });
                                }}
                                render={({ values }) => (
                                  <Form>
                                    <StyledPanelFormWrapper>
                                      <FieldArray
                                        name="relatedItems"
                                        render={(arrayHelpers) => (
                                          <div>
                                            {values.relatedItems &&
                                            values.relatedItems.length ? (
                                              values.relatedItems.map(
                                                (file, index) => (
                                                  <S.Flex
                                                    mb={1}
                                                    alignItems="center"
                                                    key={index}
                                                  >
                                                    <div
                                                      style={{
                                                        flexBasis: "60%",
                                                      }}
                                                    >
                                                      <StoreItemsSelect
                                                        name={`relatedItems.${index}.id`}
                                                      />
                                                    </div>
                                                    <RepeatableFieldButton
                                                      type="button"
                                                      onClick={() => {
                                                        arrayHelpers.remove(
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <SVG.MinusSvg />
                                                    </RepeatableFieldButton>
                                                    <RepeatableFieldButton
                                                      type="button"
                                                      onClick={() => {
                                                        arrayHelpers.insert(
                                                          index + 1,
                                                          {
                                                            label: "",
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <SVG.PlusSvg />
                                                    </RepeatableFieldButton>
                                                  </S.Flex>
                                                )
                                              )
                                            ) : (
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  arrayHelpers.push({
                                                    label: "",
                                                  });
                                                }}
                                              >
                                                Add a related file.
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      />
                                      <button type="submit" disabled={loading}>
                                        Sav{loading ? "ing" : "e"}
                                      </button>
                                    </StyledPanelFormWrapper>
                                  </Form>
                                )}
                              />
                            );
                          }}
                        </Mutation>
                      )}
                    </>
                  )}
                </EditableContent>
              </S.Panel>
              {/* <Notes subject={file} />
            <ActivityFeed subject={file} /> */}
            </Page>
          </div>
        );
      }}
    </Query>
  );
};

export default StoreItem;
