import React from "react";
import Graph from "./Graph";
import TopNav from "./TopNav";
import { Tag } from "./Tags";
import { Query } from "@apollo/react-components";
import {
  MembershipListQueryData,
  SalesReportListQueryData,
  EventsListQueryData,
  StudentDesignSubmissionListQueryData,
  AchievementAwardSubmissionListQueryData,
} from "./apiTypes";
import { achievementAwardSubmissionListQuery } from "./queries/achievementAwardSubmission";
import EventsList from "./EventsList";
import { eventListQuery } from "./queries/event";
import { StudentDesignSubmissionListQuery } from "./queries/studentDesignSubmission";
import Page from "./Page";
import * as S from "./styles";
import { Link } from "react-router-dom";
import { membershipListQuery } from "./queries/membership";
import { salesReportListQuery } from "./queries/salesReport";

// Will need to have queries for all Pending Member Apps/Renewals, AA entries, Upcoming events
// Graph will need to pull in registration data for 4 different timeline types

const Dashboard = () => {
  return (
    <Page>
      <TopNav />
      <S.Flex flexWrap="wrap">
        <Applications />
        <AwardEntries />
      </S.Flex>
      <S.Panel>
        <Graph />
      </S.Panel>
      <Query<EventsListQueryData>
        query={eventListQuery}
        variables={{ first: 10, orderBy: "START_DESC" }}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return null;
          if (error) return <>Oops.</>;
          if (!data || !data.events || !Array.isArray(data.events.edges))
            return <>No data.</>;
          const events = data.events.edges.map((edge) => {
            return edge.node;
          });
          return <EventsList title events={events} />;
        }}
      </Query>
    </Page>
  );
};

const Applications = () => {
  return (
    <>
      <S.Box width={1 / 2}>
        <S.Panel height="100%">
          <S.H2>Member Applications</S.H2>
          <Query<MembershipListQueryData>
            query={membershipListQuery}
            variables={{
              first: 6,
              orderBy: "INSERTED_AT_DESC",
              filter: { applicationStatus: "PENDING" },
            }}
          >
            {({ loading, error, data }) => {
              if (loading && !data) return <></>;
              if (error)
                return <S.P>There was an issue loading the Applications.</S.P>;
              if (
                !data ||
                !data.memberships ||
                !Array.isArray(data.memberships.edges)
              )
                return <S.P>There are no new Applications at this time.</S.P>;
              return (
                <>
                  {data.memberships.edges && data.memberships.edges.length ? (
                    <S.GroupList>
                      {data.memberships.edges.map((edge) => {
                        const membership = edge.node;
                        if (!membership) return null;
                        var daysDifference = Math.floor(
                          (new Date().getTime() -
                            new Date(membership.insertedAt).getTime()) /
                            (1000 * 3600 * 24)
                        );
                        return (
                          <li key={membership.id}>
                            <Link
                              to={`/companies/${membership.company.id}/dues`}
                            >
                              <S.Title>
                                {membership.company.name}
                                <span>
                                  {membership.categories.map((category) => {
                                    return <Tag key={category}>{category}</Tag>;
                                  })}
                                  Applied{" "}
                                  {daysDifference === 0 ? (
                                    <>Today</>
                                  ) : daysDifference === 1 ? (
                                    <>1 Day Ago</>
                                  ) : (
                                    <>{`${daysDifference} Days Ago`}</>
                                  )}
                                </span>
                              </S.Title>
                            </Link>
                          </li>
                        );
                      })}
                    </S.GroupList>
                  ) : (
                    <S.P>There are no new Applications at this time.</S.P>
                  )}
                </>
              );
            }}
          </Query>
        </S.Panel>
      </S.Box>
      <S.Box width={1 / 2}>
        <S.Panel height="100%">
          <S.H2>Membership Renewals &amp; Dues</S.H2>
          <Query<SalesReportListQueryData>
            query={salesReportListQuery}
            variables={{
              first: 6,
              orderBy: "REPORTED_ON_DESC",
              filter: { status: "PENDING" },
            }}
          >
            {({ loading, error, data }) => {
              if (loading && !data) return <></>;
              if (error)
                return <S.P>There was an issue loading the Requests.</S.P>;
              if (
                !data ||
                !data.salesReports ||
                !Array.isArray(data.salesReports.edges)
              )
                return <S.P>There are no new Requests at this time.</S.P>;
              return (
                <>
                  {data.salesReports.edges && data.salesReports.edges.length ? (
                    <S.GroupList>
                      {data.salesReports.edges.map((edge) => {
                        const salesReport = edge.node;
                        if (!salesReport) return null;
                        var daysDifference = Math.floor(
                          (new Date().getTime() -
                            new Date(salesReport.reportedOn).getTime()) /
                            (1000 * 3600 * 24)
                        );
                        return (
                          <li key={salesReport.id}>
                            <Link
                              to={`/companies/${salesReport.company.id}/dues`}
                            >
                              <S.Title>
                                {salesReport.company.name}
                                <span>
                                  Reported{" "}
                                  {daysDifference === 0 ? (
                                    <>Today</>
                                  ) : daysDifference === 1 ? (
                                    <>1 Day Ago</>
                                  ) : (
                                    <>{`${daysDifference} Days Ago`}</>
                                  )}
                                </span>
                              </S.Title>
                            </Link>
                          </li>
                        );
                      })}
                    </S.GroupList>
                  ) : (
                    <S.P>There are no new Requests at this time.</S.P>
                  )}
                </>
              );
            }}
          </Query>
        </S.Panel>
      </S.Box>
    </>
  );
};

const AwardEntries = () => {
  const year = new Date().getFullYear() + 1;

  return (
    <>
      <S.Box width={1 / 2}>
        <S.Panel height="100%">
          <S.H2>Achievement Award Entries</S.H2>
          <Query<AchievementAwardSubmissionListQueryData>
            query={achievementAwardSubmissionListQuery}
            variables={{
              filter: {
                year: year,
              },
              first: 6,
              orderBy: "INSERTED_AT_DESC",
            }}
          >
            {({ loading, error, data }) => {
              if (loading && !data) return <>Loading...</>;
              if (error) return <S.P>No Entries Pending</S.P>;
              if (
                !data ||
                !data.achievementAwardSubmissions ||
                !Array.isArray(data.achievementAwardSubmissions.edges)
              )
                return <S.P>No Entries Pending</S.P>;
              return (
                <S.GroupList>
                  {data.achievementAwardSubmissions.count === 0 && <S.Box>No entries found.</S.Box>}
                  {data.achievementAwardSubmissions.edges.map((edge) => {
                    const achievementAwardSubmission = edge.node;
                    if (!achievementAwardSubmission) return <React.Fragment />;
                    var daysDifference = Math.floor(
                      (new Date().getTime() -
                        new Date(
                          achievementAwardSubmission.insertedAt
                        ).getTime()) /
                        (1000 * 3600 * 24)
                    );
                    return (
                      <li key={achievementAwardSubmission.id}>
                        <Link
                          to={`/awards/achievement/${achievementAwardSubmission.id}`}
                        >
                          <S.Title>
                            {achievementAwardSubmission.name}
                            <span>
                              Submitted{" "}
                              {daysDifference === 0 ? (
                                <>Today</>
                              ) : daysDifference === 1 ? (
                                <>1 Day Ago</>
                              ) : (
                                <>{`${daysDifference} Days Ago`}</>
                              )}
                            </span>
                          </S.Title>
                        </Link>
                      </li>
                    );
                  })}
                </S.GroupList>
              );
            }}
          </Query>
        </S.Panel>
      </S.Box>
      <S.Box width={1 / 2}>
        <S.Panel height="100%">
          <S.H2>Student Design Challenge Entries</S.H2>
          <Query<StudentDesignSubmissionListQueryData>
            query={StudentDesignSubmissionListQuery}
            variables={{
              filter: {
                status: undefined,
                hasConceptOutline: true,
                year: new Date().getFullYear() + 1,
              },
              first: 6,
              orderBy: "INSERTED_AT_DESC",
            }}
          >
            {({ loading, error, data }) => {
              if (loading && !data) return <>Loading...</>;
              if (error) return <S.P>No Entries Pending</S.P>;
              if (
                !data ||
                !data.studentDesignSubmissions ||
                !Array.isArray(data.studentDesignSubmissions.edges)
              )
                return <S.P>No Entries Pending</S.P>;
              return (
                <S.GroupList>
                  {data.studentDesignSubmissions.count === 0 && <S.Box>No entries found.</S.Box>}
                  {data.studentDesignSubmissions.edges.map((edge) => {
                    const studentDesignSubmission = edge.node;
                    if (!studentDesignSubmission) return <React.Fragment />;
                    var daysDifference = Math.floor(
                      (new Date().getTime() -
                        new Date(
                          studentDesignSubmission.insertedAt
                        ).getTime()) /
                        (1000 * 3600 * 24)
                    );
                    return (
                      <li key={studentDesignSubmission.id}>
                        <Link
                          to={`/awards/student/${studentDesignSubmission.id}`}
                        >
                          <S.Title>
                            {studentDesignSubmission.name}
                            <span>
                              Submitted{" "}
                              {daysDifference === 0 ? (
                                <>Today</>
                              ) : daysDifference === 1 ? (
                                <>1 Day Ago</>
                              ) : (
                                <>{`${daysDifference} Days Ago`}</>
                              )}
                            </span>
                          </S.Title>
                        </Link>
                      </li>
                    );
                  })}
                </S.GroupList>
              );
            }}
          </Query>
        </S.Panel>
      </S.Box>
    </>
  );
};

export default Dashboard;
