import { gql } from "graphql.macro";

export const createLocationMutation = gql`
  mutation createLocationMutation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      name
      types
      numEmployees
      company {
        id
      }
    }
  }
`;

export const archiveLocationMutation = gql`
  mutation archiveLocationMutation($input: ArchiveLocationInput!) {
    archiveLocation(input: $input) {
      id
    }
  }
`;

export const updateLocationMutation = gql`
  mutation updateLocationMutation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      name
      types
      numEmployees
      id
    }
  }
`;
