import { ApolloLink, Observable } from "apollo-link";
import {
  HttpOptions,
  selectURI,
  selectHttpOptionsAndBody,
  fallbackHttpConfig,
  serializeFetchParameter,
  createSignalIfSupported,
  parseAndCheckHttpResponse
} from "apollo-link-http-common";
import { extractFiles } from "../util";

export function createUploadLink({
  uri: fetchUri = "/graphql",
  fetch: linkFetch = fetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions
}: HttpOptions = {}) {
  const linkConfig = {
    http: { includeExtensions },
    options: fetchOptions,
    credentials,
    headers
  };

  return new ApolloLink((operation, forward) => {
    const uri = selectURI(operation, fetchUri);
    const context = operation.getContext();

    console.debug(context);

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: { ...context.headers }
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      linkConfig,
      contextConfig
    );

    const { clone, files } = extractFiles(body);

    if (files.size && body.query) {
      delete options.headers["content-type"];

      const form = new FormData();
      const variables = serializeFetchParameter(clone.variables, "Variables");

      form.append("query", clone.query);
      form.append("variables", variables);

      files.forEach((key, file) => {
        form.append(key, file, "name" in file ? file.name : undefined);
      });

      options.body = form;
    } else options.body = serializeFetchParameter(clone, "Payload");

    return new Observable(observer => {
      const { controller, signal } = createSignalIfSupported();
      if (controller) options.signal = signal;

      linkFetch(uri, options)
        .then((response: Response) => {
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then((result: any) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error: any) => {
          if (error.name === "AbortError") {
            // Fetch was aborted.
            return;
          }

          if (error.result && error.result.errors && error.result.data) {
            // There is a GraphQL result to forward.
            observer.next(error.result);
          }

          observer.error(error);
        });

      // Cleanup function.
      return () => {
        // Abort fetch.
        if (controller) controller.abort();
      };
    });
  });
}
