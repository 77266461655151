import React, { useState } from "react";
import moment from "moment";
import * as S from "./styles";
import * as SVG from "./svgs";
import Table, { TR, TD } from "./Table";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import ContactNav from "./ContactNav";
import { Contact } from "./apiTypes";

const ContactAnalytics: React.FunctionComponent<{ contact: Contact }> = ({
  contact
}) => {
  const googleAnalyticsViewId = "210998074";

  return <>Analytics here</>;
};

const ContactAnalyticsSummary: React.FunctionComponent<{
  contact: Contact;
}> = ({ contact }) => {
  return (
    <Table cols="2fr 1fr" mt={1}>
      <TR>
        <TD>
          <S.Title>
            /about-us<span>11/14/2018 12:30:03</span>
          </S.Title>
        </TD>
        <TD>Total Views: 3</TD>
      </TR>
      <TR>
        <TD>
          <S.Title>
            /about-us<span>11/14/2018 12:30:03</span>
          </S.Title>
        </TD>
        <TD>Total Views: 3</TD>
      </TR>
    </Table>
  );
};

export default ContactAnalytics;
export { ContactAnalyticsSummary };
