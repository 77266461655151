import { gql } from "graphql.macro";

export const createSalesReportMutation = gql`
  mutation createSalesReportMutation($input: CreateSalesReportInput!) {
    createSalesReport(input: $input) {
      id
      company {
        id
      }
      dollarAmount
      reportedOn
      status
      year
    }
  }
`;

export const updateSalesReportMutation = gql`
  mutation updateSalesReportMutation($input: UpdateSalesReportInput!) {
    updateSalesReport(input: $input) {
      id
      dollarAmount
      reportedOn
      status
      year
    }
  }
`;
