import React, { useState } from "react";
import * as S from "./styles";
import { Query } from "@apollo/react-components";
import { useMutation } from "@apollo/react-hooks";
import { Formik, Field, Form } from "formik";
import {
  StyledPanelFormWrapper
} from "./StyledForm";
import {
  CreateAchievementAwardCategoryValues,
  UpdateAchievementAwardCategoryValues,
  AchievementAwardCategory,
} from "./apiTypes";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import {
  createAchievementAwardCategoryMutation,
  updateAchievementAwardCategoryMutation,
} from "./mutations/achievementAwardSubmission";
import { achievementAwardCategoryListQuery } from "./queries/achievementAwardSubmission";

const SettingsAchievementAwardCategories: React.FunctionComponent<{}> = ({}) => {
  const addDialog = useDialogState();
  const editDialog = useDialogState();

  const [category, setCategory] = useState<AchievementAwardCategory | null>(
    null
  );

  return (
    <S.Box pb={2} pt={3} my={3} mx={3}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="center" justifyContent="space-between">
          Achievement Award Categories
          <DialogDisclosure {...addDialog} as={S.Button} size="small">
            Add an Award Category
          </DialogDisclosure>
        </S.Flex>
      </S.UnderlinedHeader>
      <Query<{ achievementAwardCategories: AchievementAwardCategory[] }>
        query={achievementAwardCategoryListQuery}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <S.Box />;
          if (error)
            return <S.Box>There are no award categories loaded.</S.Box>;
          if (!data || !data.achievementAwardCategories)
            return <S.Box>There are no award categories in the system.</S.Box>;
          return (
            <S.Flex flexWrap="wrap">
              {data.achievementAwardCategories.map((c) => (
                <S.Box width={1 / 2} my={2} key={c.id}>
                  <S.Panel my={0} mx={2}>
                    <S.UnderlinedHeader>
                      <S.Flex
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {c.name}
                        <DialogDisclosure
                          {...editDialog}
                          onClick={() => setCategory(c)}
                          as={S.Button}
                          size="small"
                        >
                          Edit
                        </DialogDisclosure>
                      </S.Flex>
                    </S.UnderlinedHeader>
                    <S.P>{c.description}</S.P>
                  </S.Panel>
                </S.Box>
              ))}
            </S.Flex>
          );
        }}
      </Query>
      <DialogWithBackdrop {...addDialog}>
        <AddCategory closeModal={() => addDialog.hide()} />
      </DialogWithBackdrop>
      {editDialog.visible && category && (
        <DialogWithBackdrop {...editDialog}>
          <EditCategory
            category={category}
            closeModal={() => editDialog.hide()}
          />
        </DialogWithBackdrop>
      )}
    </S.Box>
  );
};

const EditCategory: React.FunctionComponent<{
  category: AchievementAwardCategory;
  closeModal: () => void;
}> = ({ category, closeModal }) => {
  const [updateAchievementAwardCategory, { loading }] = useMutation<
    { updateAchievementAwardCategory: { id: string } },
    { input: UpdateAchievementAwardCategoryValues }
  >(updateAchievementAwardCategoryMutation, {
    refetchQueries: ["achievementAwardCategoryListQuery"],
  });

  return (
    <S.Box p={3} my={0}>
      <S.UnderlinedHeader>Update Store Category</S.UnderlinedHeader>
      <Formik<UpdateAchievementAwardCategoryValues>
        initialValues={{
          categoryId: category.id,
          name: category.name,
          description: category.description,
        }}
        onSubmit={async (values) => {
          let input: any = { ...values };
          await updateAchievementAwardCategory({
            variables: {
              input,
            },
          });
          closeModal();
        }}
        render={({ values }) => (
          <StyledPanelFormWrapper>
            <Form>
              <label>
                Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="name"
                name="name"
                required
                placeholder={"Name the Award Category."}
                type="text"
              />
              <label>
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="description"
                name="description"
                placeholder="A description of the awards category, viewed on the application."
                component="textarea"
              />
              <S.Flex alignItems="center">
                <S.Button type="submit" disabled={loading}>
                  Sav{loading ? "ing" : "e"} Category
                </S.Button>
              </S.Flex>
            </Form>
          </StyledPanelFormWrapper>
        )}
      />
    </S.Box>
  );
};

const AddCategory: React.FunctionComponent<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  const [createAchievementAwardCategory, { loading }] = useMutation<
    { createAchievementAwardCategory: { id: string } },
    { input: CreateAchievementAwardCategoryValues }
  >(createAchievementAwardCategoryMutation, {
    refetchQueries: ["achievementAwardCategoryListQuery"],
  });

  return (
    <S.Box p={3} my={0}>
      <S.UnderlinedHeader>Create Award Category</S.UnderlinedHeader>
      <Formik<CreateAchievementAwardCategoryValues>
        initialValues={{
          name: "",
          description: "",
        }}
        onSubmit={async (values) => {
          await createAchievementAwardCategory({
            variables: {
              input: values,
            },
          });
          closeModal();
        }}
        render={({ values }) => (
          <StyledPanelFormWrapper>
            <Form>
              <label htmlFor="name">
                Name
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="name"
                name="name"
                required
                placeholder={"Name the Award Category."}
                type="text"
              />
              <label>
                Description
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                required
                id="description"
                name="description"
                placeholder="A description of the award category, viewed on the application."
                component="textarea"
              />
              <S.Button type="submit" disabled={loading}>
                Sav{loading ? "ing" : "e"} Category
              </S.Button>
            </Form>
          </StyledPanelFormWrapper>
        )}
      />
    </S.Box>
  );
};

export default SettingsAchievementAwardCategories;
