import React, { useCallback, useMemo } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import queryString from "query-string";
import UnauthedMessage from "./UnauthedMessage";
import { useAuth } from "../contexts/Auth";
import { usePromise } from "../hooks";
import FullPageLoader from "../FullPageLoader";

function AuthExchanger({
  location
}: RouteComponentProps<{}>): React.ReactElement | null {
  const { isAuthenticated, exchangeAuthCode } = useAuth();
  const queryParams = useMemo(() => queryString.parse(location.search), [
    location.search
  ]);

  const code = Array.isArray(queryParams.code)
    ? queryParams.code[0]
    : queryParams.code;
  const state = Array.isArray(queryParams.state)
    ? queryParams.state[0]
    : queryParams.state;

  const exchange = useCallback<() => Promise<void>>(async () => {
    if (isAuthenticated) return;
    if (!code) return;
    return exchangeAuthCode(code);
  }, [isAuthenticated, exchangeAuthCode, code]);

  const { isSettled } = usePromise(exchange);

  if (!isSettled) {
    return <FullPageLoader />;
  }

  if (isAuthenticated) {
    return <Redirect to={state || "/"} />;
  }

  return <UnauthedMessage />;
}

export default React.memo(AuthExchanger);
