import React from "react";
import * as S from "./styles";
import moment from "moment";
import NewSalesReport from "./NewSalesReport";
import EditableContent from "./EditableContent";
import { CompanyInvoicesSummary } from "./InvoicesSummary";
import Notes, { ActivityFeed } from "./Notes";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import { Mutation } from "@apollo/react-components";
import { Tag } from "./Tags";
import { RouteComponentProps } from "react-router-dom";
import Table, { TR, TD } from "./Table";
import NumberFormat from "react-number-format";
import {
  StyledPanelFormWrapper,
  Select
} from "./StyledForm";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import { Formik, Form, Field } from "formik";
import {
  Company,
  SalesReport as SalesReportType,
  NewItemData,
  NewMembershipValues,
  UpdateMembershipValues,
  Membership,
} from "./apiTypes";
import { useMutation } from "@apollo/react-hooks";
import { updateSalesReportMutation } from "./mutations/salesReport";
import {
  createMembershipMutation,
  updateMembershipMutation,
} from "./mutations/membership";

type CompanyMembershipProps = RouteComponentProps<{ companyId: string }> & {
  company: Company;
};

const CompanyMembership: React.FunctionComponent<CompanyMembershipProps> = ({
  company,
}) => {
  const newSalesDialog = useDialogState();
  const newDialog = useDialogState();

  const membershipApplications =
    company.memberships &&
    company.memberships.length &&
    company.memberships.filter(
      (membership) =>
        membership.applicationStatus &&
        membership.applicationStatus === "PENDING"
    );
  
  const recentSalesReport =
    company.salesReports &&
    company.salesReports.length &&
    company.salesReports[company.salesReports.length - 1];

  const salesReports = company.salesReports.sort((a, b) => {
    return b.year - a.year;
  });

  return (
    <PageWithSidePanel thirds>
      <Page>
        {company.activeMembership ? (
          <S.Panel pb={2}>
            <EditableContent>
              {({ isOpen, open, close }) => (
                <>
                  <S.UnderlinedHeader noMargin>
                    <S.Flex
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      Active Membership
                      {!isOpen ? (
                        <S.Button size="small" onClick={open}>
                          Edit
                        </S.Button>
                      ) : (
                        <S.Button size="small" onClick={close}>
                          Cancel
                        </S.Button>
                      )}
                    </S.Flex>
                  </S.UnderlinedHeader>
                  {!isOpen ? (
                    <>
                       <div>
                        <MembershipTags recentSalesReport={recentSalesReport || undefined} membership={company.activeMembership} />
                      </div>
                      <S.Title>
                        Since{" "}
                        {moment(company.activeMembership.startedOn).format(
                          "MMMM D, YYYY"
                        )}
                      </S.Title>
                      <Table>
                        <TR borderless>
                          <TD>Membership Privileges</TD>
                          <TD>
                            <S.P>
                              {company.activeMembership
                                .deactivateWhenDelinquent ? (
                                <>Standard</>
                              ) : (
                                <>Member Access Locked Open</>
                              )}
                            </S.P>
                          </TD>
                        </TR>
                        <TR borderless>
                          <TD>Payment Interval</TD>
                          <TD>
                            <S.P>
                              {company.activeMembership.paymentInterval}
                            </S.P>
                          </TD>
                        </TR>
                        <TR borderless>
                          <TD>Membership Easement</TD>
                          <TD>
                            <S.P>
                              {company.activeMembership.easeInto ? `Yes` : "No"}
                              {company.activeMembership.easeInto &&
                              company.activeMembership.yearsToEase === 2 ? (
                                <span>First Easement Year (66% discount)</span>
                              ) : company.activeMembership.easeInto &&
                                company.activeMembership.yearsToEase === 1 ? (
                                <span>Second Easement Year (33% discount)</span>
                              ) : (
                                <></>
                              )}
                            </S.P>
                          </TD>
                        </TR>
                        <TR borderless>
                          <TD>Application Submitted On</TD>
                          <TD>
                            <S.P>
                              {moment(
                                company.activeMembership.insertedAt
                              ).format("MMMM D, YYYY h:mma")}
                            </S.P>
                          </TD>
                        </TR>
                        {company.activeMembership.updatedAt !==
                        company.activeMembership.insertedAt ? (
                          <TR borderless>
                            <TD>Last Updated</TD>
                            <TD>
                              <S.P>
                                {moment(
                                  company.activeMembership.updatedAt
                                ).format("MMMM D, YYYY h:ma")}
                              </S.P>
                            </TD>
                          </TR>
                        ) : (
                          <></>
                        )}
                      </Table>
                    </>
                  ) : (
                    <Mutation<any>
                      mutation={updateMembershipMutation}
                      refetchQueries={["companyQuery"]}
                    >
                      {(updateMembership, { loading }) => (
                        <Formik<UpdateMembershipValues>
                          initialValues={
                            company.activeMembership
                              ? {
                                  membershipId: company.activeMembership.id,
                                  category:
                                    company.activeMembership.categories[0],
                                  categories:
                                    company.activeMembership.categories,
                                  paymentInterval:
                                    company.activeMembership.paymentInterval,
                                  deactivateWhenDelinquent:
                                    company.activeMembership
                                      .deactivateWhenDelinquent,
                                  easeInto: company.activeMembership.easeInto,
                                  yearsToEase:
                                    company.activeMembership.yearsToEase,
                                  startedOn: company.activeMembership.startedOn,
                                  applicationStatus:
                                    company.activeMembership.applicationStatus,
                                  endedOn: company.activeMembership.endedOn,
                                }
                              : {
                                  membershipId: company.memberships[0].id,
                                  category:
                                    company.memberships[0].categories[0],
                                  categories: company.memberships[0].categories,
                                  paymentInterval:
                                    company.memberships[0].paymentInterval,
                                  deactivateWhenDelinquent:
                                    company.memberships[0]
                                      .deactivateWhenDelinquent,
                                  easeInto: company.memberships[0].easeInto,
                                  yearsToEase:
                                    company.memberships[0].yearsToEase,
                                  startedOn: company.memberships[0].startedOn,
                                  applicationStatus:
                                    company.memberships[0].applicationStatus,
                                }
                          }
                          onSubmit={async (values) => {
                            values.categories = values.category
                              ? values.category.split(",")
                              : [];
                            if (values.yearsToEase && values.yearsToEase > 0) {
                              values.easeInto = true;
                            } else {
                              values.easeInto = false;
                            }
                            if (
                              values.applicationStatus === "APPROVED" &&
                              !company.activeMembership &&
                              company.memberships[0].applicationStatus !=
                                "APPROVED"
                            ) {
                              values.startedOn = moment(new Date()).format(
                                "YYYY-MM-DD"
                              );
                            }
                            if (values.endedOn && values.endedOn !== "") {
                              values.endedOn = moment(values.endedOn).format(
                                "YYYY-MM-DD"
                              );
                            }
                            try {
                              await updateMembership({
                                variables: {
                                  input: {
                                    membershipId: values.membershipId,
                                    categories: values.categories,
                                    paymentInterval: values.paymentInterval,
                                    deactivateWhenDelinquent:
                                      values.deactivateWhenDelinquent,
                                    easeInto: values.easeInto,
                                    yearsToEase: values.yearsToEase,
                                    startedOn: values.startedOn,
                                    endedOn: values.endedOn || null,
                                    applicationStatus: values.applicationStatus,
                                  },
                                },
                              });
                              close();
                            } catch (err) {
                              throw err;
                            }
                          }}
                          render={({ values }) => (
                            <StyledPanelFormWrapper>
                              <Form>
                                <label htmlFor="category">
                                  Membership Categories
                                  <span
                                    style={{
                                      color: "red",
                                      display: "inline-block",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <Field
                                  required
                                  id="category"
                                  name="category"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "Academic",
                                      value: "Academic",
                                    },
                                    { label: "Associate", value: "Associate" },
                                    { label: "Converter", value: "Converter" },
                                    {
                                      label: "International Converter",
                                      value: "International",
                                    },
                                    {
                                      label: "Trade Press",
                                      value: "Trade Press",
                                    },
                                    {
                                      label: "Supporting (Banking Sector)",
                                      value: "Supporting - Banking",
                                    },
                                    {
                                      label: "Supporting (Other Sectors)",
                                      value: "Supporting",
                                    },
                                  ]}
                                />
                                <label htmlFor="paymentInterval">
                                  Payment Interval
                                </label>
                                <Field
                                  required
                                  id="paymentInterval"
                                  name="paymentInterval"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    { label: "Annual", value: "annual" },
                                    {
                                      label: "Semi-Annual",
                                      value: "semi_annual",
                                    },
                                    { label: "Quarterly", value: "quarterly" },
                                  ]}
                                />
                                <label htmlFor="yearsToEase">
                                  Membership Dues Easement
                                </label>
                                <Field
                                  required
                                  id="yearsToEase"
                                  name="yearsToEase"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "No Easements",
                                      value: 0,
                                    },
                                    {
                                      label:
                                        "Easement Year 1 (66% discount this year, 33% next year)",
                                      value: 2,
                                    },
                                    {
                                      label:
                                        "Easement Year 2 (33% discount, full next year)",
                                      value: 1,
                                    },
                                  ]}
                                />
                                <label>Membership Start Date</label>
                                <Field
                                  id="startedOn"
                                  name="startedOn"
                                  type="date"
                                  value={
                                    values.startedOn
                                      ? moment(values.startedOn).format(
                                          "YYYY-MM-DD"
                                        )
                                      : undefined
                                  }
                                />
                                <label>Membership End Date</label>
                                <Field
                                  id="endedOn"
                                  name="endedOn"
                                  type="date"
                                  value={
                                    values.endedOn
                                      ? moment(values.endedOn).format(
                                          "YYYY-MM-DD"
                                        )
                                      : undefined
                                  }
                                />
                                <S.Flex>
                                  <Field
                                    id="deactivateWhenDelinquent"
                                    name="deactivateWhenDelinquent"
                                    type="checkbox"
                                  />
                                  <label htmlFor="deactivateWhenDelinquent">
                                    Lock Member Only Access When Past Due
                                  </label>
                                </S.Flex>
                                <S.Flex>
                                  <button type="submit" disabled={loading}>
                                    Updat{loading ? "ing" : "e"} Membership
                                  </button>
                                  <button onClick={close} disabled={loading}>
                                    Cancel{loading ? "ling" : ""}
                                  </button>
                                </S.Flex>
                              </Form>
                            </StyledPanelFormWrapper>
                          )}
                        />
                      )}
                    </Mutation>
                  )}
                </>
              )}
            </EditableContent>
          </S.Panel>
        ) : membershipApplications && membershipApplications.length ? (
          <S.Panel pb={2}>
            <EditableContent>
              {({ isOpen, open, close }) => (
                <>
                  <S.UnderlinedHeader noMargin>
                    <S.Flex
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      Membership Application
                      {!isOpen ? (
                        <div>
                          {!recentSalesReport || (recentSalesReport && recentSalesReport.status === "PENDING") ? (
                            <DialogDisclosure
                              {...newDialog}
                              size="small"
                              style={{ marginRight: "10px" }}
                              as={S.Button}
                            >
                              Add Sales Report
                            </DialogDisclosure>
                          ) : (
                            <></>
                          )}
                          <S.Button size="small" onClick={open}>
                            Edit
                          </S.Button>
                        </div>
                      ) : (
                        <S.Button size="small" onClick={close}>
                          Cancel
                        </S.Button>
                      )}
                    </S.Flex>
                  </S.UnderlinedHeader>
                  {!isOpen ? (
                    <>
                      <MembershipTags membership={company.memberships && company.memberships[0]} recentSalesReport={recentSalesReport || undefined} />
                      <Table>
                        <TR borderless>
                          <TD>Application Submitted On</TD>
                          <TD>
                            <S.P>
                              {moment(company.memberships[0].insertedAt).format(
                                "MMMM D, YYYY h:mma"
                              )}
                            </S.P>
                          </TD>
                        </TR>
                        {company.memberships[0].updatedAt !==
                        company.memberships[0].insertedAt ? (
                          <TR borderless>
                            <TD>Last Updated</TD>
                            <TD>
                              <S.P>
                                {moment(
                                  company.memberships[0].updatedAt
                                ).format("MMMM D, YYYY h:ma")}
                              </S.P>
                            </TD>
                          </TR>
                        ) : (
                          <></>
                        )}
                        <TR borderless>
                          <TD>Membership Privileges</TD>
                          <TD>
                            <S.P>
                              {company.memberships[0]
                                .deactivateWhenDelinquent ? (
                                <>Standard</>
                              ) : (
                                <>Member Access Locked Open</>
                              )}
                            </S.P>
                          </TD>
                        </TR>
                        <TR borderless>
                          <TD>Payment Interval</TD>
                          <TD>
                            <S.P>{company.memberships[0].paymentInterval}</S.P>
                          </TD>
                        </TR>
                        <TR borderless>
                          <TD>Membership Easement</TD>
                          <TD>
                            <S.P>
                              {company.memberships[0].easeInto ? "Yes" : "No"}
                              {company.memberships[0].easeInto &&
                              company.memberships[0].yearsToEase === 2 ? (
                                <span>First Easement Year (66% discount)</span>
                              ) : company.memberships[0].easeInto &&
                                company.memberships[0].yearsToEase === 1 ? (
                                <span>Second Easement Year (33% discount)</span>
                              ) : (
                                <></>
                              )}
                            </S.P>
                          </TD>
                        </TR>
                      </Table>
                      <DialogWithBackdrop {...newDialog}>
                        <NewSalesReport companyId={company.id} />
                      </DialogWithBackdrop>
                    </>
                  ) : (
                    <Mutation<any>
                      mutation={updateMembershipMutation}
                      refetchQueries={["companyQuery"]}
                    >
                      {(updateMembership, { loading }) => (
                        <Formik<UpdateMembershipValues>
                          initialValues={
                            company.activeMembership
                              ? {
                                  membershipId: company.activeMembership.id,
                                  category:
                                    company.activeMembership.categories[0],
                                  categories:
                                    company.activeMembership.categories,
                                  paymentInterval:
                                    company.activeMembership.paymentInterval,
                                  deactivateWhenDelinquent:
                                    company.activeMembership
                                      .deactivateWhenDelinquent,
                                  easeInto: company.activeMembership.easeInto,
                                  yearsToEase:
                                    company.activeMembership.yearsToEase,
                                  startedOn: company.activeMembership.startedOn,
                                  applicationStatus:
                                    company.activeMembership.applicationStatus,
                                }
                              : {
                                  membershipId: company.memberships[0].id,
                                  category: "",
                                  categories: company.memberships[0].categories,
                                  paymentInterval:
                                    company.memberships[0].paymentInterval,
                                  deactivateWhenDelinquent:
                                    company.memberships[0]
                                      .deactivateWhenDelinquent,
                                  easeInto: company.memberships[0].easeInto,
                                  yearsToEase:
                                    company.memberships[0].yearsToEase,
                                  startedOn: company.memberships[0].startedOn,
                                  applicationStatus:
                                    company.memberships[0].applicationStatus,
                                }
                          }
                          onSubmit={async (values) => {
                            values.categories = values.category
                              ? values.category.split(",")
                              : [];
                            if (values.yearsToEase && values.yearsToEase > 0) {
                              values.easeInto = true;
                            } else {
                              values.easeInto = false;
                            }
                            if (
                              values.applicationStatus === "APPROVED" &&
                              !company.activeMembership &&
                              company.memberships[0].applicationStatus !=
                                "APPROVED"
                            ) {
                              values.startedOn = moment(new Date()).format(
                                "YYYY-MM-DD"
                              );
                            }
                            try {
                              await updateMembership({
                                variables: {
                                  input: {
                                    membershipId: values.membershipId,
                                    categories: values.categories,
                                    paymentInterval: values.paymentInterval,
                                    deactivateWhenDelinquent:
                                      values.deactivateWhenDelinquent,
                                    easeInto: values.easeInto,
                                    yearsToEase: values.yearsToEase,
                                    startedOn: values.startedOn,
                                    applicationStatus: values.applicationStatus,
                                  },
                                },
                              });
                              close();
                            } catch (err) {
                              throw err;
                            }
                          }}
                          render={({ values }) => (
                            <StyledPanelFormWrapper>
                              <Form>
                                <label htmlFor="category">
                                  Membership Categories
                                  <span
                                    style={{
                                      color: "red",
                                      display: "inline-block",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <Field
                                  required
                                  id="category"
                                  name="category"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "Academic",
                                      value: "Academic",
                                    },
                                    {
                                      label: "Associate",
                                      value: "Associate",
                                    },
                                    {
                                      label: "Converter",
                                      value: "Converter",
                                    },
                                    {
                                      label: "International Converter",
                                      value: "International",
                                    },
                                    {
                                      label: "Trade Press",
                                      value: "Trade Press",
                                    },
                                    {
                                      label: "Supporting (Banking Sector)",
                                      value: "Supporting - Banking",
                                    },
                                    {
                                      label: "Supporting (Other Sectors)",
                                      value: "Supporting",
                                    },
                                  ]}
                                />
                                <label>
                                  Application Status
                                  <span>
                                    WARNING: If membership is approved without a
                                    sales report for this year, then an invoice
                                    will not be automatically generated.
                                  </span>
                                </label>
                                <Field
                                  id="applicationStatus"
                                  name="applicationStatus"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "PENDING",
                                      value: "PENDING",
                                    },
                                    { label: "APPROVED", value: "APPROVED" },
                                    { label: "DECLINED", value: "DECLINED" },
                                  ]}
                                />
                                <label htmlFor="paymentInterval">
                                  Payment Interval
                                </label>
                                <Field
                                  required
                                  id="paymentInterval"
                                  name="paymentInterval"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    { label: "Annual", value: "annual" },
                                    {
                                      label: "Semi-Annual",
                                      value: "semi_annual",
                                    },
                                    {
                                      label: "Quarterly",
                                      value: "quarterly",
                                    },
                                  ]}
                                />
                                <label htmlFor="yearsToEase">
                                  Membership Dues Easement
                                </label>
                                <Field
                                  required
                                  id="yearsToEase"
                                  name="yearsToEase"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "No Easements",
                                      value: 0,
                                    },
                                    {
                                      label:
                                        "Easement Year 1 (66% discount this year, 33% next year)",
                                      value: 2,
                                    },
                                    {
                                      label:
                                        "Easement Year 2 (33% discount, full next year)",
                                      value: 1,
                                    },
                                  ]}
                                />
                                <S.Flex>
                                  <Field
                                    id="deactivateWhenDelinquent"
                                    name="deactivateWhenDelinquent"
                                    type="checkbox"
                                  />
                                  <label htmlFor="deactivateWhenDelinquent">
                                    Lock Member Only Access When Past Due
                                  </label>
                                </S.Flex>
                                <S.Flex>
                                  <button type="submit" disabled={loading}>
                                    Updat{loading ? "ing" : "e"} Membership
                                  </button>
                                  <button onClick={close} disabled={loading}>
                                    Cancel{loading ? "ling" : ""}
                                  </button>
                                </S.Flex>
                              </Form>
                            </StyledPanelFormWrapper>
                          )}
                        />
                      )}
                    </Mutation>
                  )}
                </>
              )}
            </EditableContent>
          </S.Panel>
        ) : (
          <S.Panel pb={2}>
            <EditableContent>
              {({ isOpen, open, close }) => (
                <>
                  <S.UnderlinedHeader noMargin>
                    <S.Flex
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      Active Membership
                      {!isOpen ? (
                        <S.Button size="small" onClick={open}>
                          Add
                        </S.Button>
                      ) : (
                        <S.Button size="small" onClick={close}>
                          Cancel
                        </S.Button>
                      )}
                    </S.Flex>
                  </S.UnderlinedHeader>
                  {!isOpen ? (
                    <S.P>This company does not have an active membership.</S.P>
                  ) : (
                    <Mutation<NewItemData, { input: NewMembershipValues }>
                      mutation={createMembershipMutation}
                      refetchQueries={["companyQuery"]}
                    >
                      {(createMembership, { loading }) => (
                        <Formik<NewMembershipValues>
                          initialValues={{
                            category: "",
                            applicationStatus: "PENDING",
                            categories: [""],
                            paymentInterval: "annual",
                            deactivateWhenDelinquent: true,
                            easeInto: false,
                            yearsToEase: 0,
                            companyId: company.id,
                          }}
                          onSubmit={async (values) => {
                            values.categories = values.category
                              ? values.category.split(", ")
                              : [];
                            if (values.yearsToEase && values.yearsToEase > 0) {
                              values.easeInto = true;
                            } else {
                              values.easeInto = false;
                            }
                            try {
                              if (values.applicationStatus !== "PENDING") {
                                await createMembership({
                                  variables: {
                                    input: {
                                      companyId: values.companyId,
                                      categories: values.categories,
                                      paymentInterval: values.paymentInterval,
                                      deactivateWhenDelinquent:
                                        values.deactivateWhenDelinquent,
                                      easeInto: values.easeInto,
                                      yearsToEase: values.yearsToEase,
                                      applicationStatus:
                                        values.applicationStatus,
                                      startedOn: moment(new Date()).format(
                                        "YYYY-MM-DD"
                                      ),
                                    },
                                  },
                                });
                              } else {
                                await createMembership({
                                  variables: {
                                    input: {
                                      companyId: values.companyId,
                                      categories: values.categories,
                                      paymentInterval: values.paymentInterval,
                                      deactivateWhenDelinquent:
                                        values.deactivateWhenDelinquent,
                                      easeInto: values.easeInto,
                                      yearsToEase: values.yearsToEase,
                                      startedOn: values.startedOn,
                                      applicationStatus:
                                        values.applicationStatus,
                                    },
                                  },
                                });
                              }
                              close();
                            } catch (err) {
                              throw err;
                            }
                          }}
                          render={({ values }) => (
                            <StyledPanelFormWrapper>
                              <Form>
                                <label htmlFor="category">
                                  Membership Categories
                                  <span
                                    style={{
                                      color: "red",
                                      display: "inline-block",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <Field
                                  required
                                  id="category"
                                  name="category"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "Academic",
                                      value: "Academic",
                                    },
                                    {
                                      label: "Associate",
                                      value: "Associate",
                                    },
                                    {
                                      label: "Converter",
                                      value: "Converter",
                                    },
                                    {
                                      label: "International Converter",
                                      value: "International",
                                    },
                                    {
                                      label: "Trade Press",
                                      value: "Trade Press",
                                    },
                                    {
                                      label: "Supporting (Banking Sector)",
                                      value: "Supporting - Banking",
                                    },
                                    {
                                      label: "Supporting (Other Sectors)",
                                      value: "Supporting",
                                    },
                                  ]}
                                />
                                <label>
                                  Application Status
                                  <span>
                                    WARNING: If membership is approved without a
                                    sales report for this year, then an invoice
                                    will not be automatically generated.
                                  </span>
                                </label>
                                <Field
                                  id="applicationStatus"
                                  name="applicationStatus"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "PENDING",
                                      value: "PENDING",
                                    },
                                    { label: "APPROVED", value: "APPROVED" },
                                    { label: "DECLINED", value: "DECLINED" },
                                  ]}
                                />
                                <label htmlFor="paymentInterval">
                                  Payment Interval
                                </label>
                                <Field
                                  required
                                  id="paymentInterval"
                                  name="paymentInterval"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    { label: "Annual", value: "annual" },
                                    {
                                      label: "Semi-Annual",
                                      value: "semi_annual",
                                    },
                                    { label: "Quarterly", value: "quarterly" },
                                  ]}
                                />
                                <label htmlFor="yearsToEase">
                                  Membership Dues Easement
                                </label>
                                <Field
                                  required
                                  id="yearsToEase"
                                  name="yearsToEase"
                                  component={Select}
                                  closeMenuOnSelect={true}
                                  options={[
                                    {
                                      label: "No Easements",
                                      value: 0,
                                    },
                                    {
                                      label:
                                        "Easement Year 1 (66% discount this year, 33% next year)",
                                      value: 2,
                                    },
                                    {
                                      label:
                                        "Easement Year 2 (33% discount, full next year)",
                                      value: 1,
                                    },
                                  ]}
                                />
                                <S.Flex>
                                  <Field
                                    id="deactivateWhenDelinquent"
                                    name="deactivateWhenDelinquent"
                                    type="checkbox"
                                  />
                                  <label htmlFor="deactivateWhenDelinquent">
                                    Lock Member Only Access When Past Due
                                  </label>
                                </S.Flex>
                                <S.Flex>
                                  <button type="submit" disabled={loading}>
                                    Creat{loading ? "ing" : "e"} Membership
                                  </button>
                                  <button onClick={close} disabled={loading}>
                                    Cancel{loading ? "ling" : ""}
                                  </button>
                                </S.Flex>
                              </Form>
                            </StyledPanelFormWrapper>
                          )}
                        />
                      )}
                    </Mutation>
                  )}
                </>
              )}
            </EditableContent>
          </S.Panel>
        )}
        {company.memberships && company.memberships.length ? (
          <S.Panel pb={2}>
            <S.UnderlinedHeader noMargin>Membership History</S.UnderlinedHeader>
            <S.GroupList>
              {company.memberships.map((membership) => {
                if (!membership) return null;
                return (
                  <React.Fragment key={membership.id}>
                    {membership.categories && membership.categories.length ? (
                      membership.categories.map((category) => (
                        <Tag key={category}>{category}</Tag>
                      ))
                    ) : (
                      <Tag color="lightorange">Uncategorized Membership</Tag>
                    )}
                    <S.Title>
                      {(membership.startedOn &&
                        moment(membership.startedOn).format("MMMM D, YYYY")) ||
                        "Pending"}
                      {membership.endedOn && membership.startedOn
                        ? " - " +
                          moment(membership.endedOn).format("MMMM D, YYYY")
                        : membership.startedOn
                        ? " - Present"
                        : ""}
                    </S.Title>
                    <Table>
                      {membership.updatedAt !== membership.insertedAt ? (
                        <TR borderless>
                          <TD>Last Updated</TD>
                          <TD>
                            <S.P>
                              {moment(membership.updatedAt).format(
                                "MMMM D, YYYY h:mma"
                              )}
                            </S.P>
                          </TD>
                        </TR>
                      ) : (
                        <></>
                      )}
                      <TR borderless>
                        <TD>Membership Privileges</TD>
                        <TD>
                          <S.P>
                            {membership.deactivateWhenDelinquent ? (
                              <>Standard</>
                            ) : (
                              <>Member Access Locked Open</>
                            )}
                          </S.P>
                        </TD>
                      </TR>
                      <TR borderless>
                        <TD>Payment Interval</TD>
                        <TD>
                          <S.P>{membership.paymentInterval}</S.P>
                        </TD>
                      </TR>
                      <TR borderless>
                        <TD>Membership Easement</TD>
                        <TD>
                          <S.P>
                            {membership.easeInto ? "Yes" : "No"}
                            {membership.easeInto &&
                            membership.yearsToEase === 2 ? (
                              <span>First Easement Year (66% discount)</span>
                            ) : membership.easeInto &&
                              membership.yearsToEase === 1 ? (
                              <span>Second Easement Year (33% discount)</span>
                            ) : (
                              <></>
                            )}
                          </S.P>
                        </TD>
                      </TR>
                    </Table>
                  </React.Fragment>
                );
              })}
            </S.GroupList>
          </S.Panel>
        ) : (
          <></>
        )}
        <CompanyInvoicesSummary company={company} />
        <Notes subject={company} />
        <ActivityFeed subject={company} />
      </Page>
      <PageSidePanel id="side-panel">
        <S.Box>
          <S.UnderlinedHeader noMargin>
            <S.Flex alignItems="flex-end" justifyContent="space-between">
              Reported Sales &amp; Dues
              <DialogDisclosure {...newSalesDialog} as={S.Button} size="small">
                Add Sales Report
              </DialogDisclosure>
            </S.Flex>
          </S.UnderlinedHeader>
          <Table cols="200px 1fr">
            {salesReports.map((report) => {
              return <SalesReport key={report.id} report={report} />;
            })}
          </Table>
        </S.Box>
        <DialogWithBackdrop {...newSalesDialog}>
          <NewSalesReport companyId={company.id} />
        </DialogWithBackdrop>
      </PageSidePanel>
    </PageWithSidePanel>
  );
};

type MembershipTagProps = {
  membership?: Membership;
  recentSalesReport?: SalesReportType;
};

const MembershipTags: React.FunctionComponent<MembershipTagProps> = ({
  membership,
  recentSalesReport,
}) => {
  const today = new Date();
  const todayMonth = today.getMonth();
  // If todayMonth > (10) November => expected duesYear is today's year; if todayMonth >= (0) January && todayMonth < 10 => expected duesYear is previous year
  let duesYear = today.getFullYear();
  if (todayMonth >=0 && todayMonth < 10) {
    duesYear--;
  }
  // Dues process starting Nov 1 - last Feb in the next year
  const openDate = moment(new Date(duesYear, 10, 1));
  let closeDate = moment(new Date(duesYear+1, 2, 1));
  closeDate.subtract(1, 'days');
  let day = parseInt(closeDate.format('d'));
  while (day !== 5) {
    closeDate.subtract(1, 'days');
    day = parseInt(closeDate.format('d'));
  }

  return (
    <>
      {membership ? (
        <>
          {membership.categories && membership.categories.map((category) => (
            <Tag key={category}>{category}</Tag>
          ))}
          {membership.applicationStatus === "APPROVED" ? (
            <>
              {!membership.endedOn || membership.endedOn === "" ? (
                // Membership active
                <>
                  {/* Need additional check on member flag for if pastdue */}
                  {membership && recentSalesReport ? (
                    <>
                    {recentSalesReport.status === "PENDING" ? (
                        <Tag color="lightorange">SALES PENDING</Tag>
                      ) : recentSalesReport.status === "REJECTED" ? (
                        <Tag color="lightyellow">SALES DECLINED</Tag>
                      ) : recentSalesReport.status === "APPROVED" ? (
                        <Tag>SALES APPROVED</Tag>
                      ) : (
                        <Tag color="lightorange">NEED SALES</Tag>
                      )}
                      </>
                  ) : !recentSalesReport && moment(today) >= openDate && moment(today) <= closeDate ? (
                    <Tag color="lightorange">NEED SALES</Tag>
                  ) : (
                    <></>
                  )}
                </>
              ) : membership.endedOn && membership.endedOn !== "" && moment(membership.endedOn) < moment(today) ? (
                // Membership ended
                <Tag color="lightyellow">Membership Ended</Tag>
              ) : (
                // Membership ending
                <Tag color="lightyellow">Membership Cancelled</Tag>
              )}
              {membership.easeInto && (
                <Tag color="lightpurple">
                  Eased – Year{" "}
                  {membership.yearsToEase &&
                  membership.yearsToEase > 0
                    ? 3 - membership.yearsToEase
                    : ""}
                </Tag>
              )}
            </>
          ) : membership.applicationStatus === "DECLINED" ? (
            <Tag color="lightyellow">APPLICATION DENIED</Tag>
          ) : (
            // Pending application
            <>
              <Tag color="lightorange">APPLICATION PENDING</Tag>
              {recentSalesReport && recentSalesReport.status === "PENDING" ? (
                <Tag color="lightorange">SALES PENDING</Tag>
              ) : recentSalesReport && recentSalesReport.status === "REJECTED" ? (
                <Tag color="lightyellow">SALES DECLINED</Tag>
              ) : recentSalesReport && recentSalesReport.status === "APPROVED" ? (
                <Tag>SALES APPROVED</Tag>
              ) : (
                <Tag color="lightorange">NEED SALES</Tag>
              )}
            </>
          )}
        </>
      ) : (
        // No membership
        <></>
      )}
    </>
  );
}

const SalesReport: React.FunctionComponent<{ report: SalesReportType }> = ({
  report,
}) => {
  const editDialog = useDialogState();
  const [updateSalesReport, { loading }] = useMutation(
    updateSalesReportMutation,
    {
      refetchQueries: ["companyQuery"],
    }
  );

  return (
    <>
      <TR py={2}>
        <TD>
          <S.Title>
            {report.year}: {report.status}
            <span>
              Reported on {moment(report.reportedOn).format("MM/D/YYYY")}
            </span>
            {report.updatedAt !== report.insertedAt && (
              <span>
                Updated on {moment(report.updatedAt).format("MM/D/YYYY h:mma")}
              </span>
            )}
            {report.status === "PENDING" && (
              <DialogDisclosure {...editDialog} as={S.Button} size="small">
                Edit Report
              </DialogDisclosure>
            )}
          </S.Title>
        </TD>
        <TD>
          <NumberFormat
            value={report.dollarAmount}
            fixedDecimalScale
            decimalScale={2}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </TD>
      </TR>
      <DialogWithBackdrop {...editDialog}>
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>Edit Sales Report</S.UnderlinedHeader>
          <Formik
            initialValues={{
              salesReportId: report.id,
              dollarAmount: report.dollarAmount,
              reportedOn: report.reportedOn,
              status: report.status,
              year: report.year,
            }}
            onSubmit={async (values) => {
              await updateSalesReport({
                variables: {
                  input: { ...values },
                },
              });
              editDialog.hide();
            }}
            render={({ values }) => (
              <StyledPanelFormWrapper>
                <Form>
                  <label htmlFor="year">Sales Year</label>
                  <Field id="year" name="year" type="number" />
                  <label>Reported On</label>
                  <Field
                    id="reportedOn"
                    name="reportedOn"
                    type="date"
                    value={
                      values.reportedOn
                        ? moment(values.reportedOn).format("YYYY-MM-DD")
                        : undefined
                    }
                  />
                  <label htmlFor="dollarAmount">US Sales for this Year</label>
                  <S.Flex alignItems="center">
                    $
                    <Field
                      id="dollarAmount"
                      name="dollarAmount"
                      type="number"
                    />
                  </S.Flex>
                  <label>Report Status</label>
                  <Field
                    id="status"
                    name="status"
                    component={Select}
                    closeMenuOnSelect={true}
                    options={[
                      {
                        label: "PENDING",
                        value: "PENDING",
                      },
                      { label: "APPROVED", value: "APPROVED" },
                      { label: "REJECTED", value: "REJECTED" },
                    ]}
                  />
                  <S.Button type="submit" disabled={loading}>
                    Updat{loading ? "ing" : "e"} Sales Report
                  </S.Button>
                </Form>
              </StyledPanelFormWrapper>
            )}
          />
        </S.Box>
      </DialogWithBackdrop>
    </>
  );
};



export default CompanyMembership;
export { MembershipTags };
