import { gql } from "graphql.macro";

export const archiveNoteMutation = gql`
  mutation archiveNoteMutation($input: ArchiveNoteInput!) {
    archiveNote(input: $input) {
      id
    }
  }
`;

export const createNoteMutation = gql`
  mutation createNoteMutation($input: CreateNoteInput!) {
    createNote(input: $input) {
      body
      subjectId
    }
  }
`;

export const updateNoteMutation = gql`
  mutation updateNoteMutation($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      body
      noteId
    }
  }
`;

export const unarchiveNoteMutation = gql`
  mutation unarchiveNoteMutation($input: UnarchiveNoteInput!) {
    unarchiveNote(input: $input) {
      id
    }
  }
`;
