import React from "react";
import * as S from "./styles";
import Table, { TR, TD } from "./Table";
import * as SVG from "./svgs";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import { Tag } from "./Tags";
import { Mutation } from "@apollo/react-components";
import { Event } from "./apiTypes";
import RegistrationsList from "./RegistrationsList";
import EditableContent from "./EditableContent";
import { StyledPanelFormWrapper } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { updateEventMutation } from "./mutations/event";
import { Link, RouteComponentProps } from "react-router-dom";

type SharedEventProps = {
  event: Event;
};
type EventRegistrationsProps = RouteComponentProps<{ eventId: string }> &
  SharedEventProps;

// TODO: Implement props typing
const EventRegistrations: React.FunctionComponent<EventRegistrationsProps> = ({
  event
}) => {
  return (
    <Page>
      <RegistrationsList event={event} />
    </Page>
  );
};

export default EventRegistrations;
