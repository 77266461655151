import queryString from "query-string";
import { joinPath } from "./util";
import config from "./config";
import logger from "./logger";
import client from "./apollo";
import { User } from "./apiTypes";
import { gql } from "graphql.macro";

export type StoredAuthState = {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
};

export const AUTH_STORAGE_KEY = "fpa-database:session";

export function expired(tokenTimestamp: number) {
  return tokenTimestamp * 1000 < +new Date();
}

export async function getAccessToken(): Promise<string | null> {
  const storedData = getAuthStorage();

  if (storedData) {
    if (storedData.expiresAt && expired(storedData.expiresAt)) {
      try {
        const { access_token } = await refresh();
        return access_token;
      } catch (err) {
        logOut();
        return null;
      }
    }

    return storedData.accessToken;
  }

  return null;
}

export function getAuthStorage(): StoredAuthState | null {
  try {
    return JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || "null");
  } catch (err) {
    logger.warn("Error occurred while fetching stored authentication:", err);
    return null;
  }
}

export type APIOAuthToken = {
  token_type: "Bearer";
  access_token: string;
  refresh_token: string;
  expires_at: number;
};

export function receiveToken(token?: APIOAuthToken): void {
  if (token) {
    setAuthStorage({
      accessToken: token.access_token,
      refreshToken: token.refresh_token,
      expiresAt: token.expires_at
    });
  }
}

export function setAuthStorage({
  accessToken,
  refreshToken,
  expiresAt
}: StoredAuthState): void {
  try {
    localStorage.setItem(
      AUTH_STORAGE_KEY,
      JSON.stringify({
        accessToken: accessToken || null,
        refreshToken: refreshToken || null,
        expiresAt: expiresAt || null
      })
    );
  } catch (err) {
    // Do nothing
  }
}

export function deleteAuthStorage(): void {
  try {
    localStorage.removeItem(AUTH_STORAGE_KEY);
  } catch (err) {
    // Do nothing
  }
}

export function authorize(state?: string) {
  const query = queryString.stringify({
    response_type: "code",
    client_id: config.apiClientId,
    redirect_uri: config.authRedirectUri,
    state: state
  });
  const authPath = joinPath(config.apiUrl, `/oauth/authorize?${query}`);
  window.location.href = authPath;
}

export async function exchangeAuthCode(code: string): Promise<APIOAuthToken> {
  const response = await fetch(joinPath(config.apiUrl, "/oauth/token"), {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      code,
      grant_type: "authorization_code",
      redirect_uri: config.authRedirectUri,
      client_id: config.apiClientId
    })
  });

  console.debug("Exchanging authorization code.");

  if (response.ok) {
    const token = await response.json();
    receiveToken(token);
    console.debug("Received token.", token);
    return token;
  } else {
    throw new Error(`${response.status} ${response.statusText}`);
  }
}

export async function refresh(refreshToken?: string): Promise<APIOAuthToken> {
  const storedData = getAuthStorage();
  const storedToken =
    refreshToken || (storedData && storedData.refreshToken) || null;
  const response = await fetch(joinPath(config.apiUrl, "/oauth/token"), {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: refreshToken || storedToken
    })
  });

  if (response.ok) {
    const token = await response.json();
    receiveToken(token);
    return token;
  } else {
    throw new Error(`${response.status} ${response.statusText}`);
  }
}

export function logOut() {
  deleteAuthStorage();
}

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    me {
      id
      admin
      contact {
        id
        firstName
        lastName
        photoUrl
      }
    }
  }
`;

export function bootstrapAppData(): Promise<{ user: User | null }> {
  return getAccessToken().then(token => {
    console.debug("Bootstrapping app data with token.", token);
    if (!token) return { user: null };
    return client
      .query<{ me: User }>({
        query: CURRENT_USER_QUERY
      })
      .then(result => {
        if (result.data && result.data.me) {
          return { user: result.data.me };
        }

        return { user: null };
      });
  });
}
