import React from "react";
import CompanyHQ from "./CompanyHQ";
import UserImage from "./UserAvatar";
import CompanyBGCats from "./CompanyBGCats";
import EditableContent from "./EditableContent";
import { CompanyInvoicesSummary } from "./InvoicesSummary";
import { PrimaryPhoneNumber } from "./PhoneNumbers";
import { PrimaryEmail } from "./Emails";
import * as S from "./styles";
import Notes, { ActivityFeed } from "./Notes";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import { Mutation } from "@apollo/react-components";
import { Company } from "./apiTypes";
import { primaryContact } from "./util";
import { StyledPanelFormWrapper } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { updateCompanyMutation } from "./mutations/company";
import { Link, RouteComponentProps } from "react-router-dom";

type SharedCompanyProps = {
  company: Company;
};
type CompanySummaryProps = RouteComponentProps<{ companyId: string }> &
  SharedCompanyProps;

// TODO: Implement props typing
const CompanySummary: React.FunctionComponent<CompanySummaryProps> = ({
  company
}) => {
  const primary = primaryContact(company.contactMemberships);

  return (
    <PageWithSidePanel thirds>
      <Page>
        <CompanyHQ company={company} />
        <S.Panel pb={2}>
          <EditableContent>
            {({ isOpen, open, close }) => (
              <>
                <S.UnderlinedHeader>
                  <S.Flex alignItems="flex-end" justifyContent="space-between">
                    Description
                    {!isOpen ? (
                      <S.Button size="small" onClick={open}>
                        Edit
                      </S.Button>
                    ) : (
                      <S.Button size="small" onClick={close}>
                        Cancel
                      </S.Button>
                    )}
                  </S.Flex>
                </S.UnderlinedHeader>
                {!isOpen ? (
                  <S.P>{company.description ? company.description : ""}</S.P>
                ) : (
                  <Mutation<any>
                    mutation={updateCompanyMutation}
                    refetchQueries={["companyQuery"]}
                  >
                    {(updateCompany, { loading }) => {
                      return (
                        <Formik
                          initialValues={{
                            description: company.description
                              ? company.description
                              : ""
                          }}
                          onSubmit={values => {
                            updateCompany({
                              variables: {
                                input: { ...values, companyId: company.id }
                              }
                            });
                            close();
                          }}
                          render={() => (
                            <Form>
                              <StyledPanelFormWrapper>
                                <Field
                                  required
                                  id="description"
                                  name="description"
                                  placeholder="..."
                                  component="textarea"
                                />
                                <button type="submit" disabled={loading}>
                                  Sav{loading ? "ing" : "e"}
                                </button>
                              </StyledPanelFormWrapper>
                            </Form>
                          )}
                        />
                      );
                    }}
                  </Mutation>
                )}
              </>
            )}
          </EditableContent>
        </S.Panel>
        <CompanyInvoicesSummary company={company} />
        <Notes subject={company} />
        <ActivityFeed subject={company} />
      </Page>
      <PageSidePanel id="side-panel">
        {primary && primary.contact ? (
          <S.Box>
            <S.UnderlinedHeader>Primary Contact</S.UnderlinedHeader>
            <Link to={`/contacts/${primary.contact.id}`}>
              <S.Flex my={2} alignItems="center">
                <UserImage
                  large
                  src={
                    primary.contact.photoUrl
                      ? primary.contact.photoUrl
                      : "/images/empty-user.png"
                  }
                />
                <S.H3>
                  {`${primary.contact.firstName} ${primary.contact.lastName}`}
                  <span>{primary.title}</span>
                </S.H3>
              </S.Flex>
            </Link>
            <PrimaryPhoneNumber subject={primary.contact} />
            <PrimaryEmail subject={primary.contact} />
          </S.Box>
        ) : (
          <S.Box>
            <S.UnderlinedHeader>Primary Contact</S.UnderlinedHeader>
            No primary contact on record.
          </S.Box>
        )}
        <S.Box>
          <CompanyBGCats company={company} />
        </S.Box>
      </PageSidePanel>
    </PageWithSidePanel>
  );
};

export default CompanySummary;
