import React, { useCallback, useMemo, useEffect, useRef } from "react";
import Tree, { TreeItem, TreeParent } from "./DropdownList";
import * as S from "./styles";
import {
  Company,
  BuyersGuideCategory as BuyersGuideCategoryType,
  BuyersGuideCategoryListQueryData,
} from "./apiTypes";
import EditableContent from "./EditableContent";
import { StyledPanelFormWrapper } from "./StyledForm";
import {
  useFormik,
  useField,
  FormikProvider,
  useFormikContext,
  FormikHelpers,
} from "formik";
import { updateCompanyMutation } from "./mutations/company";
import { treeify, Tree as TreeType } from "treeify-js";
import { buyersGuideCategoriesListQuery } from "./queries/buyersGuideCategory";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { usePrevious } from "./hooks";

type BGCategoryFormValues = { categories: string[] };

function CategoriesTreeEditor({
  name,
  categories,
  isEditing,
}: {
  name: keyof BGCategoryFormValues;
  categories: TreeType<BuyersGuideCategoryType>[];
  isEditing?: boolean;
}) {
  const { setFieldValue } = useFormikContext<BGCategoryFormValues>();
  const [{ value: checkedValues }] = useField<string[]>(name);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      let selected = [];

      if (Array.isArray(checkedValues)) {
        if (checked) {
          selected = checkedValues.concat(value);
        } else {
          const index = checkedValues.indexOf(value);
          selected = [
            ...checkedValues.slice(0, index),
            ...checkedValues.slice(index + 1),
          ];
        }
      } else {
        selected = [value];
      }

      setFieldValue(name, selected);
    },
    [checkedValues]
  );

  const isSelected = useCallback(
    (category: BuyersGuideCategoryType) =>
      checkedValues.indexOf(category.id) > -1,
    [checkedValues]
  );

  if (!isEditing && (!Array.isArray(checkedValues) || !checkedValues.length))
    return <div>This company is not categorized.</div>;

  return (
    <Tree>
      {categories.map((cat) => (
        <CategoryTreeItem
          key={cat.id}
          category={cat}
          isSelected={isSelected}
          onChange={handleChange}
          isEditing={isEditing}
        />
      ))}
    </Tree>
  );
}

function isTreeSelected<V>(
  element: TreeType<V>,
  isSelected: (element: V) => boolean
): boolean {
  if (isSelected(element)) {
    return true;
  } else if (element.children && element.children.length) {
    return element.children.some((c) => isTreeSelected(c, isSelected));
  }

  return false;
}

function CategoryTreeItem({
  category,
  isSelected,
  onChange,
  isEditing,
}: {
  category: TreeType<BuyersGuideCategoryType>;
  isSelected: (category: BuyersGuideCategoryType) => boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isEditing?: boolean;
}) {
  const checked = isSelected(category);
  const label = (
    <>
      {isEditing && (
        <input
          name="categories"
          type="checkbox"
          value={category.id}
          checked={checked}
          onChange={onChange}
        />
      )}
      {category.name}
    </>
  );

  if (!category.parentId || category.children.length) {
    if (!isEditing && !isTreeSelected(category, isSelected)) return null;

    return (
      <TreeItem>
        <TreeParent>{label}</TreeParent>
        <Tree>
          {category.children.map((cat) => (
            <CategoryTreeItem
              key={cat.id}
              category={cat}
              isSelected={isSelected}
              onChange={onChange}
              isEditing={isEditing}
            />
          ))}
        </Tree>
      </TreeItem>
    );
  }

  return isEditing || checked ? <li>{label}</li> : null;
}

const BuyersGuideTreeEditor: React.FunctionComponent<{
  company: Company;
  categories: BuyersGuideCategoryType[];
  cancelEdit: () => void;
  isEditing?: boolean;
}> = ({ company, categories, cancelEdit, isEditing }) => {
  const wasEditing = usePrevious(isEditing);
  const initialValues = useMemo(
    () => ({
      categories: company.buyersGuideCategories
        ? company.buyersGuideCategories.map((c) => c.id)
        : [],
    }),
    [company]
  );
  const [updateCompany] = useMutation(updateCompanyMutation);
  const handleSubmit = useCallback(
    async (
      values: BGCategoryFormValues,
      formik: FormikHelpers<BGCategoryFormValues>
    ) => {
      try {
        await updateCompany({
          variables: {
            input: { companyId: company.id, bgCategoryIds: values.categories },
          },
        });
        cancelEdit();
      } catch (err) {
        console.error(err);
        formik.setSubmitting(false);
      }
    },
    []
  );
  const form = useFormik<BGCategoryFormValues>({
    onSubmit: handleSubmit,
    initialValues,
  });
  const treeCategories = useMemo(() => treeify(categories, { multi: true }), [
    categories,
  ]);

  useEffect(() => {
    if (!wasEditing && isEditing) form.resetForm({ values: initialValues });
  }, [isEditing]);

  return (
    <FormikProvider value={form}>
      <StyledPanelFormWrapper>
        <form onSubmit={form.handleSubmit}>
          <CategoriesTreeEditor
            name="categories"
            categories={treeCategories}
            isEditing={isEditing}
          />
          {isEditing && (
            <button type="submit" disabled={form.isSubmitting}>
              {form.isSubmitting ? "Saving..." : "Save"}
            </button>
          )}
        </form>
      </StyledPanelFormWrapper>
    </FormikProvider>
  );
};

const CompanyBuyersGuide: React.FunctionComponent<{ company: Company }> = ({
  company,
}) => {
  const catQuery = useQuery<BuyersGuideCategoryListQueryData>(
    buyersGuideCategoriesListQuery
  );

  return (
    <EditableContent>
      {({ isOpen, open, close }) => (
        <>
          <S.UnderlinedHeader>
            <S.Flex alignItems="flex-end" justifyContent="space-between">
              Buyer's Guide Categories
              {!isOpen ? (
                <S.Button size="small" onClick={open}>
                  Edit
                </S.Button>
              ) : (
                <S.Button size="small" onClick={close}>
                  Cancel
                </S.Button>
              )}
            </S.Flex>
          </S.UnderlinedHeader>
          {catQuery.loading && !catQuery.data ? (
            <React.Fragment />
          ) : !catQuery.data ||
            !catQuery.data.buyersGuideCategories ||
            !catQuery.data.buyersGuideCategories.length ? (
            <div>There are no categories in the system.</div>
          ) : (
            <BuyersGuideTreeEditor
              company={company}
              categories={catQuery.data.buyersGuideCategories}
              isEditing={isOpen}
              cancelEdit={close}
            />
          )}
        </>
      )}
    </EditableContent>
  );
};

export default CompanyBuyersGuide;
