import { gql } from "graphql.macro";

export const createStoreItemMutation = gql`
  mutation createStoreItemMutation($input: CreateStoreItemInput!) {
    createStoreItem(input: $input) {
      id
    }
  }
`;

export const updateStoreItemMutation = gql`
  mutation updateStoreItemMutation($input: UpdateStoreItemInput!) {
    updateStoreItem(input: $input) {
      id
      name
      categories
      description
      price
      sku
      deactivatedAt
      publishedAt
    }
  }
`;

export const archiveStoreItemMutation = gql`
  mutation archiveStoreItemMutation($input: ArchiveStoreItemInput!) {
    archiveStoreItem(input: $input) {
      storeItemId
    }
  }
`;

export const unarchiveStoreItemMutation = gql`
  mutation unarchiveStoreItemMutation($input: UnarchiveStoreItemInput!) {
    unarchiveStoreItem(input: $input) {
      storeItemId
    }
  }
`;
