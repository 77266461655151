import React from "react";
import { useMutation } from "@apollo/react-hooks";
import * as S from "./styles";
import UserImage from "./UserAvatar";
import { Formik, Field, Form, FieldArray } from "formik";
import { StyledPanelFormWrapper, RepeatableFieldButton } from "./StyledForm";
import { Link } from "react-router-dom";
import * as SVG from "./svgs";
import { PrimaryAddress } from "./Addresses";
import { EmailList } from "./Emails";
import { PhoneNumberList } from "./PhoneNumbers";
import { Location as LocationType } from "./apiTypes";
import {
  updateLocationMutation,
  archiveLocationMutation,
} from "./mutations/location";
import {
  createAddressMutation,
  updateAddressMutation,
} from "./mutations/address";
import {
  updateEmailAddressMutation,
  createEmailAddressMutation,
  deleteEmailAddressMutation,
} from "./mutations/emailAddress";
import {
  updatePhoneNumberMutation,
  createPhoneNumberMutation,
  deletePhoneNumberMutation,
} from "./mutations/phoneNumber";
import { associationMutations, primaryAssociation } from "./util";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";

type LocationProps = {
  location: LocationType;
};

const Location: React.FunctionComponent<LocationProps> = ({ location }) => {
  const editDialog = useDialogState();
  const primary = primaryAssociation(location.addresses);

  const [createAddress] = useMutation(createAddressMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [updateAddress] = useMutation(updateAddressMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [createEmailAddress] = useMutation(createEmailAddressMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [deleteEmailAddress] = useMutation(deleteEmailAddressMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [updateEmailAddress] = useMutation(updateEmailAddressMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [createPhoneNumber] = useMutation(createPhoneNumberMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [deletePhoneNumber] = useMutation(deletePhoneNumberMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [updatePhoneNumber] = useMutation(updatePhoneNumberMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [deleteLocation] = useMutation(archiveLocationMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });
  const [updateLocation, { loading }] = useMutation(updateLocationMutation, {
    refetchQueries: ["LocationListQuery", "companyQuery"],
  });

  return (
    <>
      <S.Panel>
        <S.UnderlinedHeader>
          <S.Flex alignItems="flex-end" justifyContent="space-between">
            <S.Title>{location.name}</S.Title>
            <DialogDisclosure {...editDialog} as={S.Button} size="small">
              Edit
            </DialogDisclosure>
          </S.Flex>
        </S.UnderlinedHeader>
        <LocationAtAGlance
          location={location}
          email={true}
          phone={true}
          employees={true}
        />
      </S.Panel>
      <DialogWithBackdrop {...editDialog}>
        <Formik
          initialValues={{
            name: location.name ? location.name : "",
            numEmployees: location.numEmployees
              ? location.numEmployees
              : 0,
            address: primary
              ? primary
              : {
                  city: "",
                  state: "",
                  country: "",
                  postalCode: "",
                  streetAddress: "",
                  suiteNumber: "",
                },
            phoneNumbers: location.phoneNumbers ? location.phoneNumbers : [],
            emailAddresses: location.emailAddresses
              ? location.emailAddresses
              : [],
          }}
          onSubmit={async (values) => {
            await updateLocation({
              variables: {
                input: {
                  name: values.name,
                  locationId: location.id,
                  numEmployees: values.numEmployees,
                },
              },
            });
            let input: any = { ...values };
            if (primary) {
              await updateAddress({
                variables: {
                  input: {
                    addressId: primary.id,
                    preferred: true,
                    city: input.address.city,
                    label: "plant",
                    country: input.address.country,
                    postalCode: input.address.postalCode,
                    state: input.address.state,
                    streetAddress: input.address.streetAddress,
                    suiteNumber: input.address.suiteNumber,
                  },
                },
              });
            } else {
              await createAddress({
                variables: {
                  input: {
                    preferred: true,
                    city: input.address.city,
                    label: "plant",
                    country: input.address.country,
                    postalCode: input.address.postalCode,
                    state: input.address.state,
                    streetAddress: input.address.streetAddress,
                    suiteNumber: input.address.suiteNumber,
                    subjectId: location.id,
                  },
                },
              });
            }
            await associationMutations(
              input.phoneNumbers,
              location.phoneNumbers ? location.phoneNumbers : [],
              {
                create: (a) =>
                  createPhoneNumber({
                    variables: {
                      input: {
                        subjectId: location.id,
                        phoneNumber: a.phoneNumber,
                        preferred: false,
                        label: a.label,
                      },
                    },
                  }),
                update: ({ id, ...a }) =>
                  updatePhoneNumber({
                    variables: {
                      input: {
                        phoneNumber: a.phoneNumber,
                        preferred: false,
                        label: a.label,
                        phoneNumberId: id,
                      },
                    },
                  }),
                destroy: ({ id }) =>
                  deletePhoneNumber({
                    variables: { input: { phoneNumberId: id } },
                  }),
              }
            );
            await associationMutations(
              input.emailAddresses,
              location.emailAddresses ? location.emailAddresses : [],
              {
                create: (a) =>
                  createEmailAddress({
                    variables: {
                      input: {
                        emailAddress: a.emailAddress,
                        label: a.label,
                        preferred: false,
                        subjectId: location.id,
                      },
                    },
                  }),
                update: ({ id, ...a }) =>
                  updateEmailAddress({
                    variables: {
                      input: {
                        emailAddress: a.emailAddress,
                        label: a.label,
                        preferred: false,
                        subjectId: location.id,
                      },
                    },
                  }),
                destroy: ({ id }) =>
                  deleteEmailAddress({
                    variables: { input: { emailAddressId: id } },
                  }),
              }
            );
            editDialog.hide();
          }}
          render={({ values }) => (
            <S.Box p={3} my={0}>
              <S.UnderlinedHeader>
                Edit Location{values.name ? `: ${values.name}` : ``}
              </S.UnderlinedHeader>
              <Form>
                <StyledPanelFormWrapper>
                  <label htmlFor="name">
                    Location Name
                    <span style={{ color: "red", display: "inline-block" }}>
                      *
                    </span>
                  </label>
                  <Field
                    required
                    id="name"
                    name="name"
                    placeholder={values.name ? values.name : ""}
                    type="text"
                  />
                  <label htmlFor="name">Number of Employees</label>
                  <Field
                    id="numEmployees"
                    name="numEmployees"
                    placeholder={values.numEmployees ? values.numEmployees : ""}
                    type="number"
                  />
                  <label htmlFor={`address.streetAddress`}>
                    Street Address
                  </label>
                  <Field
                    id={`address.streetAddress`}
                    name={`address.streetAddress`}
                    placeholder={
                      values.address.streetAddress
                        ? values.address.streetAddress
                        : "Street Address"
                    }
                    type="text"
                  />
                  <label htmlFor={`address.suiteNumber`}>Suite Number</label>
                  <Field
                    id={`address.suiteNumber`}
                    name={`address.suiteNumber`}
                    placeholder={
                      values.address.suiteNumber
                        ? values.address.suiteNumber
                        : "Street Address 2, Suite Number, Etc."
                    }
                    type="text"
                  />
                  <S.Flex>
                    <S.HalfWidth>
                      <label htmlFor={`address.city`}>City</label>
                      <Field
                        id={`address.city`}
                        name={`address.city`}
                        placeholder={
                          values.address.city ? values.address.city : "City"
                        }
                        type="text"
                      />
                    </S.HalfWidth>
                    <S.HalfWidth>
                      <label htmlFor={`address.state`}>State</label>
                      <Field
                        id={`address.state`}
                        name={`address.state`}
                        placeholder={
                          values.address.state ? values.address.state : "State"
                        }
                        type="text"
                      />
                    </S.HalfWidth>
                  </S.Flex>
                  <S.Flex>
                    <S.HalfWidth>
                      <label htmlFor={`address.postalCode`}>Postal Code</label>
                      <Field
                        id={`address.postalCode`}
                        name={`address.postalCode`}
                        type="text"
                        placeholder={
                          values.address.postalCode
                            ? values.address.postalCode
                            : "######-####"
                        }
                      />
                      <span style={{fontSize: 10, lineHeight: 0.9, fontWeight: "bold"}}>Please consult the <a style={{color: "#3496E3"}} target="_blank" href="https://m.usps.com/m/ZipLookupAction">USPS website</a> to identify the correct 9 digit postal code for this location.</span>
                    </S.HalfWidth>
                    <S.HalfWidth>
                      <label htmlFor={`address.country`}>Country</label>
                      <Field
                        id={`address.country`}
                        name={`address.country`}
                        type="text"
                        placeholder={
                          values.address.country
                            ? values.address.country
                            : "Country"
                        }
                      />
                    </S.HalfWidth>
                  </S.Flex>
                  <label htmlFor="phoneNumbers">Phone Numbers</label>
                  <FieldArray
                    name="phoneNumbers"
                    render={(arrayHelpers) => (
                      <div>
                        {values.phoneNumbers &&
                        values.phoneNumbers.filter((a) => a.label !== "delete")
                          .length > 0 ? (
                          values.phoneNumbers.map((phoneNumber, index) => (
                            <S.Flex mb={1} key={index}>
                              <Field
                                id={`phoneNumbers.${index}.label`}
                                name={`phoneNumbers.${index}.label`}
                                component="select"
                                className="tag"
                              >
                                {["", "main", "office", "fax"].map((opt) => (
                                  <option
                                    value={opt}
                                    key={opt}
                                    selected={phoneNumber.label === opt}
                                  >
                                    {opt}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                id={`phoneNumbers.${index}.phoneNumber`}
                                name={`phoneNumbers.${index}.phoneNumber`}
                                placeholder="###-###-#### ext: ###"
                                type="text"
                                className="tagged-field"
                              />
                              <RepeatableFieldButton
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              >
                                <SVG.MinusSvg />
                              </RepeatableFieldButton>
                              <RepeatableFieldButton
                                type="button"
                                onClick={() => {
                                  arrayHelpers.insert(index + 1, {
                                    label: "",
                                  });
                                }}
                              >
                                <SVG.PlusSvg />
                              </RepeatableFieldButton>
                            </S.Flex>
                          ))
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              arrayHelpers.push({ label: "" });
                            }}
                          >
                            Add a phone number.
                          </button>
                        )}
                      </div>
                    )}
                  />
                  <label htmlFor="emails">Email Addresses</label>
                  <FieldArray
                    name="emailAddresses"
                    render={(arrayHelpers) => (
                      <div>
                        {values.emailAddresses &&
                        values.emailAddresses.filter(
                          (a) => a.label !== "delete"
                        ).length > 0 ? (
                          values.emailAddresses.map((email, index) => (
                            <S.Flex mb={1} key={index}>
                              <Field
                                id={`emailAddresses.${index}.label`}
                                name={`emailAddresses.${index}.label`}
                                component="select"
                                className="tag"
                              >
                                {["", "main", "corporate"].map((opt) => (
                                  <option
                                    key={opt}
                                    value={opt}
                                    selected={email.label === opt}
                                  >
                                    {opt}
                                  </option>
                                ))}
                              </Field>
                              <Field
                                id={`emailAddresses.${index}.emailAddress`}
                                name={`emailAddresses.${index}.emailAddress`}
                                placeholder="sample@co.org"
                                type="text"
                                className="tagged-field"
                              />
                              <RepeatableFieldButton
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                <SVG.MinusSvg />
                              </RepeatableFieldButton>
                              <RepeatableFieldButton
                                type="button"
                                onClick={() =>
                                  arrayHelpers.insert(index + 1, "")
                                } // insert an empty string at a position
                              >
                                <SVG.PlusSvg />
                              </RepeatableFieldButton>
                            </S.Flex>
                          ))
                        ) : (
                          <button
                            type="button"
                            onClick={() => arrayHelpers.push("")}
                          >
                            Add an email.
                          </button>
                        )}
                      </div>
                    )}
                  />
                  <S.UnderlinedHeader>
                    <S.Flex>
                      <button type="submit" disabled={loading}>
                        Sav{loading ? "ing" : "e"}
                      </button>
                      <button
                        onClick={() => editDialog.hide()}
                        disabled={loading}
                      >
                        Cancel{loading ? "ling" : ""}
                      </button>
                    </S.Flex>
                  </S.UnderlinedHeader>
                  <S.Flex>
                  {/* <button
                    type="reset"
                    onClick={async (e) => {
                      e.preventDefault();
                      if (
                        window.confirm(
                          "Are you sure you need to reassign this Location?"
                        )
                      ) {
                        await deleteLocation({
                          variables: {
                            input: {
                              locationId: location.id,
                            },
                          },
                        });
                        editDialog.hide();
                      }
                      return false;
                    }}
                    disabled={loading}
                  >
                    Reassign Location
                  </button> */}
                    <button
                      type="reset"
                      onClick={async (e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            "Are you sure you want to archive this Location?"
                          )
                        ) {
                          await deleteLocation({
                            variables: {
                              input: {
                                locationId: location.id,
                              },
                            },
                          });
                          editDialog.hide();
                        }
                        return false;
                      }}
                      disabled={loading}
                    >
                      Archive Location
                    </button>
                  </S.Flex>
                </StyledPanelFormWrapper>
              </Form>
            </S.Box>
          )}
        />
      </DialogWithBackdrop>
    </>
  );
};

type LocationAtAGlanceProps = {
  location: LocationType;
  phone: boolean;
  email: boolean;
  employees: boolean;
};

const LocationAtAGlance: React.FunctionComponent<LocationAtAGlanceProps> = ({
  location,
  phone,
  email,
  employees,
}) => {
  return (
    <>
      <PrimaryAddress subject={location} />
      {phone && (
        <S.Flex my={3} mb={2} alignItems="center">
          <SVG.PhoneSvg />
          <PhoneNumberList subject={location} />
        </S.Flex>
      )}
      {email && (
        <S.Flex my={3} mb={2} alignItems="center">
          <SVG.EmailSvg />
          <EmailList subject={location} />
        </S.Flex>
      )}
      {employees && (
        <S.Flex my={3} mb={2} alignItems="center">
          <SVG.PersonsSvg />
          {location.numEmployees || "Number of Employees Unknown"}
        </S.Flex>
      )}
    </>
  );
};

export { LocationAtAGlance };
export default Location;
