export const colors = {
  grey: "#555F73",
  lightgrey: "#4c5661",
  blue: "#3496E3",
  offwhite: "#F8F9FB",
  lightblue: "#EEF1F8",
  purple: "#984DF6",
  green: "#87B43C",
  red: "#DE3838"
};

export const tagColors = {
  lightpurple: "#D9BDFC",
  darkpurple: "#984DF6",
  lightyellow: "#F9E6B3",
  darkyellow: "#A7852E",
  lightorange: "#FFD3A1",
  darkorange: "#94642D",
  lightblue: "#D1E1F3",
  darkblue: "#2E69A7",
  lightgreen: "#87B43C",
  darkgreen: "#3A4E1B",
  bronze: "#B08D57",
  silver: "#979797",
  gold: "#FFEA2E"
};
