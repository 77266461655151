import React from "react";
import Searchbar from "./Searchbar";
import UserMenu from "./UserMenu";
import * as S from "./styles";

const TopNav = () => {
  return (
    <S.Flex mb={4} px={3}>
      <Searchbar />
      <UserMenu />
    </S.Flex>
  );
};

export default TopNav;
