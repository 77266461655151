import { gql } from "graphql.macro";

export const archiveGroupMutation = gql`
  mutation archiveGroupMutation($input: ArchiveGroupInput!) {
    archiveGroup(input: $input) {
      id
    }
  }
`;

export const createGroupMutation = gql`
  mutation createGroupMutation($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      applicableTo
      color
      name
      restricted
    }
  }
`;

export const updateGroupMutation = gql`
  mutation updateGroupMutation($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      applicableTo
      color
      name
      restricted
    }
  }
`;
