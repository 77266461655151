import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";
import * as S from "./styles";
import * as SVG from "./svgs";
import Select from "react-select";
import EventRegistrationFormField, {
  FormField as FormFieldType,
} from "./EventRegistrationFormField";
import EditableContent from "./EditableContent";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import { AddressForm } from "./Addresses";
import { Mutation } from "@apollo/react-components";
import { Event } from "./apiTypes";
import { Link, RouteComponentProps } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { __InputValue } from "graphql";
import Date from "./Date";
import StyledFormWrapper, { StyledPanelFormWrapper, FormikDatetime, Select as SelectField } from "./StyledForm";
import { ValuesOfCorrectType } from "graphql/validation/rules/ValuesOfCorrectType";
import { type } from "os";
import { isEqual } from "lodash";
import { updateEventMutation } from "./mutations/event";

const FormEditorWrapper = styled.div`
  height: 100vh;
  width: 375px;
  padding-top: 10px;
`;
const FormEditor = styled.div`
  background: #f8f9fb;
  padding: 15px;
  height: 100%;
`;

const FormBuilderWrapper = styled.div`
  width: calc(100% - 375px);
`;

const LocalLink = styled.a<{
  active: boolean;
}>`
  cursor: pointer;
  font-weight: 500;
  padding: 6px 10px;
  margin: 0 10px;
  border-bottom: 2px solid ${(p) => p.theme.colors.lightblue};
  &:hover {
    color: ${(p) => p.theme.colors.blue};
    border-color: ${(p) => p.theme.colors.blue};
  }
  &:first-child {
    margin-left: 0;
  }
  ${(p) =>
    p.active &&
    css`
      color: ${(p) => p.theme.colors.blue};
      border-color: ${(p) => p.theme.colors.blue};
    `}
`;

const PageWrapper = styled.div<{
  active: boolean;
}>`
  display: none;
  ${(p) =>
    p.active &&
    css`
      display: block;
    `}
`;

type SharedEventProps = {
  event: Event;
};
type EventRegistrationFormProps = RouteComponentProps<{ eventId: string }> &
  SharedEventProps;

const fieldOptions = [
  {
    label: "Single Line Text",
    value: "text",
  },
  {
    label: "Paragraph Text",
    value: "textarea",
  },
  {
    label: "Dropdown",
    value: "select",
  },
  {
    label: "Multiple Choice",
    value: "radio",
  },
  {
    label: "Checkbox",
    value: "checkboxes"
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Address",
    value: "address",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Phone Number",
    value: "phone",
  },
  {
    label: "Email Address",
    value: "email",
  },
  {
    label: "File Upload",
    value: "file",
  },
  {
    label: "Copy",
    value: "p",
  },
  {
    label: "Header",
    value: "header",
  },
  {
    label: "Subheader",
    value: "subheader",
  },
];

let fields = [
  { page: 1,
    fields: [
    {
      name: "Text",
      type: "p",
      copy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat mollit anim id est laborum.",
    },
    {
      name: "Text",
      type: "header",
      copy: "Page 1 Title",
    },
    {
      name: "Text",
      type: "p",
      copy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat mollit anim id est laborum.",
    },
    {
      name: "Single Line Text",
      type: "text",
    },
    {
      name: "Paragraph Text",
      type: "textarea",
    },
    {
      name: "Dropdown",
      type: "select",
      required: true,
      options: [
        { value: "1", label: "Sample 1"},
        { value: "2", label: "Sample 2"},
        { value: "3", label: "Sample 3"}
      ]
    },
    {
      name: "Multiple Choice",
      type: "radio",
      required: false,
      placeholder: "",
      copy: "",
      allowMulti: false,
      options: [
        { value: "1", label: "Sample 1"},
        { value: "2", label: "Sample 2"},
        { value: "3", label: "Sample 3"}
      ]
    },
    {
      name: "Checkbox",
      type: "checkboxes",
      options: [
        { value: "", label: "I would like to ..."},
        { value: "", label: "My guest would like to ..."},
      ]
    },
    {
      name: "Number",
      type: "number",
    },
    ]
  },
  {
    page: 2,
    fields: [
    {
      name: "Text",
      type: "header",
      copy: "Page 2 Title",
    },
    {
      name: "Text",
      type: "p",
      copy: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat mollit anim id est laborum.",
    },
    {
      name: "Name",
      type: "name",
    },
    {
      name: "Address",
      type: "address",
    },
    {
      name: "Date",
      type: "date",
    },
    {
      name: "Phone Number",
      type: "phone",
    },
    {
      name: "Email Address",
      type: "email",
    },
    {
      name: "Text",
      type: "subheader",
      copy: "Page 2 Subtitle",
    },
    {
      name: "Text",
      type: "p",
      copy: "Lorem ipsum dolor sit <a href='/'>amet</a>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat mollit anim id est laborum.",
    },
    {
      name: "File Upload",
      type: "file",
    }
    ]
  }
];

const EventRegistrationForm: React.FunctionComponent<EventRegistrationFormProps> = ({
  event,
}) => {
  const [formLogicOpen, setformLogicOpen] = useState(true);
  const [toggledField, setToggledField] = useState<FormFieldType>({
    name: "",
    type: "",
  });
  const [page, setPage] = useState(1);

  function toggleformLogic() {
    setToggledField({ name: "", type: "" });
    setformLogicOpen(!formLogicOpen);
  }

  function updateField() {
    setformLogicOpen(false);

    // set selected formfield active
    // update fieldeditor to use active field
  }

  function togglePage(n: number) {
    setPage(n);
  }

  function createNewField() {
    setformLogicOpen(false);
    setToggledField({ name: "", type: "" });
    // set newest formfield active
    // set fieldeditor to use activefield
  }

  function createNewPage() {
    fields.push({
      page: fields.length + 1,
      fields: []
    })
    togglePage(fields.length + 1);
  }
  function setUpdateField(field: FormFieldType) {
    setformLogicOpen(false);
    setToggledField(field);
  }

  const handleUpdateFormSubmit = () => {};

  return (
    <Page py={0} pr={0}>
      <S.Flex mt={0} mb={0} mx={0}>
        <FormBuilderWrapper>
          <S.Panel mt={3} pb={2}>
            <EditableContent>
              {({ isOpen, open, close }) => (
                <>
                  <S.UnderlinedHeader>
                    <S.Flex alignItems="flex-end" justifyContent="space-between">
                      Registration Details
                      {!isOpen ? (
                        <S.Button size="small" onClick={open}>
                          Edit
                        </S.Button>
                      ) : (
                        <S.Button size="small" onClick={close}>
                          Cancel
                        </S.Button>
                      )}
                    </S.Flex>
                  </S.UnderlinedHeader>
                  {!isOpen ? (
                    <S.Flex>
                      <S.Box width={1 / 2} my={0} pb={3}>
                        <S.P>
                          <b>Registration Open Date</b>
                        </S.P>
                        <S.P>
                          {event.registrationOpensAt ? (
                            <Date date={event.registrationOpensAt} />
                          ) : (
                            "No Registration Open Date"
                          )}
                        </S.P>
                      </S.Box>
                      <S.Box width={1 / 2} my={0} pb={3}>
                        <S.P>
                          <b>Registration Close Date</b>
                        </S.P>
                        <S.P>
                          {event.registrationClosesAt ? (
                            <Date date={event.registrationClosesAt} />
                          ) : (
                            "No Registration Close Date"
                          )}
                        </S.P>
                      </S.Box>
                    </S.Flex>
                  ) : (
                    <Mutation<any>
                      mutation={updateEventMutation}
                      refetchQueries={["eventQuery"]}
                    >
                      {(updateEvent, { loading }) => {
                        return (
                          <Formik
                            initialValues={{
                              registrationOpensAt:
                                event.registrationOpensAt || undefined,
                              registrationClosesAt:
                                event.registrationClosesAt || undefined
                            }}
                            onSubmit={values => {
                              updateEvent({
                                variables: {
                                  input: { ...values, eventId: event.id }
                                }
                              });
                              close();
                            }}
                            render={() => (
                              <Form>
                                <StyledPanelFormWrapper>
                                  <S.Flex>
                                    <S.HalfWidth>
                                      <label htmlFor="registrationOpensAt">
                                        Registration Open Date
                                      </label>
                                      <Field
                                        id="registrationOpensAt"
                                        name="registrationOpensAt"
                                        component={FormikDatetime}
                                      />
                                    </S.HalfWidth>
                                    <S.HalfWidth>
                                      <label htmlFor="registrationClosesAt">
                                        Registration Close Date
                                      </label>
                                      <Field
                                        id="registrationClosesAt"
                                        name="registrationClosesAt"
                                        component={FormikDatetime}
                                      />
                                    </S.HalfWidth>
                                  </S.Flex>
                                  <button type="submit" disabled={loading}>
                                    Sav{loading ? "ing" : "e"}
                                  </button>
                                </StyledPanelFormWrapper>
                              </Form>
                            )}
                          />
                        );
                      }}
                    </Mutation>
                  )}
                </>
              )}
            </EditableContent>
          </S.Panel>
          <S.Panel>
            <S.Flex justifyContent="space-between" alignItems="center">
              <div>
                {fields.map((obj, index) => (
                  <LocalLink key={index} active={page === obj.page} onClick={() => togglePage(obj.page)}>
                    Page {obj.page}
                  </LocalLink>
                ))}
                <S.Button onClick={() => createNewPage()} size="small">
                  Add Page
                </S.Button>
              </div>
              <S.Button onClick={() => createNewPage()} size="small">
                Save
              </S.Button>
            </S.Flex>
            <>
              {fields.map((obj, index) => (
                <PageWrapper key={index} active={page === obj.page}>
                  <S.Box pt={3}>
                    <StyledFormWrapper>
                      {obj.fields.map((field, index) => (
                        <EventRegistrationFormField
                          key={`${obj.page}-${index}`}
                          active={isEqual(field, toggledField)}
                          toggledFieldFunction={() => setUpdateField(field)}
                          field={field}
                        />
                      ))}
                      <S.Button onClick={() => createNewField()} size="small">
                        Add New Field
                      </S.Button>
                    </StyledFormWrapper>
                  </S.Box>
                </PageWrapper>
              ))}
            </>
          </S.Panel>
        </FormBuilderWrapper>
        <FormEditorWrapper>
          <S.Flex alignItems="flex-end">
            <LocalLink active={formLogicOpen} onClick={toggleformLogic}>
              Form Logic
            </LocalLink>
            <LocalLink
              active={!formLogicOpen}
              onClick={() => updateField()}
            >
              Field Settings
            </LocalLink>
          </S.Flex>
          {formLogicOpen ? (
            <FormEditor>
              <Formik
                initialValues={{
                  name: "",
                  introduction: "",
                }}
                onSubmit={handleUpdateFormSubmit}
                render={() => (
                  <StyledPanelFormWrapper>
                    <Form>
                      <label htmlFor="name">Form Name</label>
                      <Field
                        required
                        id="name"
                        name="name"
                        placeholder="This shows on the website registration page..."
                        type="text"
                      />
                      <label htmlFor="introduction">
                        Form Introduction Copy
                      </label>
                      <Field
                        required
                        id="introduction"
                        name="introduction"
                        placeholder="This shows on the website registration page..."
                        component="textarea"
                      />
                      <label htmlFor="startDate">
                        Registration Open Date and Time
                      </label>
                      <Field
                        id="startDate"
                        name="startDate"
                        type="datetime"
                        placeholder="MM/DD/YYYY HH:MMam"
                      />
                      <label htmlFor="endDate">
                        Registration Close Date and Time
                      </label>
                      <Field
                        id="endDate"
                        name="endDate"
                        type="datetime"
                        placeholder="MM/DD/YYYY HH:MMam"
                      />
                      <S.Button type="submit">
                        Update Registration Form
                      </S.Button>
                    </Form>
                  </StyledPanelFormWrapper>
                )}
              />
            </FormEditor>
          ) : (
            <FormEditor>
              {isEqual(toggledField, { name: "", type: "" }) ? (
                <NewFieldForm />
              ) : (
                <UpdateFieldForm field={toggledField} />
              )}
            </FormEditor>
          )}
        </FormEditorWrapper>
      </S.Flex>
    </Page>
  );
};

const NewFieldForm = () => {
  const handleNewFieldSubmit = () => {};
  const [currentType, setCurrentType] = useState("text");

  const handleChangeType = (newType: string) => {
    setCurrentType(newType);
  };
  return (
    <Formik
      initialValues={{
        type: "",
        name: "",
        placeholder: "",
        copy: "",
        required: false,
        options: [{ label: "", value: "" }],
        allowMulti: false,
      }}
      onSubmit={handleNewFieldSubmit}
      render={({ values }) => (
        <StyledPanelFormWrapper>
          <Form>
            <label htmlFor="type">Field Type</label>
            <Field
              required
              id="type"
              name="type"
              value=""
              placeholder="Choose the field type."
              component={SelectField}
              closeMenuOnSelect={true}
              options={fieldOptions}
            />
            <label htmlFor="name">Field Name</label>
            <Field
              required
              id="name"
              name="name"
              type="text"
              placeholder={values.type}
            />
            <label htmlFor="placeholder">Field Placeholder Text</label>
            <Field
              id="placeholder"
              name="placeholder"
              type="text"
            />
            <label htmlFor="copy">Copy/Notes</label>
            <Field
              id="copy"
              name="copy"
              placeholder=""
              component="textarea"
            />
            <S.Flex>
              <Field id="required" name="required" type="checkbox" />
              <label htmlFor="required">Require this field.</label>
            </S.Flex>
            <S.Flex>
              <Field id="allowMulti" name="allowMulti" type="checkbox" />
              <label htmlFor="allowMulti">Allow multiple selections for this field.</label>
            </S.Flex>
            <S.Button type="submit">Add Field</S.Button>
          </Form>
        </StyledPanelFormWrapper>
      )}
    />
  );
};

const UpdateFieldForm: React.FunctionComponent<{ field: FormFieldType }> = ({
  field,
}) => {
  const handleUpdateFieldSubmit = () => {};

  const [currentType, setCurrentType] = useState(
    field.type ? field.type : "text"
  );

  const handleChangeType = (newType: string) => {
    setCurrentType(newType);
  };

  return (
    <Formik
      initialValues={{
        type: field.type ? field.type : "",
        name: field.name ? field.name : "",
        placeholder: field.placeholder ? field.placeholder : "",
        copy: field.copy ? field.copy : "",
        required: field.required ? field.required : false,
        options: field.options ? field.options : [{ label: "", value: "" }],
        allowMulti: field.allowMulti ? field.allowMulti : false,
        previousButton: field.previousButton ? field.previousButton : "",
        nextButton: field.nextButton ? field.nextButton : "",
        time: field.time ? field.time : false,
      }}
      onSubmit={handleUpdateFieldSubmit}
      render={() => (
        <StyledPanelFormWrapper>
          <Form>
            <label htmlFor="type">Field Type</label>
            <Field
              required
              id="type"
              name="type"
              placeholder="Choose the field type."
              component={SelectField}
              closeMenuOnSelect={true}
              options={fieldOptions}
            />
            {currentType === "address" ? (
              <></>
            ) : (
              <>
                <label htmlFor="name">Field Title</label>
                <Field
                  required
                  id="name"
                  name="name"
                  placeholder={field.name ? field.name : "Field Title"}
                  type="text"
                />
                <label htmlFor="placeholder">Placeholder Text</label>
                <Field
                  id="placeholder"
                  name="placeholder"
                  placeholder={field.placeholder ? field.placeholder : ""}
                  type="text"
                />
              </>
            )}
            <label htmlFor="copy">copy/Notes</label>
            <Field
              id="copy"
              name="copy"
              placeholder={
                field.copy
                  ? field.copy
                  : "copy for the user..."
              }
              component="textarea"
            />
            {currentType === "section break" || currentType === "page break" ? (
              <></>
            ) : (
              <Field
                id="required"
                name="required"
                value={field.required ? field.required : false}
                component="checkbox"
              />
            )}
            {currentType === "date" ? (
              <Field
                id="time"
                name="time"
                value={field.time ? field.time : false}
                component="checkbox"
              />
            ) : (
              <></>
            )}
            <S.Button type="submit">Update Field</S.Button>
          </Form>
        </StyledPanelFormWrapper>
      )}
    />
  );
};

export default EventRegistrationForm;
