import { gql } from "graphql.macro";

export const createMembershipMutation = gql`
  mutation createMembershipMutation($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      id
      categories
      company {
        id
      }
      deactivateWhenDelinquent
      easeInto
      paymentInterval
      startedOn
      endedOn
      applicationStatus
    }
  }
`;

export const updateMembershipMutation = gql`
  mutation updateMembershipMutation($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      id
      categories
      deactivateWhenDelinquent
      easeInto
      paymentInterval
      applicationStatus
      endedOn
      startedOn
    }
  }
`;

export const cancelMembershipMutation = gql`
  mutation cancelMembershipMutation($input: CancelMembershipInput!) {
    cancelMembership(input: $input) {
      id
    }
  }
`;
