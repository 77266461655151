import React from "react";
import moment from "moment";
import UserImage from "./UserAvatar";
import Page from "./Page";
import NewContactMembership from "./NewContactMembership";
import CompanyContact from "./CompanyContact";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Link } from "react-router-dom";
import { PrimaryAddress } from "./Addresses";
import { PhoneNumberList } from "./PhoneNumbers";
import { EmailList } from "./Emails";
import { Tag } from "./Tags";
import { Company } from "./apiTypes";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";

type CompanyContactsProps = {
  company: Company;
};

const CompanyContacts: React.FunctionComponent<CompanyContactsProps> = ({
  company,
}) => {
  const newDialog = useDialogState();

  const contactMemberships =
    company.contactMemberships &&
    company.contactMemberships
      .sort((a, b) => {
        const aName =
          a.contact && a.contact.firstName && a.contact.lastName
            ? `${a.contact.lastName} ${a.contact.firstName}`.toUpperCase()
            : "";
        const bName =
          b.contact && b.contact.firstName && b.contact.lastName
            ? `${b.contact.lastName} ${b.contact.firstName}`.toUpperCase()
            : "";
        if (aName < bName) {
          return 1;
        } else if (aName > bName) {
          return -1;
        } else return 0;
      })
      .sort((a, b) => {
        return a.endedOn ? 1 : -1;
      })
      .sort((a, b) => {
        return a.secondaryContact ? -1 : 1;
      })
      .sort((a, b) => {
        return a.primaryContact ? -1 : 1;
      });

  return (
    <Page>
      <S.Flex mx={3} justifyContent="space-between">
        <S.Title>Total Contacts: {company.contactMemberships.length}</S.Title>
        <DialogDisclosure {...newDialog} as={S.Button}>
          Add New Contact
        </DialogDisclosure>
      </S.Flex>
      <DialogWithBackdrop {...newDialog}>
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Company Contact</S.UnderlinedHeader>
          <NewContactMembership
            closeFunction={() => newDialog.hide()}
            company={company}
          />
        </S.Box>
      </DialogWithBackdrop>
      <S.Flex flexWrap="wrap">
        {contactMemberships && contactMemberships.length > 0 ? (
          contactMemberships.map((membership) => {
            if (!membership) return null;
            if (!membership.contact) return null;

            return <CompanyContact key={membership.id} companyContact={membership} />;
          })
        ) : (
          <S.Box mx={3} mb={3}>
            There are currently no contacts for this company.
          </S.Box>
        )}
      </S.Flex>
    </Page>
  );
};

export default CompanyContacts;
