import React, { useState } from "react";
import styled from "styled-components";
import * as S from "./styles";
import * as SVG from "./svgs";
import EditableContent from "./EditableContent";
import { Mutation, Query } from "@apollo/react-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Tree, { TreeItem, TreeParent } from "./DropdownList";
import { MutationFunction } from "@apollo/react-common";
import { Formik, Field, Form, FieldArray } from "formik";
import {
  StyledPanelFormWrapper,
  RepeatableFieldButton,
  Select,
} from "./StyledForm";
import { Group as GroupType, GroupListQueryData, Contact } from "./apiTypes";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import {
  createGroupMutation,
  updateGroupMutation,
  archiveGroupMutation,
} from "./mutations/group";
import { updateContactMutation } from "./mutations/contact";
import { groupsListQuery } from "./queries/group";

const Groups: React.FunctionComponent<{
  contact: Contact;
}> = ({ contact }) => {
  return (
    <EditableContent>
      {({ isOpen, open, close }) => (
        <>
          <S.UnderlinedHeader>
            <S.Flex alignItems="flex-end" justifyContent="space-between">
              Contact Groups
              {!isOpen ? (
                <S.Button size="small" onClick={open}>
                  Edit
                </S.Button>
              ) : (
                <S.Button size="small" onClick={close}>
                  Cancel
                </S.Button>
              )}
            </S.Flex>
          </S.UnderlinedHeader>
          {!isOpen ? (
            contact.groups && contact.groups.length ? (
              <S.Box pl={3} pr={0}>
                <S.List noBorder noPadding>
                  {contact.groups.map((group) => (
                    <li key={group.id}>
                      <S.Flex alignItems="center">
                        {group.restricted ? <SVG.LockSvg /> : <></>}
                        {group.name}
                      </S.Flex>
                    </li>
                  ))}
                </S.List>
              </S.Box>
            ) : (
              <>Currently in no contact groups.</>
            )
          ) : (
            <Mutation<any>
              mutation={updateContactMutation}
              refetchQueries={["ContactQuery"]}
            >
              {(updateContact, { loading }) => {
                return (
                  <Formik
                    initialValues={{
                      groups: contact.groups.map((g) => ({
                        label: g.name,
                        value: g.id,
                      })),
                    }}
                    onSubmit={(values) => {
                      var groups =
                        values.groups && values.groups.length
                          ? values.groups.map((g) => {
                              return g.value;
                            })
                          : [];
                      updateContact({
                        variables: {
                          input: {
                            groupIds: groups,
                            contactId: contact.id,
                          },
                        },
                      });
                      close();
                    }}
                    render={({ values }) => (
                      <Query<GroupListQueryData> query={groupsListQuery}>
                        {({ loading, error, data }) => {
                          if (loading && !data) return <div />;
                          if (error) return <div>Oops.</div>;
                          if (!data || !data.groups) return <div>No data.</div>;
                          const groupOptions = data.groups.map((group) => {
                            return { value: group.id, label: group.name };
                          });
                          return (
                            <Form>
                              <StyledPanelFormWrapper>
                                <FieldArray
                                  name="groups"
                                  render={(arrayHelpers) => (
                                    <div>
                                      {values.groups && values.groups.length ? (
                                        values.groups.map((group, index) => (
                                          <S.Flex
                                            mb={1}
                                            alignItems="center"
                                            key={index}
                                          >
                                            <div
                                              style={{
                                                flexBasis: "70%",
                                                marginRight: 5,
                                              }}
                                            >
                                              <Field
                                                id={`groups.${index}`}
                                                name={`groups.${index}.value`}
                                                component={Select}
                                                closeMenuOnSelect={true}
                                                options={groupOptions}
                                              />
                                            </div>
                                            <RepeatableFieldButton
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <SVG.MinusSvg />
                                            </RepeatableFieldButton>
                                            <RepeatableFieldButton
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.insert(
                                                  index + 1,
                                                  ""
                                                )
                                              }
                                            >
                                              <SVG.PlusSvg />
                                            </RepeatableFieldButton>
                                          </S.Flex>
                                        ))
                                      ) : (
                                        <button
                                          type="button"
                                          onClick={() => arrayHelpers.push("")}
                                        >
                                          Add a contact group.
                                        </button>
                                      )}
                                    </div>
                                  )}
                                />
                                <button type="submit" disabled={loading}>
                                  Sav{loading ? "ing" : "e"}
                                </button>
                              </StyledPanelFormWrapper>
                            </Form>
                          );
                        }}
                      </Query>
                    )}
                  />
                );
              }}
            </Mutation>
          )}
        </>
      )}
    </EditableContent>
  );
};

const SettingsGroups: React.FunctionComponent<{}> = ({}) => {
  const addDialog = useDialogState();

  return (
    <S.Box pb={2} pt={3} my={3} mx={3}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="center" justifyContent="space-between">
          Contact Groups
          <DialogDisclosure {...addDialog} as={S.Button} size="small">
            Add a Contact Group
          </DialogDisclosure>
        </S.Flex>
      </S.UnderlinedHeader>
      <Query<GroupListQueryData> query={groupsListQuery}>
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Oops.</div>;
          if (!data || !data.groups) return <div>No data.</div>;
          const adminGroups = data.groups.filter((group) => group.restricted);
          const standardGroups = data.groups.filter(
            (group) => !group.restricted
          );
          return (
            <S.Flex>
              <ContactGroups groups={standardGroups} />
              <ContactGroups groups={adminGroups} admin />
            </S.Flex>
          );
        }}
      </Query>
      <DialogWithBackdrop {...addDialog}>
        <AddGroupForm closeModal={() => addDialog.hide()} />
      </DialogWithBackdrop>
    </S.Box>
  );
};

const ContactGroups: React.FunctionComponent<{
  groups: GroupType[];
  admin?: boolean;
}> = ({ groups, admin }) => {
  const editDialog = useDialogState();

  const [editGroup, setEditGroup] = useState<GroupType | undefined>();

  return (
    <S.Panel width={1 / 2}>
      <EditableContent>
        {({ isOpen, open, close }) => (
          <S.Box pr={0}>
            <S.UnderlinedHeader>
              <S.Flex alignItems="flex-end" justifyContent="space-between">
                {admin ? "Administrative" : "Standard"} Contact Groups
                {!isOpen ? (
                  <S.Button size="small" onClick={open}>
                    Edit
                  </S.Button>
                ) : (
                  <S.Button size="small" onClick={close}>
                    Done
                  </S.Button>
                )}
              </S.Flex>
            </S.UnderlinedHeader>
            <S.Box pl={3}>
              {!isOpen ? (
                <S.List noBorder noPadding style={{ listStyle: "none" }}>
                  {groups.map((group) => (
                    <li key={group.id}>{group.name}</li>
                  ))}
                </S.List>
              ) : (
                <S.List noBorder>
                  {groups.map((group) => (
                    <li key={group.id}>
                      <S.Flex
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {group.name}
                        <DialogDisclosure
                          {...editDialog}
                          onClick={() => setEditGroup(group)}
                          as={S.Button}
                          size="small"
                        >
                          Edit
                        </DialogDisclosure>
                      </S.Flex>
                    </li>
                  ))}
                </S.List>
              )}
            </S.Box>
          </S.Box>
        )}
      </EditableContent>
      <DialogWithBackdrop {...editDialog}>
        <EditGroupForm group={editGroup} closeModal={() => editDialog.hide()} />
      </DialogWithBackdrop>
    </S.Panel>
  );
};

const EditGroupForm: React.FunctionComponent<{
  group?: GroupType;
  closeModal: () => void;
}> = ({ group, closeModal }) => {
  const [updateGroup, { loading }] = useMutation<
    { updateGroup: { id: string } },
    { input: any }
  >(updateGroupMutation, {
    refetchQueries: ["groupsListQuery"],
  });
  const [archiveGroup] = useMutation<
    { archiveGroup: { id: string } },
    { input: { groupId: string } }
  >(archiveGroupMutation, {
    refetchQueries: ["groupsListQuery"],
  });

  return (
    <>
      {group ? (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>Update Contact Group</S.UnderlinedHeader>
          <Formik
            initialValues={{
              name: group.name,
              restricted: group.restricted,
            }}
            onSubmit={async (values) => {
              let input: any = { ...values };
              await updateGroup({
                variables: {
                  input: {
                    groupId: group.id,
                    restricted: input.restricted,
                    applicableTo: ["CONTACT"],
                    name: input.name,
                  },
                },
              });
              closeModal();
            }}
            render={({ values }) => (
              <StyledPanelFormWrapper>
                <Form>
                  <label>Contact Group Name</label>
                  <Field
                    id="name"
                    name="name"
                    required
                    placeholder={"Name the Group."}
                    type="text"
                  />
                  <S.Flex>
                    <Field
                      name="restricted"
                      label="restricted"
                      component="input"
                      type="checkbox"
                    />
                    <label>Only Admins can assign this group.</label>
                  </S.Flex>
                  <S.Flex alignItems="center">
                    <S.Button type="submit" disabled={loading}>
                      Sav{loading ? "ing" : "e"} Category
                    </S.Button>
                    <S.Button
                      disabled={loading}
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Are you sure you want to archive this Contact Group?"
                          )
                        ) {
                          await archiveGroup({
                            variables: {
                              input: {
                                groupId: group.id,
                              },
                            },
                          });
                        }
                      }}
                    >
                      Archiv{loading ? "ing" : "e"} Category
                    </S.Button>
                  </S.Flex>
                </Form>
              </StyledPanelFormWrapper>
            )}
          />
        </S.Box>
      ) : (
        <AddGroupForm closeModal={() => closeModal()} />
      )}
    </>
  );
};

const AddGroupForm: React.FunctionComponent<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  return (
    <S.Box p={3} my={0}>
      <S.UnderlinedHeader>Create Contact Group</S.UnderlinedHeader>
      <Mutation<MutationFunction>
        mutation={createGroupMutation}
        refetchQueries={["groupsListQuery"]}
      >
        {(createGroup, { loading }) => (
          <Formik
            initialValues={{
              name: "",
              restricted: false,
            }}
            onSubmit={async (values) => {
              let input: any = { ...values };
              await createGroup({
                variables: {
                  input: {
                    restricted: input.restricted,
                    applicableTo: ["CONTACT"],
                    name: input.name,
                  },
                },
              });
              closeModal();
            }}
            render={({ values }) => (
              <StyledPanelFormWrapper>
                <Form>
                  <label>Contact Group Name</label>
                  <Field
                    id="name"
                    name="name"
                    required
                    placeholder={"Name the Group."}
                    value={values.name}
                    type="text"
                  />
                  <S.Flex>
                    <Field
                      name="restricted"
                      label="restricted"
                      checked={values.restricted}
                      component="input"
                      type="checkbox"
                    />
                    <label>Only Admins can change this group.</label>
                  </S.Flex>
                  <S.Button type="submit" disabled={loading}>
                    Sav{loading ? "ing" : "e"} Category
                  </S.Button>
                </Form>
              </StyledPanelFormWrapper>
            )}
          />
        )}
      </Mutation>
    </S.Box>
  );
};

export default Groups;
export { SettingsGroups };
