import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

function buttonSize(props: { size?: "small" | "normal" | "large" }) {
  switch (props.size) {
    case "small":
      return css`
        padding: 6px 12px;
        font-size: 0.75rem;
      `;
    case "large":
      return false;
    default:
      return false;
  }
}

function buttonStyles<P>(props: P) {
  return css`
    display: inline-block;
    padding: 10px 20px;
    background: #fff;
    border: 1px solid rgba(118, 132, 158, 0.02); /* Control */
    box-shadow: 0px 1px 2px rgba(118, 132, 158, 0.1),
      inset 0px 0px 1px rgba(118, 132, 158, 0.03);
    border-radius: 4px;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    color: ${p => p.theme.colors.grey};
    &:hover {
      color: ${p => p.theme.colors.blue};
      border: 1px solid ${p => p.theme.colors.blue};
    }

    ${buttonSize};
  `;
}

export const Button = styled.button`
  ${buttonStyles};
  max-height: 50px;
`;

export const LinkButton = styled(Link)`
  ${buttonStyles}
`;

export const ButtonedLink = styled.a`
  ${buttonStyles}
`;
