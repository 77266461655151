import React, { useState } from "react";
import Page, { PageButtons } from "./Page";
import Select from "react-select";
import Moment from "react-moment";
import * as S from "./styles";
import * as SVG from "./svgs";
import { LocationAtAGlance } from "./Location";
import { Link, useLocation } from "react-router-dom";
import Table, { TR, TD } from "./Table";
import { Query } from "@apollo/react-components";
import Tags, { Tag } from "./Tags";
import { match } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { achievementAwardCategoryListQuery } from "./queries/achievementAwardSubmission";
import {
  AchievementAwardSubmissionListQueryData,
  SortValue,
  BooleanSortValue,
  FilterValue,
  AchievementAwardCategory,
} from "./apiTypes";
import { achievementAwardSubmissionListQuery } from "./queries/achievementAwardSubmission";

const AchievementAwards: React.FunctionComponent<{
  match: match;
  companyId?: string;
}> = ({ match, companyId }) => {
  const { data, loading, error } = useQuery<{
    achievementAwardCategories: AchievementAwardCategory[];
  }>(achievementAwardCategoryListQuery);

  const sortOptions = [
    { label: "Name A-Z", value: "NAME_ASC" },
    { label: "Name Z-A", value: "NAME_DESC" },
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
    { label: "Group ID (Descending)", value: "GROUP_ID_DESC" },
    { label: "Group ID (Ascending)", value: "GROUP_ID_ASC" },
  ];
  
  const newDate = new Date();
  let yearOptions = newDate.getMonth() > 6 ? [
    {
      label: `${newDate.getFullYear() + 1}`,
      value: `${newDate.getFullYear() + 1}`,
    },
  ] : [];
  const currentYear = newDate.getFullYear();
  for (let i = currentYear; i >= 2020; i--) {
    yearOptions.push({ label: `${i}`, value: `${i}` });
  }
  const statuses = [
    { label: "All", value: undefined },
    { label: "Archived", value: "ARCHIVED" },
    // { label: "Past Due", value: "PASTDUE" },
  ];
  const categories =
    data &&
    data.achievementAwardCategories.map((c) => {
      return { label: c.name, value: c.id };
    });

  const awardTiers = [
    { label: "Gold Award Winner", value: "GOLD" },
    { label: "Silver Award Winner", value: "SILVER" },
    { label: "Bronze Award Winner", value: "BRONZE" },
    { label: "N/A", value: undefined },
  ];
  const sampleOptions = [
    { label: "N/A", value: undefined },
    { label: "Samples Received", value: true },
    { label: "No Samples Received", value: false },
  ];

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const [status, setStatus] = useState<SortValue>();
  const [awardFilters, setAwardFilters] = useState<SortValue>();
  const [categoryFilters, setCategoryFilters] = useState<FilterValue>(
    categories
  );
  const [samplesReceived, setSamplesReceived] = useState<BooleanSortValue>();
  const [year, setYear] = useState<SortValue>(yearOptions[0]);

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleStatus = (newValues: FilterValue) => {
    setStatus(newValues);
  };
  const handleAwardFilters = (newValues: FilterValue) => {
    setAwardFilters(newValues);
  };
  const handleCategoryFilters = (newValues: FilterValue) => {
    setCategoryFilters(newValues);
  };
  const handleSamplesReceived = (newValue: BooleanSortValue) => {
    setSamplesReceived(newValue);
  };
  const handleYear = (newValues: SortValue) => {
    setYear(newValues);
  };

  const query = new URLSearchParams(useLocation().search);
  const pageSize = 24;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;
  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "NAME_ASC",
    filter: {
      companyId: companyId ? companyId : undefined,
      archived:
        status &&
        !Array.isArray(status) &&
        (status as { label: string; value: string }).value === "ARCHIVED",
      awardTier:
        awardFilters && !Array.isArray(awardFilters)
          ? (awardFilters as { label: string; value: string }).value
          : undefined,
      categoryIds: Array.isArray(categoryFilters)
        ? categoryFilters
            .map((f) => f.value)
            .filter((f) => categoryFilters.some((cat) => cat.value === f))
        : undefined,
      samplesReceived:
        samplesReceived && !Array.isArray(samplesReceived)
          ? (samplesReceived as { label: string; value: boolean }).value
          : undefined,
    },
    year:
      year && !Array.isArray(year)
        ? parseInt((year as { label: string; value: string }).value)
        : new Date().getFullYear(),
  };

  return (
    <Page>
      <Query<AchievementAwardSubmissionListQueryData>
        query={achievementAwardSubmissionListQuery}
        fetch-policy="network-only"
        variables={queryVars}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Oops.</div>;
          if (
            !data ||
            !data.achievementAwardSubmissions ||
            !Array.isArray(data.achievementAwardSubmissions.edges)
          )
            return <div>No data.</div>;
          return (
            <>
              <S.Flex justifyContent="space-between" mb={3} mx={3}>
                <S.Title>
                  Total Entries: {data.achievementAwardSubmissions.count}
                </S.Title>
              </S.Flex>
              <S.Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mx={3}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <label>SORT BY</label>
                  <Select
                    closeMenuOnSelect={true}
                    onChange={handleSort}
                    options={sortOptions}
                    value={sort}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <label>FILTER BY</label>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Status
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={false}
                        onChange={handleStatus}
                        options={statuses}
                        value={status}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Submission Year
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleYear}
                        options={yearOptions}
                        value={year}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Categories
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={handleCategoryFilters}
                        options={categories}
                        value={categoryFilters}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Award Tier
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleAwardFilters}
                        options={awardTiers}
                        value={awardFilters}
                      />
                    </div>
                  </S.Flex>
                  <S.Flex
                    alignItems="center"
                    justifyContent="space-between"
                    my={2}
                  >
                    Samples Status
                    <div
                      style={{
                        flexBasis: "70%",
                        marginLeft: "10",
                      }}
                    >
                      <Select
                        closeMenuOnSelect={true}
                        onChange={handleSamplesReceived}
                        options={sampleOptions}
                        value={samplesReceived}
                      />
                    </div>
                  </S.Flex>
                </div>
                <div style={{ marginLeft: 20, marginTop: 26 }}>
                  <S.ButtonedLink
                    size="small"
                    href="https://data.heroku.com/dataclips/xnfahkrxyhkyjpfbnnauaohkexhr.csv"
                    target="_blank"
                    download="https://data.heroku.com/dataclips/xnfahkrxyhkyjpfbnnauaohkexhr.csv"
                  >
                    <S.Flex alignItems="center">
                      <SVG.DownloadSvg />
                      Download
                    </S.Flex>
                  </S.ButtonedLink>
                </div>
              </S.Flex>
              <PageButtons
                topMargin={4}
                pageInfo={data.achievementAwardSubmissions.pageInfo}
                basePath="awards/achievement"
              />
              <S.Panel>
                <Table cols="80px 3fr 2fr 2fr 1fr 1fr">
                  <TR header>
                    <TD>GROUP ID</TD>
                    <TD>NAME</TD>
                    <TD>COMPANY</TD>
                    <TD>PLANT</TD>
                    <TD>SUBMISSION DATE</TD>
                    <TD />
                  </TR>
                  {data.achievementAwardSubmissions.count === 0 && <S.Box>No entries found.</S.Box>}
                  {data.achievementAwardSubmissions.edges.map((edge) => {
                    const achievementAwardSubmission = edge.node;
                    if (!achievementAwardSubmission) return <React.Fragment />;
                    const primaryImage = achievementAwardSubmission.images.filter(
                      (image) => image.preferred
                    );
                    const categories =
                      (achievementAwardSubmission.categories &&
                        achievementAwardSubmission.categories.map(
                          (category) => {
                            const award =
                              achievementAwardSubmission.awards &&
                              achievementAwardSubmission.awards.filter(
                                (award) => award.category.id === category.id
                              );
                            return {
                              name: category.name,
                              tier: award && award[0] && award[0].tier,
                            };
                          }
                        )) ||
                      [];
                    return (
                      <TR key={achievementAwardSubmission.id} pt={4} pb={2}>
                        <TD>{achievementAwardSubmission.groupId}</TD>
                        <TD>
                          {primaryImage[0] &&
                            primaryImage[0].file &&
                            primaryImage[0].file.url && (
                              <img
                                style={{ marginRight: 10 }}
                                src={primaryImage[0].file.url}
                                width="80"
                              />
                            )}
                          <Link
                            to={`${match.path}/${achievementAwardSubmission.id}`}
                          >
                            <S.H3>{achievementAwardSubmission.name}</S.H3>
                          </Link>
                        </TD>
                        <TD>
                          {achievementAwardSubmission.company && (
                            <Link
                              to={`/companies/${achievementAwardSubmission.company.id}`}
                            >
                              <S.Title>
                                {achievementAwardSubmission.company.name}
                              </S.Title>
                            </Link>
                          )}
                        </TD>
                        <TD>
                          {achievementAwardSubmission.location && (
                            <Link
                              to={`/companies/${achievementAwardSubmission.company.id}/plants`}
                            >
                              <S.Title>
                                {achievementAwardSubmission.location &&
                                  achievementAwardSubmission.location.name}
                              </S.Title>
                              {achievementAwardSubmission.location && (
                                <LocationAtAGlance
                                  location={achievementAwardSubmission.location}
                                  email={false}
                                  phone={false}
                                  employees={false}
                                />
                              )}
                            </Link>
                          )}
                        </TD>
                        <TD>
                          <Moment format="MMMM D, YYYY">
                            {achievementAwardSubmission.insertedAt}
                          </Moment>
                        </TD>
                        <TD>
                          <S.LinkButton
                            size="small"
                            to={`${match.path}/${achievementAwardSubmission.id}`}
                          >
                            View
                          </S.LinkButton>
                        </TD>
                        <Tags>
                          <S.Flex>
                            {achievementAwardSubmission.archivedAt && (
                              <Tag color="lightorange">Archived</Tag>
                            )}
                            {achievementAwardSubmission.highestAchievement && (
                              <Tag
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                color="lightpurple"
                              >
                                <S.Flex alignItems="center">
                                  Highest Achievement Award
                                </S.Flex>
                              </Tag>
                            )}
                            {categories &&
                              categories.map((category) => (
                                <Tag
                                  key={category.name}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <S.Flex alignItems="center">
                                    {category.tier &&
                                    category.tier === "GOLD" ? (
                                      <SVG.AwardsSvg color="gold" />
                                    ) : category.tier &&
                                      category.tier === "SILVER" ? (
                                      <SVG.AwardsSvg color="silver" />
                                    ) : category.tier &&
                                      category.tier === "BRONZE" ? (
                                      <SVG.AwardsSvg color="bronze" />
                                    ) : (
                                      <></>
                                    )}
                                    {category.name}
                                  </S.Flex>
                                </Tag>
                              ))}
                            {achievementAwardSubmission.samplesReceived ? (
                              <Tag
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                color="lightgreen"
                              >
                                {" "}
                                <S.Flex alignItems="center">
                                  Samples Received
                                </S.Flex>
                              </Tag>
                            ) : (
                              <Tag
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                color="lightyellow"
                              >
                                {" "}
                                <S.Flex alignItems="center">
                                  Awaiting Samples
                                </S.Flex>
                              </Tag>
                            )}
                          </S.Flex>
                        </Tags>
                      </TR>
                    );
                  })}
                </Table>
              </S.Panel>
              <PageButtons
                topMargin={4}
                pageInfo={data.achievementAwardSubmissions.pageInfo}
                basePath="awards/achievement"
              />
            </>
          );
        }}
      </Query>
    </Page>
  );
};

export default AchievementAwards;
