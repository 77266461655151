import { gql } from "graphql.macro";

export const activateContactMutation = gql`
  mutation activateContactMutation($input: ActivateContactInput!) {
    activateContact(input: $input) {
      id
    }
  }
`;

export const createContactMutation = gql`
  mutation createContactMutation($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      firstName
      lastName
      middleName
      nickname
      prefix
      suffix
      allergies
      golfHandedness
      birthday
      groups {
        id
      }
      photoUrl
    }
  }
`;

export const deactivateContactMutation = gql`
  mutation deactivateContactMutation($input: DeactivateContactInput!) {
    deactivateContact(input: $input) {
      id
    }
  }
`;

export const updateContactMutation = gql`
  mutation updateContactMutation($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      firstName
      lastName
      middleName
      nickname
      prefix
      suffix
      allergies
      golfHandedness
      groups {
        id
      }
      photoUrl
    }
  }
`;
