import { gql } from "graphql.macro";

export const achievementAwardQuery = gql`
  query achievementAwardQuery($submissionId: ID!) {
    achievementAwardSubmission(submissionId: $submissionId) {
      id
      awards {
        category {
          id
          name
        }
        tier
      }
      categories {
        id
        name
      }
      certified
      company {
        id
        name
        logoUrl
        activeMembership {
          id
          endedOn
        }
      }
      contact {
        id
        firstName
        lastName
        photoUrl
      }
      description
      designerFirm
      designerName
      documents {
        file {
          id
          filename
          url
        }
      }
      endUser
      groupId
      highestAchievement
      images {
        file {
          id
          url
        }
        preferred
      }
      insertedAt
      keySuppliers
      location {
        id
        company {
          id
        }
        addresses {
          id
          city
          country
          postalCode
          state
          streetAddress
          suiteNumber
          preferred
        }
        name
      }
      name
      produced
      samplesReceived
      stripeCheckoutSessionId
      submissionCategories {
        category {
          id
          name
        }
        defense
      }
      updatedAt
      year
      archivedAt
    }
  }
`;

export const achievementAwardSubmissionListQuery = gql`
  query achievementAwardSubmissionListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $year: Int
    $orderBy: AchievementAwardSubmissionsOrderByInput
    $filter: AchievementAwardSubmissionsFilterInput
  ) {
    achievementAwardSubmissions(
      first: $first
      last: $last
      before: $before
      after: $after
      year: $year
      orderBy: $orderBy
      filter: $filter
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          awards {
            category {
              id
              name
            }
            tier
          }
          categories {
            id
            name
          }
          certified
          company {
            id
            name
            activeMembership {
              id
              endedOn
            }
          }
          location {
            id
            name
            addresses {
              id
              city
              country
              postalCode
              state
              preferred
            }
            company {
              id
              logoUrl
              activeMembership {
                id
              }
            }
          }
          description
          groupId
          highestAchievement
          images {
            file {
              id
              url
            }
            preferred
          }
          insertedAt
          samplesReceived
          name
          year
          archivedAt
        }
      }
    }
  }
`;

export const achievementAwardCategoryQuery = gql`
  query achievementAwardCategoryQuery($categoryId: ID!) {
    achievementAwardCategory(categoryId: $categoryId) {
      id
      description
      name
      submission {
        id
        awards {
          category {
            id
            name
          }
          tier
        }
        highestAchievement
        name
        company {
          id
          name
        }
        description
        year
      }
    }
  }
`;

export const achievementAwardCategoryListQuery = gql`
  query achievementAwardCategoryListQuery {
    achievementAwardCategories {
      id
      description
      name
    }
  }
`;
