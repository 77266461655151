import React from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import moment from "moment";
import Table, { TR, TD } from "./Table";
import { Query } from "@apollo/react-components";
import Tags, { Tag } from "./Tags";
import { Link, useLocation } from "react-router-dom";
import { DateRange } from "./Date";
import { Event } from "./apiTypes";

const EventsList: React.FunctionComponent<{
  events: (Event | undefined)[];
  title?: boolean;
}> = ({ events, title }) => {
  return (
    <>
      <S.Flex flexWrap="wrap">
        <S.Panel width="100%">
          {title && (
            <S.Box my={0} pb={3}>
              <S.H2>Events</S.H2>
            </S.Box>
          )}
          <Table cols="2fr 2fr 120px 2fr 120px">
            <TR header>
              <TD>DATES</TD>
              <TD>DETAILS</TD>
              <TD>REGISTRANTS</TD>
              <TD>REGISTRATION STATUS</TD>
              <TD />
            </TR>
            {events.map(event => {
              if (!event) return null;
              const today = new Date();
              const hasRegistration =
                event.registrationOpensAt && event.registrationClosesAt;
              const open =
                event.registrationOpensAt &&
                event.registrationClosesAt &&
                new Date(event.registrationOpensAt) <= today &&
                today <= new Date(event.registrationClosesAt);
              const closed =
                event.registrationOpensAt &&
                event.registrationClosesAt &&
                new Date(event.registrationOpensAt) <= today &&
                today >= new Date(event.registrationClosesAt);
              return (
                <TR key={event.id} py={3}>
                  <TD>
                    <b>
                      {event.startsAt && event.endsAt ? (
                        <DateRange start={event.startsAt} end={event.endsAt} />
                      ) : (
                        <></>
                      )}
                    </b>
                  </TD>
                  <TD>
                    <Link to={`/events/${event.id}`}>
                      <S.Title>{event.title}</S.Title>
                    </Link>
                  </TD>
                  {hasRegistration ? (
                    <>
                      <TD>
                        <SVG.PersonsSvg />
                        {(event.registrations && event.registrations.count) ||
                          0}
                      </TD>
                      {open ? (
                        <TD>
                          <SVG.CheckSvg /> Open until{" "}
                          {moment(event.registrationClosesAt).format(
                            "MMMM D, YYYY"
                          )}
                        </TD>
                      ) : closed ? (
                        <TD>
                          <SVG.CrossSvg /> Closed since{" "}
                          {moment(event.registrationClosesAt).format(
                            "MMMM D, YYYY"
                          )}
                        </TD>
                      ) : (
                        <TD>
                          Starts{" "}
                          {moment(event.registrationOpensAt).format(
                            "MMMM D, YYYY"
                          )}
                        </TD>
                      )}
                    </>
                  ) : (
                    <>
                      <TD>No Registration Accepted</TD>
                      <TD />
                    </>
                  )}
                  <TD>
                    <S.LinkButton size="small" to={`/events/${event.id}`}>
                      View Event
                    </S.LinkButton>
                  </TD>
                </TR>
              );
            })}
          </Table>
        </S.Panel>
      </S.Flex>
    </>
  );
};

export default EventsList;
