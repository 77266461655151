import React, { useState, useCallback } from "react";
import Tags, { Tag } from "./Tags";
import Avatar, { AvatarList } from "./UserAvatar";
import styled from "styled-components";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Select as FormSelect } from "./StyledForm";
import Select, { ValueType } from "react-select";
import { Field } from "formik";
import { CompanyTags } from "./Company";
import Page, { PageButtons } from "./Page";
import NewCompany from "./NewCompany";
import { Query } from "@apollo/react-components";
import { Link, useLocation } from "react-router-dom";
import { primaryContact, authorizedContacts } from "./util";
import { useFormikContext } from "formik";
import { CompanyListQueryData } from "./apiTypes";
import { UrlList, PrimaryUrl } from "./Urls";
import { Logo } from "./Image";
import { PhoneNumberList, PrimaryPhoneNumber } from "./PhoneNumbers";
import { PrimaryEmail, EmailList } from "./Emails";
import { PrimaryAddress } from "./Addresses";
import { companyListQuery, companySearchQuery } from "./queries/company";
import { SortValue, FilterValue } from "./apiTypes";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import { useQuery } from "@apollo/react-hooks";

const CompanyListWrapper = styled.div`
  @media screen and (max-width: 1350px) {
    ${S.Box}.company-wrapper {
      ${S.Flex} {
        ${S.Box} {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    ${S.Box}.company-wrapper {
      width: 100%;
    }
  }
`;

const Companies = () => {
  return (
    <Page>
      <TopNav />
      <CompanyList />
    </Page>
  );
};

const CompanyList: React.FunctionComponent = () => {
  const query = new URLSearchParams(useLocation().search);
  const pageSize = 24;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;

  const newDialog = useDialogState();
  const sortOptions = [
    { label: "Name A-Z", value: "NAME_ASC" },
    { label: "Name Z-A", value: "NAME_DESC" },
    // { label: "Duration of Membership", value: "duration of membership" },
    // { label: "Account Balance", value: "account balance" },
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
  ];

  const membershipCategoryFilters = [
    { label: "Academic Members", value: "Academic" },
    { label: "Associate Members", value: "Associate" },
    { label: "Converter Members", value: "Converter" },
    { label: "Trade Press Members", value: "Trade Press" },
    {
      label: "International Converter Members",
      value: "International",
    },
    {
      label: "Supporting Members (Banking Sector)",
      value: "Supporting - Banking",
    },
    {
      label: "Supporting Members (Other Sectors)",
      value: "Supporting",
    },
  ];

  const statuses = [
    { label: "Unarchived", value: undefined },
    { label: "Archived", value: "ARCHIVED" },
    // { label: "Past Due", value: "PASTDUE" },
  ];

  const filterOptions = membershipCategoryFilters.concat([
    // { label: "Non-Members", value: "Non-Members" },
    // { label: "Active", value: "Active" },
    // { label: "Deactivated", value: "Deactivated" }
  ]);

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);
  const [statusFilters, setStatusFilters] = useState<SortValue>(statuses[0]);
  const [categories, setCategories] = useState<FilterValue>([]);

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleStatusFilters = (newValues: FilterValue) => {
    setStatusFilters(newValues);
  };
  const handleCategories = (newValues: FilterValue) => {
    setCategories(newValues);
  };

  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "NAME_ASC",
    filter: {
      active:
        statusFilters &&
        !Array.isArray(statusFilters) &&
        !(
          (statusFilters as { label: string; value: string }).value ===
          "ARCHIVED"
        ),
      membershipCategories: Array.isArray(categories)
        ? categories
            .map((c) => c.value)
            .filter((c) =>
              membershipCategoryFilters.some((cat) => cat.value === c)
            )
        : [],
    },
  };

  const { loading, error, data } = useQuery<CompanyListQueryData>(
    companyListQuery,
    { variables: queryVars }
  );

  if (loading && !data) return <CompanyListWrapper />;
  if (error) return <CompanyListWrapper>Oops.</CompanyListWrapper>;
  if (!data || !data.companies || !Array.isArray(data.companies.edges))
    return <CompanyListWrapper>No data.</CompanyListWrapper>;

  return (
    <CompanyListWrapper>
      <S.Flex justifyContent="space-between" mx={3}>
        <S.Title>Total Companies: {data.companies.count}</S.Title>
        <DialogDisclosure {...newDialog} as={S.Button}>
          Add New Company
        </DialogDisclosure>
      </S.Flex>
      <S.Flex justifyContent="space-between" alignItems="flex-start" mx={3}>
        <div style={{ flex: 1, marginRight: 20 }}>
          <label>SORT BY</label>
          <Select
            closeMenuOnSelect={true}
            onChange={handleSort}
            options={sortOptions}
            value={sort}
          />
        </div>
        <div style={{ flex: 1, marginLeft: 20 }}>
          <label>FILTER BY</label>
          <S.Flex alignItems="center" justifyContent="space-between" my={2}>
            Status
            <div
              style={{
                flexBasis: "70%",
                marginLeft: "10",
              }}
            >
              <Select
                closeMenuOnSelect={false}
                onChange={handleStatusFilters}
                options={statuses}
                value={statusFilters}
              />
            </div>
          </S.Flex>
          <S.Flex alignItems="center" justifyContent="space-between" my={2}>
            Membership
            <div
              style={{
                flexBasis: "70%",
                marginLeft: "10",
              }}
            >
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={handleCategories}
                options={membershipCategoryFilters}
                value={categories}
              />
            </div>
          </S.Flex>
        </div>
        <div style={{ marginLeft: 20, marginTop: 26 }}>
          <S.ButtonedLink
            size="small"
            href="https://data.heroku.com/dataclips/qfpsyuvwsavxmrkhufybzrqvdqrz.csv"
            target="_blank"
            download="https://data.heroku.com/dataclips/qfpsyuvwsavxmrkhufybzrqvdqrz.csv"
          >
            <S.Flex alignItems="center">
              <SVG.DownloadSvg />
              Download
            </S.Flex>
          </S.ButtonedLink>
        </div>
      </S.Flex>
      <PageButtons
        topMargin={4}
        pageInfo={data.companies.pageInfo}
        basePath="companies"
      />
      <S.Flex flexWrap="wrap">
        {data.companies.edges.map((edge) => {
          const company = edge.node;
          if (!company) return null;
          const primary = primaryContact(company.contactMemberships);

          const contacts = authorizedContacts(company.contactMemberships);

          const recentSalesReport =
            company.salesReports &&
            company.salesReports.length &&
            company.salesReports[company.salesReports.length - 1] &&
            company.salesReports[company.salesReports.length - 1];

          return (
            <S.Box
              key={company.id}
              className="company-wrapper"
              width={1 / 2}
              my={2}
            >
              <S.Panel my={0} height="100%">
                <Tags>
                  <CompanyTags company={company} />
                </Tags>
                <Link to={`/companies/${company.id}`}>
                  <S.Box mt={3}>
                    {company.logoUrl ? <Logo src={company.logoUrl} /> : <></>}
                    <S.H1>{company.name}</S.H1>
                  </S.Box>
                </Link>
                <S.Flex flexWrap="wrap">
                  <S.Box width={1 / 2} pr={3}>
                    <PrimaryAddress subject={company} />
                    <PrimaryEmail subject={company} />
                    <PrimaryUrl subject={company} />
                    <PrimaryPhoneNumber subject={company} />
                  </S.Box>
                  {primary && primary.contact ? (
                    <S.Box width={1 / 2}>
                      <Link to={`/contacts/${primary.contact.id}`}>
                        <S.H3>{`${primary.contact.firstName} ${primary.contact.lastName}`}</S.H3>
                      </Link>
                      <PrimaryAddress subject={primary.contact} />
                      <PrimaryEmail subject={primary.contact} />
                      <PrimaryUrl subject={primary.contact} />
                      <PrimaryPhoneNumber subject={primary.contact} />
                    </S.Box>
                  ) : (
                    <S.Box width={1 / 2}>
                      There is no primary contact on record.
                    </S.Box>
                  )}
                </S.Flex>
                {contacts ? (
                  <AvatarList>
                    {contacts.map((contact) =>
                      contact.contact.photoUrl ? (
                        <Avatar key={contact.id} src={contact.contact.photoUrl} />
                      ) : (
                        <></>
                      )
                    )}
                  </AvatarList>
                ) : (
                  <div>There are no authorized contacts on record.</div>
                )}
              </S.Panel>
            </S.Box>
          );
        })}
      </S.Flex>
      <PageButtons pageInfo={data.companies.pageInfo} basePath="companies" />
      <DialogWithBackdrop {...newDialog}>
        <NewCompany />
      </DialogWithBackdrop>
    </CompanyListWrapper>
  );
};

const CompanySelect = (props: { name: string }) => {
  const formik = useFormikContext<any>();
  const { data, loading, error } = useQuery<CompanyListQueryData>(
    companySearchQuery,
    {
      variables: { 
        first: 800, 
        orderBy: "NAME_ASC",
        filter: {
          active: true
        }
      },
    }
  );

  const handleChange = useCallback(
    ({ value }: any) => {
      formik.setFieldValue(props.name, value);
    },
    [props.name, formik.setFieldValue]
  );

  if (loading && !data) return <div />;
  if (error) return <div>Oops.</div>;
  if (!data || !data.companies || !Array.isArray(data.companies.edges))
    return <div>No data.</div>;

  var companiesList = data.companies.edges
    .map((edge) => {
      const company = edge.node;
      if (!company) return null;
      return { label: company.name, value: company.id };
    })
    .filter(Boolean) as ValueType<{ label: string; value: string }>[];

  return (
    <Field
      id="companyId"
      name="companyId"
      component={FormSelect}
      closeMenuOnSelect={true}
      options={companiesList}
      onChange={handleChange}
    />
  );
};

export default Companies;
export { CompanySelect };
