import React, { useState } from "react";
import Select from "react-select";
import Page, { PageButtons } from "./Page";
import Table, { TR, TD } from "./Table";
import Moment from "react-moment";
import { LocationAtAGlance } from "./Location";
import { Query } from "@apollo/react-components";
import Tags, { Tag } from "./Tags";
import NewAchievementAwardSubmission from "./NewAchievementAwardSubmission";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Link, useLocation } from "react-router-dom";
import {
  Company,
  AchievementAwardSubmissionListQueryData,
  SortValue
} from "./apiTypes";
import {
  achievementAwardSubmissionListQuery
} from "./queries/achievementAwardSubmission";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";

type CompanyAwardsProps = {
  company: Company;
};

const CompanyAwards: React.FunctionComponent<CompanyAwardsProps> = ({
  company,
}) => {
  const sortOptions = [
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
    { label: "Award Year (Newest)", value: "YEAR_DESC" },
    { label: "Award Year (Oldest)", value: "YEAR_ASC" },
    { label: "Name A-Z", value: "NAME_ASC" },
    { label: "Name Z-A", value: "NAME_DESC" },
  ];

  const [sort, setSort] = useState<SortValue>(sortOptions[0]);

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };

  const newDialog = useDialogState();
  const query = new URLSearchParams(useLocation().search);
  const pageSize = 24;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;
  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "YEAR_DESC",
    filter: {
      companyId: company.id,
    },
  };

  return (
    <Page>
      <Query<AchievementAwardSubmissionListQueryData>
        query={achievementAwardSubmissionListQuery}
        fetch-policy="network-only"
        variables={queryVars}
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Oops.</div>;
          if (
            !data ||
            !data.achievementAwardSubmissions ||
            !Array.isArray(data.achievementAwardSubmissions.edges)
          )
            return <div>No data.</div>;
          return (
            <>
              <S.Flex mx={3} justifyContent="space-between">
                <S.Title>
                  Total Awards: {data.achievementAwardSubmissions.count}
                </S.Title>
                <DialogDisclosure {...newDialog} as={S.Button}>
                  Add New Award
                </DialogDisclosure>
              </S.Flex>
              <S.Flex
                justifyContent="space-between"
                alignItems="flex-start"
                mx={3}
                mt={3}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <label>SORT BY</label>
                  <Select
                    closeMenuOnSelect={true}
                    onChange={handleSort}
                    options={sortOptions}
                    value={sort}
                  />
                </div>
              </S.Flex>
              {data.achievementAwardSubmissions.edges && data.achievementAwardSubmissions.edges.length ? <><PageButtons
                topMargin={4}
                pageInfo={data.achievementAwardSubmissions.pageInfo}
                basePath={`companies/${company.id}/awards`}
              />
              <S.Panel>
                <Table cols="80px 3fr 2fr 2fr 1fr 1fr">
                  <TR header>
                    <TD>GROUP ID</TD>
                    <TD>NAME</TD>
                    <TD>COMPANY</TD>
                    <TD>PLANT</TD>
                    <TD>SUBMISSION DATE</TD>
                    <TD />
                  </TR>
                  {data.achievementAwardSubmissions.edges.map((edge) => {
                    const achievementAwardSubmission = edge.node;
                    if (!achievementAwardSubmission) return <React.Fragment />;
                    const primaryImage = achievementAwardSubmission.images.filter(
                      (image) => image.preferred
                    );
                    const categories =
                      (achievementAwardSubmission.categories &&
                        achievementAwardSubmission.categories.map(
                          (category) => {
                            const award =
                              achievementAwardSubmission.awards &&
                              achievementAwardSubmission.awards.filter(
                                (award) => award.category.id === category.id
                              );
                            return {
                              name: category.name,
                              tier: award && award[0] && award[0].tier,
                            };
                          }
                        )) ||
                      [];
                    return (
                      <TR key={achievementAwardSubmission.id} pt={4} pb={2}>
                        <TD>{achievementAwardSubmission.groupId}</TD>
                        <TD>
                          {primaryImage[0] &&
                            primaryImage[0].file &&
                            primaryImage[0].file.url && (
                              <img
                                style={{ marginRight: 10 }}
                                src={primaryImage[0].file.url}
                                width="80"
                                alt={primaryImage[0].file.filename}
                              />
                            )}
                          <Link
                            to={`awards/achievement/${achievementAwardSubmission.id}`}
                          >
                            <S.H3>{achievementAwardSubmission.name}</S.H3>
                          </Link>
                        </TD>
                        <TD>
                          {achievementAwardSubmission.company && (
                            <Link
                              to={`/companies/${achievementAwardSubmission.company.id}`}
                            >
                              <S.Title>
                                {achievementAwardSubmission.company.name}
                              </S.Title>
                            </Link>
                          )}
                        </TD>
                        <TD>
                          {achievementAwardSubmission.location && (
                            <Link
                              to={`/companies/${achievementAwardSubmission.company.id}/plants`}
                            >
                              <S.Title>
                                {achievementAwardSubmission.location &&
                                  achievementAwardSubmission.location.name}
                              </S.Title>
                              {achievementAwardSubmission.location && (
                                <LocationAtAGlance
                                  location={achievementAwardSubmission.location}
                                  email={false}
                                  phone={false}
                                  employees={false}
                                />
                              )}
                            </Link>
                          )}
                        </TD>
                        <TD>
                          <Moment format="MMMM D, YYYY">
                            {achievementAwardSubmission.insertedAt}
                          </Moment>
                        </TD>
                        <TD>
                          <S.LinkButton
                            size="small"
                            to={`awards/achievement/${achievementAwardSubmission.id}`}
                          >
                            View
                          </S.LinkButton>
                        </TD>
                        <Tags>
                          <S.Flex>
                            {achievementAwardSubmission.archivedAt && (
                              <Tag color="lightorange">Archived</Tag>
                            )}
                            {achievementAwardSubmission.highestAchievement && (
                              <Tag
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                color="lightpurple"
                              >
                                <S.Flex alignItems="center">
                                  Highest Achievement Award
                                </S.Flex>
                              </Tag>
                            )}
                            {categories &&
                              categories.map((category) => (
                                <Tag
                                  key={category.name}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <S.Flex alignItems="center">
                                    {category.tier &&
                                    category.tier === "GOLD" ? (
                                      <SVG.AwardsSvg color="gold" />
                                    ) : category.tier &&
                                      category.tier === "SILVER" ? (
                                      <SVG.AwardsSvg color="silver" />
                                    ) : category.tier &&
                                      category.tier === "BRONZE" ? (
                                      <SVG.AwardsSvg color="bronze" />
                                    ) : (
                                      <></>
                                    )}
                                    {category.name}
                                  </S.Flex>
                                </Tag>
                              ))}
                            {achievementAwardSubmission.samplesReceived ? (
                              <Tag
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                color="lightgreen"
                              >
                                {" "}
                                <S.Flex alignItems="center">
                                  Samples Received
                                </S.Flex>
                              </Tag>
                            ) : (
                              <Tag
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                color="lightyellow"
                              >
                                {" "}
                                <S.Flex alignItems="center">
                                  Awaiting Samples
                                </S.Flex>
                              </Tag>
                            )}
                          </S.Flex>
                        </Tags>
                      </TR>
                    );
                  })}
                </Table>
              </S.Panel>
              <PageButtons
                topMargin={4}
                pageInfo={data.achievementAwardSubmissions.pageInfo}
                basePath={`companies/${company.id}/awards`}
              /></> : <S.Box mx={3}>There are no awards for this company.</S.Box>}
              <DialogWithBackdrop {...newDialog}>
                <NewAchievementAwardSubmission companyId={company.id} />
              </DialogWithBackdrop>
            </>
          );
        }}
      </Query>
    </Page>
  );
};

export default CompanyAwards;
