import { gql } from "graphql.macro";

export const membershipListQuery = gql`
  query membershipListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: MembershipFilterInput
  ) {
    memberships(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          categories
          deactivateWhenDelinquent
          deactivatedAt
          easeInto
          endedOn
          paymentInterval
          startedOn
          company {
            id
            name
          }
          insertedAt
          updatedAt
        }
      }
    }
  }
`;

export const membershipQuery = gql`
  query membershipQuery($membershipId: ID!) {
    membership(membershipId: $membershipId) {
      id
      categories
      deactivateWhenDelinquent
      deactivatedAt
      easeInto
      endedOn
      paymentInterval
      startedOn
      insertedAt
      updatedAt
    }
  }
`;
