import React, { useCallback, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { DownarrowSvg } from "./svgs";

const TreeParentWrapper = styled.a<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  line-height: 1;
  margin-left: -14px;

  & > svg {
    transform: rotate(-90deg);
    margin-right: 4px;
  }

  ${p =>
    p.isOpen &&
    css`
      & > svg {
        transform: rotate(0deg);
      }
    `}
  &:hover {
    color: ${p => p.theme.colors.blue};
  }
`;

const TreeItemWrapper = styled.li`
  display: block;
  margin: 6px 0 6px 12px;
`;

const TreeWrapper = styled.ul<{ isOpen?: boolean }>`
  display: ${p => (p.isOpen ? "block" : "none")};
  margin: 0;
  padding-left: 18px;

  & & {
    margin: 0;
  }
`;

type TreeProps = {
  _isTreeOpen?: boolean;
  _toggleTree?: () => void;
};

const Tree: React.FC<TreeProps> = ({ _isTreeOpen = true, children }) => (
  <TreeWrapper isOpen={_isTreeOpen}>{children}</TreeWrapper>
);

type TreeItemProps = {};

export const TreeItem: React.FC<TreeItemProps> = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => {
    setOpen(open => !open);
  }, []);

  const clones = React.Children.map(children, (child: any) => {
    if (!child) return null;
    if (typeof child === "string") return child;
    return React.cloneElement(child, {
      _isTreeOpen: isOpen,
      _toggleTree: toggle
    });
  });

  return <TreeItemWrapper children={clones} />;
};

type TreeParentProps = {
  _isTreeOpen?: boolean;
  _toggleTree?: () => void;
  children: React.ReactNode;
};

export const TreeParent: React.FC<TreeParentProps> = ({
  _isTreeOpen,
  _toggleTree,
  children
}) => {
  return (
    <TreeParentWrapper isOpen={_isTreeOpen} onClick={_toggleTree}>
      <DownarrowSvg />
      {children}
    </TreeParentWrapper>
  );
};

export default Tree;
