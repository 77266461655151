import React from "react";
import moment from "moment";
import * as S from "./styles";
import * as SVG from "./svgs";
import Currency from "react-currency-formatter";
import { Query } from "@apollo/react-components";
import { RouteComponentProps } from "react-router-dom";
import Notes, { ActivityFeed } from "./Notes";
import Table, { TR, TD } from "./Table";
import Page from "./Page";
import TopNav from "./TopNav";
import InvoiceNav from "./InvoiceNav";
import { Invoice as InvoiceType } from "./apiTypes";
import { updateInvoiceMutation } from "./mutations/invoice";
import { InvoiceQuery } from "./queries/invoice";

type InvoiceProps = RouteComponentProps<{ invoiceId: string }>;

const Invoice: React.FunctionComponent<InvoiceProps> = ({ match }) => {
  return (
    <Query<{ invoice: InvoiceType }, { invoiceId: string }>
      query={InvoiceQuery}
      variables={{ invoiceId: match.params.invoiceId }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (error)
          return (
            <Page>
              <TopNav />
              There is no Invoice associated with this id/url.
            </Page>
          );
        if (!data || !data.invoice)
          return (
            <Page>
              <TopNav />
              There is no Invoice associated with this id/url.
            </Page>
          );
        const invoice = data.invoice;
        if (!invoice)
          return (
            <Page>
              <TopNav />
              There is no Invoice associated with this id/url.
            </Page>
          );

        return (
          <>
            <InvoiceNav invoice={invoice} />
            <Page>
              <S.Panel>
                <Table cols="1fr 1fr 2fr 1fr">
                  <TR header>
                    <TD>SERVICE DATE</TD>
                    <TD>QUANTITY</TD>
                    <TD>DESCRIPTION</TD>
                    <TD>AMOUNT</TD>
                  </TR>
                  {invoice.lines &&
                    invoice.lines.map((line) => {
                      if (!line) return <React.Fragment />;
                      return (
                        <TR py={2} key={line.id}>
                          <TD>{moment(line.updatedAt).format("MM/DD/YYY")}</TD>
                          <TD>{line.quantity}</TD>
                          <TD>{line.description}</TD>
                          <TD>
                            <Currency
                              quantity={line.amount}
                              currency={line.currency}
                            />
                          </TD>
                        </TR>
                      );
                    })}
                </Table>
              </S.Panel>
              <S.Flex justifyContent="flex-end">
                <S.Box width={1 / 2}>
                  <S.UnderlinedHeader>Payments</S.UnderlinedHeader>
                  <Table cols="1fr 1fr 1fr">
                    <TR py={2} header>
                      <TD>AMOUNT</TD>
                      <TD>PAYMENT DETAILS</TD>
                      <TD>STATUS</TD>
                    </TR>
                    {invoice.payments &&
                      invoice.payments.map((payment) => (
                        <TR key={payment.id}>
                          <TD>
                            <Currency quantity={payment.amount} />
                          </TD>
                          <TD>
                            <S.P>
                              {payment.paymentMethod}
                              <span>
                                Stripe Charge ID: {payment.stripeChargeId}
                              </span>
                            </S.P>
                          </TD>
                          <TD>
                            {payment.succeeded ? (
                              <S.P>
                                Successful
                                <span>
                                  {moment(payment.clearedAt).format(
                                    "MM/DD/YYY h:mma"
                                  )}
                                </span>
                              </S.P>
                            ) : (
                              <S.P>
                                Failed
                                <span>
                                  {moment(payment.insertedAt).format(
                                    "MM/DD/YYY h:mma"
                                  )}
                                </span>
                              </S.P>
                            )}
                          </TD>
                        </TR>
                      ))}
                  </Table>
                </S.Box>
                <S.Box width={1 / 2}>
                  <Table cols="3fr 3fr">
                    <TR py={2} borderless>
                      <TD>
                        <b>SUBTOTAL</b>
                      </TD>
                      <TD>{invoice.subtotal}</TD>
                    </TR>
                    <TR py={2} borderless>
                      <TD>
                        <b>TAX</b>
                      </TD>
                      <TD>{invoice.tax}</TD>
                    </TR>
                    <TR py={2}>
                      <TD>
                        <b>TOTAL</b>
                      </TD>
                      <TD>{invoice.total}</TD>
                    </TR>
                    <TR py={3} borderless>
                      <TD>
                        <b>BALANCE DUE</b>
                      </TD>
                      <TD>
                        <b>
                          {invoice.total ? (
                            <Currency quantity={invoice.total} />
                          ) : (
                            <></>
                          )}
                        </b>
                      </TD>
                    </TR>
                  </Table>
                </S.Box>
              </S.Flex>
              {/* <Notes subject={invoice} />
        <ActivityFeed subject={invoice} /> */}
            </Page>
          </>
        );
      }}
    </Query>
  );
};

export default Invoice;
