import {
  useRef,
  useState,
  useMemo,
  useEffect,
  useReducer,
  useCallback,
  Reducer
} from "react";

export function usePrevious<V>(value: V): V | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<V>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useDebounce<V>(value: V, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<V>(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useSticky(
  ref: React.RefObject<HTMLElement>,
  sticky = true
): boolean {
  const initialOffset = useRef<number | null>(null);
  const [stuck, setStuck] = useState<boolean>(false);

  useEffect(() => {
    if (sticky) {
      if (ref.current && initialOffset.current === null)
        initialOffset.current =
          ref.current.getBoundingClientRect().top + window.pageYOffset;

      const onScroll = () => {
        const top = initialOffset.current || 0;
        const scrollY = window.pageYOffset;
        const shouldStick = top - scrollY <= 0;
        if (stuck !== shouldStick) setStuck(shouldStick);
      };
      window.addEventListener("scroll", onScroll);
      onScroll();

      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [sticky, stuck, ref.current]);

  return stuck;
}

type EditToggleReturn = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export function useEditToggle(): EditToggleReturn {
  const [isOpen, setOpen] = useState(false);

  const props = useMemo(
    () => ({
      isOpen,
      open: () => setOpen(true),
      close: () => setOpen(false),
      toggle: () => setOpen(open => !open)
    }),
    [isOpen]
  );

  return props;
}

enum PromiseStatus {
  pending,
  settled,
  rejected
}

type PromiseState<D> = {
  data: D | null;
  error: any;
  status: PromiseStatus;
};

enum PromiseActionType {
  settled,
  rejected,
  pending
}

type PromisePendingAction = {
  type: PromiseActionType.pending;
};

type PromiseSettledAction<D> = {
  type: PromiseActionType.settled;
  payload: D;
};

type PromiseRejectedAction = {
  type: PromiseActionType.rejected;
  error: any;
};

type PromiseAction<D> =
  | PromisePendingAction
  | PromiseSettledAction<D>
  | PromiseRejectedAction;

function promiseReducer<D>(
  state: PromiseState<D>,
  action: PromiseAction<D>
): PromiseState<D> {
  switch (action.type) {
    case PromiseActionType.pending:
      return { ...state, status: PromiseStatus.pending };
    case PromiseActionType.settled:
      return {
        status: PromiseStatus.settled,
        data: action.payload,
        error: null
      };
    case PromiseActionType.rejected:
      return {
        status: PromiseStatus.rejected,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
}

export function usePromise<D>(promiseFn: () => Promise<D>) {
  const [state, dispatch] = useReducer<
    Reducer<PromiseState<D>, PromiseAction<D>>
  >(promiseReducer, { data: null, error: null, status: PromiseStatus.pending });

  const reload = useCallback(() => {
    dispatch({ type: PromiseActionType.pending });

    return promiseFn()
      .then(data =>
        dispatch({ type: PromiseActionType.settled, payload: data })
      )
      .catch(error => {
        // logger.warn(`Promise rejected: ${error}`);
        dispatch({ type: PromiseActionType.rejected, error: error });
      });
  }, [promiseFn, dispatch]);

  // Fire promise on mount
  useEffect(() => {
    reload();
  }, [reload]);

  const returnVal = useMemo(
    () => ({
      data: state.data,
      error: state.error,
      isRejected: state.status === PromiseStatus.rejected,
      isPending: state.status === PromiseStatus.pending,
      isSettled: state.status === PromiseStatus.settled,
      reload: reload
    }),
    [state, reload]
  );

  return returnVal;
}
