import React from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Link } from "react-router-dom";
import Searchbar from "./Searchbar";
import Tags, { Tag } from "./Tags";
import { useMutation } from "@apollo/react-hooks";
import { match } from "react-router-dom";
import PageNav, { NavInfo, BackButton, TopPageNav } from "./PageNav";
import { Download, UseMutationReturn } from "./apiTypes";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { archiveDownloadMutation } from "./mutations/download";

type DownloadNavProps = {
  file: Download;
  match: match<{ downloadId: string }>;
};

function EditDownloadMenu({ file }: { file: Download }) {
  const menu = useMenuState();
  const [archiveDownload]: UseMutationReturn<
    { archiveDownload: Download },
    { input: { downloadId: string } }
  > = useMutation(archiveDownloadMutation, {
    refetchQueries: ["downloadQuery"],
  });

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit File
      </MenuDisclosure>
      <Menu {...menu}>
        <MenuItem
          {...menu}
          onClick={async () => {
            if (window.confirm("Are you sure you want to archive this File?")) {
              await archiveDownload({
                variables: { input: { downloadId: file.id } },
              });
              menu.hide();
            }
          }}
        >
          Archive File
        </MenuItem>
        <MenuItem {...menu}>Add/Remove as Store Item</MenuItem>
      </Menu>
    </>
  );
}

const DownloadNav: React.FunctionComponent<DownloadNavProps> = ({
  match,
  file,
}) => {
  return (
    <PageNav>
      <S.Box py={3} mx={3} my={0}>
        <TopPageNav />
        <S.Flex pl={2} justifyContent="space-between" alignItems="center">
          <div>
            <S.H1>{file.name}</S.H1>
            {/* {studentDesignSubmission.deactivatedAt && (
                  <Tag color="lightorange">Archived</Tag>
                )} */}
            {file.archivedAt && (
              <Tag color="lightorange">Archived</Tag>
            )}
          </div>
          <EditDownloadMenu file={file} />
        </S.Flex>
      </S.Box>
    </PageNav>
  );
};

export default DownloadNav;
