import React, { useState, useCallback, useMemo } from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import EditableContent from "./EditableContent";
import { Mutation, Query } from "@apollo/react-components";
import { MutationFunction } from "@apollo/react-common";
import { Formik, Field, Form } from "formik";
import { StyledPanelFormWrapper } from "./StyledForm";
import {
  BuyersGuideCategory as BuyersGuideCategoryType,
  BuyersGuideCategoryListQueryData,
} from "./apiTypes";
import Tree, { TreeItem, TreeParent } from "./DropdownList";
import { treeify, Tree as TreeType } from "treeify-js";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import {
  createBuyersGuideCategoryMutation,
  updateBuyersGuideCategoryMutation,
  archiveBuyersGuideCategoryMutation,
} from "./mutations/buyersGuideCategory";
import {
  buyersGuideCategoriesListQuery
} from "./queries/buyersGuideCategory";

const BuyersGuide: React.FunctionComponent<{
  categories: BuyersGuideCategoryType[];
}> = ({ categories }) => {
  const treeCategories = treeify(categories, { multi: true });

  return (
    <S.Flex width={1} flexWrap="wrap">
      {treeCategories.map((parent) => (
        <S.Panel width={1 / 4} key={parent.id}>
          <BuyersGuideCategoriesManager parent={parent} />
        </S.Panel>
      ))}
    </S.Flex>
  );
};

function tree(child: TreeType<BuyersGuideCategoryType>) {
  if (child.children && child.children.length) {
    return (
      <TreeItem key={child.id}>
        <TreeParent>{child.name}</TreeParent>
        <Tree>
          {child.children.map((c) => {
            return tree(c);
          })}
        </Tree>
      </TreeItem>
    );
  } else {
    return <TreeItem key={child.id}>{child.name}</TreeItem>;
  }
}

const BuyersGuideTree: React.FunctionComponent<{
  categories: BuyersGuideCategoryType[];
}> = ({ categories }) => {
  const treeCategories = useMemo(() => treeify(categories, { multi: true }), [
    categories,
  ]);
  if (!categories.length) return <>There are no categories on record.</>;

  return (
    <Tree>
      {treeCategories.map((parent) => (
        <TreeItem key={parent.id}>
          <TreeParent>{parent.name}</TreeParent>
          {parent.children ? (
            <Tree>{parent.children.map((child) => tree(child))}</Tree>
          ) : (
            <></>
          )}
        </TreeItem>
      ))}
    </Tree>
  );
};

function BuyersGuideCategoriesManager({
  parent,
}: {
  parent: TreeType<BuyersGuideCategoryType>;
}): React.ReactElement {
  const addDialog = useDialogState();
  const editDialog = useDialogState();

  const [selectedEditCategory, setSelectedEditCategory] = useState<
    BuyersGuideCategoryType | undefined
  >();

  const [breadcrumbs, setBreadcrumbs] = useState<
    TreeType<BuyersGuideCategoryType>[]
  >([]);

  const handleCrumbClick = useCallback(
    (sliceIdx: number) => () => {
      setBreadcrumbs(breadcrumbs.slice(0, sliceIdx));
    },
    [breadcrumbs]
  );

  const handleChildClick = useCallback(
    (child: TreeType<BuyersGuideCategoryType>) => () => {
      setBreadcrumbs(breadcrumbs.concat(child));
    },
    [breadcrumbs]
  );

  const root = breadcrumbs.length
    ? breadcrumbs[breadcrumbs.length - 1]
    : parent;
  const currentCategories: TreeType<BuyersGuideCategoryType>[] = (
    root || parent
  ).children;

  return (
    <>
      <EditableContent>
        {({ isOpen, open, close }) => (
          <div>
            <S.UnderlinedHeader>
              <S.Flex alignItems="center" justifyContent="space-between">
                {!isOpen ? (
                  <>
                    <div>
                      <b>{parent.name}</b>
                    </div>
                    <S.Flex>
                      <S.Button className="edit" size="small" onClick={open}>
                        Edit
                      </S.Button>
                      <S.ButtonedLink
                        size="small"
                        style={{marginLeft: 10}}
                        href={`https://api.db.flexpack.org/reports/buyers_guide_table/${parent.id}`}
                        target="_blank"
                        download={`https://api.db.flexpack.org/reports/buyers_guide_table/${parent.id}`}
                      >
                        <S.Flex alignItems="center">
                          <SVG.DownloadSvg />
                          Download
                        </S.Flex>
                      </S.ButtonedLink>
                    </S.Flex>
                  </>
                ) : (
                  <>
                    <div>
                      <a href="#" onClick={handleCrumbClick(0)}>
                        <b>{parent.name}</b>
                      </a>
                      {breadcrumbs && breadcrumbs.length ? (
                        breadcrumbs.map((crumb, idx) => (
                          <>
                            {" > "}
                            <a
                              href="#"
                              key={crumb.id}
                              onClick={handleCrumbClick(idx + 1)}
                            >
                              <b>{crumb.name}</b>
                            </a>
                          </>
                        ))
                      ) : (
                        <DialogDisclosure
                          {...editDialog}
                          onClick={() => setSelectedEditCategory(parent)}
                          as={S.Button}
                          size="small"
                        >
                          Edit
                        </DialogDisclosure>
                      )}
                    </div>
                    <S.Button className="edit" size="small" onClick={close}>
                      Done
                    </S.Button>
                  </>
                )}
              </S.Flex>
            </S.UnderlinedHeader>
            {!isOpen ? (
              <Tree>
                {parent.children ? (
                  <Tree>{parent.children.map((child) => tree(child))}</Tree>
                ) : (
                  <></>
                )}
              </Tree>
            ) : (
              <>
                <S.Box pl={3} pr={0}>
                  <S.List noBorder noPadding>
                    {currentCategories.map((child) => (
                      <li key={child.id}>
                        <S.Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <div onClick={handleChildClick(child)}>
                            {child.name}
                          </div>
                          <DialogDisclosure
                            {...editDialog}
                            onClick={() => setSelectedEditCategory(child)}
                            as={S.Button}
                            size="small"
                          >
                            Edit
                          </DialogDisclosure>
                        </S.Flex>
                      </li>
                    ))}
                  </S.List>
                </S.Box>
                <DialogDisclosure {...addDialog} as={S.Button} size="small">
                  Add a category
                </DialogDisclosure>
              </>
            )}
          </div>
        )}
      </EditableContent>
      <DialogWithBackdrop {...editDialog}>
        {editDialog.visible && (
          <EditCategoryForm
            category={selectedEditCategory ? selectedEditCategory : undefined}
            closeModal={() => editDialog.hide()}
          />
        )}
      </DialogWithBackdrop>
      <DialogWithBackdrop {...addDialog}>
        {addDialog.visible && (
          <EditCategoryForm parent={root} closeModal={() => addDialog.hide()} />
        )}
      </DialogWithBackdrop>
    </>
  );
}

const EditCategoryForm: React.FunctionComponent<{
  category?: BuyersGuideCategoryType;
  parent?: BuyersGuideCategoryType;
  closeModal: () => void;
}> = ({ category, parent, closeModal }) => {
  return (
    <S.Box p={3} my={0}>
      <S.UnderlinedHeader>
        {category ? "Update" : "Create"} Buyer's Guide Category
      </S.UnderlinedHeader>
      <Mutation<MutationFunction>
        mutation={createBuyersGuideCategoryMutation}
        refetchQueries={["buyersGuideCategoriesListQuery"]}
      >
        {(createBuyersGuideCategory, { loading }) => (
          <Mutation<MutationFunction>
            mutation={updateBuyersGuideCategoryMutation}
            refetchQueries={["buyersGuideCategoriesListQuery"]}
          >
            {(updateBuyersGuideCategory, { loading }) => (
              <Mutation<MutationFunction>
                mutation={archiveBuyersGuideCategoryMutation}
                refetchQueries={["buyersGuideCategoriesListQuery"]}
              >
                {(archiveBuyersGuideCategory, { loading }) => {
                  return (
                    <Formik
                      initialValues={{
                        name: category ? category.name : "",
                        archive: false,
                      }}
                      onSubmit={async (values) => {
                        let input: any = { ...values };
                        if (category) {
                          await updateBuyersGuideCategory({
                            variables: {
                              input: {
                                categoryId: category.id,
                                name: input.name,
                              },
                            },
                          });
                        } else {
                          await createBuyersGuideCategory({
                            variables: {
                              input: {
                                name: input.name,
                                parentId: parent ? parent.id : undefined,
                              },
                            },
                          });
                        }
                        closeModal();
                      }}
                      render={({ values }) => (
                        <StyledPanelFormWrapper>
                          <Form>
                            <label>Category Name</label>
                            <Field
                              required
                              id="name"
                              name="name"
                              placeholder="Name the Category."
                              type="text"
                            />
                            <S.Flex>
                              <S.Button type="submit" disabled={loading}>
                                Sav{loading ? "ing" : "e"} Category
                              </S.Button>
                              {category && (
                                <S.Button
                                  disabled={loading}
                                  onClick={async () => {
                                    if (
                                      window.confirm(
                                        "Are you sure you want to archive this Buyer's Guide Category?"
                                      )
                                    ) {
                                      await archiveBuyersGuideCategory({
                                        variables: {
                                          input: {
                                            categoryId: category.id,
                                          },
                                        },
                                      });
                                    }
                                    closeModal();
                                  }}
                                >
                                  Archiv{loading ? "ing" : "e"} Category
                                </S.Button>
                              )}
                            </S.Flex>
                          </Form>
                        </StyledPanelFormWrapper>
                      )}
                    />
                  );
                }}
              </Mutation>
            )}
          </Mutation>
        )}
      </Mutation>
    </S.Box>
  );
};

// Calls the BuyersGuide component to list the categories (with the individual edits included)
// and hooks in a NewBuyersGuideCategoryForm for new parent categories
const SettingsBuyersGuide: React.FunctionComponent<{}> = ({}) => {
  const addDialog = useDialogState();

  return (
    <S.Box pb={2} pt={3} my={3} mx={3}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          Buyer's Guide Categories
          <DialogDisclosure {...addDialog} as={S.Button} size="small">
            Add a Buyer's Guide Main Category
          </DialogDisclosure>
        </S.Flex>
      </S.UnderlinedHeader>
      <Query<BuyersGuideCategoryListQueryData>
        query={buyersGuideCategoriesListQuery}
      >
        {({ loading, data, error }) => {
          if (loading && !data) return <>Loading...</>;
          if (error && !data) {
            return (
              <div>
                There was an issue loading the Buyer's Guide Categories. Please
                contact support.
              </div>
            );
          }
          if (!data || !data.buyersGuideCategories)
            return <div>There are no categories in the system.</div>;
          return <BuyersGuide categories={data.buyersGuideCategories} />;
        }}
      </Query>
      <DialogWithBackdrop {...addDialog}>
        <EditCategoryForm closeModal={() => addDialog.hide()} />
      </DialogWithBackdrop>
    </S.Box>
  );
};

export default BuyersGuide;
export { SettingsBuyersGuide, BuyersGuideTree };
