import React from "react";
import * as S from "./styles";
import * as SVG from "./svgs";
import styled from "styled-components";
import { StyledPanelFormWrapper } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";
import FileInput from "./FileInput";
import {
  DocumentValues
} from "./apiTypes";

const StyledDocument = styled.div`
  cursor: pointer;
  a {
    color: black;
    &:hover {
      color: #ffffff;
      transition: 0s ease-in;
    }
  }
`;

type DocumentGalleryProps = {
  documents?: DocumentValues[];
  subjectId: string;
  createFunc: (variables: any) => void;
  deleteFunc: (variables: any) => void;
};

const DocumentGallery: React.FunctionComponent<DocumentGalleryProps> = ({
  documents,
  subjectId,
  createFunc,
  deleteFunc,
}) => {
  const addDialog = useDialogState();

  return (
    <S.Panel my={0} mx={3}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          Supporting Documents
          <DialogDisclosure {...addDialog} as={S.Button} size="small">
            Add Document
          </DialogDisclosure>
        </S.Flex>
      </S.UnderlinedHeader>
      <>
        {(documents &&
          documents.map((document, index) => {
            if (!document) return <></>;
            if (!document.file) return <></>;
            return (
              <Document
                key={index}
                document={document}
                subjectId={subjectId}
                deleteFunc={deleteFunc}
              />
            );
          })) || <>No documents have been added.</>}
      </>
      <DialogWithBackdrop {...addDialog}>
        <Formik
          initialValues={{
            file: null,
            submissionId: subjectId,
          }}
          onSubmit={async (values) => {
            if (
              values &&
              values.file &&
              ((values.file as unknown) as File).size < 8000000
            ) {
              await createFunc({
                variables: { input: values },
              });
            } else {
              alert("That file is too large.");
            }
            addDialog.hide();
          }}
          render={({ values }) => (
            <S.Box p={3} my={0}>
              <S.UnderlinedHeader>New Document</S.UnderlinedHeader>
              <StyledPanelFormWrapper>
                <Form>
                  <label>
                    <span>May not exceed 8MB in size.</span>
                  </label>
                  <Field id="file" name="file" component={FileInput} required />
                  <button type="submit" disabled={false}>
                    Sav{false ? "ing" : "e"}
                  </button>
                </Form>
              </StyledPanelFormWrapper>
            </S.Box>
          )}
        />
      </DialogWithBackdrop>
    </S.Panel>
  );
};

const Document: React.FunctionComponent<{
  document: DocumentValues;
  subjectId: string;
  deleteFunc: (variables: any) => void;
}> = ({ document, subjectId, deleteFunc }) => {
  const menu = useMenuState();

  if (!document) return <></>;
  if (!document.file) return <></>;
  if (!document.file.url || !document.file.id) return <></>;
  const documentId = document.file.id;
  const documentUrl = document.file.url;
  return (
    <StyledDocument>
      <MenuDisclosure {...menu} as={S.Box} my={3}>
        <S.Flex alignItems="center">
          <SVG.BookSvg />
          {document.file.filename}
        </S.Flex>
      </MenuDisclosure>
      <Menu {...menu}>
        <MenuItem {...menu}>
          <S.A href={documentUrl} target="_blank">
            Open document.
          </S.A>
        </MenuItem>
        <MenuItem
          {...menu}
          onClick={async () => {
            if (
              window.confirm("Are you sure you want to delete this document?")
            ) {
              await deleteFunc({
                variables: {
                  input: { submissionId: subjectId, fileId: documentId },
                },
              });
              menu.hide();
            }
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </StyledDocument>
  );
};

export { DocumentGallery };
export default Document;
