import React, { useState } from "react";
import styled from "styled-components";
import Downshift from "downshift";
import * as S from "./styles";
import { gql } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";
import { useDebounce } from "./hooks";
import { Link } from "react-router-dom";
import { Calendar } from "./Date";
import UserImage from "./UserAvatar";
import {
  Membership,
  Group
} from "./apiTypes";
import moment from 'moment';

const SearchbarWrapper = styled(S.Flex)`
  width: 100%;
  position: relative;
  align-items: center;

  svg path {
    fill: ${(p) => p.theme.colors.grey};
    margin-right: 10px;
  }
  div {
    width: 90%;
    input {
      width: 100%;
      padding: 10px;
      border: 0;
      background: transparent;
      color: ${(p) => p.theme.colors.grey};
      font-size: 1.25rem;
    }
    input::placeholder {
      color: ${(p) => p.theme.colors.grey};
    }
  }
`;

const DropDown = styled.div`
  position: absolute;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  z-index: 3000;
  border: 1px solid ${(props) => props.theme.lightgrey};
  max-height: 620px;
  overflow-y: scroll;
`;

const DropDownItem = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  padding: 1rem;
  transition: all 0.2s;
  display: flex;
  align-items: center;

  a:hover h3 {
    color: ${(p) => p.theme.colors.blue};
  }
`;

type Node = {
  id: string;
};
type Contact = Node & {
  __typename: "Contact";
  firstName: string;
  lastName: string;
  photoUrl?: string;
  companyMemberships: Membership[];
  groups?: Group[];
  deactivatedAt: string;
};
type Company = Node & {
  __typename: "Company";
  name: string;
  logoUrl?: string;
  activeMembership: Membership;
  deactivatedAt: string;
};
type Download = Node & {
  __typename: "Download";
  name: string;
  acl: string;
  insertedAt: string;
  archivedAt: string;
};
type Event = Node & {
  __typename: "Event";
  title: string;
  cancelledAt: string;
  startsAt: string;
  endsAt: string;
  registrationClosesAt: string;
  registrationOpensAt: string;
};
type StudentDesignSubmission = Node & {
  __typename: "StudentDesignSubmission";
  name: string;
  year: string;
};
type AchievementAwardSubmission = Node & {
  __typename: "AchievementAwardSubmission";
  name: string;
  year: string;
};
type SearchResult = Company | Contact | Download | Event | StudentDesignSubmission | AchievementAwardSubmission;

export const searchQuery = gql`
  query searchQuery($query: String!) {
    search(query: $query) {
      __typename
      ... on Node {
        id
      }
      ... on Contact {
        firstName
        lastName
        deactivatedAt
        photoUrl
        groups {
          name
        }
        companyMemberships {
          company {
            name
          }
        }
      }
      ... on Company {
        name
        logoUrl
        activeMembership {
          applicationStatus
          endedOn
          startedOn
          deactivatedAt
          categories
        }
        deactivatedAt
      }
      ... on Download {
        name
        archivedAt
        insertedAt
        acl
      }
      ... on Event {
        title
        startsAt
        endsAt
        cancelledAt
        registrationClosesAt
        registrationOpensAt
      }
      ... on StudentDesignSubmission {
        id
        name
        year
      }
      ... on AchievementAwardSubmission {
        id
        name
        year
      }
    }
  }
`;

function Searchbar(): React.ReactElement {
  const [searchField, setSearchField] = useState<string>("");
  const debouncedSearch = useDebounce(searchField, 350);
  const searchValid =
    typeof debouncedSearch === "string" && debouncedSearch.trim().length > 2;

  // TODO: Update search type
  const { data, loading } = useQuery<{ search: SearchResult[] }>(searchQuery, {
    variables: { query: debouncedSearch },
    skip: !searchValid,
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchField(e.target.value);
  };

  return (
    <SearchbarWrapper pr={3}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2 15.8L21.7 20.3C22.1 20.7 22.1 21.3 21.7 21.7C21.5 21.9 21.2 22 21 22C20.8 22 20.5 21.9 20.3 21.7L15.8 17.2C14.3 18.3 12.5 19 10.5 19C5.8 19 2 15.2 2 10.5C2 5.8 5.8 2 10.5 2C15.2 2 19 5.8 19 10.5C19 12.5 18.3 14.4 17.2 15.8ZM10.5 4C6.9 4 4 6.9 4 10.5C4 14.1 6.9 17 10.5 17C12.3 17 13.9 16.3 15.1 15.1C16.3 13.9 17 12.3 17 10.5C17 6.9 14.1 4 10.5 4Z"
          fill="black"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="21"
          height="20"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2 15.8L21.7 20.3C22.1 20.7 22.1 21.3 21.7 21.7C21.5 21.9 21.2 22 21 22C20.8 22 20.5 21.9 20.3 21.7L15.8 17.2C14.3 18.3 12.5 19 10.5 19C5.8 19 2 15.2 2 10.5C2 5.8 5.8 2 10.5 2C15.2 2 19 5.8 19 10.5C19 12.5 18.3 14.4 17.2 15.8ZM10.5 4C6.9 4 4 6.9 4 10.5C4 14.1 6.9 17 10.5 17C12.3 17 13.9 16.3 15.1 15.1C16.3 13.9 17 12.3 17 10.5C17 6.9 14.1 4 10.5 4Z"
            fill="white"
          />
        </mask>
      </svg>
      <Downshift onChange={() => {}}>
        {({ getInputProps, isOpen }) => (
          <div>
            <input
              {...getInputProps({
                type: "search",
                placeholder: "Search the Database...",
                id: "search",
                className: loading ? "loading" : "",
                onChange: handleChange,
              })}
            />
            {isOpen && searchField !== "" && (
              <DropDown>
                {data && data.search && data.search.length ? (
                  data.search.map((item) => {
                    if (item && item.__typename === "Company") {
                      return (
                        <DropDownItem key={item.id}>
                          <Link to={`/companies/${item.id}`}>
                            <S.Flex>
                              <img height="40" src={item.logoUrl} style={{ marginRight: "10px" }} />
                              <S.H3>
                                {item.name}
                                {item.activeMembership && item.activeMembership.categories ? <span><b>{`${item.activeMembership.categories.join(" ")} Member`}</b></span> : <></>}
                                {item.deactivatedAt ? <span>Archived Company</span> : <></>}
                              </S.H3>
                            </S.Flex>
                          </Link>
                        </DropDownItem>
                      );
                    } else if (item && item.__typename === "Contact") {
                      return (
                        <DropDownItem key={item.id}>
                          <Link to={`/contacts/${item.id}`}>
                            <S.Flex>
                              <UserImage large src={item.photoUrl} style={{ marginRight: "10px" }} />
                              <S.H3>
                                {item.firstName} {item.lastName}
                                {item.companyMemberships ? <span><b>{`${item.companyMemberships.map((membership) => { return membership.company.name}).join(", ")}`}</b></span> : <>No company associations</>}
                                {item.groups && item.groups.length > 0 ? <span><b>Groups: </b>{`${item.groups.map((g) => {return g.name}).join(" ")}`}</span> : <></>}
                                {item.deactivatedAt ? <span>Archived Contact</span> : <></>}
                              </S.H3>
                            </S.Flex>
                          </Link>
                        </DropDownItem>
                      );
                    } else if (item && item.__typename === "Event") {
                      const today = new Date();
                      const open = new Date(item.registrationOpensAt) <= today && today <= new Date(item.registrationClosesAt);
                      const closed = new Date(item.registrationOpensAt) <= today && today >= new Date(item.registrationClosesAt);
                      return (
                        <DropDownItem key={item.id}>
                          <Link to={`/events/${item.id}`}>
                            <S.Flex>
                              <Calendar start={item.startsAt} end={item.endsAt} />
                              <S.H3>
                                {item.title}
                                <span>Registration {open ? (
                                  <><b>open</b> until {moment(item.registrationClosesAt).format("MM/DD/YY")}</>
                                ) : closed ? (
                                  <><b>closed</b> {moment(item.registrationClosesAt).format("MM/DD/YYYY")}</>
                                ) : (
                                  <><b>will open</b> {moment(item.registrationOpensAt).format("MM/DD/YYYY")}</>
                                )}</span>
                                {item.cancelledAt ? <span>Cancelled Event</span> : <></>}
                              </S.H3>
                            </S.Flex>
                          </Link>
                        </DropDownItem>
                      );
                    } else if (item && item.__typename === "Download") {
                      return (
                        <DropDownItem key={item.id}>
                          <Link to={`/files/${item.id}`}>
                            <S.H3>
                              {item.name}
                              <span><b>{item.acl === "MEMBERS" ? "Member" : item.acl === "PRIVATE" ? "Private" : "Public"} File</b></span>
                              <span>Added {moment(item.insertedAt).format("MM/DD/YYYY h:mma")}</span>
                              {item.archivedAt ? <span>Archived File</span> : <></>}
                            </S.H3>
                          </Link>
                        </DropDownItem>
                      );
                    } else if (item && item.__typename === "StudentDesignSubmission") {
                      return (
                        <DropDownItem key={item.id}>
                          <Link to={`/awards/student/${item.id}`}>
                            <S.Flex>
                              {/* <img height="40" src={item.logoUrl} style={{ marginRight: "10px" }} /> */}
                              <S.H3>
                                {item.name}
                                <span>Student Design Challenge Submission – {item.year}</span>
                              </S.H3>
                            </S.Flex>
                          </Link>
                        </DropDownItem>
                      );
                    } else if (item && item.__typename === "AchievementAwardSubmission") {
                      return (
                        <DropDownItem key={item.id}>
                          <Link to={`/awards/achievement/${item.id}`}>
                            <S.Flex>
                              {/* <img height="40" src={item} style={{ marginRight: "10px" }} /> */}
                              <S.H3>
                                {item.name}
                                <span>Achievement Award Submission – {item.year}</span>
                              </S.H3>
                              </S.Flex>
                          </Link>
                        </DropDownItem>
                      );
                    } else { return <></>; }
                  })
                ) : (
                  <DropDownItem>No Results Found</DropDownItem>
                )}
              </DropDown>
            )}
          </div>
        )}
      </Downshift>
    </SearchbarWrapper>
  );
}

export default Searchbar;
