import React from "react";
import * as S from "./styles";
import Table, { TR, TD } from "./Table";
import * as SVG from "./svgs";
import Page, { PageWithSidePanel, PageSidePanel } from "./Page";
import { Tag } from "./Tags";
import Date from "./Date";
import Notes, { ActivityFeed } from "./Notes";
import { Mutation } from "@apollo/react-components";
import { Event } from "./apiTypes";
import EditableContent from "./EditableContent";
import { StyledPanelFormWrapper, FormikDatetime } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { updateEventMutation } from "./mutations/event";
import { Link, RouteComponentProps } from "react-router-dom";
import moment from "moment";

type SharedEventProps = {
  event: Event;
};
type EventDetailsProps = RouteComponentProps<{ eventId: string }> &
  SharedEventProps;

const EventDetails: React.FunctionComponent<EventDetailsProps> = ({
  event
}) => {
  return (
    <Page>
      <S.Panel pb={2}>
        <EditableContent>
          {({ isOpen, open, close }) => (
            <>
              <S.UnderlinedHeader>
                <S.Flex alignItems="flex-end" justifyContent="space-between">
                  Event Details
                  {!isOpen ? (
                    <S.Button size="small" onClick={open}>
                      Edit
                    </S.Button>
                  ) : (
                    <S.Button size="small" onClick={close}>
                      Cancel
                    </S.Button>
                  )}
                </S.Flex>
              </S.UnderlinedHeader>
              {!isOpen ? (
                <div>
                  <S.Flex>
                    <S.Box width={1 / 2} my={0} pb={3}>
                      <S.P>
                        <b>Event Start Date</b>
                      </S.P>
                      <S.P>
                        {event.startsAt ? (
                          <Date date={event.startsAt} />
                        ) : (
                          "No Start Date"
                        )}
                      </S.P>
                    </S.Box>
                    <S.Box width={1 / 2} my={0} pb={3}>
                      <S.P>
                        <b>Event End Date</b>
                      </S.P>
                      <S.P>
                        {event.endsAt ? (
                          <Date date={event.endsAt} />
                        ) : (
                          "No End Date"
                        )}
                      </S.P>
                    </S.Box>
                  </S.Flex>
                  <S.Box>
                    <S.UnderlinedHeader>Description</S.UnderlinedHeader>
                    <S.P>{event.description ? event.description : "..."}</S.P>
                  </S.Box>
                </div>
              ) : (
                <Mutation<any>
                  mutation={updateEventMutation}
                  refetchQueries={["eventQuery"]}
                >
                  {(updateEvent, { loading }) => {
                    return (
                      <Formik
                        initialValues={{
                          title: event.title || "",
                          description: event.description || "",
                          startsAt: event.startsAt || undefined,
                          endsAt: event.endsAt || undefined
                        }}
                        onSubmit={values => {
                          updateEvent({
                            variables: {
                              input: { ...values, eventId: event.id }
                            }
                          });
                          close();
                        }}
                        render={() => (
                          <Form>
                            <StyledPanelFormWrapper>
                              <label htmlFor="title">Event Title</label>
                              <Field id="title" name="title" type="text" />
                              <S.Flex>
                                <S.HalfWidth>
                                  <label htmlFor="startsAt">Start Date</label>
                                  <Field
                                    id="startsAt"
                                    name="startsAt"
                                    component={FormikDatetime}
                                  />
                                </S.HalfWidth>
                                <S.HalfWidth>
                                  <label htmlFor="endsAt">End Date</label>
                                  <Field
                                    id="endsAt"
                                    name="endsAt"
                                    component={FormikDatetime}
                                  />
                                </S.HalfWidth>
                              </S.Flex>
                              <label>Description</label>
                              <Field
                                id="description"
                                name="description"
                                component="textarea"
                              />
                              <button type="submit" disabled={loading}>
                                Sav{loading ? "ing" : "e"}
                              </button>
                            </StyledPanelFormWrapper>
                          </Form>
                        )}
                      />
                    );
                  }}
                </Mutation>
              )}
            </>
          )}
        </EditableContent>
      </S.Panel>
      <S.Panel>
        <S.UnderlinedHeader>
          <S.Flex justifyContent="space-between" alignItems="center">
            Event Registration Data
            {event.id === "RXZlbnQ6MQ==" ? (
              <S.ButtonedLink
                size="small"
                href="https://c5ci.wufoo.com/reports/fpa-2020-annual-meeting/"
                target="_blank"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  View Report
                </S.Flex>
              </S.ButtonedLink>
            ) : event.id === "RXZlbnQ6Mg==" ? (
              <S.ButtonedLink
                size="small"
                href="https://c5ci.wufoo.com/reports/fpa-2021-annual-meeting-registrants/"
                target="_blank"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  View Report
                </S.Flex>
              </S.ButtonedLink>
            ) : (
              <></>
            )}
          </S.Flex>
        </S.UnderlinedHeader>
        <S.Box my={0} mx={0} pending>
        <Table cols="2fr 130px 100px 200px 200px">
          <TR header>
            <TD></TD>
            <TD>PARTICIPANTS</TD>
            <TD>FEE</TD>
            <TD>TOTAL FEES</TD>
            <TD></TD>
          </TR>
          <TR py={2}>
            <TD>Total Attendees</TD>
            <TD>###</TD>
            <TD>$</TD>
            <TD>$$$$</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href=""
                target="_blank"
                download=""
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download Report
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Total Registrants</TD>
            <TD>{event.registrations.count}</TD>
            <TD>$</TD>
            <TD>$$$</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href=""
                target="_blank"
                download=""
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download Report
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Total Guests</TD>
            <TD>###</TD>
            <TD>$</TD>
            <TD>$$$</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href=""
                target="_blank"
                download=""
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download Report
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Event One Participants</TD>
            <TD>###</TD>
            <TD>$</TD>
            <TD>$$$</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href=""
                target="_blank"
                download=""
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download Report
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Event Two Participants</TD>
            <TD>###</TD>
            <TD>$</TD>
            <TD>$$$</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href=""
                target="_blank"
                download=""
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download Report
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Event Three Participants</TD>
            <TD>###</TD>
            <TD>$</TD>
            <TD>$$$</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href=""
                target="_blank"
                download=""
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download Report
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Event Four Participants</TD>
            <TD>###</TD>
            <TD>$</TD>
            <TD>$$$</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href=""
                target="_blank"
                download=""
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download Report
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR>
            <TD></TD>
            <TD></TD>
            <TD><strong>TOTAL:</strong></TD>
            <TD><strong>$$$$</strong></TD>
            <TD></TD>
          </TR>
        </Table>
        </S.Box>
      </S.Panel>
      {/* <Notes subject={event} />
      <ActivityFeed subject={event} /> */}
    </Page>
  );
};

export default EventDetails;
