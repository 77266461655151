import { gql } from "graphql.macro";

export const storeCategoriesListQuery = gql`
  query storeCategoriesListQuery {
    storeCategories {
      id
      description
      insertedAt
      name
      photoUrl
      storeItems(first: 10) {
        count
        edges {
          node {
            id
            name
          }
        }
      }
      updatedAt
    }
  }
`;

export const storeCategoryQuery = gql`
  query storeCategoryQuery($categoryId: ID!) {
    storeCategory(categoryId: $categoryId) {
      id
      description
      insertedAt
      name
      photoUrl
      storeItems {
        count
        edges {
          node {
            id
            name
          }
        }
      }
      updatedAt
    }
  }
`;
