import { gql } from "graphql.macro";

export const createEmailAddressMutation = gql`
  mutation createEmailAddressMutation($input: CreateEmailAddressInput!) {
    createEmailAddress(input: $input) {
      label
      emailAddress
      id
      preferred
    }
  }
`;

export const deleteEmailAddressMutation = gql`
  mutation deleteEmailAddressMutation($input: DeleteEmailAddressInput!) {
    deleteEmailAddress(input: $input) {
      id
    }
  }
`;

export const updateEmailAddressMutation = gql`
  mutation updateEmailAddressMutation($input: UpdateEmailAddressInput!) {
    updateEmailAddress(input: $input) {
      label
      emailAddress
      subjectId
      id
      preferred
    }
  }
`;
