import React, { useRef, useCallback, useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "graphql.macro";
import { File as FileType } from "./apiTypes";

type FileUploadProps = {
  onUpload: (files: FileType[]) => void;
  multiple?: boolean;
};

const UPLOAD_FILES_MUTATION = gql`
  mutation UploadFiles($files: [Upload!]) {
    uploadFiles(files: $files) {
      id
      url
      filename
      contentType
    }
  }
`;

export function useFileUpload() {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadFiles, { loading: uploading }] = useMutation<{
    uploadFiles: FileType[];
  }>(UPLOAD_FILES_MUTATION);

  const upload = useCallback(async () => {
    if (files.length) {
      const resp = await uploadFiles({ variables: { files } });
      setFiles([]);
      if (resp && resp.data) return resp.data;
    }
  }, [uploadFiles, files]);

  return { files, setFiles, upload, uploading };
}

export default function FileUpload({
  onUpload,
  multiple = false
}: FileUploadProps) {
  const fileInput = useRef<HTMLInputElement>(null);
  const { files, setFiles, upload, uploading } = useFileUpload();

  // Upload the files immediately if the
  useEffect(() => {
    if (files.length)
      upload().then(data => {
        if (data && data.uploadFiles) onUpload(data.uploadFiles);
      });
  }, [onUpload, upload, files]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const fileList = e.target.files;
    const list = Array.prototype.slice.call(fileList);
    setFiles(curr => curr.concat(list));
    e.target.value = "";
  };

  return (
    <>
      <input
        disabled={uploading}
        ref={fileInput}
        type="file"
        multiple={multiple}
        onChange={handleChange}
      />
      {uploading && "Uploading..."}
    </>
  );
}
