import React, { useState } from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/react-components";
import TopNav from "./TopNav";
import Page, { PageButtons } from "./Page";
import { useMutation } from "@apollo/react-hooks";
import * as S from "./styles";
import { Link, useLocation } from "react-router-dom";
import EventsList from "./EventsList";
import styled from "styled-components";
import NewEvent from "./NewEvent";
import { eventListQuery } from "./queries/event";
import { SortValue, FilterValue, EventsListQueryData } from "./apiTypes";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import Select from "react-select";

const Events = () => {
  const addDialog = useDialogState();

  const sortOptions = [
    { label: "Start Date (Newest)", value: "START_DESC" },
    { label: "Start Date (Oldest)", value: "START_ASC" },
    { label: "Date Added (Newest)", value: "INSERTED_AT_DESC" },
    { label: "Date Added (Oldest)", value: "INSERTED_AT_ASC" },
    { label: "Name A-Z", value: "NAME_ASC" },
    { label: "Name Z-A", value: "NAME_DESC" },
  ];

  const filterOptions = [
    { label: "Open Registration", value: "open" },
    { label: "Closed Registration", value: "closed" },
    { label: "Active", value: "active" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Upcoming", value: "upcoming" },
    { label: "Past", value: "page" },
  ];

  const [sort, setSort] = useState<SortValue>(sortOptions[4]);
  const [filters, setFilters] = useState<FilterValue>([]);

  const handleSort = (newValue: SortValue) => {
    setSort(newValue);
  };
  const handleFilters = (newValues: FilterValue) => {
    setFilters(newValues);
  };

  const query = new URLSearchParams(useLocation().search);
  const pageSize = 20;
  const first =
    query.has("after") || (!query.has("after") && !query.has("before"))
      ? pageSize
      : undefined;
  const last = query.has("before") ? pageSize : undefined;

  const queryVars = {
    first,
    last,
    after: query.get("after"),
    before: query.get("before"),
    orderBy:
      sort && !Array.isArray(sort)
        ? (sort as { label: string; value: string }).value
        : "START_DESC",
  };

  return (
    <Page>
      <TopNav />
      <Query<EventsListQueryData>
        query={eventListQuery}
        variables={queryVars}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading && !data) return <div />;
          if (error) return <div>Oops.</div>;
          if (!data || !data.events || !Array.isArray(data.events.edges))
            return <div>No data.</div>;
            const events = data.events.edges.map((edge) => {
              return edge.node;
            });
            return (
            <>
              <S.Flex justifyContent="space-between" mx={3}>
                <S.Title>Total Events: {data.events.count}</S.Title>
                <DialogDisclosure {...addDialog} as={S.Button}>
                  Add New Event
                </DialogDisclosure>
              </S.Flex>
              <S.Flex
                justifyContent="space-between"
                alignItems="flex-end"
                mx={3}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <label>SORT BY</label>
                  <Select
                    closeMenuOnSelect={true}
                    onChange={handleSort}
                    options={sortOptions}
                    value={sort}
                  />
                </div>
              </S.Flex>
              <PageButtons
                topMargin={4}
                pageInfo={data.events.pageInfo}
                basePath="events"
              />
              <EventsList events={events} />
              <PageButtons pageInfo={data.events.pageInfo} basePath="events" />
            </>
          );
        }}
      </Query>
      <DialogWithBackdrop {...addDialog}>
        <NewEvent />
      </DialogWithBackdrop>
    </Page>
  );
};

export default Events;
