import { gql } from "graphql.macro";

export const createStudentDesignSubmissionMutation = gql`
  mutation createStudentDesignSubmissionMutation(
    $input: CreateStudentDesignSubmissionInput!
  ) {
    createStudentDesign(input: $input) {
      id
    }
  }
`;
export const updateStudentDesignSubmissionMutation = gql`
  mutation updateStudentDesignSubmissionMutation(
    $input: UpdateStudentDesignSubmissionInput!
  ) {
    updateStudentDesign(input: $input) {
      id
    }
  }
`;

export const createStudentDesignImageMutation = gql`
  mutation createStudentDesignImageMutation(
    $input: CreateStudentDesignImageInput!
  ) {
    createStudentDesignImage(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const updateStudentDesignImageMutation = gql`
  mutation updateStudentDesignImageMutation(
    $input: UpdateStudentDesignImageInput!
  ) {
    updateStudentDesignImage(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const deleteStudentDesignImageMutation = gql`
  mutation deleteStudentDesignImageMutation(
    $input: DeleteStudentDesignImageInput!
  ) {
    deleteStudentDesignImage(input: $input) {
      submission {
        id
      }
    }
  }
`;

export const createStudentDesignDocumentMutation = gql`
  mutation createStudentDesignDocumentMutation(
    $input: CreateStudentDesignDocumentInput!
  ) {
    createStudentDesignDocument(input: $input) {
      submission {
        id
      }
    }
  }
`;
export const deleteStudentDesignDocumentMutation = gql`
  mutation deleteStudentDesignDocumentMutation(
    $input: DeleteStudentDesignDocumentInput!
  ) {
    deleteStudentDesignDocument(input: $input) {
      submission {
        id
      }
    }
  }
`;

export const archiveStudentDesignSubmissionMutation = gql`
  mutation archiveStudentDesignSubmissionMutation(
    $input: ArchiveStudentDesignSubmissionInput!
  ) {
    archiveStudentDesignSubmission(input: $input) {
      id
    }
  }
`;
