import { gql } from "graphql.macro";

export const salesReportListQuery = gql`
  query SalesReportListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: SalesReportOrderBy
    $filter: SalesReportFilterInput
  ) {
    salesReports(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          dollarAmount
          reportedOn
          status
          updatedAt
          year
          insertedAt
          company {
            id
            name
          }
        }
      }
    }
  }
`;

export const salesReportQuery = gql`
  query salesReportQuery($salesReportId: ID!) {
    salesReport(salesReportId: $salesReportId) {
      id
      dollarAmount
      reportedOn
      status
      updatedAt
      year
      insertedAt
      company {
        id
        name
      }
    }
  }
`;
