import React from "react";
import styled from "styled-components";
import moment from "moment";
import * as theme from "./theme";

type DateProps = {
  date: string;
  time?: boolean;
  format?: string;
};

type DateRangeProps = {
  start: string;
  end: string;
  time?: boolean;
};

const Date: React.FunctionComponent<DateProps> = ({ date, time, format }) => {
  if (format) return <>{moment(date).format(format)}</>;
  const dateFormat = time ? "MMMM D, YYYY H:MM a" : "MMMM D, YYYY";
  return <>{moment(date).format(dateFormat)}</>;
};

const DateRange: React.FunctionComponent<DateRangeProps> = ({
  start,
  end,
  time
}) => {
  let startMoment = moment(start);
  let endMoment = moment(end);
  const sameYear = startMoment.isSame(endMoment, "year");
  const sameMonth = startMoment.isSame(endMoment, "month");
  const sameDay = startMoment.isSame(endMoment, "day");

  const startFormat = sameYear ? "MMMM D" : "MMM D, YYYY";
  const endFormat = sameMonth ? "D, YYYY" : "MMMM D, YYYY";

  if (sameDay && sameMonth && sameYear)
    return (
      <>
        {time
          ? `${startMoment.format("MMMM D, YYYY")} ${startMoment.format(
              "h:MM a"
            )} - ${endMoment.format("h:MM a")}`
          : `${startMoment.format("MMMM D, YYYY")}`}
      </>
    );
  return (
    <>
      {startMoment.format(startFormat)} - {endMoment.format(endFormat)}
    </>
  );
};

const StyledCalendar = styled.div`
  text-align: center;
  width: 130px!important;
  height: 60px;
  margin-right: 10px;
  div.month {
    background: #de3838;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    padding: 4px;
    width: 100%;
  }
  border: 1px solid ${theme.colors.grey};
  div.range {
    color: ${theme.colors.grey};
    padding: 6px;
    width: 100%;
    background: #fff;
  }
`;

const Calendar: React.FunctionComponent<DateRangeProps> = ({ start, end }) => {
  let range =
    moment(start).format("MMMM D YYYY") !== moment(end).format("MMMM D YYYY")
      ? `${moment(start).format("D")} - ${moment(end).format("D")}`
      : `${moment(start).format("D")}`;

  return (
    <StyledCalendar>
      <div className="month">{moment(start).format("MMMM YYYY")}</div>
      <div className="range">{range}</div>
    </StyledCalendar>
  );
};

export default Date;
export { DateRange, Calendar };
