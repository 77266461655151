import React from "react";
import AuthExchanger from "./Auth/AuthExchanger";
import Dashboard from "./Dashboard";
import Companies from "./Companies";
import Company from "./Company";
import Contacts from "./Contacts";
import Contact from "./Contact";
import Events from "./Events";
import Event from "./Event";
import Registration from "./Registration";
import Settings from "./Settings";
import Invoice from "./Invoice";
import StoreItems from "./StoreItems";
import StoreItem from "./StoreItem";
import Downloads from "./Downloads";
import Download from "./Download";
import Awards from "./Awards";
import AchievementAward from "./AchievementAward";
import Reports from "./Reports";
import StudentDesignChallenge from "./StudentDesign";
import { Switch, Route, Redirect } from "react-router-dom";

const Main = () => {
  return (
    <Switch>
      <Route
        exact
        path="/auth/exchange"
        render={(props) => <AuthExchanger {...props} />}
      />
      <Redirect exact from="/" to="/dashboard" />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/companies" exact component={Companies} />
      <Route path="/companies/:companyId" component={Company} />
      <Route path="/contacts" exact component={Contacts} />
      <Route path="/contacts/:contactId" component={Contact} />
      <Route path="/events" exact component={Events} />
      <Route path="/events/:eventId" component={Event} />
      <Redirect exact from="/registrations" to="/events" />
      <Route path="/registrations/:registrationId" component={Registration} />
      <Route path="/settings" component={Settings} />
      <Route path='/invoices' component={() => { 
          window.location.href = 'https://dashboard.stripe.com/invoices'; 
          return null;
      }}/>
      <Route path="/invoices/:invoiceNum" component={Invoice} />
      <Route path="/store" exact component={StoreItems} />
      <Route path="/store/:storeItemId" component={StoreItem} />
      <Route path="/files" exact component={Downloads} />
      <Route path="/files/:downloadId" component={Download} />
      <Route path="/awards/achievement/:id" component={AchievementAward} />
      <Route path="/awards/student/:id" component={StudentDesignChallenge} />
      <Route path="/awards" component={Awards} />
      <Route path="/reports" exact component={Reports} />
    </Switch>
  );
};

export default Main;
