import { gql } from "graphql.macro";

export const createUserMutation = gql`
  mutation createUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      contact {
        id
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const sendPasswordResetMutation = gql`
  mutation sendPasswordResetMutation($input: SendPasswordResetInput!) {
    sendPasswordReset(input: $input) {
      id
    }
  }
`;
