import React, { useEffect } from "react";
import { authorize } from "../auth";
import { RouteComponentProps } from "react-router";

function AuthInitiator(
  props: RouteComponentProps<{ referrer?: string }>
): null {
  const referrer =
    (props.location.state && props.location.state.referrer) || undefined;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    authorize(referrer);
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return null;
}

export default React.memo(AuthInitiator);
