// TOOD: Enable LOG_LEVEL configuration

export function log(...args: any[]) {
  console.log(...args);
}

export function info(...args: any[]) {
  console.info(...args);
}

export function debug(...args: any[]) {
  console.debug(...args);
}

export function warn(...args: any[]) {
  console.warn(...args);
}

export function table(...args: any[]) {
  console.table(...args);
}

export default {
  log,
  info,
  debug,
  warn,
  table
};
