import { gql } from "graphql.macro";

export const StoreItemQuery = gql`
  query StoreItemQuery($storeItemId: ID!) {
    storeItem(storeItemId: $storeItemId) {
      id
      name
      categories {
        id
        name
      }
      description
      download {
        id
        acl
        file {
          id
          filename
          url
        }
      }
      maxQuantity
      price
      sku
      summary
      publishedAt
      deactivatedAt
      archivedAt
      insertedAt
      updatedAt
    }
  }
`;

export const StoreItemsListQuery = gql`
  query StoreItemsListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    storeItems(first: $first, last: $last, before: $before, after: $after) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          categories {
            id
            name
          }
          description
          download {
            id
            acl
            file {
              id
              filename
              url
            }
          }
          maxQuantity
          price
          sku
          summary
          publishedAt
          deactivatedAt
          archivedAt
        }
      }
    }
  }
`;

export const storeItemsSearchQuery = gql`
  query storeItemsSearchQuery(
    $first: Int!
    $last: Int
    $before: String
    $after: String
  ) {
    storeItems(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          id
          name
          archivedAt
        }
      }
    }
  }
`;
