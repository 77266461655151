import React from "react";
import styled from "styled-components";
import UserIcon from "./UserAvatar";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { useAuth } from "./contexts/Auth";
import { Query } from "@apollo/react-components";
import { Link } from "react-router-dom";
import { CURRENT_USER_QUERY } from "./auth";

const UserMenuDisclosure = styled(MenuDisclosure)`
  padding: 0;
  height: 40px;
  width: 40px;
  display: block;
  border: 2px solid #fff;
  border-radius: 50%;
  background: transparent;
  align-self: center;
  overflow: hidden;
  outline: none;

  ${UserIcon} {
    display: block;
    border: 0;
  }

  &:focus {
    box-shadow: 0 0 4px ${p => p.theme.colors.blue};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${p => p.theme.colors.grey};
  font-size: 14px;
  &:hover {
    color: #fff;
  }
`;
const MenuLink = styled(Link)`
  color: ${p => p.theme.colors.grey};
  font-size: 14px;
  transition: all 0s;
  &:hover {
    color: #fff;
  }
`;

const UserMenu = () => {
  const menu = useMenuState({ placement: "bottom-end" });
  const { logOut } = useAuth();

  return (
    <Query<any> query={CURRENT_USER_QUERY} variables={{}}>
      {({ loading, error, data }) => {
        if (loading && !data) return <div />;
        if (error) return <div>Oops.</div>;
        if (!data || !data.me) return <div>No data.</div>;
        const me = data.me;
        if (!me) return <div />;
        return (
          <>
            <UserMenuDisclosure {...menu}>
              <UserIcon src={me.contact.photoUrl} />
            </UserMenuDisclosure>
            <Menu {...menu} aria-label="User">
              <StyledMenuItem {...menu}>
                <MenuLink to={`/contacts/${me.contact.id}`}>
                  My Contact Page
                </MenuLink>
              </StyledMenuItem>
              {/* <StyledMenuItem {...menu}>Update Password</StyledMenuItem> */}
            </Menu>
          </>
        );
      }}
    </Query>
  );
};

export default UserMenu;
