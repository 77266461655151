import React from "react";
import moment from "moment";
import EditableContent from "./EditableContent";
import Tags, { Tag } from "./Tags";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { Query, Mutation } from "@apollo/react-components";
import { RouteComponentProps } from "react-router-dom";
import * as S from "./styles";
import Notes, { ActivityFeed } from "./Notes";
import FileInput from "./FileInput";
import Table, { TR, TD } from "./Table";
import TopNav from "./TopNav";
import Page from "./Page";
import DownloadNav from "./DownloadNav";
import { Download as DownloadType } from "./apiTypes";
import { updateDownloadMutation } from "./mutations/download";
import { DownloadQuery } from "./queries/download";

type DownloadProps = RouteComponentProps<{ downloadId: string }>;

const Download: React.FunctionComponent<DownloadProps> = ({ match }) => {
  return (
    <Query<{ download: DownloadType }, { downloadId: string }>
      query={DownloadQuery}
      variables={{ downloadId: match.params.downloadId }}
    >
      {({ loading, error, data }) => {
        if (loading && !data)
          return (
            <Page>
              <TopNav />
              Loading...
            </Page>
          );
        if (error)
          return (
            <Page>
              <TopNav />
              There is no File associated with this id/url.
            </Page>
          );
        if (!data || !data.download)
          return (
            <Page>
              <TopNav />
              There is no File associated with this id/url.
            </Page>
          );
        const file = data.download;
        if (!file)
          return (
            <Page>
              <TopNav />
              There is no File associated with this id/url.
            </Page>
          );
        return (
          <div>
            <DownloadNav file={file} match={match} />
            <Page>
              <S.Panel pb={2}>
                <EditableContent>
                  {({ isOpen, open, close }) => (
                    <>
                      <S.UnderlinedHeader noMargin>
                        <S.Flex
                          alignItems="flex-end"
                          justifyContent="space-between"
                        >
                          File Details
                          {!isOpen ? (
                            <div>
                            {file.file && file.file.url ? (
                              <>
                                <S.ButtonedLink
                                  size="small"
                                  style={{ marginRight: "10px"}}
                                  href={`https://www.flexpack.org/publication/${file.id}/download`}
                                  target="_blank"
                                >Download</S.ButtonedLink>
                                <S.ButtonedLink
                                  size="small"
                                  style={{ marginRight: "10px"}}
                                  href={`https://www.flexpack.org/publication/${file.id}`}
                                  target="_blank"
                                >View on Website</S.ButtonedLink>
                              </>
                            ) : (
                              <></>
                            )}
                            <S.Button size="small" onClick={open}>
                              Edit
                            </S.Button>
                            </div>
                          ) : (
                            <S.Button size="small" onClick={close}>
                              Cancel
                            </S.Button>
                          )}
                        </S.Flex>
                      </S.UnderlinedHeader>
                      {!isOpen ? (
                        <>
                          <S.Flex>
                            {file.acl && file.acl === "PRIVATE" ? (
                              <Tag color="lightorange">Private</Tag>
                            ) : file.acl && file.acl === "PUBLIC" ? (
                              <Tag color="lightgreen">Public</Tag>
                            ) : file.acl && file.acl === "MEMBERS" ? (
                              <Tag color="lightblue">Members Only</Tag>
                            ) : (<></>)}
                            {file.report && <Tag color="darkgreen">Report</Tag>}
                            {file.fpaNow && <Tag color="darkblue">FPA NOW</Tag>}
                          </S.Flex>
                          <Table cols="1fr 2fr">
                            <TR py={1} borderless>
                              <TD>
                                <S.Title>File Name</S.Title>
                              </TD>
                              <TD>{file.name}</TD>
                            </TR>
                            <TR py={1} borderless>
                              <TD><S.Title>File Description</S.Title></TD>
                              <TD><S.P>{file.description}</S.P></TD>
                            </TR>
                            <TR py={1} borderless>
                              <TD>
                                <S.Title>Website URL</S.Title>
                              </TD>
                              <TD>
                                {`https://www.flexpack.org/publication/${file.id}`}
                              </TD>
                            </TR>
                            <TR py={1} borderless>
                              <TD>
                                <S.Title>Download URL</S.Title>
                              </TD>
                              <TD>
                                {`https://www.flexpack.org/publication/${file.id}/download`}
                              </TD>
                            </TR>
                            <TR py={1} borderless>
                              <TD>
                                <S.Title>File Created On</S.Title>
                              </TD>
                              <TD>
                                {moment(file.insertedAt).format("MM/D/YYYY h:ma")}
                              </TD>
                            </TR>
                            <TR py={1} borderless>
                              <TD>
                                <S.Title>File Last Updated On</S.Title>
                              </TD>
                              <TD>
                                {moment(file.updatedAt).format("MM/D/YYYY h:ma")}
                              </TD>
                            </TR>
                          </Table>
                        </>
                      ) : (
                        <Mutation<any>
                          mutation={updateDownloadMutation}
                          refetchQueries={["DownloadQuery"]}
                        >
                          {(updateFile, { loading }) => {
                            return (
                              <Formik
                                initialValues={{
                                  name: file.name ? file.name : "",
                                  description: file.description
                                    ? file.description
                                    : "",
                                  file: undefined,
                                  acl: file.acl ? file.acl : "PUBLIC",
                                  fpaNow: file.fpaNow ? file.fpaNow : false,
                                  report: file.report ? file.report : false,
                                }}
                                onSubmit={async (e) => {
                                  var values = {
                                    name: e.name,
                                    acl: e.acl,
                                    fpaNow: e.fpaNow,
                                    report: e.report,
                                    description: e.description
                                  };
                                  if (e.file) {
                                    await updateFile({
                                      variables: {
                                        input: {
                                          ...values,
                                          file: e.file,
                                          downloadId: file.id,
                                        },
                                      },
                                    });
                                  } else {
                                    await updateFile({
                                      variables: {
                                        input: {
                                          ...values,
                                          fileId:
                                            file.file && file.file.id
                                              ? file.file.id
                                              : undefined,
                                          downloadId: file.id,
                                        },
                                      },
                                    });
                                  }
                                  close();
                                }}
                                render={({ values }) => (
                                  <Form>
                                    <StyledPanelFormWrapper>
                                      <label htmlFor="name">File Name</label>
                                      <Field
                                        required
                                        id="name"
                                        name="name"
                                        placeholder="File Name"
                                        type="text"
                                      />
                                      <label htmlFor="file">File</label>
                                      <Field
                                        id="file"
                                        name="file"
                                        value={values.file}
                                        component={FileInput}
                                      />
                                      <S.Flex>
                                        <Field
                                          id="fpaNow"
                                          name="fpaNow"
                                          type="checkbox"
                                        />
                                        <label htmlFor="fpaNow">
                                          This is a FPA NOW document.
                                        </label>
                                      </S.Flex>
                                      <S.Flex>
                                        <Field
                                          id="report"
                                          name="report"
                                          type="checkbox"
                                        />
                                        <label htmlFor="report">
                                          This is a Report.
                                        </label>
                                      </S.Flex>
                                      <label htmlFor="acl">
                                        File Access Level
                                      </label>
                                      <Field
                                        required
                                        id="acl"
                                        name="acl"
                                        component={Select}
                                        closeMenuOnSelect={true}
                                        options={[
                                          {
                                            label: "Open To the Public",
                                            value: "PUBLIC",
                                          },
                                          {
                                            label: "Members Only",
                                            value: "MEMBERS",
                                          },
                                          {
                                            label:
                                              "Closed Access; Private Only.",
                                            value: "PRIVATE",
                                          },
                                        ]}
                                      />
                                      <label htmlFor="acl">
                                        File Description
                                      </label>
                                      <Field
                                        id="description"
                                        name="description"
                                        placeholder="A description of the uploaded file."
                                        component="textarea"
                                      />
                                      <button type="submit" disabled={loading}>
                                        Sav{loading ? "ing" : "e"}
                                      </button>
                                    </StyledPanelFormWrapper>
                                  </Form>
                                )}
                              />
                            );
                          }}
                        </Mutation>
                      )}
                    </>
                  )}
                </EditableContent>
              </S.Panel>
              <Notes subject={file} />
              <ActivityFeed subject={file} />
            </Page>
          </div>
        );
      }}
    </Query>
  );
};

export default Download;
