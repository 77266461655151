import React from "react";
import TopNav from "./TopNav";
import Page from "./Page";
import { SettingsBuyersGuide } from "./BuyersGuide";
import { SettingsGroups } from "./Groups";
import { SettingsStoreCategories } from "./StoreCategories";
import AchievementAwardCategories from "./AchievementAwardCategories";
import * as S from "./styles";

const Settings = () => {
  return (
    <Page>
      <TopNav />
      <SettingsGroups />
      <SettingsBuyersGuide />
      <S.Flex>
        <S.Box width={1 / 2}>
          <AchievementAwardCategories />
        </S.Box>
        <S.Box width={1 / 2}>
          {/* <SettingsStoreCategories /> */}
        </S.Box>
      </S.Flex>
    </Page>
  );
};

export default Settings;
