import React from "react";
import moment from "moment";
import * as S from "./styles";
import { useMutation } from "@apollo/react-hooks";
import Tags, { Tag } from "./Tags";
import CompanyMerge from "./CompanyMerge";
import { match } from "react-router-dom";
import Searchbar from "./Searchbar";
import { useMenuState, Menu, MenuDisclosure, MenuItem } from "./Menu";
import { CompanyTags } from "./Company";
import { MembershipTags } from "./CompanyMembership";
import PageNav, {
  NavInfo,
  BackButton,
  LocalNavA,
  LocalNav,
  TopPageNav,
} from "./PageNav";
import { Company, UseMutationReturn } from "./apiTypes";
import { Logo } from "./Image";
import {
  activateCompanyMutation,
  deactivateCompanyMutation,
} from "./mutations/company";
import { updateMembershipMutation } from "./mutations/membership";

type SubNavProps = {
  company: Company;
  match: match<{ companyId: string }>;
};

function EditCompanyMenu({ company }: { company: Company }) {
  const menu = useMenuState();
  const [activateCompany]: UseMutationReturn<
    { activateCompany: Company },
    { input: { companyId: string } }
  > = useMutation(activateCompanyMutation, {
    refetchQueries: ["companyQuery"],
  });
  const [deactivateCompany]: UseMutationReturn<
    { deactivateCompany: Company },
    { input: { companyId: string } }
  > = useMutation(deactivateCompanyMutation, {
    refetchQueries: ["companyQuery"],
  });
  const [updateMembership] = useMutation(updateMembershipMutation, {
    refetchQueries: ["companyQuery"],
  });

  return (
    <>
      <MenuDisclosure {...menu} as={S.Button}>
        Edit Company
      </MenuDisclosure>
      <Menu {...menu}>
        {/* <MenuItem {...menu}>Merge Company</MenuItem> */}
        {company.deactivatedAt ? (
          <MenuItem
            {...menu}
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to unarchive this Company?"
                )
              ) {
                await activateCompany({
                  variables: { input: { companyId: company.id } },
                });
                menu.hide();
              }
            }}
          >
            Unarchive Company
          </MenuItem>
        ) : (
          <MenuItem
            {...menu}
            onClick={async () => {
              if (
                window.confirm("Are you sure you want to archive this Company?")
              ) {
                await deactivateCompany({
                  variables: { input: { companyId: company.id } },
                });
                menu.hide();
              }
            }}
          >
            Archive Company
          </MenuItem>
        )}
        {/* <MenuItem
          {...menu}
          onClick={async () => {
            menu.hide();
            return <CompanyMerge company={company} />;
          }}
        >
          Merge Company
        </MenuItem> */}
      </Menu>
    </>
  );
}

const CompanyNav: React.FunctionComponent<SubNavProps> = ({
  match,
  company,
}) => {

  return (
    <PageNav>
      <S.Box pt={3} px={3} my={0}>
        <TopPageNav />
        <NavInfo>
          <S.Flex ml={3}>
            {company.logoUrl ? <Logo src={company.logoUrl} /> : <></>}
            <div>
              <S.H1>
                {company.name}{" "}
                {company.activeMembership ? (
                  company.activeMembership.startedOn ? (
                    <span>
                      member since{" "}
                      {moment(company.activeMembership.startedOn).format(
                        "MMMM D, YYYY"
                      )}
                    </span>
                  ) : (
                    <span>
                      applicant since{" "}
                      {moment(company.activeMembership.insertedAt).format(
                        "MMMM D, YYYY"
                      )}
                    </span>
                  )
                ) : (
                  <></>
                )}
              </S.H1>
              <Tags>
                <CompanyTags company={company} />
              </Tags>
            </div>
          </S.Flex>
          <EditCompanyMenu company={company} />
        </NavInfo>
        {company.mergedInto && company.mergedAt ? <S.P style={{color: "red"}}><b>This company merged into <S.A style={{color: "red"}} href={`/companies/${company.mergedInto.id}`}>{company.mergedInto.name}</S.A> on {moment(company.mergedAt).format("MMMM d, YYYY")}.</b></S.P> : <></>}
        <LocalNav>
          <LocalNavA to={`${match.url}/summary`}>Summary</LocalNavA>
          <LocalNavA to={`${match.url}/plants`}>Plants</LocalNavA>
          <LocalNavA to={`${match.url}/contacts`}>Contacts</LocalNavA>
          <LocalNavA to={`${match.url}/awards`}>Awards</LocalNavA>
          <LocalNavA to={`${match.url}/dues`}>Dues</LocalNavA>
        </LocalNav>
      </S.Box>
    </PageNav>
  );
};

export default CompanyNav;
