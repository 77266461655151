import { gql } from "graphql.macro";

export const activateCompanyMutation = gql`
  mutation activateCompanyMutation($input: ActivateCompanyInput!) {
    activateCompany(input: $input) {
      id
    }
  }
`;

export const createCompanyMutation = gql`
  mutation createCompanyMutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      description
      termsAcceptedAt
      federalIdNumber
      naicsCodes
      name
      numEmployees
      groups {
        id
      }
      id
      salesReports {
        id
      }
    }
  }
`;

export const deactivateCompanyMutation = gql`
  mutation deactivateCompanyMutation($input: DeactivateCompanyInput!) {
    deactivateCompany(input: $input) {
      id
    }
  }
`;

export const updateCompanyMutation = gql`
  mutation updateCompanyMutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      federalIdNumber
      naicsCodes
      name
      numEmployees
      description
      groups {
        id
      }
      buyersGuideCategories {
        id
      }
      termsAcceptedAt
    }
  }
`;
