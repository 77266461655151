import styled from "styled-components";
import { Menu, MenuItem } from "reakit/Menu";

const StyledMenu = styled(Menu)`
  background: #fff;
  box-shadow: 0 2px 8px rgba(118, 132, 158, 0.2);
  border-radius: 4px;
  padding: 4px 0;
  font-size: 14px;
  z-index: 3000;
`;

const StyledMenuItem = styled(MenuItem)`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  border: 0;
  padding: 4px 8px;
  outline: 0;

  &:focus {
    color: #fff;
    background: ${p => p.theme.colors.blue};
  }
`;

export { StyledMenu as Menu, StyledMenuItem as MenuItem };
export {
  useMenuState,
  MenuDisclosure,
  MenuSeparator,
  MenuGroup,
  MenuItemCheckbox,
  MenuItemRadio
} from "reakit/Menu";
