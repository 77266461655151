import { joinPath } from "./util";

const config = {
  appUrl: process.env.REACT_APP_URL || "http://localhost:3000",
  authRedirectUri: "",
  apiClientId: process.env.REACT_APP_API_CLIENT_ID || "",
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:4000"
};

config.authRedirectUri = joinPath(config.appUrl, "/auth/exchange");

export default config;
