import { gql } from "graphql.macro";

export const registrationListQuery = gql`
  query registrationListQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: RegistrationsOrderByInput
    $filter: RegistrationsFilterInput
  ) {
    registrations(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      count
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          cancelledAt
          completedAt
          contact {
            id
            firstName
            lastName
            companyMemberships {
              id
              company {
                id
                name
              }
            }
          }
          event {
            id
          }
          form {
            id
          }
          id
          insertedAt
          updatedAt
        }
      }
    }
  }
`;

export const registrationQuery = gql`
  query registrationQuery($registrationId: ID!) {
    registration(registrationId: $registrationId) {
      id
    }
  }
`;
