import React from "react";
import moment from "moment";
import UserImage from "./UserAvatar";
import Page from "./Page";
import { Formik, Field, Form, FieldArray } from "formik";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { PrimaryAddress } from "./Addresses";
import { PhoneNumberList } from "./PhoneNumbers";
import { EmailList } from "./Emails";
import { Tag } from "./Tags";
import {
  CompanyContact as CompanyContactType,
  UpdateContactWorkHistoryFormValues,
} from "./apiTypes";
import {
  updateCompanyContactMutation,
  deleteCompanyContactMutation,
} from "./mutations/companyContact";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";

type CompanyContactProps = {
  companyContact: CompanyContactType;
};

const CompanyContact: React.FunctionComponent<CompanyContactProps> = ({
  companyContact,
}) => {
  const editDialog = useDialogState();
  const [deleteCompanyContact] = useMutation(deleteCompanyContactMutation, {
    refetchQueries: ["companyQuery"],
  });
  const [updateCompanyContact, { loading }] = useMutation(
    updateCompanyContactMutation,
    {
      refetchQueries: ["companyQuery"],
    }
  );

  return (
    <S.Box width={1 / 3} mx={0} mb={3} key={companyContact.id}>
      <S.Panel pt={0} height="100%">
        {companyContact.endedOn ? (
          <Tag color="lightorange">Inactive</Tag>
        ) : (
          <Tag>Active</Tag>
        )}
        {companyContact.authorizedRepresentative ? (
          <Tag color="lightgreen">Authorized</Tag>
        ) : (
          <></>
        )}
        {companyContact.primaryContact ? (
          <Tag color="lightpurple">Primary</Tag>
        ) : (
          <></>
        )}
        {companyContact.secondaryContact ? (
          <Tag color="lightpurple">Secondary</Tag>
        ) : (
          <></>
        )}
        {companyContact.billingContact ? (
          <Tag color="lightyellow">Billing</Tag>
        ) : (
          <></>
        )}
        <S.Flex alignItems="flex-start" justifyContent="space-between">
          <Link to={`/contacts/${companyContact.contact.id}`}>
            <S.Flex my={2} alignItems="center">
              {companyContact.contact.photoUrl ? (
                <UserImage large src={companyContact.contact.photoUrl} />
              ) : (
                <></>
              )}
              <S.H3>
                {`${companyContact.contact.firstName} ${companyContact.contact.lastName}`}
                <span>{companyContact.title}</span>
              </S.H3>
            </S.Flex>
          </Link>
          <DialogDisclosure {...editDialog} as={S.Button} size="small">
            Edit
          </DialogDisclosure>
        </S.Flex>
        <PrimaryAddress subject={companyContact.contact} />
        <S.Flex my={3} mb={2} alignItems="center">
          <SVG.PhoneSvg />
          <PhoneNumberList subject={companyContact.contact} labelled />
        </S.Flex>
        <S.Flex my={3} mb={2} alignItems="center">
          <SVG.EmailSvg />
          <EmailList subject={companyContact.contact} labelled />
        </S.Flex>
        {companyContact.contact.birthday ? (
          <S.Flex my={3} mb={2} alignItems="center">
            <SVG.CakeSvg />
            {moment(companyContact.contact.birthday).format("MMMM D")}
          </S.Flex>
        ) : (
          <></>
        )}
      </S.Panel>
      <DialogWithBackdrop {...editDialog}>
        <Formik<UpdateContactWorkHistoryFormValues>
          initialValues={{
            startedOn: companyContact.startedOn
              ? companyContact.startedOn
              : undefined,
            endedOn: companyContact.endedOn
              ? companyContact.endedOn
              : undefined,
            title: companyContact.title ? companyContact.title : "",
            authorizedRepresentative: companyContact.authorizedRepresentative
              ? companyContact.authorizedRepresentative
              : false,
            primaryContact: companyContact.primaryContact
              ? companyContact.primaryContact
              : false,
            secondaryContact: companyContact.secondaryContact
              ? companyContact.secondaryContact
              : false,
            billingContact: companyContact.billingContact
              ? companyContact.billingContact
              : false,
            contactLevel: companyContact.billingContact
              ? companyContact.secondaryContact &&
                companyContact.secondaryContact === true
                ? "secondary/billing"
                : companyContact.primaryContact &&
                  companyContact.primaryContact === true
                ? "primary/billing"
                : "billing"
              : companyContact.secondaryContact &&
                companyContact.secondaryContact === true
              ? "secondary"
              : companyContact.primaryContact &&
                companyContact.primaryContact === true
              ? "primary"
              : companyContact.billingContact &&
                companyContact.billingContact === true
              ? "authorized"
              : "",
          }}
          onSubmit={async (values) => {
            if (values.endedOn && values.endedOn !== "") {
              values.endedOn = moment(values.endedOn).format("YYYY-MM-DD");
              values.billingContact = false;
              values.secondaryContact = false;
              values.primaryContact = false;
              values.authorizedRepresentative = false;
            } else {
              if (values.contactLevel === "primary/billing") {
                values.billingContact = true;
                values.primaryContact = true;
                values.secondaryContact = false;
                values.authorizedRepresentative = true;
              } else if (values.contactLevel === "secondary/billing") {
                values.billingContact = true;
                values.secondaryContact = true;
                values.primaryContact = false;
                values.authorizedRepresentative = true;
              } else if (values.contactLevel === "primary") {
                values.billingContact = false;
                values.primaryContact = true;
                values.secondaryContact = false;
                values.authorizedRepresentative = true;
              } else if (values.contactLevel === "secondary") {
                values.billingContact = false;
                values.secondaryContact = true;
                values.primaryContact = false;
                values.authorizedRepresentative = true;
              } else if (values.contactLevel === "billing") {
                values.billingContact = true;
                values.secondaryContact = false;
                values.primaryContact = false;
                values.authorizedRepresentative = true;
              } else if (values.contactLevel === "authorized") {
                values.billingContact = false;
                values.secondaryContact = false;
                values.primaryContact = false;
                values.authorizedRepresentative = true;
              } else {
                values.billingContact = false;
                values.secondaryContact = false;
                values.primaryContact = false;
                values.authorizedRepresentative = false;
              }
            }
            if (values.startedOn) {
              values.startedOn = moment(values.startedOn).format("YYYY-MM-DD");
            }
            await updateCompanyContact({
              variables: {
                input: {
                  companyContactId: companyContact.id,
                  startedOn: values.startedOn || null,
                  endedOn: values.endedOn || null,
                  title: values.title,
                  authorizedRepresentative: values.authorizedRepresentative,
                  primaryContact: values.primaryContact,
                  secondaryContact: values.secondaryContact,
                  billingContact: values.billingContact,
                },
              },
            });
            editDialog.hide();
          }}
          render={({ values }) => (
            <S.Box p={3} my={0}>
              <S.UnderlinedHeader>
                Edit Company Contact{" "}
                {companyContact.contact
                  ? `: ${companyContact.contact.firstName} ${companyContact.contact.lastName}`
                  : ``}
              </S.UnderlinedHeader>
              <StyledPanelFormWrapper>
                <Form>
                  <S.Box mb={1}>
                    <S.Flex>
                      <S.HalfWidth>
                        <label>
                          Starting Date
                          <span
                            style={{ color: "red", display: "inline-block" }}
                          >
                            *
                          </span>
                        </label>
                        <Field
                          required
                          id="startedOn"
                          name="startedOn"
                          type="date"
                          value={
                            values.startedOn
                              ? moment(values.startedOn).format("YYYY-MM-DD")
                              : undefined
                          }
                        />
                      </S.HalfWidth>
                      <S.HalfWidth>
                        <label>Ending Date</label>
                        <Field
                          id="endedOn"
                          name="endedOn"
                          type="date"
                          value={
                            values.endedOn
                              ? moment(values.endedOn).format("YYYY-MM-DD")
                              : undefined
                          }
                        />
                      </S.HalfWidth>
                    </S.Flex>
                    <label htmlFor="title">
                      Job Title
                      <span style={{ color: "red", display: "inline-block" }}>
                        *
                      </span>
                    </label>
                    <Field
                      required
                      id="title"
                      name="title"
                      placeholder="Title"
                      type="text"
                      value={values.title ? values.title : ""}
                    />
                    <label htmlFor="contactLevel">Contact Level</label>
                    <Field
                      id="contactLevel"
                      name="contactLevel"
                      component={Select}
                      closeMenuOnSelect={true}
                      options={[
                        { label: "None", value: "" },
                        {
                          label: "Primary & Billing Contact",
                          value: "primary/billing",
                        },
                        {
                          label: "Secondary & Billing Contact",
                          value: "secondary/billing",
                        },
                        {
                          label: "Primary Contact",
                          value: "primary",
                        },
                        {
                          label: "Secondary Contact",
                          value: "secondary",
                        },
                        {
                          label: "Billing Contact",
                          value: "billing",
                        },
                        {
                          label: "Authorized Contact",
                          value: "authorized",
                        },
                      ]}
                    />
                    <S.UnderlinedHeader>
                      <S.Flex>
                        <button type="submit" disabled={loading}>
                          Sav{loading ? "ing" : "e"}
                        </button>
                        <button
                          onClick={() => editDialog.hide()}
                          disabled={loading}
                        >
                          Cancel{loading ? "ling" : ""}
                        </button>
                      </S.Flex>
                    </S.UnderlinedHeader>
                    <button
                      type="reset"
                      onClick={async (e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            "Are you sure you want to delete this Company Contact?"
                          )
                        ) {
                          await deleteCompanyContact({
                            variables: {
                              input: {
                                companyContactId: companyContact.id,
                              },
                            },
                          });
                          editDialog.hide();
                        }
                        return false;
                      }}
                      disabled={loading}
                    >
                      Delete Company Contact
                    </button>
                  </S.Box>
                </Form>
              </StyledPanelFormWrapper>
            </S.Box>
          )}
        />
      </DialogWithBackdrop>
    </S.Box>
  );
};

export default CompanyContact;
