import styled, { css } from "styled-components";
import * as S from "./styles";

type UserImageProps = {
  large?: boolean;
  userIcon?: boolean;
};

const Avatar = styled.img<UserImageProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;

  ${p =>
    p.large &&
    css`
      height: 60px;
      width: 60px;
    `}
  ${p =>
    p.userIcon &&
    css`
      width: 150px;
      height: 150px;
    `}
`;

export const AvatarList = styled(S.Box)`
  ${Avatar} {
    margin-left: -20px;
  }
`;

AvatarList.defaultProps = {
  px: 3
};

export default Avatar;
