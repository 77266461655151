import styled from "styled-components";

const Logo = styled.img`
  max-height: 90px;
  max-width: 250px;
  margin: auto 0 auto 0;
`;

const Image = styled.img`
  max-height: 90px;
  max-width: 250px;
  border-radius: 4px;
  margin: auto 10px auto 0;
`;

export default Image;
export { Logo };
