import React from "react";
import { useEditToggle } from "./hooks";

type EditableContentRendererProps = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export type EditableContentProps = {
  children: (props: EditableContentRendererProps) => React.ReactElement;
};

function EditableContent({
  children
}: EditableContentProps): React.ReactElement {
  const props = useEditToggle();
  return children(props);
}

export default React.memo(EditableContent);
