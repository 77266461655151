import React, { useState } from "react";
import Select from "react-select";
import FileUpload from "./FileUpload";
import * as S from "./styles";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFetchResult } from "@apollo/react-common";
import { StyledPanelFormWrapper } from "./StyledForm";
import { createSalesReportMutation } from "./mutations/salesReport";
import { NewItemData, NewSalesReportValues } from "./apiTypes";
import moment from "moment";

const NewSalesReport: React.FC<{
  companyId: string;
  loading?: boolean;
  onSubmit: (
    input: NewSalesReportValues
  ) => Promise<void | MutationFetchResult<{ createSalesReport: NewItemData }>>;
}> = ({ loading, companyId, onSubmit }) => {
  const [newId, setNewId] = useState<string | null>(null);

  const handleSubmit = async (input: NewSalesReportValues) => {
    try {
      const result = await onSubmit(input);
      if (!result) throw "Could not create company for unknown reason.";
      if (result.errors) throw "GraphQL errors."; // TODO: Handle the errors
      if (
        result.data &&
        result.data.createSalesReport &&
        result.data.createSalesReport.id
      )
        return setNewId(result.data.createSalesReport.id);
      throw "Could not create company for unknown reason.";
    } catch (err) {
      // TODO: Handle errors and display them to the user
      console.error(err);
    }
  };

  const nextYear = parseInt(moment(new Date()).format("YYYY")) - 1;

  return (
    <Formik
      initialValues={{
        companyId: companyId,
        dollarAmount: 0,
        reportedOn: moment(new Date()).format("YYYY-MM-DD"),
        // status: "PENDING",
        year: parseInt(moment(new Date().setFullYear(nextYear)).format("YYYY")),
      }}
      onSubmit={handleSubmit}
      render={({ values }) => (
        <S.Box p={3} my={0}>
          <S.UnderlinedHeader>New Sales Report</S.UnderlinedHeader>
          <Form>
            <StyledPanelFormWrapper>
              <label htmlFor="year">
                Sales Year
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field id="year" name="year" type="number" />
              {/* <label>Report Status</label>
              <Field
                id="status"
                name="status"
                component={Select}
                closeMenuOnSelect={true}
                options={[
                  {
                    label: "PENDING",
                    value: "PENDING"
                  },
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "REJECTED", value: "REJECTED" }
                ]}
              /> */}
              <label htmlFor="dollarAmount">
                US Sales for this Year
                <span style={{ color: "red", display: "inline-block" }}>*</span>
              </label>
              <Field
                id="dollarAmount"
                name="dollarAmount"
                type="number"
                required
              />
              <S.Button type="submit" disabled={loading}>
                Creat{loading ? "ing" : "e"} Sales Report
              </S.Button>
            </StyledPanelFormWrapper>
          </Form>
        </S.Box>
      )}
    />
  );
};

const ConnectedNewSalesReport: React.FunctionComponent<{
  companyId: string;
}> = ({ companyId }) => {
  return (
    <Mutation<
      { createSalesReport: NewItemData },
      { input: NewSalesReportValues }
    >
      mutation={createSalesReportMutation}
      refetchQueries={["companyQuery"]}
    >
      {(createSalesReport, { loading }) => (
        <NewSalesReport
          companyId={companyId}
          onSubmit={(input) => createSalesReport({ variables: { input } })}
          loading={loading}
        />
      )}
    </Mutation>
  );
};

export default ConnectedNewSalesReport;
