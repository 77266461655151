import React from "react";
import styled from "styled-components";
import moment from "moment";
import * as S from "./styles";
import EditableContent from "./EditableContent";
import NewCompanyMembership from "./NewCompanyMembership";
import { Mutation } from "@apollo/react-components";
import { useMutation } from "@apollo/react-hooks";
import { Logo } from "./Image";
import { Tag } from "./Tags";
import { StyledPanelFormWrapper, Select } from "./StyledForm";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import { useDialogState, DialogWithBackdrop, DialogDisclosure } from "./Dialog";
import {
  Contact,
  CompanyContact,
  UpdateContactWorkHistoryFormValues,
} from "./apiTypes";
import {
  updateCompanyContactMutation,
  deleteCompanyContactMutation,
} from "./mutations/companyContact";

function ContactCompanyMemberships({ contact }: { contact: Contact }) {
  const addDialog = useDialogState();

  return (
    <S.Panel>
      <S.UnderlinedHeader noMargin>
        <S.Flex alignItems="center" justifyContent="space-between">
          Work History
          <DialogDisclosure {...addDialog} as={S.Button} size="small">
            Add Job
          </DialogDisclosure>
        </S.Flex>
      </S.UnderlinedHeader>
      <S.GroupList>
        {contact.companyMemberships &&
        contact.companyMemberships.length >= 1 ? (
          contact.companyMemberships.reverse().map((companyMembership) => {
            if (!companyMembership) return null;
            return (
              <ContactCompanyMembership
                key={companyMembership.id}
                contact={contact}
                companyMembership={companyMembership}
              />
            );
          })
        ) : (
          <S.Box>There are no jobs in the work history.</S.Box>
        )}
      </S.GroupList>
      <DialogWithBackdrop {...addDialog}>
        <NewCompanyMembership contact={contact} closeFunction={() => addDialog.hide()}/>
      </DialogWithBackdrop>
    </S.Panel>
  );
}

type ContactCompanyMembershipProps = {
  companyMembership: CompanyContact;
  contact: Contact;
};
const ContactCompanyMembership: React.FunctionComponent<ContactCompanyMembershipProps> = ({
  companyMembership,
  contact,
}) => {
  const [deleteCompanyContact] = useMutation(deleteCompanyContactMutation, {
    refetchQueries: ["ContactQuery"],
  });

  return (
    <li>
      <EditableContent>
        {({ isOpen, open, close }) => (
          <>
            {!isOpen ? (
              <S.Box pt={0}>
                <S.Flex justifyContent="space-between">
                  <div>
                    {companyMembership.company && (companyMembership.company.deactivatedAt || companyMembership.company.deactivatedAt === "") ? (
                      <Tag color="lightorange">Archived</Tag>
                    ) : ( 
                      <></>
                    )}
                    {companyMembership.endedOn ? (
                      <Tag color="lightorange">Inactive</Tag>
                    ) : (
                      <Tag>Active</Tag>
                    )}
                    {companyMembership.authorizedRepresentative ? (
                      <Tag color="lightgreen">Authorized</Tag>
                    ) : (
                      <></>
                    )}
                    {companyMembership.primaryContact ? (
                      <Tag color="lightpurple">Primary</Tag>
                    ) : (
                      <></>
                    )}
                    {companyMembership.secondaryContact ? (
                      <Tag color="lightpurple">Secondary</Tag>
                    ) : (
                      <></>
                    )}
                    {companyMembership.billingContact ? (
                      <Tag color="lightyellow">Billing</Tag>
                    ) : (
                      <></>
                    )}
                  </div>
                  <S.Button size="small" onClick={open}>
                    Edit
                  </S.Button>
                </S.Flex>
                <S.Title className="inline">
                  <span>
                    {moment(companyMembership.startedOn).format("MMMM D, YYYY")}{" "}
                    -{" "}
                    {companyMembership.endedOn
                      ? moment(companyMembership.endedOn).format("MMMM D, YYYY")
                      : `Present`}
                  </span>
                </S.Title>
                <S.Flex my={3} alignItems="center">
                  {companyMembership.company ? (
                    <Link to={`/companies/${companyMembership.company.id}`}>
                      <S.Flex my={2} alignItems="center">
                        {companyMembership.company.logoUrl && <Logo src={companyMembership.company.logoUrl} />}
                        <S.H3>
                          {companyMembership.company.name}
                          <span>{companyMembership.title}</span>
                        </S.H3>
                      </S.Flex>
                    </Link>
                  ) : (
                    <></>
                  )}
                </S.Flex>
              </S.Box>
            ) : (
              <Mutation<any>
                mutation={updateCompanyContactMutation}
                refetchQueries={["ContactQuery"]}
              >
                {(updateCompanyContact, { loading }) => {
                  return (
                    <Formik<UpdateContactWorkHistoryFormValues>
                      initialValues={{
                        startedOn: companyMembership.startedOn
                          ? companyMembership.startedOn
                          : undefined,
                        endedOn: companyMembership.endedOn
                          ? companyMembership.endedOn
                          : undefined,
                        title: companyMembership.title
                          ? companyMembership.title
                          : "",
                        authorizedRepresentative: companyMembership.authorizedRepresentative
                          ? companyMembership.authorizedRepresentative
                          : false,
                        primaryContact: companyMembership.primaryContact
                          ? companyMembership.primaryContact
                          : false,
                        secondaryContact: companyMembership.secondaryContact
                          ? companyMembership.secondaryContact
                          : false,
                        billingContact: companyMembership.billingContact
                          ? companyMembership.billingContact
                          : false,
                        contactLevel:
                          companyMembership.secondaryContact &&
                          companyMembership.secondaryContact === true
                            ? "secondary"
                            : companyMembership.primaryContact &&
                              companyMembership.primaryContact === true
                            ? "primary"
                            : "",
                      }}
                      onSubmit={async (values) => {
                        if (values.endedOn && values.endedOn !== "") {
                          values.endedOn = moment(values.endedOn).format(
                            "YYYY-MM-DD"
                          );
                          values.billingContact = false;
                          values.secondaryContact = false;
                          values.primaryContact = false;
                          values.authorizedRepresentative = false;
                        } else {
                          if (values.contactLevel === "primary/billing") {
                            values.billingContact = true;
                            values.primaryContact = true;
                            values.secondaryContact = false;
                            values.authorizedRepresentative = true;
                          } else if (
                            values.contactLevel === "secondary/billing"
                          ) {
                            values.billingContact = true;
                            values.secondaryContact = true;
                            values.primaryContact = false;
                            values.authorizedRepresentative = true;
                          } else if (values.contactLevel === "primary") {
                            values.billingContact = false;
                            values.primaryContact = true;
                            values.secondaryContact = false;
                            values.authorizedRepresentative = true;
                          } else if (values.contactLevel === "secondary") {
                            values.billingContact = false;
                            values.secondaryContact = true;
                            values.primaryContact = false;
                            values.authorizedRepresentative = true;
                          } else if (values.contactLevel === "billing") {
                            values.billingContact = true;
                            values.secondaryContact = false;
                            values.primaryContact = false;
                            values.authorizedRepresentative = true;
                          } else if (values.contactLevel === "authorized") {
                            values.billingContact = false;
                            values.secondaryContact = false;
                            values.primaryContact = false;
                            values.authorizedRepresentative = true;
                          } else {
                            values.billingContact = false;
                            values.secondaryContact = false;
                            values.primaryContact = false;
                            values.authorizedRepresentative = false;
                          }
                        }
                        if (values.startedOn) {
                          values.startedOn = moment(values.startedOn).format(
                            "YYYY-MM-DD"
                          );
                        }
                        await updateCompanyContact({
                          variables: {
                            input: {
                              companyContactId: companyMembership.id,
                              startedOn: values.startedOn || null,
                              endedOn: values.endedOn || null,
                              title: values.title,
                              authorizedRepresentative:
                                values.authorizedRepresentative,
                              primaryContact: values.primaryContact,
                              secondaryContact: values.secondaryContact,
                            },
                          },
                        });
                        close();
                      }}
                      render={({ values }) => (
                        <StyledPanelFormWrapper>
                          <Form>
                            <S.Box mb={1}>
                              <S.Flex>
                                <S.HalfWidth>
                                  <label>
                                    Starting Date
                                    <span
                                      style={{
                                        color: "red",
                                        display: "inline-block",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <Field
                                    required
                                    id="startedOn"
                                    name="startedOn"
                                    type="date"
                                    value={
                                      values.startedOn
                                        ? moment(values.startedOn).format(
                                            "YYYY-MM-DD"
                                          )
                                        : undefined
                                    }
                                  />
                                </S.HalfWidth>
                                <S.HalfWidth>
                                  <label>Ending Date</label>
                                  <Field
                                    id="endedOn"
                                    name="endedOn"
                                    type="date"
                                    value={
                                      values.endedOn
                                        ? moment(values.endedOn).format(
                                            "YYYY-MM-DD"
                                          )
                                        : undefined
                                    }
                                  />
                                </S.HalfWidth>
                              </S.Flex>
                              <label htmlFor="title">
                                Job Title
                                <span
                                  style={{
                                    color: "red",
                                    display: "inline-block",
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <Field
                                required
                                id="title"
                                name="title"
                                placeholder="Title"
                                type="text"
                                value={values.title ? values.title : ""}
                              />
                              <label htmlFor="contactLevel">
                                Contact Level
                              </label>
                              <Field
                                id="contactLevel"
                                name="contactLevel"
                                component={Select}
                                closeMenuOnSelect={true}
                                options={[
                                  { label: "None", value: "" },
                                  {
                                    label: "Primary & Billing Contact",
                                    value: "primary/billing",
                                  },
                                  {
                                    label: "Secondary & Billing Contact",
                                    value: "secondary/billing",
                                  },
                                  {
                                    label: "Primary Contact",
                                    value: "primary",
                                  },
                                  {
                                    label: "Secondary Contact",
                                    value: "secondary",
                                  },
                                  {
                                    label: "Billing Contact",
                                    value: "billing",
                                  },
                                  {
                                    label: "Authorized Contact",
                                    value: "authorized",
                                  },
                                ]}
                              />
                              <S.UnderlinedHeader>
                                <S.Flex>
                                  <button type="submit" disabled={loading}>
                                    Sav{loading ? "ing" : "e"}
                                  </button>
                                  <button
                                    onClick={() => close()}
                                    disabled={loading}
                                  >
                                    Cancel{loading ? "ling" : ""}
                                  </button>
                                </S.Flex>
                              </S.UnderlinedHeader>
                              <button
                                type="reset"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this Company Contact?"
                                    )
                                  ) {
                                    await deleteCompanyContact({
                                      variables: {
                                        input: {
                                          companyContactId:
                                            companyMembership.id,
                                        },
                                      },
                                    });
                                    close();
                                  }
                                  return false;
                                }}
                                disabled={loading}
                              >
                                Delete Company Contact
                              </button>
                            </S.Box>
                          </Form>
                        </StyledPanelFormWrapper>
                      )}
                    />
                  );
                }}
              </Mutation>
            )}
          </>
        )}
      </EditableContent>
    </li>
  );
};

export default ContactCompanyMemberships;
