import { gql } from "graphql.macro";

export const createCompanyContactMutation = gql`
  mutation createCompanyContactMutation($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      authorizedRepresentative
      company {
        id
      }
      contact {
        id
      }
      endedOn
      primaryContact
      secondaryContact
      startedOn
      title
      id
    }
  }
`;

export const deleteCompanyContactMutation = gql`
  mutation deleteCompanyContactMutation($input: DeleteCompanyContactInput!) {
    deleteCompanyContact(input: $input) {
      id
    }
  }
`;

export const updateCompanyContactMutation = gql`
  mutation updateCompanyContactMutation($input: UpdateCompanyContactInput!) {
    updateCompanyContact(input: $input) {
      authorizedRepresentative
      endedOn
      primaryContact
      secondaryContact
      startedOn
      title
      id
    }
  }
`;
