import { gql } from "graphql.macro";

export const archiveBuyersGuideCategoryMutation = gql`
  mutation archiveBuyersGuideCategoryMutation(
    $input: ArchiveBuyersGuideCategoryInput!
  ) {
    archiveBuyersGuideCategory(input: $input) {
      id
    }
  }
`;

export const createBuyersGuideCategoryMutation = gql`
  mutation createBuyersGuideCategoryMutation(
    $input: CreateBuyersGuideCategoryInput!
  ) {
    createBuyersGuideCategory(input: $input) {
      name
      parentId
    }
  }
`;

export const unarchiveBuyersGuideCategoryMutation = gql`
  mutation unarchiveBuyersGuideCategoryMutation(
    $input: UnarchiveBuyersGuideCategoryInput!
  ) {
    unarchiveBuyersGuideCategory(input: $input) {
      id
    }
  }
`;

export const updateBuyersGuideCategoryMutation = gql`
  mutation updateBuyersGuideCategoryMutation(
    $input: UpdateBuyersGuideCategoryInput!
  ) {
    updateBuyersGuideCategory(input: $input) {
      id
      name
      parentId
    }
  }
`;
