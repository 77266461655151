import React from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { ApolloProvider } from "@apollo/react-common";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/Auth";
import AuthExchanger from "./Auth/AuthExchanger";
import AuthInitiator from "./Auth/AuthInitiator";
import UnauthedMessage from "./Auth/UnauthedMessage";
import ForbiddenMessage from "./Auth/ForbiddenMessage";
import Main from "./Main";
import MainNav from "./MainNav";
import * as theme from "./theme";
import client from "./apollo";

const AppWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 14px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    line-height: 1.4;
    color: ${theme.colors.grey};
    background: ${theme.colors.offwhite};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  h1, h2, h3, h4 {
    color: ${theme.colors.grey};
    margin: 0;
  }
  h1 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.25;
    text-transform: uppercase;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1;
    text-transform: uppercase;
  }
  a {
    transition: all 0.3s;
    text-decoration: none;
    color: ${theme.colors.grey};
  }
  button, input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

`;

const Content = () => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route exact path="/login" component={AuthInitiator} />
        <Route exact path="/auth/exchange" component={AuthExchanger} />
        <Route component={UnauthedMessage} />
      </Switch>
    );
  }

  if (user && !user.admin) {
    return <Route component={ForbiddenMessage} />;
  }

  return (
    <AppWrapper>
      <MainNav />
      <Main />
    </AppWrapper>
  );
};

const App = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <>
              <GlobalStyle />
              <Content />
            </>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
